import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Contact() {
  const { t } = useTranslation();
  return (
    <div className="container">
      <h2 className="h4 with-bar">{t('Contact Information')}</h2>
      <p className="body1 text-white">{t('Contact Information - Bodytext 1')}</p>
    </div>
  );
}
