import React from 'react';
import imprintDe from './imprint-de.md';
import ReactMarkdown from 'react-markdown';
import { Box, Container, Typography } from '@material-ui/core';
import useMarkdown from '../utils/use-markdown';
import AppBackLink from '../components/BackLink';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export default function Imprint() {
  const { t } = useTranslation();
  const [text] = useMarkdown(imprintDe);

  return (
    <Container className="article">
      <Helmet>
        <title>{t('imprint-title')}</title>
        <meta name="description" content={t('imprint-description')} />
      </Helmet>
      <AppBackLink to="/" />
      <Box mb={4}>
        <Typography variant="h1" className="with-bar terms-bar">
          Impressum
        </Typography>
      </Box>
      {text && <ReactMarkdown children={text} />}
    </Container>
  );
}
