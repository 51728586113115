import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { RatingControllerSendRatingParameter } from '../../parameters/rating-controller-send-rating.parameter';
import { RatingControllerSendRatingRequestBody } from '../../request-bodies/rating-controller-send-rating.request-body';
import { RatingControllerSendRatingResponse } from '../../responses/rating-controller-send-rating.response';

interface RatingControllerSendRatingOpenApiParameters {
  parameters: RatingControllerSendRatingParameter;
  requestBody: RatingControllerSendRatingRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.POST,
  apiName: 'customer',
  path: '/tailor/{tailorId}/rating',
  operationId: 'RatingController_sendRating',
  parameters: [
    {
      name: 'tailorId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class RatingControllerSendRatingMethod
  extends BaseOpenApiMethod<RatingControllerSendRatingResponse>
  implements Method<RatingControllerSendRatingResponse, RatingControllerSendRatingOpenApiParameters>
{
  public call(parameters: RatingControllerSendRatingOpenApiParameters): Promise<RatingControllerSendRatingResponse> {
    return super.call(parameters);
  }
}
