import React from 'react';
import { Link } from '@reach/router';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ReactComponent as Arrow } from './arrow.svg';
import BlogItem from '../../interfaces/item';
import classes from './Card.module.css';
import { getPage } from '../../../utils/pages';

export default function BlogCard({ id, title, summary, imageUrl, youtubeVideoUrl }: BlogItem) {
  return (
    <Link to={`${getPage('blogList')}/${id}`} className={classes.card}>
      <div className={classes.imageBlock}>
        <div className={classes.imageWrapper}>
          {imageUrl && (
            <LazyLoadImage
              className={classes.image}
              alt={title}
              src={imageUrl}
              effect="blur"
              // height={image.height}
              // width={image.width}
            />
          )}
          {youtubeVideoUrl && (
            <iframe
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className={classes.videoIframe}
              frameBorder="0"
              height="315"
              src={`${youtubeVideoUrl}?rel=0`}
              title={title}
              width="560"
            />
          )}
          <div className={classes.mediaOverlay} />
        </div>
        <div title={title} className={classes.title}>
          {title}
        </div>
      </div>
      <div className={classes.summaryBlock}>
        <div className={classes.summary}>{summary}</div>
        <div className={classes.readMoreBlock}>
          <Arrow title="Read More" />
        </div>
      </div>
    </Link>
  );
}
