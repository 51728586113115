import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { ProfileControllerAddAddressRequestBody } from '../../request-bodies/profile-controller-add-address.request-body';
import { ProfileControllerAddAddressResponse } from '../../responses/profile-controller-add-address.response';

interface ProfileControllerAddAddressOpenApiParameters {
  requestBody: ProfileControllerAddAddressRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.PATCH,
  apiName: 'customer',
  path: '/profile/address-list',
  operationId: 'ProfileController_addAddress',
  parameters: [],
})
export class ProfileControllerAddAddressMethod
  extends BaseOpenApiMethod<ProfileControllerAddAddressResponse>
  implements Method<ProfileControllerAddAddressResponse, ProfileControllerAddAddressOpenApiParameters>
{
  public call(parameters: ProfileControllerAddAddressOpenApiParameters): Promise<ProfileControllerAddAddressResponse> {
    return super.call(parameters);
  }
}
