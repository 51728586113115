import CartApi from '../interfaces/api';
import { v4 as uuid } from 'uuid';
import API_SERVICE from '../../api/api.service';
import { CartControllerMigrateParameter } from '../../api/parameters/cart-controller-migrate.parameter';
import appRequest from '../../utils/request';
import { clearDiscountFromStorage } from '../utils';
import { Repair } from '../../types';

async function fetchCartItems(): Promise<Repair[]> {
  const { data } = await appRequest('cart');
  return data.items;
}

async function pushCartItem(productId: string, garmentId: string, quantity?: number, comment?: string, voucherId?: string): Promise<Repair> {
  const requestBody = {
    productId,
    voucherId,
    garmentId,
    comment: comment ?? '',
    value: quantity ? quantity.toString() : '0',
  };
  const { data } = await appRequest('cart', {
    method: 'POST',
    data: requestBody,
  });
  return data;
}

/**
 * Cart API definitions for production use.
 *
 * These definitions can only be covered by end-to-end (e2e) tests.
 */
const cartApiProd: CartApi = {
  addItem: async (productId: string, quantity?: number, comment?: string) => {
    return await pushCartItem(productId, uuid(), quantity, comment);
  },
  addItemToGarment: async (garmentId: string, productId: string, quantity?: number, comment?: string) => {
    return await pushCartItem(productId, garmentId, quantity, comment);
  },
  addVoucherToCart: async (voucherId: string, garmentId: string) => {
    return await pushCartItem(voucherId, garmentId);
  },
  clearCart: async () => {
    //await API_SERVICE.cartController.clearCart.call();
    await appRequest('cart', {
      method: 'DELETE',
    });
    // TODO: disable this method
  },
  deleteItem: async (itemId: string) => {
    await API_SERVICE.cartController.removeCartItem.call({ parameters: { itemId } });
  },
  fetchCartConstants: async () => {
    const numBoxes = localStorage.getItem('numBoxes');
    const { data } = await appRequest('cart-constants');
    return {
      numBoxes: numBoxes === null ? 0 : +numBoxes,
      servicePrices: {
        boxPriceCent: data.boxPriceCent,
        courierPriceCent: data.courierPriceCent,
        shippingExemptionThreshold: data.shippingExemptionThreshold,
      },
    };
  },
  fetchCart: () => {
    const numBoxes = localStorage.getItem('numBoxes');

    if (!numBoxes) {
      localStorage.setItem('numBoxes', '0');
    }

    const itemsRequest = fetchCartItems();
    const constantsRequest = API_SERVICE.cartConstantsController.get.call();
    return Promise.all([itemsRequest, constantsRequest]).then(([items, constants]) => ({
      items,
      discount: null,
      voucherDiscount: null,
      numBoxes: numBoxes === null ? 0 : +numBoxes,
      servicePrices: {
        boxPriceCent: constants.boxPriceCent,
        courierPriceCent: constants.courierPriceCent,
        shippingExemptionThreshold: constants.shippingExemptionThreshold,
      },
      delivery: {
        shippingOrder: true,
        doorToDoorOrder: false,
        city: null,
      },
      voucher: false,
      voucherItems: [],
      creationTime: 0
    }));
  },
  fetchItems: async () => {
    return await fetchCartItems();
  },
  migrateCart: async (previousUserId: string | null = null) => {
    const parameters: CartControllerMigrateParameter = {};
    if (previousUserId) {
      parameters.previousUserId = previousUserId;
    }
    await API_SERVICE.cartController.migrate.call({ parameters });
  },
  updateNumBoxes: async (numBoxes: number) => {
    localStorage.setItem('numBoxes', `${numBoxes}`);
  },
  getDiscount: async (code: string) => {
    const { data, status } = await appRequest(`cart/discount/${code}`, {
      method: 'GET',
    });

    const { message, percent, _id } = data;

    if (status === 500) {
      throw new Error(message);
    }

    localStorage.setItem('discountCode', code);
    localStorage.setItem('discount', percent);
    localStorage.setItem('discountId', _id);
    return data;
  },
  putDiscount: async () => {
    //await appRequest(`cart/discount/${discountId}`, {
    //  method: 'PUT',
    //});
    clearDiscountFromStorage();
  },
};

export default cartApiProd;
