import React, { ElementType } from 'react';
import CartVoucher from '../Voucher/CartVoucher';
import { VoucherItem } from 'Voucher/intefaces/item';

interface CartGarmentProps {
  component?: ElementType;
  disableRemove?: boolean;
  allowRepairAddition?: boolean;
  withoutGarmentButton?: boolean;
  voucherItems: VoucherItem[];
}

/**
 * Cart garment (card) controller component.
 */
export default function VoucherGarment({ disableRemove = false, voucherItems }: CartGarmentProps) {
  return (
    <>
      {voucherItems.map((x, i) => (
        <CartVoucher key={i} voucherIndex={i} component="li" disableRemove={disableRemove} voucherItem={x} />
      ))}
    </>
  );
}
