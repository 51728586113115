/**
 * Markdown
 */
const diesesMalMacheIchAllesRichtigBodyEn = `

 ## Cashmere Wool
 
 Anyone who has worn a cashmere sweater understands why many consider this special natural fiber to be one of the most comfortable of all. Cashmere is known to be one of the noblest wools in the world.
 
 But why is it so? The wool comes from the so-called cashmere goats, which can be combed only once a year. Before utilization, the wool obtained goes through an elaborate separation process. Thus, after sorting, you get a tiny amount of cashmere wool. On average, four goats are needed for your cashmere sweater. If you compare this with sheep's wool, you quickly realize how little this actually is. One sheep provides wool for about 15 sweaters.
 
 ## Washing your cashmere sweater? Not always necessary!
 
 You are afraid to wash your cashmere sweater? Understandable, because if washed incorrectly, it can quickly shrink, lose its shape or become matted. Stick to a few simple rules of thumb and you're guaranteed nothing will go wrong next time!
 
 First of all: Merino and cashmere wool are very high-quality materials that absorb odors and can store heat particularly well. Similar to our own hair, wool also wants to be cared for carefully. Learn how to care for it here:
 
 But before you get started, keep in mind that your favourite sweater doesn't actually need to be washed that often. Wool is naturally dirt-repellent. To maintain this protection and keep your sweater looking new for a long time, you should avoid washing it whenever possible. It is recommended to wash your sweater only after the tenth time of wearing it. Between washes it is enough to air out your sweater to remove unwanted odors - especially if there are no visible stains. Simply hang your sweater on a hanger and let it air out overnight, preferably outdoors in the fresh air.
 
 ## Not washing at all is not an option? But please be careful!
 
 ### 1. Hand washing
 
 The gentlest way to wash is by hand. Use a special detergent or alternatively a mild shampoo (yes, the same one you use for your hair). Soak the garment in clear water with the detergent for about 30 minutes. Make sure that the water temperature is below 30 degrees. To prevent your sweater from swelling, don't leave it in the water bath much longer after washing. Rinse the garment with clean water afterwards. Do this with as little friction as possible. When you're done, just squeeze it gently with your hands, and avoid wringing or distorting the sweater.
 
 Since we want to preserve the original shape of your sweater, you should let the wet garment dry lying down, for example on a towel. Avoid direct sunlight or heating - even if you want to do it quickly.
 
 This way your favorite sweater will still look as good as new next year!
 
 ### 2. Machine wash
 
 In addition to washing by hand, you can also use the hand or wool washing program of the washing machine at a maximum of 30 degrees Celsius. Here you should pay attention to the following: We are used to turning our clothes inside out during the washing process. But our beloved cashmere sweater doesn't like that at all, so you should skip this step. It is best to wash only in small quantities, more than three to four cashmere pieces should not end up together in one drum. Also, make sure to wash at low speed (maximum 600 rpm) - and no spinning, please! The detergent should be a special detergent for cashmere and fabric softener should strictly be avoided!
 
 ## Care Tips
 
 Proper care after washing will also help your sweater last longer. Here are three more tips from us:
 
 Actually, ironing cashmere sweaters is not really necessary. But if it should be necessary, you can put a light cotton cloth in between to avoid direct contact between the iron and the sweater. We also recommend ironing on the lowest setting.
 
 But proper care also continues in the closet. Although we quickly tend to hang our "good" clothes on the hanger, caution is needed with cashmere garments. The best way to store them is to lay them down. To do this, you can fold them loosely and refrain from stacking other garments on top.
 
 Just like all wool sweaters, cashmere sweaters can develop small knots in the places where natural friction occurs. But here you should not reach for tweezers or scissors - There are tools that are specially designed for the sensitivity of your cashmere sweater - for example, a cashmere comb or pilling razor. These are really worth investing in.
 
 > "After wearing your sweater, simply hang it on a hanger and let it air overnight, preferably outside in the fresh air.“
 
 `;

export default diesesMalMacheIchAllesRichtigBodyEn;
