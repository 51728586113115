import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { OrderControllerUpdatePickupAndDeliveryParameter } from '../../parameters/order-controller-update-pickup-and-delivery.parameter';
import { OrderControllerUpdatePickupAndDeliveryRequestBody } from '../../request-bodies/order-controller-update-pickup-and-delivery.request-body';

interface OrderControllerUpdatePickupAndDeliveryOpenApiParameters {
  parameters: OrderControllerUpdatePickupAndDeliveryParameter;
  requestBody: OrderControllerUpdatePickupAndDeliveryRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.PATCH,
  apiName: 'customer',
  path: '/order/reschedule-pickup-and-delivery?orderId={orderId}&{customerId}&{transportId}',
  operationId: 'OrderController_updatePickupAndDelivery',
  parameters: [
    {
      name: 'orderId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
    {
      name: 'customerId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
    {
      name: 'transportId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class OrderControllerUpdatePickupAndDeliveryMethod
  extends BaseOpenApiMethod<void>
  implements Method<void, OrderControllerUpdatePickupAndDeliveryOpenApiParameters>
{
  public call(parameters: OrderControllerUpdatePickupAndDeliveryOpenApiParameters): Promise<void> {
    return super.call(parameters);
  }
}
