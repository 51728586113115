import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { AuthControllerGetUserRoleByEmailParameter } from '../../parameters/auth-controller-get-user-role-by-email.parameter';

interface AuthControllerGetUserRoleByEmailOpenApiParameters {
  parameters: AuthControllerGetUserRoleByEmailParameter;
}

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/auth/role-by-email',
  operationId: 'AuthController_getUserRoleByEmail',
  parameters: [
    {
      name: 'email',
      required: true,
      in: OpenApiParameterType.QUERY,
    },
  ],
})
export class AuthControllerGetUserRoleByEmailMethod extends BaseOpenApiMethod<void> implements Method<void, AuthControllerGetUserRoleByEmailOpenApiParameters> {
  public call(parameters: AuthControllerGetUserRoleByEmailOpenApiParameters): Promise<void> {
    return super.call(parameters);
  }
}
