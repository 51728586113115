import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { DiscountControllerGetPaginatedParameter } from '../../parameters/discount-controller-get-paginated.parameter';
import { DiscountControllerGetPaginatedResponse } from '../../responses/discount-controller-get-paginated.response';

interface DiscountControllerGetPaginatedOpenApiParameters {
  parameters: DiscountControllerGetPaginatedParameter;
}

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/discount',
  operationId: 'DiscountController_getPaginated',
  parameters: [
    {
      name: 'lastEvaluatedKey',
      required: false,
      in: OpenApiParameterType.QUERY,
    },
    {
      name: 'limit',
      required: false,
      in: OpenApiParameterType.QUERY,
    },
  ],
})
export class DiscountControllerGetPaginatedMethod
  extends BaseOpenApiMethod<DiscountControllerGetPaginatedResponse>
  implements Method<DiscountControllerGetPaginatedResponse, DiscountControllerGetPaginatedOpenApiParameters>
{
  public call(parameters?: DiscountControllerGetPaginatedOpenApiParameters): Promise<DiscountControllerGetPaginatedResponse> {
    return super.call(parameters);
  }
}
