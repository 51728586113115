import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { HealthControllerMjmlStorageResponse } from '../../responses/health-controller-mjml-storage.response';

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/health/storage/mjml',
  operationId: 'HealthController_mjmlStorage',
  parameters: [],
})
export class HealthControllerMjmlStorageMethod
  extends BaseOpenApiMethod<HealthControllerMjmlStorageResponse>
  implements Method<HealthControllerMjmlStorageResponse, void>
{
  public call(): Promise<HealthControllerMjmlStorageResponse> {
    return super.call();
  }
}
