import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { CustomerControllerGetPaginatedParameter } from '../../parameters/customer-controller-get-paginated.parameter';
import { CustomerControllerGetPaginatedResponse } from '../../responses/customer-controller-get-paginated.response';

interface CustomerControllerGetPaginatedOpenApiParameters {
  parameters: CustomerControllerGetPaginatedParameter;
}

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/customer',
  operationId: 'CustomerController_getPaginated',
  parameters: [
    {
      name: 'lastEvaluatedKey',
      required: false,
      in: OpenApiParameterType.QUERY,
    },
    {
      name: 'limit',
      required: false,
      in: OpenApiParameterType.QUERY,
    },
  ],
})
export class CustomerControllerGetPaginatedMethod
  extends BaseOpenApiMethod<CustomerControllerGetPaginatedResponse>
  implements Method<CustomerControllerGetPaginatedResponse, CustomerControllerGetPaginatedOpenApiParameters>
{
  public call(parameters?: CustomerControllerGetPaginatedOpenApiParameters): Promise<CustomerControllerGetPaginatedResponse> {
    return super.call(parameters);
  }
}
