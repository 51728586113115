import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { ProductControllerSetByIdRequestBody } from '../../request-bodies/product-controller-set-by-id.request-body';

interface ProductControllerSetByIdOpenApiParameters {
  requestBody: ProductControllerSetByIdRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.PUT,
  apiName: 'customer',
  path: '/product/{productId}',
  operationId: 'ProductController_setById',
  parameters: [],
})
export class ProductControllerSetByIdMethod extends BaseOpenApiMethod<void> implements Method<void, ProductControllerSetByIdOpenApiParameters> {
  public call(parameters: ProductControllerSetByIdOpenApiParameters): Promise<void> {
    return super.call(parameters);
  }
}
