import ImageFile from '../interfaces/imageInterface';
import ImageMinimalInterface from '../interfaces/imageMinimalInterface';

import {
  ADD_IMAGES,
  REMOVE_IMAGE,
  CLEAR_IMAGES,
  UPDATE_IMAGE,
  UPLOAD_IMAGES,
  MARK_FILE_UPLOAD_COMPLETED,
  MARK_FILE_UPLOAD_FAILED,
  MARK_FILE_UPLOAD_STARTED,
  MARK_ALL_UPLOADS_STARTED,
  MARK_SOME_UPLOADS_FAILED,
  MARK_ALL_UPLOADS_COMPLETED,
  CLEAR_STATE,
  MARK_SUBMIT_FAILED,
  MARK_SUBMIT_STARTED,
  RETRY_UPLOAD,
  REMOVE_SIZE_ERROR,
  SET_SIZE_ERROR,
  ADD_FIRST_NAME,
  ADD_LAST_NAME,
} from './types';

function updateImageFile(imageFile: ImageFile) {
  return {
    type: UPDATE_IMAGE,
    payload: {
      imageFile,
    },
  };
}

function addImageFiles(imageFiles: ImageFile[]) {
  return {
    type: ADD_IMAGES,
    payload: {
      imageFiles,
    },
  };
}

function removeImageFile(imageFile: ImageMinimalInterface) {
  return {
    type: REMOVE_IMAGE,
    payload: {
      imageFile,
    },
  };
}

function setFirstName(firstName: string) {
  return {
    type: ADD_FIRST_NAME,
    payload: {
      firstName,
    },
  };
}

function setLastName(lastName: string) {
  return {
    type: ADD_LAST_NAME,
    payload: {
      lastName,
    },
  };
}

function clearImagesFiles() {
  return {
    type: CLEAR_IMAGES,
  };
}

function markAllUploadsStarted() {
  return {
    type: MARK_ALL_UPLOADS_STARTED,
  };
}

function markSomeUploadsFailed() {
  return {
    type: MARK_SOME_UPLOADS_FAILED,
  };
}

function markAllUploadsCompleted() {
  return {
    type: MARK_ALL_UPLOADS_COMPLETED,
  };
}

function uploadImagesFiles() {
  return {
    type: UPLOAD_IMAGES,
  };
}

function markFileUploadCompleted(id: string) {
  return {
    type: MARK_FILE_UPLOAD_COMPLETED,
    payload: {
      id,
    },
  };
}

function markFileUploadFailed(id: string) {
  return {
    type: MARK_FILE_UPLOAD_FAILED,
    payload: {
      id,
    },
  };
}

function markFileUploadStarted(id: string) {
  return {
    type: MARK_FILE_UPLOAD_STARTED,
    payload: {
      id,
    },
  };
}

function clearState() {
  return {
    type: CLEAR_STATE,
  };
}

function markSubmitAsFailed() {
  return {
    type: MARK_SUBMIT_FAILED,
  };
}

function markSubmitAsStarted() {
  return {
    type: MARK_SUBMIT_STARTED,
  };
}

function retryUpload() {
  return {
    type: RETRY_UPLOAD,
  };
}

function setSizeError(id: string) {
  return {
    type: SET_SIZE_ERROR,
    payload: {
      id,
    },
  };
}

function removeSizeError(id: string) {
  return {
    type: REMOVE_SIZE_ERROR,
    payload: {
      id,
    },
  };
}

export {
  removeSizeError,
  removeImageFile,
  setSizeError,
  retryUpload,
  markSomeUploadsFailed,
  markSubmitAsFailed,
  markSubmitAsStarted,
  markFileUploadFailed,
  markFileUploadStarted,
  markFileUploadCompleted,
  markAllUploadsStarted,
  markAllUploadsCompleted,
  clearState,
  clearImagesFiles,
  updateImageFile,
  uploadImagesFiles,
  addImageFiles,
  setFirstName,
  setLastName,
};
