import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { CartConstantsControllerGetBoxPriceCentResponse } from '../../responses/cart-constants-controller-get-box-price-cent.response';

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/cart-constants/box-price-cent',
  operationId: 'CartConstantsController_getBoxPriceCent',
  parameters: [],
})
export class CartConstantsControllerGetBoxPriceCentMethod
  extends BaseOpenApiMethod<CartConstantsControllerGetBoxPriceCentResponse>
  implements Method<CartConstantsControllerGetBoxPriceCentResponse, void>
{
  public call(): Promise<CartConstantsControllerGetBoxPriceCentResponse> {
    return super.call();
  }
}
