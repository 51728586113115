enum OrderTimeframe {
  MORNING = 'MORNING',
  AFTERNOON = 'AFTERNOON',
  EVENING = 'EVENING',
}
const PickUpTimeFrameTimeframes = {
  MORNING: {
    rangeMin: 12,
    rangeMax: 14,
  },
  AFTERNOON: {
    rangeMin: 14,
    rangeMax: 16,
  },
  EVENING: {
    rangeMin: 16,
    rangeMax: 18,
  },
};
const DeliveryTimeFrameTimeframes = {
  MORNING: {
    rangeMin: 14,
    rangeMax: 16,
  },
  AFTERNOON: {
    rangeMin: 16,
    rangeMax: 18,
  },
  EVENING: {
    rangeMin: 18,
    rangeMax: 20,
  },
};

const getTimeframeRange = (timeframe: OrderTimeframe) => {
  const pickup = PickUpTimeFrameTimeframes[timeframe];
  const delivery = DeliveryTimeFrameTimeframes[timeframe];

  return `(P: ${pickup.rangeMin}-${pickup.rangeMax}, D: ${delivery.rangeMin}-${delivery.rangeMax})`;
};

export { OrderTimeframe, PickUpTimeFrameTimeframes, DeliveryTimeFrameTimeframes, getTimeframeRange };
