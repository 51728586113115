import OrderStatusApi from '../interfaces/api';
import appRequest from '../../utils/request';
import {Order, ShippingOrder} from '../../types';

async function fetchOrder(customerId: string, orderId: string): Promise<Order | null> {
  try {
    const { data, status } = await appRequest(`customer/order/${orderId}/${customerId}`);
    if ([401, 500].includes(status)) {
      return null;
    }
    return data;
  } catch (e) {
    console.error('Order Status Request Failed:', e);
    // TODO : add check if http error has status 404, else handle error.
    return null;
  }
}
async function fetchShippingOrder(customerId: string, orderId: string): Promise<ShippingOrder | null> {
  try {
    const { data, status } = await appRequest(`customer/order/${orderId}/${customerId}`);
    if ([401, 500].includes(status)) {
      return null;
    }
    return data;
  } catch (e) {
    console.error('Order Status Request Failed:', e);
    // TODO : add check if http error has status 404, else handle error.
    return null;
  }
}

/**
 * Order-Status API definitions for production use.
 *
 * These definitions can only be covered by end-to-end (e2e) tests.
 */
const orderStatusApiProd: OrderStatusApi = {
  fetchOrder,
  fetchShippingOrder
};

export default orderStatusApiProd;
