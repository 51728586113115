/**
 * Markdown
 */
const donateClothesEn = `

In one of our previous articles, we talked about how to keep your clothes from being thrown away. In addition to swap parties and renting out your clothes, there are many other options. One option is to donate your clothes so that they can either be worn by someone else or reused for a new purpose. Here you can find out why this is a good idea and how best to donate clothes.

## HE DILEMMA OF GIVING: WHY YOUR GOOD INTENTIONS SOMETIMES DON'T GET THE DESIRED EFFECT

In Germany, an incredible one million tons of old clothes are put into these containers or collections every year. Imagine that this corresponds to the amount of 62,000 lorries that, if they were lined up, would form a lorry line from Flensburg to Innsbruck.

The problem is that not everything that is thrown into these containers is actually suitable for donation. Damaged or unusable garments often end up in these collections, resulting in a waste of resources and additional disposal costs. It is therefore important to donate more consciously and to check whether the clothes are in good condition, without holes or stains.

However, before you donate your clothes, find out about recycling options. There are specific programmes and centres for the recycling of textiles which offer an environmentally friendly solution. Or you just upcycl your clothes yourself!

## SUSTAINABLE AND UNIQUE: YOUR CLOTHING’S NEXT CHAPTER 

Clothes are something special. It accompanies us through different stages of life and gives our expression an individual touch. It would be a shame if she just ended up in the garbage without a chance at a second life. But sometimes there is no other way but to part with certain garments. In such moments it is important to look for alternative concepts that give our clothes a new purpose.  
There are a large number of companies dedicated to this issue and offering innovative solutions. We have selected some companies that have developed alternative concepts and thus make a personal contribution to the protection of our environment.

### Repairs

A particularly sustainable and resource-saving option is to repair the clothes. Repairs can extend the life cycle of our garments and keep them from being thrown away.  
Repairing clothes offers a number of advantages. On the one hand, we save money because we don't have to buy new clothes. Instead, we invest in the quality and longevity of our existing wardrobe. Repairs also allow us to maintain our personal style and give our garments an individual touch.  
There are different ways to repair clothes. We can carry out some repairs ourselves, such as sewing loose buttons or fixing small holes. For more complex repairs or if we do not have the necessary craftsmanship, we can resort to professional sewing companies or tailors. If you need help repairing your clothes, we at Repair Rebels are happy to help.

### German Kleiderkammern (clothing stores)

A wonderful way to pass on clothes sensibly is to hand them over personally to the several locations of clothing stores. Instead of just throwing the garments into an old garment container and hoping that they reach the needy, we can drop them off directly at these locations. These facilities have existed since the post-war period to provide people with warm clothes, especially in cold seasons. Today, there are still some clothing stores that help recipients of social assistance, the homeless, refugees and other needy people. It is important to call in advance and ask if there is a real need. This ensures that our clothes reach those who really need them.

### German Red Cross

The German Red Cross (DRC) provides clothing to 1. 2 million disadvantaged people every year. This is only possible because many generous people donate their clothes. The DRK has developed two models for clothing donations:  
Changing rooms: If we hand over our clothes directly to the changing rooms, clubs or local associations, there is a high chance that they will actually benefit those in need. In this way, we can ensure that our donations directly and directly help people in need.  
Resale: The rest of the clothing donations are sold to a recycling company along with the entire contents of the waste garment bin. The money raised is used to support social projects.

[More information](https://www.drk.de/spenden/spenderservice/spendentransparenz-spenderservice/kleidersammlung/)

### Malteser

The Malteser are an organisation which, similar to the GRC, has different approaches to the distribution of clothing. They operate clothing stores, work with recycling companies and even have their own second-hand shops. Of the collected clothing donations, 55% are resold, 35% are recycled, and 5% go to the clothing stores. The rest is treated as waste. The Malteser have a total of around 47 clothing stores, in which some of the donated clothes are sorted and given away either free of charge or at a low price.  
Particularly high-quality clothing can be delivered in the Malteser shop in Dortmund. The beauty of it is that all the proceeds from the sale of these garments go to Malteser's aid projects - whether in our immediate vicinity or in places around the world where the need is greatest.

[More information](https://www.malteser.de/spenden-helfen/online-spenden.html)

### Caritas

The Caritas Association Düsseldorf has been working since about 1985 to collect used and new clothes. The special thing about this is that these clothes are further processed in their own textile workshop. If collected clothes cannot be sold in their own stores, they are resold to a sorting company that works with FairWertung. This ensures that the clothes are used sensibly.

The proceeds from the sale of the clothes are used exclusively to create jobs for disadvantaged people in the context of the social department store. In this way, Caritas creates new opportunities and perspectives for people who need support.

### FairWertung

FairWertung is an impressive association of companies that work with used clothing and are committed to greater transparency in this area. What distinguishes it is its strict focus on non-profit organisations. At FairWertung, a large part of the collected clothing is resold, but 100% of the proceeds go to charitable causes.

The old clothing sacks, which come from containers or door collections, are sent unsorted to commercial textile recyclers. The surplus garments from social institutions or clothing stores are passed on to recycling companies. The sorting of the different textiles and qualities is done completely by hand. It requires a lot of care and experience to differentiate the delivered old clothes into up to 200 different items and qualities. Each individual piece goes through several sorting processes until it is finally classified and packaged according to type and quality.

This professional sorting ensures that as much as possible can be reused as second-hand clothing. Even the pieces that are no longer wearable are recycled and converted into cleaning cloths or raw materials. On average, about 50-55 percent of the collected textiles are suitable for second-hand use. The other half nevertheless finds a useful use and is processed into other useful products or properly disposed of.

[More information](https://fairwertung.de/themen-positionen/positionen/)

### Tafel

The Tafel is a non-profit organisation that works for people in need throughout Germany. Their main objective is to distribute food to people who are financially disadvantaged and cannot afford adequate nutrition. In addition to food and money donations, the board also accepts old clothes and household items.
The donations of old clothes are an important part of the support that the board offers. Many people have clothes that they no longer wear or that no longer fit them, but are still in good condition. Instead of throwing these garments away, they can be donated to the table. This ensures that the clothes are cleaned, sorted and passed on to needy people. This gives these people the opportunity to dress appropriately and to use their financial resources for other vital needs.

[More information](https://www.tafel.de/spenden)

### Awista

Awista is the company in Düsseldorf that ensures that well-preserved clothes and shoes are collected. On behalf of the city, they set up garment containers on public roads and recycling yards. The revenue generated by Awista from the sale of the collected materials goes into the municipal waste charges and helps to keep them stable. There are also other collectors who set up their own containers, for example on private property. However, the proceeds from these collections are not included in municipal waste charges. This is why it is particularly important to be able to easily identify the old clothing containers on behalf of the city. They are marked with a special sticker, which makes it easier to identify them.

[More Information here](https://www.google.com/url?q=https://www.awista.de/glossar/altkleidercontainer/&sa=D&source=docs&ust=1695032003251249&usg=AOvVaw2L0q8x66pt5Uzi0wzIePHj)

### Recycling center

Textiles that are still portable can be safely thrown into the old clothing container. However, if the substance is severely damaged, for example by paint or oil, it should be disposed of in a landfill. Broken, wet or dirty materials are best disposed of at the recycling yard.

## WHY IT'S IMPORTANT TO LOOK AT WHERE THE DONATION IS GOING

For many, donating clothing sounds like a good deed at first. And it's a completely uncomplicated one: old-clothes containers can be found in every city - they are located at street intersections, in residential areas, in parking lots. However, the scandal involving the German Red Cross - uncovered by the NDR documentary "Die Altkleiderlüge" from 2011 (unfortunately no longer available online) - showed that the donated clothing from used clothing containers only in the fewest cases ends up where the donors expect it to, namely without detours and costs with the needy. The documentary looked at the clothing containers of charitable aid organizations and private companies.  
What usually happens with the clothing donations instead: they are sold on. Clothes in good condition are sold to second-hand stores in Germany or Western Europe - according to extensive research by "Deutsche Welle," this accounts for the majority of sales, although it only involves between two and four percent of old clothes. Another 40 percent of donated clothes are resold abroad and end up - depending on their quality - on markets in Eastern Europe, the Middle East, Central Asia and, above all, Africa.  
What cannot be used at all must be recycled according to the Recycling Management Act. Such donations are then turned into cleaning rags or recycling materials, often also roofing felt, painter's fleece or car interior linings. Only what cannot be recycled in any other way may be disposed of as waste or incinerated to generate heat - that is still about five to 15 percent of clothing donations.
Since unusable items are preferably recycled and thus begin a second life, old-clothing containers - at least when it comes to environmental protection - are not an entirely bad thing. After all, a recycled sweater is still better than one that ends up in the trash and has to be burned.  
But what effect the part of the clothing donations that is sold to Africa, Asia and South America has is at least controversial. There are well-founded fears that cheap second-hand clothing has contributed to the decline of the local textile industry in sub-Saharan Africa.

At the same time as NDR showed the "old clothes lie", "Zeit Online" reported in 2011 in an article that in Tanzania alone tens of thousands of employees in the (formerly stately) textile industry had lost their jobs because of clothing donations. Against the low prices at which the donated clothing is sold on the local markets, a local product cannot compete.  
How can it be that charitable aid organizations such as the German Red Cross (DRK) allow donated clothes to be sold and exported? For example, the pants and sweaters not only have to be transported but also sorted - and that costs the organization money. These costs can be covered by resale. Only about ten percent of clothing donations to the DRK end up in Germany's more than 800 clothing stores, where they are handed out to those in need free of charge or for a small nominal fee. Today, there are stricter measures, which also prevent the organization's situation at that time and provide a remedy. A lot of progress has been made in the transparency of the organizations and with concrete donation goals, you can - for example with the German Red Cross - select the distribution itself as a private donor.

[More information](https://utopia.de/ratgeber/gebrauchte-kleidung-sinnvoll-spenden-optionen-und-tipps/)

`;
export default donateClothesEn;
