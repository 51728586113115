import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { ProfileControllerGetActiveBookingListParameter } from '../../parameters/profile-controller-get-active-booking-list.parameter';
import { ProfileControllerGetActiveBookingListResponse } from '../../responses/profile-controller-get-active-booking-list.response';

interface ProfileControllerGetActiveBookingListOpenApiParameters {
  parameters: ProfileControllerGetActiveBookingListParameter;
}

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/profile/active-booking-list',
  operationId: 'ProfileController_getActiveBookingList',
  parameters: [
    {
      name: 'lastEvaluatedKey',
      required: false,
      in: OpenApiParameterType.QUERY,
    },
    {
      name: 'limit',
      required: false,
      in: OpenApiParameterType.QUERY,
    },
  ],
})
export class ProfileControllerGetActiveBookingListMethod
  extends BaseOpenApiMethod<ProfileControllerGetActiveBookingListResponse>
  implements Method<ProfileControllerGetActiveBookingListResponse, ProfileControllerGetActiveBookingListOpenApiParameters>
{
  public call(parameters?: ProfileControllerGetActiveBookingListOpenApiParameters): Promise<ProfileControllerGetActiveBookingListResponse> {
    return super.call(parameters);
  }
}
