import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { OrderControllerCreateRequestBody } from '../../request-bodies/order-controller-create.request-body';
import { OrderControllerCreateResponse } from '../../responses/order-controller-create.response';

interface OrderControllerCreateOpenApiParameters {
  requestBody: OrderControllerCreateRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.POST,
  apiName: 'customer',
  path: '/order',
  operationId: 'OrderController_create',
  parameters: [],
})
export class OrderControllerCreateMethod
  extends BaseOpenApiMethod<OrderControllerCreateResponse>
  implements Method<OrderControllerCreateResponse, OrderControllerCreateOpenApiParameters>
{
  public call(parameters: OrderControllerCreateOpenApiParameters): Promise<OrderControllerCreateResponse> {
    return super.call(parameters);
  }
}
