import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { MailerControllerGetTemplateParameter } from '../../parameters/mailer-controller-get-template.parameter';
import { MailerControllerGetTemplateResponse } from '../../responses/mailer-controller-get-template.response';

interface MailerControllerGetTemplateOpenApiParameters {
  parameters: MailerControllerGetTemplateParameter;
}

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/mailer/template/{templateName}',
  operationId: 'MailerController_getTemplate',
  parameters: [
    {
      name: 'templateName',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class MailerControllerGetTemplateMethod
  extends BaseOpenApiMethod<MailerControllerGetTemplateResponse>
  implements Method<MailerControllerGetTemplateResponse, MailerControllerGetTemplateOpenApiParameters>
{
  public call(parameters: MailerControllerGetTemplateOpenApiParameters): Promise<MailerControllerGetTemplateResponse> {
    return super.call(parameters);
  }
}
