import React, { FC, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';
import RunningLine from 'components/RunningLine';
import LoadingButton from 'components/LoadingButton';
import { useTranslation } from 'react-i18next';
import { removePayPalGuard } from 'utils/remove-paypal-guard';
import { sendEvent } from 'utils/google-analytics';
import AppBackLink from 'components/BackLink';
import { useDispatch } from 'react-redux';
import { setVoucherCart } from 'Cart/store/actions';
import line from './line.svg';
import formatPriceCent from 'utils/format-price-cent';
import { VoucherItem } from 'Voucher/intefaces/item';
import sheet from '../../../images/svg/sheet-large.svg';
import cookies from 'js-cookie';
import RedeemOutlinedIcon from '@material-ui/icons/RedeemOutlined';

const cartEnabled: boolean = process.env.REACT_APP_CART_ENABLED === 'true';

interface ProductCategoryStepProps extends RouteComponentProps {
  voucherItem: VoucherItem;
  onSubmit?(amount?: number, comment?: string): Promise<void>;
  backLinkHref?: string;
}

const useStyles = makeStyles((theme) => {
  return {
    price: {
      display: 'flex',
      width: '25%',
      padding: theme.spacing(0, 1),
      backgroundImage: `url(${line})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '98% 1em',
    },
    voucherButton: {
      width: '100%',
      padding: '1rem',
      backgroundImage: `url(${sheet})`,
      backgroundSize: '100% 100%',
      backgroundColor: 'inherit',
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: '12px',
      },
    },
    textContainer: {
      width: '100%',
      span: {
        textAlign: 'right',
      },
    },
    voucherTitle: {
      textAlign: 'left',
    },
    boxIcon: {
      width: '50px',
      height: '50px',
      color: '#EB672C',
      [theme.breakpoints.down('sm')]: {
        width: '45px',
        height: '45px',
      },
    },
    voucherImgBlock: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'start',
      },
    },
    voucherText: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px!important',
      },
    },
    voucherPriceBlock: {
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'end',
      },
    },
  };
});

const VoucherStepForm: FC<ProductCategoryStepProps> = ({ voucherItem, onSubmit, backLinkHref = '..' }) => {
  const headingHtmlId = 'ProductDetailsStepForm-heading';
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const langCookie = cookies.get('language') || '';

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const setVoucherItem = true;
    dispatch(setVoucherCart(setVoucherItem));
    try {
      // @ts-ignore
      await onSubmit();
    } catch (err) {
      if (process.env.NODE_ENV === 'development') {
        console.error(err);
      }
      setLoading(false);
      // TODO: handle error
    } finally {
      removePayPalGuard();
      sendEvent('repair', 'add_repair_to_cart');
    }
  }

  return (
    <Box>
      <RunningLine />
      <Container maxWidth="sm">
        <Box>
          <Box mr={2}>
            <AppBackLink to={backLinkHref} isGoBackActivated />
          </Box>
          <Typography align="left" color="textPrimary" variant="h3">
            {t('CategoryVoucher')}
          </Typography>
          <Box marginTop={'15px'}></Box>
          <div className={classes.voucherButton}>
            <div className={classes.voucherImgBlock}>
              <RedeemOutlinedIcon className={classes.boxIcon} />
              <Typography align="left" color="textPrimary" variant="h5" className={classes.voucherText}>
                {!langCookie ? voucherItem.title.de : voucherItem.title[langCookie]}
              </Typography>
            </div>
            <div className={classes.voucherPriceBlock}>
              <Typography align="right" color="textPrimary" variant="h5" className={classes.voucherText}>
                {t('Value')}: {formatPriceCent(voucherItem.priceCent)}
              </Typography>
            </div>
          </div>
        </Box>
        <Box mb={3} />
        <form aria-labelledby={headingHtmlId} onSubmit={handleSubmit}>
          <Box mb={4} />
          <Box textAlign="center">
            <LoadingButton color="secondary" disabled={!cartEnabled} loading={loading} type="submit" variant="contained">
              {t('Add to Cart')}
            </LoadingButton>
          </Box>
        </form>
      </Container>
    </Box>
  );
};

export default VoucherStepForm;
