import { CityConfigDto } from '../../api/components/city-config-dto';
import { CartConstantsDto } from '../../api/components/cart-constants-dto';

import { SET_CITY_CONFIG, SET_SERVICE_PRICES } from './types';

export function setCityConfig(cityConfigs: CityConfigDto[]) {
  return {
    type: SET_CITY_CONFIG,
    payload: {
      cityConfigs,
    },
  };
}

export function setServicePrices(servicePrices: CartConstantsDto) {
  return {
    type: SET_SERVICE_PRICES,
    payload: {
      servicePrices,
    },
  };
}
