import { SET_ITEMS_VOUCHER_BY_FETCH } from './types';
import { VoucherItem } from 'Voucher/intefaces/item';

export function setVoucherItemsByFetch(voucherItems: VoucherItem[]) {
  return {
    type: SET_ITEMS_VOUCHER_BY_FETCH,
    payload: {
      voucherItems,
    },
  };
}
