import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { CustomerControllerGetByIdParameter } from '../../parameters/customer-controller-get-by-id.parameter';
import { CustomerControllerGetByIdResponse } from '../../responses/customer-controller-get-by-id.response';

interface CustomerControllerGetByIdOpenApiParameters {
  parameters: CustomerControllerGetByIdParameter;
}

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/customer/{customerId}',
  operationId: 'CustomerController_getById',
  parameters: [
    {
      name: 'customerId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class CustomerControllerGetByIdMethod
  extends BaseOpenApiMethod<CustomerControllerGetByIdResponse>
  implements Method<CustomerControllerGetByIdResponse, CustomerControllerGetByIdOpenApiParameters>
{
  public call(parameters: CustomerControllerGetByIdOpenApiParameters): Promise<CustomerControllerGetByIdResponse> {
    return super.call(parameters);
  }
}
