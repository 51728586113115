import productInitialState from './initial-state';
import { SET_ITEMS } from './types';

export default function productReducer(state = productInitialState, action) {
  switch (action.type) {
    case SET_ITEMS: {
      const { items } = action.payload;
      return { ...state, items };
    }
    default:
      return state;
  }
}
