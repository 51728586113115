import { toastr } from 'react-redux-toastr';
import appRequest from '../../utils/request';
import EditOrderRequest from '../interfaces/editOrderRequest';
import OrderEditDeliveryApi from '../interfaces/api';
import { Order, Transport } from '../../types';

/**
 * Module API definitions for production use.
 *
 * These definitions can only be covered by end-to-end (e2e) tests.
 */
const orderEditDeliveryApiProd: OrderEditDeliveryApi = {
  editOrderDelivery: async (orderId: Order['_id'], customerId: Order['customerId'], editRequest: EditOrderRequest, transportId: Transport['_id']) => {
    if (!editRequest.address || !editRequest.time.date) {
      return toastr.error('Error', 'Please enter a valid date both for pick-up and delivery');
    }
    const response = await appRequest(`/order/reschedule-delivery?orderId=${orderId}&customerId=${customerId}&transportId=${transportId}`, {
      method: 'PATCH',
      data: editRequest,
    });

    if (!response) {
      return;
    }

    const { status, data } = response;

    if (status !== 200 || data) {
      toastr.error('Server error', "Can't update data. Try again later.");
      return data;
    }
  },
};

export default orderEditDeliveryApiProd;
