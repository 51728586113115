import { CartControllerUploadImageMethod } from 'api/methods/cart-controller/upload-image-order';
import { CartControllerAddCartItemMethod } from '../methods/cart-controller/add-cart-item.method';
import { CartControllerCheckoutMethod } from '../methods/cart-controller/checkout.method';
import { CartControllerClearCartMethod } from '../methods/cart-controller/clear-cart.method';
import { CartControllerGetCartMethod } from '../methods/cart-controller/get-cart.method';
import { CartControllerGetDiscountMethod } from '../methods/cart-controller/get-discount.method';
import { CartControllerMigrateMethod } from '../methods/cart-controller/migrate.method';
import { CartControllerPutCartItemMethod } from '../methods/cart-controller/put-cart-item.method';
import { CartControllerRemoveCartItemMethod } from '../methods/cart-controller/remove-cart-item.method';

export class CartController {
  public readonly getCart = new CartControllerGetCartMethod();
  public readonly addCartItem = new CartControllerAddCartItemMethod();
  public readonly clearCart = new CartControllerClearCartMethod();
  public readonly getDiscount = new CartControllerGetDiscountMethod();
  public readonly putCartItem = new CartControllerPutCartItemMethod();
  public readonly removeCartItem = new CartControllerRemoveCartItemMethod();
  public readonly migrate = new CartControllerMigrateMethod();
  public readonly checkout = new CartControllerCheckoutMethod();
  public readonly uploadOrderImage = new CartControllerUploadImageMethod();
}
