import ProfileApi from '../interfaces/api';
import ProfilePaymentData from '../interfaces/payment-data';
import ProfileCityInfo from '../interfaces/city-info-data';
import appRequest from '../../utils/request';
import { Contact, Customer, Order, ShippingOrder, VoucherOrder } from '../../types';
import { unexpectedServerError } from '../../utils/network-error-msg';
import { toastr } from 'react-redux-toastr';
import { Address } from '../../interfaces/customer-data';
import { UserState } from '../../Auth/store/type';

async function fetchActiveBookings(customerId: Customer['_id']): Promise<Order[]> {
  const { data } = await appRequest(`/customer/active-booking-list/${customerId}`);
  return data;
}

async function fetchPreviousBookings(customerId: Customer['_id']): Promise<Order[]> {
  const { data } = await appRequest(`/customer/completed-booking-list/${customerId}`);
  return data;
}

async function fetchMyAddresses(): Promise<Address[]> {
  const response = await appRequest('/customer/address-list');

  if (!response) {
    unexpectedServerError();
    return [];
  }

  const { data } = response;

  if (!data.message) {
    return data;
  }

  if (Array.isArray(data.message)) {
    data.message.map((msg) => toastr.error('Error', msg));
    return [];
  } else {
    toastr.error('Error', data.message);
    return [];
  }
}

async function fetchShippingOrderReceipts(customerId: Customer['_id']): Promise<ShippingOrder[]> {
  const { data } = await appRequest(`/customer/completed-shipping-booking-list/${customerId}`);
  return data;
}

async function fetchVoucherOrderReceipts(customerId: Customer['_id']): Promise<VoucherOrder[]> {
  const response = await appRequest(`/voucher-group/paid?customerId=${customerId}`);

  if (!response) {
    unexpectedServerError();
    return [];
  }

  const { data } = response;

  if (!data.message) {
    return data;
  }

  if (Array.isArray(data.message)) {
    data.message.map((msg) => toastr.error('Error', msg));
    return [];
  } else {
    toastr.error('Error', data.message);
    return [];
  }
}

async function addAddressItem(address: Address): Promise<UserState | null> {
  const response = await appRequest('/customer/address-list', { method: 'PUT', data: address });

  if (!response) {
    unexpectedServerError();
    return null;
  }

  const { data } = response;

  if (!data.message) {
    return data;
  }

  if (Array.isArray(data.message)) {
    data.message.map((msg) => toastr.error('Error', msg));
    return null;
  } else {
    toastr.error('Error', data.message);
    return null;
  }
}

async function updateAddressItem(address: Address, oldAddressString: string): Promise<UserState | null> {
  const response = await appRequest(`/customer/address-list/${oldAddressString}`, { method: 'PATCH', data: address });

  if (!response) {
    unexpectedServerError();
    return null;
  }

  const { data } = response;

  if (!data.message) {
    return data;
  }

  if (Array.isArray(data.message)) {
    data.message.map((msg) => toastr.error('Error', msg));
    return null;
  } else {
    toastr.error('Error', data.message);
    return null;
  }
}

async function removeAddressItem(addressAndZip: string): Promise<void> {
  await appRequest(`/customer/address-list/${addressAndZip}`, { method: 'DELETE' });
}

async function fetchPaymentMethods(): Promise<ProfilePaymentData[]> {
  console.error('Feature is removed');
  return [];
}

async function addPaymentMethod(paymentMethod: ProfilePaymentData): Promise<ProfilePaymentData> {
  console.error('Feature is removed');
  return Promise.resolve(null as any);
}

async function removePaymentMethod(id: string): Promise<void> {
  console.error('Feature is removed');
}

async function fetchUserInfo(): Promise<Contact | null> {
  const response = await appRequest('/customer/profile');

  if (!response) {
    unexpectedServerError();
    return null;
  }

  const { status, data } = response;

  if (status !== 200 || !data) {
    toastr.error('Server error', "Can't verify address. Try again later.");
  }

  return data;
}

async function editUserInfo(userInfo: Contact): Promise<Contact | null> {
  const response = await appRequest('/customer/profile', { method: 'PATCH', data: userInfo });

  if (!response) {
    unexpectedServerError();
    return null;
  }

  const { status, data } = response;

  if (status !== 200 || !data) {
    toastr.error('Server error', "Can't verify address. Try again later.");
  }

  return data;
}

async function getCityZipCodes(): Promise<ProfileCityInfo[]> {
  const { status, data } = await appRequest('platform-config/city', {
    method: 'GET',
  });

  if (status === 500) {
    throw new Error(data.message);
  }

  return data;
}

/**
 * Profile API definitions for production use.
 *
 * These definitions can **only** be covered by end-to-end (e2e) tests.
 */
const profileApiProd: ProfileApi = {
  fetchActiveBookings,
  fetchPreviousBookings,
  fetchMyAddresses,
  addAddressItem,
  updateAddressItem,
  removeAddressItem,
  fetchPaymentMethods,
  addPaymentMethod,
  removePaymentMethod,
  fetchUserInfo,
  editUserInfo,
  getCityZipCodes,
  fetchShippingOrderReceipts,
  fetchVoucherOrderReceipts
};

export default profileApiProd;
