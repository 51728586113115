import { CartConstantsControllerGetBoxPriceCentMethod } from '../methods/cart-constants-controller/get-box-price-cent.method';
import { CartConstantsControllerGetCourierPriceCentMethod } from '../methods/cart-constants-controller/get-courtier-price-cent.method';
import { CartConstantsControllerGetShippingExemptionThresholdMethod } from '../methods/cart-constants-controller/get-shipping-exemption-threshold.method';
import { CartConstantsControllerGetMethod } from '../methods/cart-constants-controller/get.method';

export class CartConstantsController {
  public readonly get = new CartConstantsControllerGetMethod();
  public readonly getBoxPriceCent = new CartConstantsControllerGetBoxPriceCentMethod();
  public readonly getCourierPriceCent = new CartConstantsControllerGetCourierPriceCentMethod();
  public readonly getShippingExemptionThreshold = new CartConstantsControllerGetShippingExemptionThresholdMethod();
}
