import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { AssignmentControllerGetPendingParameter } from '../../parameters/assignment-controller-get-pending.parameter';
import { AssignmentControllerGetPendingResponse } from '../../responses/assignment-controller-get-pending.response';

interface AssignmentControllerGetPendingOpenApiParameters {
  parameters: AssignmentControllerGetPendingParameter;
}

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/assignment/pending/{year}/{month}/{day}',
  operationId: 'AssignmentController_getPending',
  parameters: [
    {
      name: 'year',
      required: true,
      in: OpenApiParameterType.PATH,
    },
    {
      name: 'month',
      required: true,
      in: OpenApiParameterType.PATH,
    },
    {
      name: 'day',
      required: true,
      in: OpenApiParameterType.PATH,
    },
    {
      name: 'byDate',
      required: false,
      in: OpenApiParameterType.QUERY,
    },
  ],
})
export class AssignmentControllerGetPendingMethod
  extends BaseOpenApiMethod<AssignmentControllerGetPendingResponse>
  implements Method<AssignmentControllerGetPendingResponse, AssignmentControllerGetPendingOpenApiParameters>
{
  public call(parameters: AssignmentControllerGetPendingOpenApiParameters): Promise<AssignmentControllerGetPendingResponse> {
    return super.call(parameters);
  }
}
