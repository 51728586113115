import { call, put } from 'redux-saga/effects';
import VoucherApi from 'Voucher/intefaces/api';
import { setVoucherItemsByFetch } from '../actions';

export default function* voucherSaga(api: VoucherApi) {
  try {
    const items = yield call(api.fetchProducts);
    yield put(setVoucherItemsByFetch(items));
  } catch (err) {
    if (process.env.NODE_ENV === 'development') {
      console.error(err);
    }
    // TODO: handle error
  }
}
