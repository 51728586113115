import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { TransportControllerMarkAsResolvedRequestBody } from '../../request-bodies/transport-controller-mark-as-resolved.request-body';

interface TransportControllerMarkAsResolvedOpenApiParameters {
  requestBody: TransportControllerMarkAsResolvedRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.PATCH,
  apiName: 'customer',
  path: '/transport/mark-as-resolved',
  operationId: 'TransportController_markAsResolved',
  parameters: [],
})
export class TransportControllerMarkAsResolvedMethod
  extends BaseOpenApiMethod<void>
  implements Method<void, TransportControllerMarkAsResolvedOpenApiParameters>
{
  public call(parameters: TransportControllerMarkAsResolvedOpenApiParameters): Promise<void> {
    return super.call(parameters);
  }
}
