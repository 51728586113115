/**
 * Markdown
 */
const reparierenIstEineHaltungBodyDe = `

Einfache Reparaturen unserer Kleidung sind ein großer Hoffnungsschimmer für die Modeindustrie, die Umwelt, die lokale Wirtschaft und das menschliche Wohlbefinden.

Aber warum? Das erklärt euch unsere Gründerin Monika in ihrem TED-Talk „Why repairs matter“.

Im 21. Jahrhundert haben sich Konsumdenken und Fast Fashion in den Stoff unserer Kultur eingewoben. Mit den sinkenden Kosten für Kleidung haben wir aufgehört, die Kunst und das Handwerk der Modeproduktion zu schätzen und vergessen oft die Menge an natürlichen Ressourcen, die für die Herstellung von Kleidung benötigt werden. Die Modeindustrie wurde zu einer der umweltschädlichsten, arbeitsintensivsten und verschwenderischsten Industrien der Welt. Die Arbeit an der Verlängerung der Lebensdauer von Kleidungsstücken bietet eine große Chance, die Branche in Richtung Nachhaltigkeit zu bewegen. Um dies zu erreichen, brauchen wir jedoch einen Kulturwandel!

> I am not sure if repairs will be the new "big thing" but I am sure it is the next “right thing” in fashion.

`;

export default reparierenIstEineHaltungBodyDe;
