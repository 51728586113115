import { CustomerData } from '../../interfaces/customer-data';
import CheckoutApi from '../interfaces/api';
import API_SERVICE from '../../api/api.service';

import { CartControllerCheckoutRequestBody } from '../../api/request-bodies/cart-controller-checkout.request-body';
import { setAddressesCities } from '../../utils/schema-address';
import { OrderDiscount } from '../../types';

async function createOrder(
  orderCustomId: string,
  customerData: CustomerData,
  boxCount: number,
  discount?: OrderDiscount | null
): Promise<{ customerId: string; orderId: string }> {
  if (customerData.pickupTime.date === null) {
    throw new Error('The checkout pickup date is null');
  }
  if (customerData.deliveryTime.date === null) {
    throw new Error('The checkout delivery date is null');
  }

  let pickupAddressCity = 'Dusseldorf';
  let deliveryAddressCity = 'Dusseldorf';

  setAddressesCities(
    customerData.pickupAddress.zipCode,
    customerData.deliveryAddress.zipCode,
    (key: string) => (pickupAddressCity = key),
    (key: string) => (deliveryAddressCity = key)
  );

  const { email, phoneNumber, firstName, lastName } = customerData.contact;
  const requestBody: CartControllerCheckoutRequestBody = {
    pickupAddress: {
      street: customerData.pickupAddress.street,
      city: pickupAddressCity,
      country: 'Germany',
      zipCode: customerData.pickupAddress.zipCode,
      state: 'Nordrhein-Westfalen',
      contact: {
        email,
        phoneNumber,
        firstName,
        lastName,
      },
    },
    deliveryAddress: {
      street: customerData.deliveryAddress.street,
      city: deliveryAddressCity,
      country: 'Germany',
      zipCode: customerData.deliveryAddress.zipCode,
      state: 'Nordrhein-Westfalen',
      contact: {
        email,
        phoneNumber,
        firstName,
        lastName,
      },
    },
    // TODO : fix typing issue with timeframe
    pickupTime: {
      date: customerData.pickupTime.date,
      time: customerData.pickupTime.timeframe as any,
    },
    deliveryTime: {
      date: customerData.deliveryTime.date,
      time: customerData.deliveryTime.timeframe as any,
    },
    boxCount,
  };

  if (discount) {
    requestBody.discountPercent = discount?.percent ?? 0;
    requestBody.discountValue = discount?.value ?? 0;
    requestBody.discountId = discount._id;
  }

  const order = await API_SERVICE.cartController.checkout.call({ requestBody });
  return { customerId: order.customerId, orderId: order.id };
}

// TODO: will be implemented in the admin app?
async function addDiscountCode(discountCode: string): Promise<number> {
  return 5;
}

/**
 * Checkout API definitions for production use.
 *
 * These definitions can only be covered by end-to-end (e2e) tests.
 */
const checkoutApi: CheckoutApi = {
  createOrder,
  addDiscountCode,
};

export default checkoutApi;
