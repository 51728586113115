/**
 * Markdown
 */
const reparierenIstEineHaltungBodyDe = `

 Simple repairs to our clothes are a great ray of hope for the fashion industry, the environment, local economies and human well-being.
 
 But why? Our founder Monika explains in her TED Talk, "Why repairs matter."
 
 In the 21st century, consumerism and fast fashion have woven their way into the fabric of our culture. With the decreasing cost of clothing, we have stopped appreciating the art and craft of fashion production and often forget the amount of natural resources it takes to make clothes. The fashion industry has become one of the most polluting, labor intensive, and wasteful industries in the world. Working to extend the life of garments offers a great opportunity to move the industry toward sustainability. However, to achieve this, we need a culture change!
 
 > I am not sure if repairs will be the new "big thing" but I am sure it is the next "right thing" in fashion.
 
 
 `;

export default reparierenIstEineHaltungBodyDe;
