import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { CartControllerGetDiscountParameter } from '../../parameters/cart-controller-get-discount.parameter';
import { CartControllerGetDiscountResponse } from '../../responses/cart-controller-get-discount.response';

interface CartControllerGetDiscountOpenApiParameters {
  parameters: CartControllerGetDiscountParameter;
}

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/cart/discount/{code}',
  operationId: 'CartController_getDiscount',
  parameters: [
    {
      name: 'code',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class CartControllerGetDiscountMethod
  extends BaseOpenApiMethod<CartControllerGetDiscountResponse>
  implements Method<CartControllerGetDiscountResponse, CartControllerGetDiscountOpenApiParameters>
{
  public call(parameters: CartControllerGetDiscountOpenApiParameters): Promise<CartControllerGetDiscountResponse> {
    return super.call(parameters);
  }
}
