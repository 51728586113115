import React from 'react';
import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { blogItemsDe, blogItemsEn } from '../../../Blog/data/items';
import BlogCard from '../../../Blog/components/Card';
import { useElementOnScreen } from '../../../hooks/useElementOnScreen';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import classes from './Posts.module.css';
import { getPage } from '../../../utils/pages';

const animationDelay = 0.3;

export default function Posts() {
  const [postRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  });

  const { width } = useWindowDimensions();

  const { t } = useTranslation();

  let blogItems;
  if (i18next.language === 'en') {
    blogItems = blogItemsEn;
  } else blogItems = blogItemsDe;

  const posts = blogItems
    .slice(0, 3) // first three
    .map((x, i) => (
      <div
        key={i}
        style={{
          animationDelay: width > 767 ? `calc(${animationDelay * (1 - i)}s)` : `calc(${animationDelay * (1 + i)}s)`,
        }}
        className={`${classes.post} ${isVisible ? classes.postAnimation : ''}`}
      >
        <BlogCard {...x} />
      </div>
    ));

  return (
    <div ref={postRef} className="container">
      <h2 className={`h4 with-bar mb-4 ${classes.title} ${isVisible ? classes.titleAnimation : ''}`}>{t('Tips & More')}</h2>
      <div className={`${classes.grid} mb-4`}>{posts}</div>
      <div className="text-center">
        <Link className="link text-white" to={getPage('blogList')}>
          + {t('See More')}
        </Link>
      </div>
    </div>
  );
}
