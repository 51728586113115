import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { CartControllerPutCartItemParameter } from '../../parameters/cart-controller-put-cart-item.parameter';
import { CartControllerPutCartItemRequestBody } from '../../request-bodies/cart-controller-put-cart-item.request-body';

interface CartControllerPutCartItemOpenApiParameters {
  parameters: CartControllerPutCartItemParameter;
  requestBody: CartControllerPutCartItemRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.PUT,
  apiName: 'customer',
  path: '/cart/{itemId}',
  operationId: 'CartController_putCartItem',
  parameters: [
    {
      name: 'itemId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class CartControllerPutCartItemMethod extends BaseOpenApiMethod<void> implements Method<void, CartControllerPutCartItemOpenApiParameters> {
  public call(parameters: CartControllerPutCartItemOpenApiParameters): Promise<void> {
    return super.call(parameters);
  }
}
