import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { ProfileControllerCreateRequestBody } from '../../request-bodies/profile-controller-create.request-body';
import { ProfileControllerCreateResponse } from '../../responses/profile-controller-create.response';

interface ProfileControllerCreateOpenApiParameters {
  requestBody: ProfileControllerCreateRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.POST,
  apiName: 'customer',
  path: '/profile',
  operationId: 'ProfileController_create',
  parameters: [],
})
export class ProfileControllerCreateMethod
  extends BaseOpenApiMethod<ProfileControllerCreateResponse>
  implements Method<ProfileControllerCreateResponse, ProfileControllerCreateOpenApiParameters>
{
  public call(parameters: ProfileControllerCreateOpenApiParameters): Promise<ProfileControllerCreateResponse> {
    return super.call(parameters);
  }
}
