import OrderEditDeliveryApi from '../interfaces/api';
import EditOrderRequest from '../interfaces/editOrderRequest';
import { OrderDetails } from '../../interfaces/order-details';
import { Timeframe } from '../../interfaces/customer-data';

async function editOrderDelivery(orderId: string, customerId: string, editRequest: EditOrderRequest, transportId: string): Promise<OrderDetails | null> {
  return {
    id: 'orderDto.id',
    customerId: 'orderDto.customerId',
    pickupContactDetails: {
      firstName: 'orderDto.deliveryAddress.contact.firstName',
      lastName: 'orderDto.deliveryAddress.contact.lastName',
      email: 'orderDto.deliveryAddress.contact.email',
      phoneNumber: 'orderDto.deliveryAddress.contact.phoneNumber',
    },
    deliveryContactDetails: {
      firstName: 'orderDto.pickupAddress.contact.firstName',
      lastName: 'orderDto.pickupAddress.contact.lastName',
      email: 'orderDto.pickupAddress.contact.email',
      phoneNumber: 'orderDto.pickupAddress.contact.phoneNumber',
    },
    pickupAddress: {
      street: 'Scheibenstrasse 49',
      zipCode: '40479',
    },
    deliveryAddress: {
      street: 'Humboldtstrasse 101',
      zipCode: '40237',
    },
    pickupTime: {
      date: 'Not sure how this should look',
      timeframe: Timeframe.MORNING,
    },
    deliveryTime: {
      date: 'Not sure how this should look',
      timeframe: Timeframe.AFTERNOON,
    },
  };
}

/**
 * Order Edit Delivery API definitions for automated testing.
 *
 * The definitions of this API are used in automated tests as defaults.
 */
const orderEditDeliveryApiTest: OrderEditDeliveryApi = {
  //@ts-ignore
  editOrderDelivery,
};

export default orderEditDeliveryApiTest;
