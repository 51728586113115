import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { TransportControllerMarkAsDoneRequestBody } from '../../request-bodies/transport-controller-mark-as-done.request-body';

interface TransportControllerMarkAsDoneOpenApiParameters {
  requestBody: TransportControllerMarkAsDoneRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.PATCH,
  apiName: 'customer',
  path: '/transport/mark-as-done',
  operationId: 'TransportController_markAsDone',
  parameters: [],
})
export class TransportControllerMarkAsDoneMethod extends BaseOpenApiMethod<void> implements Method<void, TransportControllerMarkAsDoneOpenApiParameters> {
  public call(parameters: TransportControllerMarkAsDoneOpenApiParameters): Promise<void> {
    return super.call(parameters);
  }
}
