import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import cookies from 'js-cookie';
import { initialState } from './initial-state';
import { UserState } from './type';
import { extractStrictly } from '../../utils/typeStrictEscape';

export const callTypesAccount = {
  list: 'list',
  action: 'action',
};

const extractUserStateSpecific = extractStrictly<UserState>({
  // This object literal is guaranteed by the compiler to have no more and no fewer properties than ISpecific
  _id: true,
  email: true,
  firstName: true,
  lastName: true,
  phoneNumber: true,
  preferredLanguage: true,
  addressList: true,
  active: true,
  role: true,
  token: true,
  refreshToken: true,
});

export const UserSlice = createSlice({
  name: 'User Object',
  initialState,
  reducers: {
    reloadUser: (state: UserState, action: PayloadAction<UserState>) => {
      return extractUserStateSpecific(action.payload);
    },
    updateContactData: (state: UserState, action: PayloadAction<Pick<UserState, 'email' | 'phoneNumber' | 'firstName' | 'lastName'>>) => {
      const newUser = { ...state, ...action.payload };

      cookies.set('user', window.btoa(JSON.stringify(newUser as UserState)));
      return newUser;
    },
    updateAddressList: (state: UserState, action: PayloadAction<UserState['addressList']>) => {
      const newUser = { ...state, addressList: action.payload };

      cookies.set('user', window.btoa(JSON.stringify(newUser as UserState)));
      return newUser;
    },
    updateLanguage: (state: UserState, action: PayloadAction<UserState['preferredLanguage']>) => {
      const newUser = { ...state, preferredLanguage: action.payload };

      cookies.set('user', window.btoa(JSON.stringify(newUser as UserState)));
      return newUser;
    },
    loginReducer: (state: UserState, action: PayloadAction<UserState>) => {
      const specific = extractUserStateSpecific(action.payload);
      cookies.set('user', window.btoa(JSON.stringify(specific)));
      return specific;
    },
    updateTokens: (state: UserState, action: PayloadAction<Pick<UserState, 'token' | 'refreshToken'>>) => {
      const { token, refreshToken } = action.payload;
      state.token = token;
      state.refreshToken = refreshToken;
      cookies.set('user', window.btoa(JSON.stringify(extractUserStateSpecific(state))));
    },
    logoutReducer: () => {
      cookies.remove('user');
      return initialState;
    },
  },
});
