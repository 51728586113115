/**
 * Markdown
 */
const weilWirIhrTreuBleibenWollenBodyEn = `

They ideally accompany us for decades, go with us to exciting parties, concerts and trips. Jeans are like our second skin. They live and age with us. And no matter how old they are, they always deserve a second chance. Yes, our favourite jeans... If only we could wear them forever! What could be wrong with that? Save yourself the stress of looking for a new pair. With a few little tricks and the right care, your jeans will last forever. Below we have collected the best tips for washing your jeans properly.

## Air your jeans instead of washing them

You've probably guessed it already. Washing at 60 degrees and then throwing them in the dryer is not the ideal solution. So, our and also Levi's tip: Don't wash them at all! And that's not a bad idea at all. There are a few things you should keep in mind: Always let your jeans air out after wearing them to get rid of unpleasant scents and simply wash out small stains carefully in spots. How? That' s very simple. You can easily remove most stains with a toothbrush and clean water. If that's not enough, try using a light dishwashing liquid solution.

## Remove odors with water vapor

If you like to take a long shower after a long day at work, you can take your pants right with you. Just put them on a hanger in the bathroom while you shower. The steam created by the shower will help remove unwanted odors. In addition, your pants directly absorb the smell of your beloved shower gel. Afterwards, your pants will look freshly washed - without the hassle of machine washing.

## Washing by hand

If airing and steam cleaning do not help, you can also wash your jeans in cold water. This is especially good for untreated raw denim or pants in bright indigo. Washing by hand is much gentler than machine washing and the color of your pants will be washed out the least. After washing, it is best to let them dry in the fresh air while lying down.

## This should always be your last choice: Machine Washing

You can't quite get used to the idea of washing your pants without the washing machine after all? That's perfectly okay from time to time! But then, please keep the following things in mind: Wash your jeans inside out. Close the zipper and the button to avoid friction on the fabric. Use a mild detergent and wash them at the lowest temperature - that is, at most 30 degrees and with no more than a thousand spins. It is even better to wash your favorite jeans in the cold wool wash cycle. This wash cycle is particularly gentle on the material and minimizes the risk that your pants will be three sizes smaller after washing. While it's okay to machine wash your pants every now and then, you might want to consider really only washing them when absolutely necessary. Since jeans are made of denim, a cotton fiber fabric, they swell when they come in contact with water, making them extra delicate. Also, frequent washing will cause your jeans to lose color faster. When your pants are washed, stay away from the dryer and let them dry lying down.

Now that summer has started, you can even let your pants dry in the fresh air. But even here you should keep one thing in the back of your head: Avoid strong, direct sunlight! This acts like a natural bleaching agent on your pants and makes them more vulnerable to damage. After that, it's best not to use the iron.

## Last but not least - correct storage

Even after washing, you can actively help your jeans last a long time. Often we think nothing of it when we fold our jeans and put them back in the closet. In the long run, however, this is not too good for your pants, because they can change their color at the folding points - which we don't want, of course. That's why it's a good idea to put your pants on a hanger in your closet.

## Final Word

The older a pair of jeans gets, the more beautiful they become - but only if you take good care of them. Thus, follow our tips and wear your jeans for the rest of your life! But if the long time of love and wearing has left marks, send them to us. Our #LocalHeros love to repair jeans. And trust us, there's more possible than you think!

> "The older a pair of jeans, the more beautiful they become - But only if you've taken proper care of them."
  
`;

export default weilWirIhrTreuBleibenWollenBodyEn;
