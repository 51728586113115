import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { ProductControllerCreateRequestBody } from '../../request-bodies/product-controller-create.request-body';
import { ProductControllerCreateResponse } from '../../responses/product-controller-create.response';

interface ProductControllerCreateOpenApiParameters {
  requestBody: ProductControllerCreateRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.POST,
  apiName: 'customer',
  path: '/product',
  operationId: 'ProductController_create',
  parameters: [],
})
export class ProductControllerCreateMethod
  extends BaseOpenApiMethod<ProductControllerCreateResponse>
  implements Method<ProductControllerCreateResponse, ProductControllerCreateOpenApiParameters>
{
  public call(parameters: ProductControllerCreateOpenApiParameters): Promise<ProductControllerCreateResponse> {
    return super.call(parameters);
  }
}
