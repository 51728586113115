import OrderApi from '../interfaces/api';
import appRequest from '../../utils/request';
import { Customer, Order } from '../../types';

/**
 * Module API definitions for production use.
 *
 * These definitions can only be covered by end-to-end (e2e) tests.
 */
const orderApiProd: OrderApi = {
  fetchOrder: async (orderId: string) => {
    const { data, status } = await appRequest(`order/by-id?orderId=${orderId}`);
    if (status === 500) {
      return null;
    }
    return data;
  },
  fetchOrderWithAssignmentListById: async (customerId: Customer['_id'], orderId: Order['_id']) => {
    const { data, status } = await appRequest(`/customer/order-with-assignment-list/${orderId}/${customerId}`);
    if (status === 500) {
      return null;
    }
    return data;
  },
  fetchShippingOrder: async (orderId: string) => {
    const { data, status } = await appRequest(`shipping-order/by-id?orderId=${orderId}`);
    if (status === 500) {
      return null;
    }
    return data;
  },
  fetchVoucherOrder: async (orderId: string) => {
    const { data, status } = await appRequest(`voucher-group/${orderId}`);
    if (status === 500) {
      return null;
    }
    return data;
  },
};

export default orderApiProd;
