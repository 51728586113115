import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { AssignmentControllerUnassignParameter } from '../../parameters/assignment-controller-unassign.parameter';

interface AssignmentControllerUnassignOpenApiParameters {
  parameters: AssignmentControllerUnassignParameter;
}

@OpenApiMethod({
  method: HttpMethods.PATCH,
  apiName: 'customer',
  path: '/assignment/{assignmentId}/unnasign',
  operationId: 'AssignmentController_unassign',
  parameters: [
    {
      name: 'assignmentId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class AssignmentControllerUnassignMethod extends BaseOpenApiMethod<void> implements Method<void, AssignmentControllerUnassignOpenApiParameters> {
  public call(parameters: AssignmentControllerUnassignOpenApiParameters): Promise<void> {
    return super.call(parameters);
  }
}
