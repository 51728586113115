import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';

@OpenApiMethod({
  method: HttpMethods.POST,
  apiName: 'customer',
  path: '/auth/create-guest-user',
  operationId: 'AuthController_createGuestUser',
  parameters: [],
})
export class AuthControllerCreateGuestUserMethod extends BaseOpenApiMethod<void> implements Method<void, void> {
  public call(): Promise<void> {
    return super.call();
  }
}
