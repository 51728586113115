import { fork } from 'redux-saga/effects';
import cartSaga from '../Cart/store/sagas';
import AppApi from '../interfaces/api';
import productSaga from '../Product/store/sagas';
import appApi from '../api';
import checkoutSaga from '../Checkout/store/sagas';
import tailorSaga from '../Tailor/store/sagas';
import platformConfigSaga from '../PlatformConfig/store/sagas';
import voucherSaga from 'Voucher/store/sagas';
import voucherCartSaga from 'Cart/store/voucherSaga';

export default function* appSaga(api: AppApi = appApi) {
  yield fork(checkoutSaga);
  yield fork(productSaga, api.product);
  yield fork(voucherSaga, api.voucher);
  yield fork(platformConfigSaga, api.platformConfig);
  yield fork(cartSaga, api.cart);
  yield fork(voucherCartSaga, api.voucherCart);
  yield fork(tailorSaga, api.tailor);
}
