import React from 'react';
import { Drawer, Box, makeStyles } from '@material-ui/core';
import Menu from '../Menu';
import { ReactComponent as Close } from '../../images/svg/back.svg';
import classes from './MenuDrawer.module.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 0,
    backgroundColor: theme.palette.primary.main,
    backgroundImage: 'none',
  },
}));

export default function MenuDrawer({ open, onClose }) {
  const muiClasses = useStyles();
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="left"
      classes={{
        paper: muiClasses.paper,
      }}
    >
      <div className={classes.container}>
        <header className={classes.header}>
          <button className={classes.closeButton} onClick={onClose}>
            <Close title="Close" />
          </button>
        </header>
        <Menu onLinkClick={onClose} />
        <footer className={classes.footer}>
          <Box mb={1}>
            <a href="mailto:hello@repair-rebels.com" className={classes.contact}>
              hello@repair-rebels.com
            </a>
          </Box>
          <Box>
            <a href="tel:+49 173 5204782" className={classes.contact}>
              +49 173 5204782
            </a>
          </Box>
        </footer>
      </div>
    </Drawer>
  );
}
