import AuthApi from '../interfaces/api';
import { UserState } from '../store/type';
import { Address } from '../../interfaces/customer-data';
import { PlaceDto } from '../../api/components/place-dto';

async function changePreferredLanguage(lang: string): Promise<void> {}

async function forgotPassword(): Promise<boolean> {
  return false;
}

async function login(email: string, password: string): Promise<UserState | null> {
  return {} as UserState;
}

async function logout(): Promise<void> {}

async function register(): Promise<any> {}

async function resetPassword(): Promise<UserState | null> {
  return null;
}

async function createPassword(): Promise<UserState | null> {
  return null;
}

async function confirmSignUp(token, abortController): Promise<boolean> {
  return false;
}

async function deleteUser(): Promise<{ success: boolean }> {
  return { success: true };
}

async function changePassword(customerId, newPassword: string): Promise<UserState | null> {
  return null;
}

async function verifyAddress(address: Address): Promise<(PlaceDto & { message?: string }) | null> {
  return {} as PlaceDto;
}

const authApiTest: AuthApi = {
  changePreferredLanguage,
  changePassword,
  forgotPassword,
  createPassword,
  login,
  logout,
  register,
  resetPassword,
  deleteUser,
  confirmSignUp,
  verifyAddress,
};

export default authApiTest;
