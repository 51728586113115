import { AssignmentControllerGetAssignedMethod } from '../methods/assignment-controller/get-assigned.method';
import { AssignmentControllerGetDoneMethod } from '../methods/assignment-controller/get-done.method';
import { AssignmentControllerGetPendingMethod } from '../methods/assignment-controller/get-pending.method';
import { AssignmentControllerGetUnassignedMethod } from '../methods/assignment-controller/get-unassigned.method';
import { AssignmentControllerMarkAsDoneMethod } from '../methods/assignment-controller/mark-as-done.method';
import { AssignmentControllerUnassignMethod } from '../methods/assignment-controller/unassign.method';

export class AssignmentController {
  public readonly getUnassigned = new AssignmentControllerGetUnassignedMethod();
  public readonly getAssigned = new AssignmentControllerGetAssignedMethod();
  public readonly getDone = new AssignmentControllerGetDoneMethod();
  public readonly getPending = new AssignmentControllerGetPendingMethod();
  public readonly markAsDone = new AssignmentControllerMarkAsDoneMethod();
  public readonly unassign = new AssignmentControllerUnassignMethod();
}
