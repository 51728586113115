import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { TailorControllerAssignAssignmentParameter } from '../../parameters/tailor-controller-assign-assignment.parameter';
import { TailorControllerAssignAssignmentRequestBody } from '../../request-bodies/tailor-controller-assign-assignment.request-body';

interface TailorControllerAssignAssignmentOpenApiParameters {
  parameters: TailorControllerAssignAssignmentParameter;
  requestBody: TailorControllerAssignAssignmentRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.PATCH,
  apiName: 'customer',
  path: '/tailor/{tailorId}/assign',
  operationId: 'TailorController_assignAssignment',
  parameters: [
    {
      name: 'tailorId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class TailorControllerAssignAssignmentMethod extends BaseOpenApiMethod<void> implements Method<void, TailorControllerAssignAssignmentOpenApiParameters> {
  public call(parameters: TailorControllerAssignAssignmentOpenApiParameters): Promise<void> {
    return super.call(parameters);
  }
}
