import PlatformConfigApi from '../interfaces/api';
import { CityConfigDto } from '../../api/components/city-config-dto';
import { CartConstantsDto } from '../../api/components/cart-constants-dto';
import { PlatformConfig } from '../../api/components/platform-config';
import API_SERVICE from '../../api/api.service';

const platformConfigApiprod: PlatformConfigApi = {
  getAllPlatformConfig: async (): Promise<PlatformConfig> => {
    return await API_SERVICE.platformConfigController.getAllPlatformConfig.call();
  },
  getAllCartConstants: async (): Promise<CartConstantsDto> => {
    return await API_SERVICE.platformConfigController.getAllCartConstants.call();
  },
  getallCityConfig: async (): Promise<CityConfigDto[]> => {
    return await API_SERVICE.platformConfigController.getAllCityConfig.call();
  },
  getSpecificCityConfig: async (cityId: string): Promise<CityConfigDto> => {
    return await API_SERVICE.platformConfigController.getCityConfig.call({ parameters: { cityId } });
  },
};

export default platformConfigApiprod;
