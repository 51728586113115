import { RouteComponentProps, useNavigate } from '@reach/router';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectCartItems } from '../../../Cart/store/selectors';
import { findByGarmentId } from '../../../Cart/utils';
import NotFound from '../../../components/NotFound';
import { groupByRepair } from '../../utils';
import ProductLoading from '../Loading';
import ProductRepairStepView from './View';
import { Product, Repair } from '../../../types';
import i18n from 'i18n';

// utility
// exporting to tests
export function filterRepairs(products: Product[], cartItems: Repair[], seo: string): Product[] {
  const foundCartItem = findByGarmentId(cartItems, seo);

  if (!foundCartItem) {
    return [];
  }

  const categoryId = foundCartItem.product.productCategory.seo?.[i18n.language];
  const typeId = foundCartItem.product.productType.seo?.[i18n.language];
  //const takenRepairIds = filteredCartItems.map(x => x.repair.id); // TODO
  return groupByRepair(products, categoryId, typeId);
  //.filter(x => !takenRepairIds.includes(x.id)); // TODO
}

interface ProductRepairStepGarmentRouteProps {
  seo: string;
  itemId: string;
}
interface ProductRepairStepGarmentProps extends RouteComponentProps<ProductRepairStepGarmentRouteProps> {
  products: Product[];
}

/**
 * Product repair step component (garment mode).
 */
export default function ProductRepairStepGarment({ products, seo, itemId }: ProductRepairStepGarmentProps) {
  const navigate = useNavigate();
  const cartItems = useSelector(selectCartItems);

  if (!seo || !itemId) {
    return <NotFound />;
  }

  if (!cartItems || !cartItems.length) {
    return <ProductLoading />;
  }

  const foundCartItem = findByGarmentId(cartItems, seo);

  if (!foundCartItem) {
    return <NotFound />;
  }

  const categoryId = foundCartItem.product.productCategory.seo?.[i18n.language];
  const typeId = foundCartItem.product.productType.seo?.[i18n.language];
  function handleRepairSelect(x: Product) {
    navigate(`../${seo}/${categoryId}/${typeId}/${x.seo?.[i18n.language]}/${itemId}`);
  }

  const repairs = filterRepairs(products, cartItems, seo);

  return (
    <ProductRepairStepView
      repairs={repairs}
      onRepairSelect={handleRepairSelect}
      categoryId={categoryId}
      typeId={typeId}
      backLinkHref={`../../success/${seo}`}
    />
  );
}
