import { makeStyles } from '@material-ui/core';
import React from 'react';
import bgSm from './partnerships-bg-sm.png';
import newBg from './partnerships-bg-new.png';
import logo from './logo.png';
import { useTranslation } from 'react-i18next';
import bar from '../../../images/svg/bar2.svg';

const useStyles = makeStyles((theme) => {
  const s = (x) => theme.spacing(x);

  return {
    introBlock: {
      background: `url(${newBg})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      minHeight: '500px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        minHeight: '0',
        alignItems: 'center',
        background: `url(${bgSm})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      },
    },
    mainContent: {
      textAlign: 'center',
      maxWidth: '300px',
      marginLeft: '100px',
      [theme.breakpoints.down('sm')]: {
        padding: `${s(5)}px ${s(0)}px`,
        margin: '0 auto',
      },
    },
    rebelsTitle: {
      backgroundImage: `url(${bar})`,
      backgroundSize: '100% 100%',
      padding: `${s(2)}px ${s(2)}px`,
      width: '100%',
      fontSize: s(4),
    },
    brendTitle: {
      color: '#ffffff',
      padding: `${s(1.5)}px ${s(0)}px`,
      fontSize: s(4),
    },
    partnershipsText: {
      color: '#ffffff',
      paddingBottom: s(2.5),
    },
    buttonLink: {
      fontSize: s(2),
      fontWeight: 500,
      color: '#ffffff',
      background: theme.palette.secondary.main,
      padding: `${s(0.5)}px ${s(1)}px`,
      transition: '0.4s ease-in-out',
      '&:hover': {
        color: '#ffffff',
        scale: 1.1,
      },
    },
  };
});

const Partnerships = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <div className="container">
        <h2 className="h4 with-bar mb-4">{t('CorporatePartnerships')}</h2>
        <div className={classes.introBlock}>
          <div className={classes.mainContent}>
            <h2 className={classes.rebelsTitle}>{t('REPAIR REBELS')}</h2>
            <div>
              <img src={logo} alt="logo" />
              <h3 className={classes.brendTitle}>{t('YourBrand')}</h3>
              <p className={classes.partnershipsText}>{t('Collaborate with brands')}</p>
              <a href="mailto:partnerships@repair-rebels.com" rel="noopener noreferrer" target="_blank" className={classes.buttonLink}>
                {t('GetInTouch')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partnerships;
