import TailorApi from '../interfaces/api';
import { Craftsman } from '../../types';
import appRequest from '../../utils/request';

async function fetchTailors(): Promise<Craftsman[]> {
  const { data } = await appRequest('/tailor/all');
  return data;
}

/**
 * Tailor API definitions for production use.
 *
 * These definitions can only be covered by end-to-end (e2e) tests.
 */
const tailorApiProd: TailorApi = {
  fetchTailors,
};

export default tailorApiProd;
