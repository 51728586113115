import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { DiscountControllerGetAllResponse } from '../../responses/discount-controller-get-all.response';

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/discount/all',
  operationId: 'DiscountController_getAll',
  parameters: [],
})
export class DiscountControllerGetAllMethod
  extends BaseOpenApiMethod<DiscountControllerGetAllResponse>
  implements Method<DiscountControllerGetAllResponse, void>
{
  public call(): Promise<DiscountControllerGetAllResponse> {
    return super.call();
  }
}
