import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { OrderControllerMarkAsPaidParameter } from '../../parameters/order-controller-mark-as-paid.parameter';
import { OrderControllerMarkAsPaidRequestBody } from '../../request-bodies/order-controller-mark-as-paid.request-body';

interface OrderControllerMarkAsPaidOpenApiParameters {
  parameters: OrderControllerMarkAsPaidParameter;
  requestBody: OrderControllerMarkAsPaidRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.PATCH,
  apiName: 'customer',
  path: '/order/{orderId}/mark-as-paid',
  operationId: 'OrderController_markAsPaid',
  parameters: [
    {
      name: 'orderId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class OrderControllerMarkAsPaidMethod extends BaseOpenApiMethod<void> implements Method<void, OrderControllerMarkAsPaidOpenApiParameters> {
  public call(parameters: OrderControllerMarkAsPaidOpenApiParameters): Promise<void> {
    return super.call(parameters);
  }
}
