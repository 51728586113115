import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { CartConstantsControllerGetShippingExemptionThresholdResponse } from '../../responses/cart-constants-controller-get-shipping-exemption-threshold.response';

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/cart-constants/shipping-exemption-threshold',
  operationId: 'CartConstantsController_getShippingExemptionThreshold',
  parameters: [],
})
export class CartConstantsControllerGetShippingExemptionThresholdMethod
  extends BaseOpenApiMethod<CartConstantsControllerGetShippingExemptionThresholdResponse>
  implements Method<CartConstantsControllerGetShippingExemptionThresholdResponse, void>
{
  public call(): Promise<CartConstantsControllerGetShippingExemptionThresholdResponse> {
    return super.call();
  }
}
