import ContactApi from '../interfaces/api';
import ContactRequest from '../interfaces/contactRequest';
import API_SERVICE from '../../api/api.service';

async function postMessage(requestBody: ContactRequest): Promise<void> {
  await API_SERVICE.mailerController.support.call({ requestBody });
}

/**
 * Contact API definitions for production use.
 *
 * These definitions can only be covered by end-to-end (e2e) tests.
 */
const contactApiProd: ContactApi = {
  postMessage,
};

export default contactApiProd;
