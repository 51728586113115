import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { CartControllerGetCartResponse } from '../../responses/cart-controller-get-cart.response';

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/cart',
  operationId: 'CartController_getCart',
  parameters: [],
})
export class CartControllerGetCartMethod extends BaseOpenApiMethod<CartControllerGetCartResponse> implements Method<CartControllerGetCartResponse, void> {
  public call(): Promise<CartControllerGetCartResponse> {
    return super.call();
  }
}
