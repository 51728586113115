import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';
import { Box, Button, IconButton, Menu } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as AccountImage } from './account.svg';
import classes from './Menu.module.css';
import { CustomerAuthRole } from '../../../enums';
import { shallowEqual, useSelector } from 'react-redux';
import AppState from '../../../interfaces/app-state';
import { getPage } from '../../../utils/pages';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiPaper-root': {
      marginLeft: '.9rem',
    },
  },
}));

export default function AuthMenu() {
  const { t } = useTranslation();
  const paper = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useSelector(
    (state: AppState) => ({
      user: state.auth,
    }),
    shallowEqual
  );
  const isMember = user.role === CustomerAuthRole.CUSTOMER;

  function handleOpenClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleSignOut() {
    setAnchorEl(null);
    sessionStorage.removeItem('customerData');
    sessionStorage.removeItem('shippingCity');
    sessionStorage.removeItem('deliveryType');
  }

  return (
    <div>
      <IconButton aria-controls="auth-menu" aria-label="Auth menu" onClick={handleOpenClick}>
        <AccountImage width="1.5rem" height="1.5rem" />
      </IconButton>
      <Menu
        className={paper.root}
        classes={{
          paper: classes.paper,
        }}
        id="auth-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <Box hidden={isMember}>
          <Button color="secondary" component={Link} fullWidth onClick={handleClose} to={`${getPage('auth')}${getPage('login')}`} variant="contained">
            {t('Login')}
          </Button>
          <div />
          <Button color="secondary" component={Link} fullWidth onClick={handleClose} to={`${getPage('auth')}${getPage('register')}`} variant="contained">
            {t('Register')}
          </Button>
        </Box>
        <Box hidden={!isMember}>
          <Button color="secondary" component={Link} fullWidth onClick={handleClose} to={getPage('profile')} variant="contained">
            {t('Profile')}
          </Button>
          <div />
          <Button color="secondary" component={Link} fullWidth onClick={handleSignOut} to={`${getPage('auth')}${getPage('logout')}`} variant="contained">
            {t('Sign Out')}
          </Button>
        </Box>
        {/*<Box display="flex" alignItems="center">*/}
        {/*  <CircularProgress color="secondary" size="25px" />*/}
        {/*</Box>*/}
      </Menu>
    </div>
  );
}
