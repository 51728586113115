import React, { useContext, useState } from 'react';
import { Box, Container, Paper, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Helmet } from 'react-helmet';
import ApiContext from '../../../api/context';
import LoadingButton from '../../../components/LoadingButton';
import AppBackLink from '../../../components/BackLink';

// schema
const schema = z.object({
  email: z.string().nonempty().email(),
});
type Schema = z.infer<typeof schema>;

/**
 * Forgot-Password component.
 */
export default function AuthForgotPassword(props: RouteComponentProps) {
  const { t } = useTranslation();
  const api = useContext(ApiContext);
  const [loading, setLoading] = useState(false);
  const [globalError, setGlobalError] = useState<string | null>(null);
  const defaultValues: Schema = {
    email: '',
  };
  const { errors, getValues, handleSubmit, register } = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });
  const [isSent, setSent] = useState(false);

  function handleChange() {
    setGlobalError(null);
  }

  async function triggerSubmit() {
    setGlobalError(null);
    setLoading(true);
    try {
      const success = await api.auth.forgotPassword(getValues().email);
      setSent(success);
    } catch (err) {
      setGlobalError(err.message); // TODO: i18n
    } finally {
      setLoading(false);
    }
  }

  const renderForm = () => (
    <Paper>
      <Typography variant="h2" component="h1">
        {t('Forgot your password?')}
      </Typography>
      <Box mb="1rem" />
      <Typography variant="body1">{t('auth_password_reset_hint')}</Typography>
      <Box mb="1.5rem" />
      <form onSubmit={handleSubmit(triggerSubmit)}>
        <TextField
          error={!!errors.email}
          fullWidth
          helperText={t(errors?.email?.message!)}
          inputRef={register}
          name="email"
          onChange={handleChange}
          placeholder={t('Email')}
          variant="outlined"
          autoComplete="email"
        />
        <Box mb="1rem" />
        <Box hidden={!globalError} ml=".75rem" mb="1rem">
          <Typography color="error" variant="body1">
            {globalError}
          </Typography>
        </Box>
        <Box textAlign="center">
          <LoadingButton color="secondary" loading={loading} type="submit" variant="contained">
            {t('Send')}
          </LoadingButton>
        </Box>
      </form>
    </Paper>
  );

  const renderSuccess = () => (
    <Paper>
      <Typography variant="h2" component="h1">
        {t('Success')}
      </Typography>
      <Box mb="1rem" />
      <Typography variant="body1">{t('auth_forgot_password_success_text')}</Typography>
      <Box mb="1.5rem" />
    </Paper>
  );

  return (
    <Container maxWidth="sm">
      <Helmet>
        <title>{t('forgot-password-title')}</title>
        <meta name="description" content={t('forgot-password-description')} />
      </Helmet>
      <Box mb={2}>
        <AppBackLink isGoBackActivated />
      </Box>
      {isSent && renderSuccess()}
      {!isSent && renderForm()}
    </Container>
  );
}
