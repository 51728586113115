import { put, takeEvery, fork } from 'redux-saga/effects';
import { setCustomerData, setCustomerShippingData } from '../actions';
import { SET_CUSTOMER_DATA, SET_CUSTOMER_SHIPPING_DATA } from '../types';

function* checkoutSetCustomerDataSaga() {
  try {
    yield takeEvery(SET_CUSTOMER_DATA, function (action: any) {
      const { customerData } = action.payload;
      sessionStorage.setItem('customerData', JSON.stringify(customerData));
      sessionStorage.setItem('joinClub', JSON.stringify(customerData.flags.joinClub));
    });
    yield takeEvery(SET_CUSTOMER_SHIPPING_DATA, function (action: any) {
      const { customerShippingData } = action.payload;
      sessionStorage.setItem('customerShippingData', JSON.stringify(customerShippingData));
      sessionStorage.setItem('joinClub', JSON.stringify(customerShippingData.flags.joinClub));
    });
  } catch (err) {
    if (process.env.NODE_ENV === 'development') {
      console.error(err);
    }
    // TODO: handle error
  }
}

export default function* checkoutSaga() {
  try {
    // retrieving customer data from sessionStorage, if any
    const customerDataStr = sessionStorage.getItem('customerData');
    const customerShippingDataStr = sessionStorage.getItem('customerShippingData');

    if (customerDataStr) {
      const customerData = JSON.parse(customerDataStr);
      if (customerData) yield put(setCustomerData(customerData));
    }

    if (customerShippingDataStr) {
      const customerShippingData = JSON.parse(customerShippingDataStr);
      if (customerShippingData) yield put(setCustomerShippingData(customerShippingData));
    }
  } catch (err) {
    if (process.env.NODE_ENV === 'development') {
      console.error(err);
    }
    // TODO: handle error
  }
  // customer data state for paypal
  yield fork(checkoutSetCustomerDataSaga);
}
