import API_SERVICE from '../../api/api.service';
import { CartControllerMigrateParameter } from '../../api/parameters/cart-controller-migrate.parameter';
import appRequest from '../../utils/request';
import { VoucherItem } from 'Voucher/intefaces/item';
import VoucherCartApi from 'Cart/interfaces/voucherApi';

async function fetchCartItems(): Promise<VoucherItem[]> {
  const { data } = await appRequest('cart');
  return data.voucherItems;
}

async function pushCartItem(voucherId: string): Promise<VoucherItem> {
  const requestBody = {
    voucherId,
  };
  const { data } = await appRequest('cart', {
    method: 'POST',
    data: requestBody,
  });
  return data;
}

/**
 * Cart API definitions for production use.
 *
 * These definitions can only be covered by end-to-end (e2e) tests.
 */
const voucherCartApiProd: VoucherCartApi = {
  addVoucherToCart: async (voucherId: string) => {
    return await pushCartItem(voucherId);
  },
  clearCart: async () => {
    //await API_SERVICE.cartController.clearCart.call();
    await appRequest('cart', {
      method: 'DELETE',
    });
    // TODO: disable this method
  },
  deleteItem: async (itemId: string) => {
    await API_SERVICE.cartController.removeCartItem.call({ parameters: { itemId } });
  },
  fetchCart: () => {
    const numBoxes = localStorage.getItem('numBoxes');

    if (!numBoxes) {
      localStorage.setItem('numBoxes', '0');
    }

    const itemsRequest = fetchCartItems();
    const constantsRequest = API_SERVICE.cartConstantsController.get.call();
    return Promise.all([itemsRequest, constantsRequest]).then(([voucherItems]) => ({
      voucherItems,
      voucher: false
    }));
  },
  fetchItems: async () => {
    return await fetchCartItems();
  },
  migrateCart: async (previousUserId: string | null = null) => {
    const parameters: CartControllerMigrateParameter = {};
    if (previousUserId) {
      parameters.previousUserId = previousUserId;
    }
    await API_SERVICE.cartController.migrate.call({ parameters });
  },
};

export default voucherCartApiProd;
