import GA4React from 'ga-4-react';

let ga4;

const initGA = async (trackingID: string) => {
  try {
    const ga4react = new GA4React(trackingID);
    ga4 = await ga4react.initialize();
  } catch (err) {
    console.error(err);
  }
};

const pageView = (location: string) => {
  if (!ga4) {
    if (process.env.NODE_ENV === 'development') {
      console.log('GA not initialized');
    }
    return null;
  }
  ga4.pageview(location);
};

const sendEvent = (category: string, action: string, label: string = '', nonInteraction: boolean = false) => {
  if (!ga4) {
    if (process.env.NODE_ENV === 'development') {
      console.log('GA not initialized');
    }
    return null;
  }
  if (process.env.NODE_ENV === 'development') {
    action += '-test';
  }
  ga4.event(action, label, category, nonInteraction);
};

export { initGA, pageView, sendEvent };
