import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { TransportControllerMarkAsStuckRequestBody } from '../../request-bodies/transport-controller-mark-as-stuck.request-body';

interface TransportControllerMarkAsStuckOpenApiParameters {
  requestBody: TransportControllerMarkAsStuckRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.PATCH,
  apiName: 'customer',
  path: '/transport/mark-as-stuck',
  operationId: 'TransportController_markAsStuck',
  parameters: [],
})
export class TransportControllerMarkAsStuckMethod extends BaseOpenApiMethod<void> implements Method<void, TransportControllerMarkAsStuckOpenApiParameters> {
  public call(parameters: TransportControllerMarkAsStuckOpenApiParameters): Promise<void> {
    return super.call(parameters);
  }
}
