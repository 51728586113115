import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { PlatformConfigControllerGetAllPlatformConfigResponse } from '../../responses/platform-config-controller-get-all-platform-config.response';

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/platform-config/all',
  operationId: 'PlatformConfigController_getAllPlatformConfig',
  parameters: [],
})
export class PlatformConfigControllerGetAllPlatformConfigMethod
  extends BaseOpenApiMethod<PlatformConfigControllerGetAllPlatformConfigResponse>
  implements Method<PlatformConfigControllerGetAllPlatformConfigResponse, void>
{
  public call(): Promise<PlatformConfigControllerGetAllPlatformConfigResponse> {
    return super.call();
  }
}
