import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { ProfileControllerRemoveAddressParameter } from '../../parameters/profile-controller-remove-address.parameter';

interface ProfileControllerRemoveAddressOpenApiParameters {
  parameters: ProfileControllerRemoveAddressParameter;
}

@OpenApiMethod({
  method: HttpMethods.DELETE,
  apiName: 'customer',
  path: '/profile/address-list/{addressId}',
  operationId: 'ProfileController_removeAddress',
  parameters: [
    {
      name: 'addressId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class ProfileControllerRemoveAddressMethod extends BaseOpenApiMethod<void> implements Method<void, ProfileControllerRemoveAddressOpenApiParameters> {
  public call(parameters: ProfileControllerRemoveAddressOpenApiParameters): Promise<void> {
    return super.call(parameters);
  }
}
