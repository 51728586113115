import { call, put } from 'redux-saga/effects';
import TailorApi from '../../interfaces/api';
import { setTailors } from '../actions';

export default function* tailorSaga(api: TailorApi) {
  try {
    const tailors = yield call(api.fetchTailors);
    yield put(setTailors(tailors));

    // for (let tailor of tailors) {
    //   yield put(tailor.place);
    // }
  } catch (err) {
    if (process.env.NODE_ENV === 'development') {
      console.error(err);
    }
    // TODO: handle error
  }
}
