import { CustomerData } from '../../interfaces/customer-data';
import CheckoutApi from '../interfaces/api';
import { OrderDiscount } from '../../types';

function generateOrderCustomId() {
  return 'TEST_ORDER_CUSTOM_ID';
}

async function createOrder(orderCustomId: string, customerData: CustomerData, boxCount: number, discount?: OrderDiscount | null) {
  return { customerId: 'customerId', orderId: 'TEST_ORDER_ID' };
}

async function addDiscountCode(discountCode: string): Promise<number> {
  return 10;
}

/**
 * Checkout API definitions for automated testing.
 *
 * The definitions of this API are used in automated tests as defaults.
 */
const checkoutApiTest: CheckoutApi = {
  generateOrderCustomId,
  createOrder,
  addDiscountCode,
};

export default checkoutApiTest;
