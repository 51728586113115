import { ProductControllerArchiveByIdMethod } from '../methods/product-controller/archive-by-id.method';
import { ProductControllerCreateMethod } from '../methods/product-controller/create.method';
import { ProductControllerGetByIdMethod } from '../methods/product-controller/get-by-id.method';
import { ProductControllerGetPaginatedMethod } from '../methods/product-controller/get-paginated.method';
import { ProductControllerGetProductCategoryListMethod } from '../methods/product-controller/get-product-category-list.method';
import { ProductControllerGetProductGridMethod } from '../methods/product-controller/get-product-grid.method';
import { ProductControllerGetProductListMethod } from '../methods/product-controller/get-product-list.method';
import { ProductControllerGetProductTypeListMethod } from '../methods/product-controller/get-product-type-list.method';
import { ProductControllerSetByIdMethod } from '../methods/product-controller/set-by-id.method';

export class ProductController {
  public readonly getProductList = new ProductControllerGetProductListMethod();
  public readonly getProductGrid = new ProductControllerGetProductGridMethod();
  public readonly getProductTypeList = new ProductControllerGetProductTypeListMethod();
  public readonly getProductCategoryList = new ProductControllerGetProductCategoryListMethod();
  public readonly getPaginated = new ProductControllerGetPaginatedMethod();
  public readonly create = new ProductControllerCreateMethod();
  public readonly getById = new ProductControllerGetByIdMethod();
  public readonly archiveById = new ProductControllerArchiveByIdMethod();
  public readonly setById = new ProductControllerSetByIdMethod();
}
