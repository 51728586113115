import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { PlatformConfigControllerGetAllCartConstantsResponse } from '../../responses/platform-config-controller-get-all-cart-constants.response';

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/platform-config/cart-constants',
  operationId: 'PlatformConfigController_getAllCartConstants',
  parameters: [],
})
export class PlatformConfigControllerGetAllCartConstantsMethod
  extends BaseOpenApiMethod<PlatformConfigControllerGetAllCartConstantsResponse>
  implements Method<PlatformConfigControllerGetAllCartConstantsResponse, void>
{
  public call(): Promise<PlatformConfigControllerGetAllCartConstantsResponse> {
    return super.call();
  }
}
