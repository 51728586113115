import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  InputAdornment,
} from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { Link, RouteComponentProps, useNavigate } from '@reach/router';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import AppBackLink from '../../../components/BackLink';
import LoadingButton from '../../../components/LoadingButton';
import { ReactComponent as UncheckedIcon } from '../../../images/svg/checkbox-unchecked.svg';
import { ReactComponent as CheckedIcon } from '../../../images/svg/checkbox-checked.svg';
import AuthRegisterData, { schema } from '../../interfaces/register-data';
import ApiContext from '../../../api/context';
import EmailTaken from '../../errors/email-taken';
import classes from './Register.module.css';
import { toastr } from 'react-redux-toastr';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Helmet } from 'react-helmet';
import { getPage } from '../../../utils/pages';

/**
 * Sign-Up component.
 */
export default function AuthRegister(props: RouteComponentProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const api = useContext(ApiContext);
  const isTablet = useMediaQuery(({ breakpoints }) => breakpoints.only('md'));
  const [loading, setLoading] = useState(false);
  const [globalError, setGlobalError] = useState<string | null>(null);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [passwordRepeatVisibility, setPasswordRepeatVisibility] = useState(false);
  const defaultValues: AuthRegisterData = {
    contact: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    },
    password: {
      password: '',
      confirm: '',
    },
    flags: {
      joinClub: false,
      acceptTerms: false,
    },
  };
  const { errors, getValues, watch, handleSubmit, register, setError } = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  async function triggerSubmit() {
    setGlobalError(null);
    setLoading(true);
    try {
      const {
        contact: { email },
        flags: { joinClub },
      } = getValues();
      if (joinClub) {
        await api.club.invite(email);
      }
      const error = await api.auth.register(getValues());

      if (error) {
        setGlobalError(t(error));
        return;
      }
      await navigate('/');
      toastr.info('Account created', "Verify your email using the link we've sent you!");
    } catch (err) {
      if (err instanceof EmailTaken) {
        setError('contact.email', { message: t('Email already taken') });
      } else {
        setGlobalError(t('Unexpected error occurred'));
        if (process.env.NODE_ENV === 'development') {
          console.error(err);
        }
      }
    } finally {
      setLoading(false);
    }
  }

  const handlePasswordInputType = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const handlePasswordRepeatInputType = () => {
    setPasswordRepeatVisibility(!passwordRepeatVisibility);
  };

  return (
    <Grid container spacing={4}>
      <Helmet>
        <title>{t('register-title')}</title>
        <meta name="description" content={t('register-description')} />
      </Helmet>
      <Grid item xs={12} lg={6}>
        <Box pl={isTablet ? 5 : 0} pb={1}>
          <AppBackLink isGoBackActivated />
        </Box>
        <div className={classes.registerSection}>
          <Typography variant="h2" component="h1">
            {t('auth_register_heading', 'Register')}
          </Typography>
          <Box mb="1.5rem" />
          <form onSubmit={handleSubmit(triggerSubmit)}>
            <Box component="fieldset" mb="1.25rem">
              <TextField
                className={classes.textField}
                error={!!errors.contact?.firstName}
                fullWidth
                helperText={t(errors.contact?.firstName?.message!)}
                inputRef={register}
                name="contact.firstName"
                placeholder={`${t('Name')}*`}
                variant="outlined"
                autoComplete="given-name"
              />
              <TextField
                className={classes.textField}
                error={!!errors.contact?.lastName}
                fullWidth
                helperText={t(errors.contact?.lastName?.message!)}
                inputRef={register}
                name="contact.lastName"
                placeholder={`${t('Last Name')}*`}
                variant="outlined"
                autoComplete="family-name"
              />
              <TextField
                className={classes.textField}
                error={!!errors.contact?.email}
                fullWidth
                helperText={t(errors.contact?.email?.message!)}
                inputRef={register}
                name="contact.email"
                placeholder={`${t('Email')}*`}
                variant="outlined"
                autoComplete="email"
              />
              <TextField
                className={classes.textField}
                error={!!errors.contact?.phoneNumber}
                fullWidth
                helperText={t(errors.contact?.phoneNumber?.message!)}
                inputRef={register}
                name="contact.phoneNumber"
                placeholder={`${t('Phone Number')}*`}
                variant="outlined"
                autoComplete="tel"
              />
            </Box>
            <Typography variant="h3" component="h2">
              {t('Password')}
            </Typography>
            <Box mb="1rem" />
            <Box component="fieldset" mb="1.5rem">
              <TextField
                className={classes.textField}
                error={!!errors.password?.password}
                fullWidth
                helperText={t(errors.password?.password?.message!)}
                inputRef={register}
                name="password.password"
                placeholder={`${t('Password')}*`}
                type={passwordVisibility ? 'text' : 'password'}
                variant="outlined"
                autoComplete="new-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" classes={{ root: classes.inputBtnWrapper }}>
                      {!passwordVisibility && (
                        <Button variant="contained" color="primary" classes={{ root: classes.inputBtn }} onClick={handlePasswordInputType}>
                          <VisibilityOffIcon />
                        </Button>
                      )}
                      {passwordVisibility && (
                        <Button variant="contained" color="primary" classes={{ root: classes.inputBtn }} onClick={handlePasswordInputType}>
                          <VisibilityIcon />
                        </Button>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                className={classes.textField}
                error={!!errors.password?.confirm}
                fullWidth
                helperText={t(errors.password?.confirm?.message!)}
                inputRef={register}
                name="password.confirm"
                placeholder={`${t('Confirm Password')}*`}
                type={passwordRepeatVisibility ? 'text' : 'password'}
                variant="outlined"
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" classes={{ root: classes.inputBtnWrapper }}>
                      {!passwordRepeatVisibility && (
                        <Button variant="contained" color="primary" classes={{ root: classes.inputBtn }} onClick={handlePasswordRepeatInputType}>
                          <VisibilityOffIcon />
                        </Button>
                      )}
                      {passwordRepeatVisibility && (
                        <Button variant="contained" color="primary" classes={{ root: classes.inputBtn }} onClick={handlePasswordRepeatInputType}>
                          <VisibilityIcon />
                        </Button>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box pl=".5rem" mb="1rem">
              <FormControlLabel
                control={<Checkbox checkedIcon={<CheckedIcon />} defaultChecked={defaultValues.flags.joinClub} icon={<UncheckedIcon />} />}
                inputRef={register}
                label={
                  <Box pl=".5rem">
                    <Typography style={{ fontWeight: 600 }} variant="body2">
                      {t('auth_register_join_club_label', 'Join the SuperRebel Club')}
                    </Typography>
                    <Typography variant="body2">({t('auth_register_join_club_sublabel', 'Subscribe to our Newsletter')})¹</Typography>
                  </Box>
                }
                name="flags.joinClub"
              />
            </Box>
            <Box pl=".5rem" mb="1rem">
              <FormControl>
                <FormControlLabel
                  control={<Checkbox checkedIcon={<CheckedIcon />} icon={<UncheckedIcon />} />}
                  inputRef={register}
                  label={
                    <Box pl=".5rem">
                      <Typography style={{ fontWeight: 600 }} variant="body2">
                        <Trans
                          i18nKey="auth_register_accept_terms_label"
                          defaultValue="Accept the Terms and Conditions"
                          t={t}
                          components={[<Link className="base-link" to={getPage('privacyPolicy')} />, <Link className="base-link" to={getPage('terms')} />]}
                        />
                      </Typography>
                    </Box>
                  }
                  name="flags.acceptTerms"
                />
                <Box hidden={!errors.flags?.acceptTerms} ml=".5rem">
                  <FormHelperText error>{t(errors.flags?.acceptTerms?.message!)}</FormHelperText>
                </Box>
              </FormControl>
            </Box>
            <Box pl=".5rem" mb="1.5rem">
              <Typography variant="caption">{t('fields_with_asterisks')}</Typography>
            </Box>
            <Box hidden={!globalError} ml=".5rem" mb="1rem">
              <Typography color="error" variant="body1">
                {globalError}
              </Typography>
            </Box>
            <Box textAlign="center">
              <LoadingButton color="secondary" loading={loading} type="submit" variant="contained" disabled={!watch()?.flags?.acceptTerms}>
                {t('auth_register_submit_label', 'Register and Continue')}
              </LoadingButton>
            </Box>
          </form>
        </div>
        <Box mt="1.5rem" pl=".5rem">
          <Typography variant="caption" component="p">
            {t('newsletter_clarification')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} lg={6} className={classes.loginBox}>
        <div className={classes.loginSection}>
          <Typography variant="h3" component="h2">
            {t('auth_register_login_heading', 'Do you already have an account?')}
          </Typography>
          <Box mb="1rem" />
          <Box textAlign="center">
            <Button color="secondary" component={Link} to={`${getPage('auth')}${getPage('login')}`} variant="contained">
              {t('Sign In')}
            </Button>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}
