import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { WarmupControllerWarmupResponse } from '../../responses/warmup-controller-warmup.response';

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/_ah/warmup',
  operationId: 'WarmupController_warmup',
  parameters: [],
})
export class WarmupControllerWarmupMethod extends BaseOpenApiMethod<WarmupControllerWarmupResponse> implements Method<WarmupControllerWarmupResponse, void> {
  public call(): Promise<WarmupControllerWarmupResponse> {
    return super.call();
  }
}
