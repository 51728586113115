import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';
import { Typography } from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import formatPriceCent from '../../../utils/format-price-cent';
import { ReactComponent as DefaultImage } from '../../../images/svg/default.svg';
import estimate from '../../../images/svg/estimate.svg';
import classes from './DetailedButton.module.css';
import cookies from 'js-cookie';

interface ProductDetailedButtonViewProps {
  name: {
    en: string;
    de: string;
  };
  manualDescription?: string;
  description?:
    | string
    | {
        en: string;
        de: string;
      };
  htmlId?: string;
  imageUrl?: string;
  priceCent?: number;
}

function ProductDetailedButtonView({ name, description, htmlId, imageUrl, priceCent, manualDescription }: ProductDetailedButtonViewProps) {
  const { t } = useTranslation();
  const langCookie = cookies.get('language') || '';

  return (
    <>
      {!!imageUrl && (
        <LazyLoadImage
          className={classes.image}
          alt={name}
          src={imageUrl}
          effect="blur"
          // height={image.height}
          // width={image.width}
        />
      )}
      {!imageUrl && <DefaultImage className={classes.image} />}
      <div className={classes.textContainer}>
        <Typography color="textPrimary" gutterBottom id={htmlId} variant="h5">
          <Typography color="textPrimary" gutterBottom id={htmlId} variant="h5">
            {!langCookie ? name.de : name[langCookie]}
          </Typography>
        </Typography>
        {typeof description === 'string' ? (
          <Typography color="textPrimary" variant="body1">
            {description}
          </Typography>
        ) : (
          description?.de && (
            <Typography color="textPrimary" variant="body1">
              {!langCookie ? description.de : description[langCookie]}
            </Typography>
          )
        )}
        {manualDescription && (
          <Typography color="textPrimary" variant="body1">
            {t(manualDescription)}
          </Typography>
        )}
        {priceCent && (
          <Typography align="right" color="textPrimary" variant="h5">
            {t('from')}: {formatPriceCent(priceCent)}
          </Typography>
        )}
      </div>
    </>
  );
}

export function ProductDetailedButtonLink() {
  const { t } = useTranslation();
  return (
    <Link className={classes.main} to="/estimate/new">
      <ProductDetailedButtonView name={t('Get estimation')} manualDescription={t('Get estimation description')} imageUrl={estimate} />
    </Link>
  );
}

interface ProductDetailedButtonProps {
  name: {
    en: string;
    de: string;
  };
  imageUrl?: string;
  onClick?(): void;
  manualDescription?: string;
  description?:
    | string
    | {
        en: string;
        de: string;
      };
  /**
   * @deprecated
   * @use `priceCent`
   */
  price?: number;
  priceCent?: number;
  /**
   * @deprecated
   * @use call router's `navigate` via `onClick`
   */
  nextUrl?: string;
}

export default function ProductDetailedButton({ name, manualDescription, description, imageUrl, onClick, priceCent }: ProductDetailedButtonProps) {
  const langCookie = cookies.get('language') || '';
  const htmlId = `ProductDetailedButton__${(!langCookie ? name.de : name[langCookie]).split(' ').join('-')}`;
  return (
    <button aria-labelledby={htmlId} className={classes.main} onClick={onClick}>
      <ProductDetailedButtonView
        name={name}
        manualDescription={manualDescription}
        description={description}
        htmlId={htmlId}
        imageUrl={imageUrl}
        priceCent={priceCent}
      />
    </button>
  );
}

// NOTE the filter styling I use for the SVG is fairly new and might cause issues on some browsers
/*<img className={classes.overlayImage} alt={name} src={bg} />*/
