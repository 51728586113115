/**
 * Markdown
 */
const repairRebelsLaunchEventDe = `
  ## Fast Fashion war gestern, Slow Fashion und damit auch Reparaturen sind die Zukunft!
 
  Düsseldorf, 25. August 2022. Wir öffneten unsere Türen für Modebegeisterte und
  Mode-Umdenker der Neuzeit. Nach einer intensiven Marktforschungs- und
  IT-Entwicklungsphase ging das Repair Rebels Online Reparatur-Buchungssystem live und
  das haben wir gebürtig gefeiert.
  Wir digitalisieren die lokalen Handwerksindustrie, um so die Modeindustrie zu
  revolutionieren und eine Alternative zur „Wegwerfkultur“ anzubieten. Auf dem Launch Event
  in unserer Co-Working Space auf der Lorettostraße 9, haben sich ganz unter dem Repair
  Rebels Motto "Repaired Clothes are Loved Stronger" die Düsseldorfer Mode-Community
  versammelt, um über die Zukunft von nachhaltiger Mode, zeitlosem Design und lokaler
  Handwerkskunst zu diskutieren.

  Langzeit Unterstützer und Partner unseres Startups, Prof. Dr. Stefan Spinler von der WHU -
  Otto Beisheim School of Management, Tobias Werthwein von der Handwerkskammer
  Düsseldorf und João Matias von YKK Europe erschienen, um unserem Team einen guten
  Start zu wünschen und hebten die steigende Bedeutung der Digitalisierung im Handwerk und
  den Bedarf für branchenübergreifende Zusammenarbeit hervor.
  
  Beim Panel Talk konnten Heiko Wunder von Wunderwerk Fashion, Schuhmachermeister
  Rolf Rainer von Runder Tisch Reparatur und Lukas Punder von CANO Shoes & Retraced
  zum Thema Globalisierung und lokale Handwerkskunst mit ihren langjährigen Erfahrungen
  praktische Insights für mehr Nachhaltigkeit innerhalb der Branche liefern. In der letzten
  Diskussionsrunde zum Thema Future Fashion Resell, Rent & Repair haben die Design &
  Brand Communication Expertin Sandra Lai-Chun Cheung und Janice Saya von The Mintage
  Vintage Store, den Gästen, mit ihren persönlichen Erfahrungen und Einblicken innerhalb des
  Wiederverkaufs in der Modebranche die Rolle der Konsumenten, sowie einfach umsetzbare
  Tipps beim nachhaltigen Shoppen aufgezeigt. Die Devise lautete, trage so lange wie möglich
  was du schon im Kleiderschrank hast, kaufe ansonsten Second-Hand ein oder miete deine
  Kleidung, sodass du erst als letzte Option nachhaltige Labels shoppst.
  
  Zwischen den Talks führten der Austausch und die Vernetzung aller Gäste mit Live DJ-Musik
  und vegetarischem Essen zu spannenden Diskussionen. “Dieser Ort kann der Treffpunkt
  einer starken Reparatur- und Handwerkskunst-Community werden, die Stimmung heute war
  extraordinär und man merkt, dass die Menschen hier was bewirken wollen”, wie Rolf Rainer
  dem Team mitteilte.
  
  Viele weitere nachhaltige Mode-Startups, Designer und Modeexperten nahmen an dem
  Abend teil und feierten mit uns. Obwohl Repair Rebels ein Online-Service ist, konnten Gäste,
  wie auch Passanten, an dem Abend ihre Kleidungsreparaturen an der Repair Station
  persönlich vorbeibringen und das gesamte Team kennenlernen. Die nächste Veranstaltung
  der Repair Rebels ist bereits für den 15. Oktober, anlässlich 
  des [Internationalen Reparaturtags](https://openrepair.org/international-repair-day/), geplant. Stay tuned!
  
  > "Die Stimmung heute war extraordinär und man merkt,
    dass die Menschen hier was bewirken wollen" - Rolf Rainer Schuhmachermeister
`;

export default repairRebelsLaunchEventDe;
