import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { ProfileControllerUpdateRequestBody } from '../../request-bodies/profile-controller-update.request-body';

interface ProfileControllerUpdateOpenApiParameters {
  requestBody: ProfileControllerUpdateRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.PATCH,
  apiName: 'customer',
  path: '/profile',
  operationId: 'ProfileController_update',
  parameters: [],
})
export class ProfileControllerUpdateMethod extends BaseOpenApiMethod<void> implements Method<void, ProfileControllerUpdateOpenApiParameters> {
  public call(parameters: ProfileControllerUpdateOpenApiParameters): Promise<void> {
    return super.call(parameters);
  }
}
