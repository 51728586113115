import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { OrderControllerUpdateDeliveryParameter } from '../../parameters/order-controller-update-delivery.parameter';
import { OrderControllerUpdateDeliveryRequestBody } from '../../request-bodies/order-controller-update-delivery.request-body';
import { OrderControllerUpdateDeliveryResponse } from '../../responses/order-controller-update-delivery.response';

interface OrderControllerUpdateDeliveryOpenApiParameters {
  parameters: OrderControllerUpdateDeliveryParameter;
  requestBody: OrderControllerUpdateDeliveryRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.PATCH,
  apiName: 'customer',
  path: '/order/reschedule-delivery?{orderId}&{customerId}&{transportId}',
  operationId: 'OrderController_updateDelivery',
  parameters: [
    {
      name: 'orderId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
    {
      name: 'customerId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
    {
      name: 'transportId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class OrderControllerUpdateDeliveryMethod
  extends BaseOpenApiMethod<OrderControllerUpdateDeliveryResponse>
  implements Method<OrderControllerUpdateDeliveryResponse, OrderControllerUpdateDeliveryOpenApiParameters>
{
  public call(parameters: OrderControllerUpdateDeliveryOpenApiParameters): Promise<OrderControllerUpdateDeliveryResponse> {
    return super.call(parameters);
  }
}
