enum OrderStatus {
  // ALL = 'all',
  UNPAID = 'unpaid',
  OPEN = 'open',
  READY_TO_PICK_UP = 'ready-to-pick-up',
  PICK_UP = 'pick-up',
  PICK_UP_NOT_POSSIBLE = 'pick-up-no-possible',
  PROCESSING = 'processing',
  READY_TO_DELIVERY = 'ready-to-delivery',
  DELIVERY = 'delivery',
  DELIVERY_NOT_POSSIBLE = 'delivery-not-possible',
  CLOSED = 'closed',
  CANCELED = 'canceled',
}

export { OrderStatus };
