import { useEffect } from 'react';

export default function ScrollRestore({ children, location }) {
  useEffect(() => {
    const timeout = setTimeout(() => window.scrollTo(0, 0), 250);

    return () => clearTimeout(timeout);
  }, [location.pathname]);
  return children;
}
