import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { PlatformConfigControllerGetCityConfigParameter } from '../../parameters/platform-config-controller-get-city-config.parameter';
import { PlatformConfigControllerGetCityConfigResponse } from '../../responses/platform-config-controller-get-city-config.response';

interface PlatformConfigControllerGetCityConfigOpenApiParameters {
  parameters: PlatformConfigControllerGetCityConfigParameter;
}

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/platform-config/city/{cityId}',
  operationId: 'PlatformConfigController_getCityConfig',
  parameters: [
    {
      name: 'cityId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class PlatformConfigControllerGetCityConfigMethod
  extends BaseOpenApiMethod<PlatformConfigControllerGetCityConfigResponse>
  implements Method<PlatformConfigControllerGetCityConfigResponse, PlatformConfigControllerGetCityConfigOpenApiParameters>
{
  public call(parameters: PlatformConfigControllerGetCityConfigOpenApiParameters): Promise<PlatformConfigControllerGetCityConfigResponse> {
    return super.call(parameters);
  }
}
