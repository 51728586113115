import { SupportedCities } from '../../enums';

export const DEFAULT_CITY = SupportedCities.DUSSELDORF;

const SUPPORTED_CITIES = Object.values(SupportedCities);

const SUPPORTED_ZIP_CODE_CITIES = [
  SupportedCities.DUSSELDORF,
  SupportedCities.COLOGNE,
];

enum ZipCodeCities {
  Dusseldorf = 'Düsseldorf',
  Cologne = 'Cologne',
  // Essen = 'Essen',
}

export type AllowedCities = typeof SUPPORTED_CITIES[number];

export { SUPPORTED_CITIES, SUPPORTED_ZIP_CODE_CITIES, ZipCodeCities };
