/**
 * Markdown
 */
const secondHandSeptemberDe = `
  Second-Hand-Mode gewinnt heutzutage immer mehr an Beliebtheit. So kauft 19% der
  deutschen Bevölkerung ihre Kleidung über diverse Internetportale, wie zum Beispiel ebay,
  upup,com oder rebelle.com, und rund 15% bedienen sich der Kleidung im Ladengeschäft
  (Modeverband Deutschland, 2020). Um der Second-Hand-Ware jedoch eine größere
  Plattform zu bieten, gibt es den sogenannten "Second-Hand-September".

  ## ÜBER SECOND-HAND-SEPTEMBER - DIE GESCHICHTE
 
  Seit 2019 inspiriert die Kampagne, entstanden in Großbritannien durch die Hilfs- und
  Entwicklungsorganisation Oxfam, Menschen auf der ganzen Welt zu einem nachhaltigeren
  Umgang mit Mode. Oxfam ist bekannt dafür, sich weltweit für die Menschenrechte und die
  Bekämpfung von Ungleichheit bezüglich Armut und Ungerechtigkeit zu engagieren. Mit
  dieser Kampagne ruft die Organisation dazu auf, für 30 Tage nichts Neues außer
  Second-Hand-Ware zu shoppen und auch zu spenden (Oxfam, 2022).
  Jetzt fragt ihr euch vielleicht noch, wozu das Ganze gut sein soll. Diese Frage können wir
  euch ganz einfach beantworten: Der Umwelt zu Liebe und um Fast Fashion zu boykottieren.
  Genaueres dazu später mehr!
  Und sind wir mal ganz ehrlich: Auch euer Kleiderschrank wird es euch danken, wenn ihr
  selber mal ordentlich aussortiert. Denn im Durchschnitt trägt jeder von uns 64% seiner
  Kleidung nicht- eine extreme Verschwendung (Statista, 2022)!
  Um der Vergeudung vorzubeugen, hat man im September die Gelegenheit, ein Teil dieser
  Bewegung zu werden. Ganz nach dem Motto: Gutes tun, alte Lasten aussortieren und
  gleichzeitig neue Unikate zum Verlieben finden- wir finden jedenfalls das klingt ziemlich cool!
  
  ## WARUM IST SECOND-HAND SO WICHTIG?
  
  Nach Angabe der Europäischen Kommission (2022), hat der europäische Textilkonsum nach
  dem Konsum von Lebensmitteln, Wohnraum und Mobilität die viertgrößten Auswirkungen
  auf die Umwelt und den Klimawandel. Hitzewellen, schmelzenden Polarkappen und weitere
  Naturkatastrophen sind schließlich die Reaktionen der Natur auf Grund der weltweit
  steigenden Treibhausgas- Emissionen.
  Um die Problematik noch besser zu verstehen, schauen wir uns ein Beispiel an: Bei der
  Produktion einer neuen Jeans werden schätzungsweise 16,2 kg CO2 ausgestoßen. Das
  entspricht einer Autostrecke von 58 Meilen/ 93 km- ein erschreckendes Ergebnis (Oxfam,
  2022)! Somit ist der Klimawandel ein Thema, was die ganze Menschheit betrifft, weshalb
  diverse Maßnahmen zur Nachhaltigkeit getroffen werden müssen. Es könnte also keinen
  besseren Zeitpunkt dafür geben, jetzt zu handeln.
  
  Durch das Spenden und mehrmalige Tragen von Kleidung können wir einen Beitrag dazu
  leisten, die Nachfrage nach neuer Kleidung zu senken, wodurch wir der Umwelt weniger
  Schaden zufügen. Insbesondere durch die Unterstützung von Hilfsorganisationen, wie
  Oxfam, kann gegen globale Ungleichheiten und Armut vorgegangen werden, da diese den
  Erlös verkaufter Kleidung in soziale Projekte investieren. Auch wenn wir nicht glauben, dass
  die Verantwortung für die Lösung zu einer nachhaltigeren Umwelt bei uns Verbrauchern
  liegt, so kann mit Hilfe des Second-Hand-Septembers ein Teil dazu beigetragen werden, ein
  umweltbewussteres Leben zu führen.
  
  ## WIE KANN ICH EIN TEIL DER KAMPAGNE WERDEN?
  
  Hast du nun Lust deinen Teil dazu beizutragen? - Dann Willkommen im Team Fashion
  Rebels! Du wirst sehen, es geht ganz einfach! Damit dir deine Mission auch auf jeden fall
  gelingt, geben wir dir jetzt noch ein paar Tipps mit.
  
  Du hast selber einen vollen Kleiderschrank und ziehst davon sowieso nur die Hälfte an?
  Dann gib dir einen Ruck und verkaufe deine Kleidung ganz einfach über beliebte Anbieter
  wie beispielsweise Ebay Kleinanzeigen, Vinted oder Vestiare Collective- aus eigener
  Erfahrung sagen wir, es funktioniert super! Oder spende direkt deinen Berg an Klamotten an
  gemeinnützige Organisationen.
  
  Keine Kleidung zum Verkaufen Zuhause? Du willst dennoch nachhaltig in gebrauchte Mode
  investieren? Dann kannst du nachhaltig shoppen gehen. Dafür hat dir dein Repair Rebels
  Team extra eine Favoritenliste von Second-Hand-Shops in Düsseldorf -für jeden Style und
  jedes Budget- zusammengestellt!
  
  Und vergiss nicht, es auf den sozialen Medien zu teilen! Allein im vergangenen Jahr haben
  mehr als 50.000 Menschen Bilder mit der Bildunterschrift #SecondHandSeptember auf den
  sozialen Medien gepostet. Wenn du also ein neues Lieblingsstück aus zweiter Hand
  ergattert hast, kannst du die Kampagne mit dem Hashtag promoten und somit mehr
  Aufmerksamkeit und Bewusstsein für nachhaltige Mode schaffen (marie claire, 2022).
  
  ## SECOND-HAND-SHOPS IN DÜSSELDORF - EMPFEHLUNG REPAIR REBELS
  
  ### BROKE
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/c0075175-9984-4b77-963c-b37a9df60d5f.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/81209a33-9509-41bb-96d5-3dc580d224be.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/e479e038-b032-490e-9ca2-aace676117d8+(1).jpg)

  **FOTOS** [@broke.thestore](https://www.instagram.com/broke.thestore/)\n
  **ADRESSE:** Tußmannstr. 5, Düsseldorf

  Dieses kleine Herzstück hat besonders für Männer einiges zu bieten, aber auch Frauen
  finden hier sicherlich einige Schätze. Hier kann man Taschen, coole oversized Shirts und
  Sneaker finden, aber auch Neuware ist vereinzelt dabei. Der Style geht eher Richtung
  Street- und Sportswear mit Marken wie Vans, Adidas, G-Star usw..
  
  ### FRAUENZIMMER VINTAGE
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/50A4062E-371C-4696-8672-04E047EAE158_1_201_a.jpeg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/79A7FA78-384C-474A-8EDF-78E47DBCCED2_1_201_a.jpeg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/9F135E4A-DE63-4F73-8D78-0AB298EE8644_1_201_a.jpeg)

  **FOTOS** [@frauenzimmer_secondhandfashion](https://www.instagram.com/frauenzimmer_secondhandfashion/)\n
  **ADRESSE:** Bilker Allee 24, Düsseldorf
  
  Hochwertige Vintage-Kleidung für Frauen erwartet dich beim “Frauenzimmer Vintage”.
  Deren Konzept definiert sich durch französische Mode und attraktive Preise. Zu finden sind
  hier im Übrigen auch Schmuck, Accessoires und Möbelstücken!
  
  ### OXFAM
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/76f9ac3a-5f6b-4e3a-8d9a-c357d5a12725.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/b6e9d703-4f4a-4ec6-bf91-ea8999f9ff5c.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/0a8e0702-e2b9-4216-9daa-f553b2cb9c04.jpg)

  **FOTOS** [@oxfam_de](https://www.instagram.com/oxfam_de/)\n
  **ADRESSE:** Nordstraße 9, Düsseldorf

  Wenn ihr bei eurem Second-Hand-Kauf noch gleichzeitig was Soziales tun wollt, können wir
  euch Oxfam empfehlen. Denn die weltweit agierende Entwicklungsorganisation bekämpft,
  mit Hilfe von Spendenware, Armut. Zu kaufen gibt es beispielsweise Kleidung, Bücher und
  Deko.
  
  ### THE MINTAGE
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/b5dffa33-cdd5-4d8f-b6a5-b0f123ab5b80.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/4c02427a-1369-454b-8497-c59d57921939.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/ee2579b0-0d67-4fbe-9d1b-178d53123592.jpg)

  **FOTOS** [@the_mintage](https://www.instagram.com/the_mintage/)\n
  **ADRESSE:** Wallstraße 27, 40213 Düsseldorf

  Klassische Vintage-Ware aus den USA und Looks von den 60iger bis 80iger Jahren gibt es
  hier ordentlich zu kaufen. Zwischen ausgefallen indie Designermarken, Cowboy Stiefeln und
  lustigen retro Details findet man hier aber natürlich auch Second-Hand-Designer-Kleidung.
  Ein Besuch lohnt sich auf jeden Fall immer!
    
  ### UNTOLD-UNISEX
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/3d3b8582-3a83-47ce-aa6f-fd25993dc1ef.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/9b41ded7-b6b7-4601-ba54-22358072d629.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/426df73f-c481-4097-8ab4-2a5211d1cca0.jpg)

  **FOTOS** [@untoldshop__](https://www.untoldshop.de/about)\n
  **ADRESSE:** Momentan ist nur der [Onlineshop](https://www.untoldshop.de/) verfügbar

  Untold bietet mit seinem Online Konzept Unisex-Vintage- und Second-Hand-Kleidung, sowie
  Accessoires an. Für sie definiert sich Kleidung eher durch die Persönlichkeit, anstatt, dass
  sie an ein Geschlecht gebunden ist. Mit Hilfe von anderen Designern und Unternehmen
  kreieren sie nachhaltige Kollektionen, die in den Alltag gut integriert werden können.
      
  ### KOKO SELECTED
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/2f1d05ce-d79c-4f26-b248-d6be28557374.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/5329176e-8228-4a0c-b082-3c88a5be0f44.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/523900e2-e797-45b5-a099-a1361695ac79.jpg)

  **FOTOS** [@koko.selected](https://www.instagram.com/koko.selected/)\n
  **ADRESSE:** Hermannstraße 47, 40233 Düsseldorf

  Auch in diesem Store in Düsseldorf findest du Second-Hand-Kleidung von guter Qualität und
  modernem Look. Bestimmte Marken gibt es hierbei nicht. Stattdessen findet ihr hier einen
  Mix aus kuratierter Fast Fashion und Vintage-Kleidung. Zusätzlich wird Kleidung für Kinder,
  sowie ein kleines Neuwarensortiment an nachhaltiger Mode, Accessoires und Dekoartikeln
  angeboten. (Textilwirtschaft, 2021)

  ### OLIVAS VINTAGE
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/cd3499a4-3ed6-4ac0-9d51-1594ad85e485.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/bd67b2a3-0891-4105-87fc-bbf606e418c0.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/1b7c076a-bb18-4db7-8f89-e45085a70af0.jpg)

  **FOTOS** [@olivas_vintage](https://www.instagram.com/olivas_vintage/)\n
  **ADRESSE:** Bilker Allee 62, 40219 Düsseldorf

  Olivas Vintage ist eines der ältesten Vintage-Läden in Düsseldorf und du wirst dich dort wie
  in einer Zeitmaschine fühlen. Fündig wird hier jeder, der an Mode mit Vergangenheit
  interessiert ist, die meist sogar handgenäht ist. Übrigens: Hier gibt es eine große
  Sortimentsauswahl für die Herren!
      
  ### SUPERBRANDS
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/170e418a-96f6-4b49-a587-bc47d487fd16.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/f5e3627d-5e50-4727-b466-05bdc14e4d4b.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/b0e28172-f5ca-4716-b0c3-d5a98c6511a7.jpg)

  **FOTOS** [@superbrands_secondhand](https://www.instagram.com/superbrands_secondhand/?hl=en#)\n
  **ADRESSE:** Bastionstr. 10, 40213 Düsseldorf

  Dies ist ein luxuriöser Designer-Laden mit exzellenter Qualität. Es gibt sowohl einen sehr
  guten Shop in Düsseldorf (Carlstadt) als auch einen Standort in Frankfurt. Alle, die auf der
  Suche nach Second-Hand Taschen, Kleidung und Accessoires von luxuriösen Marken sind,
  sollten hier mal vorbeischauen.
`;

export default secondHandSeptemberDe;
