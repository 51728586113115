import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { TailorControllerCreateRequestBody } from '../../request-bodies/tailor-controller-create.request-body';
import { TailorControllerCreateResponse } from '../../responses/tailor-controller-create.response';

interface TailorControllerCreateOpenApiParameters {
  requestBody: TailorControllerCreateRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.POST,
  apiName: 'customer',
  path: '/tailor',
  operationId: 'TailorController_create',
  parameters: [],
})
export class TailorControllerCreateMethod
  extends BaseOpenApiMethod<TailorControllerCreateResponse>
  implements Method<TailorControllerCreateResponse, TailorControllerCreateOpenApiParameters>
{
  public call(parameters: TailorControllerCreateOpenApiParameters): Promise<TailorControllerCreateResponse> {
    return super.call(parameters);
  }
}
