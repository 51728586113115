import { useState, useEffect, useRef } from 'react';

export const useElementOnScreen = (options) => {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [ranOnce, setRanOnce] = useState(false);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    const ref = containerRef.current;
    if (ref) {
      observer.observe(ref);
    }

    if (isVisible && !ranOnce) {
      setRanOnce(true);
    }

    return () => {
      if (ref) {
        observer.unobserve(ref);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, options]);

  return [containerRef, ranOnce];
};
