import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { PlatformConfigControllerGetAllPaymentMethodsResponse } from '../../responses/platform-config-controller-get-all-payment-methods.response';

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/platform-config/payment-methods',
  operationId: 'PlatformConfigController_getAllPaymentMethods',
  parameters: [],
})
export class PlatformConfigControllerGetAllPaymentMethodsMethod
  extends BaseOpenApiMethod<PlatformConfigControllerGetAllPaymentMethodsResponse>
  implements Method<PlatformConfigControllerGetAllPaymentMethodsResponse, void>
{
  public call(): Promise<PlatformConfigControllerGetAllPaymentMethodsResponse> {
    return super.call();
  }
}
