/**
 * Markdown
 */
const donateClothesDe = `

In einem unserer vorherigen Artikel haben wir bereits darüber gesprochen, wie Du Deine Kleidung vor dem Wegwerfen bewahren kannst. Neben Swap-Partys und der Vermietung Deiner Kleidung gibt es noch viele weitere Möglichkeiten. Eine Option besteht darin, Deine Kleidung zu spenden, damit sie entweder von jemand anderem getragen oder für einen neuen Zweck wiederverwendet werden kann. Hier erfährst Du, warum das eine gute Idee ist und wie Du am besten vorgehst, um Kleidung zu spenden.


In einem unserer vorherigen Artikel sind wir auf Möglichkeiten eingegangen, wie man seine Kleidung vor der Müllkippe bewahrt. Neben Swap Partys und der Vermietung deiner Kleidung gibt es aber noch viele weitere Optionen. Du kannst deine Kleidung zum Beispiel auch spenden, damit sie entweder wieder getragen oder für einen neuen Zweck wiederverwendet werden kann. Warum das Ganze und wie du es am besten spendest, erklären wir dir jetzt! 

## DAS DILEMMA DES SPENDES: WARUM DEINE GUTEN ABSICHTEN MANCHMAL NICHT DEN GEWÜNSCHTEN EFFEKT ERZIELEN ##

In Deutschland werden jedes Jahr unglaubliche eine Million Tonnen Altkleider in diese Container oder Sammlungen gegeben. Stell dir vor, das entspricht der Menge von 62.000 Lastwagen, die, wenn sie hintereinander aufgereiht wären, eine Lkw-Linie von Flensburg bis Innsbruck bilden würden (FairWertung, 2022).

Das Problem besteht darin, dass nicht alles, was in diese Container geworfen wird, auch tatsächlich als Spende geeignet ist. Oft landen beschädigte oder unbrauchbare Kleidungsstücke in diesen Sammlungen, was zu einer Verschwendung von Ressourcen führt und zusätzliche Kosten für die Entsorgung verursacht. Es ist daher wichtig, bewusster zu spenden und zu prüfen, ob die Kleidung in gutem Zustand ist, ohne Löcher oder Flecken. 

Bevor Du jedoch deine Kleidung spendest, informiere dich über Recyclingmöglichkeiten. Es gibt spezielle Programme und Zentren für die Wiederverwertung von Textilien, die eine umweltfreundliche Lösung bieten. Oder Du upcycelst Deine Kleidung einfach selbst! 

## NACHHALTIG UND EINZIGARTIG: DAS NÄCHSTE KAPITEL DEINER KLEIDUNG ##

Kleidung ist etwas Besonderes. Sie begleitet uns durch verschiedene Lebensabschnitte und verleiht unserem Ausdruck einen individuellen Touch. Es wäre schade, wenn sie einfach im Müll landen würde, ohne die Chance auf ein zweites Leben. Doch manchmal gibt es keine andere Möglichkeit, als sich von bestimmten Kleidungsstücken zu trennen. In solchen Momenten ist es wichtig, nach alternativen Konzepten zu suchen, die unserer Kleidung eine neue Bestimmung geben.

Es gibt eine Vielzahl von Unternehmen, die sich mit großer Hingabe diesem Anliegen widmen und innovative Lösungen anbieten. Wir haben einige Firmen herausgesucht, die alternative Konzepte entwickelt haben und damit einen persönlichen Beitrag zum Schutz unserer Umwelt leisten.

### Reparatur ###

Eine besonders nachhaltige und ressourcenschonende Option besteht darin, die Kleidung zu reparieren. Durch Reparaturen können wir den Lebenszyklus unserer Kleidungsstücke verlängern und sie vor dem Wegwerfen bewahren.  
Das Reparieren von Kleidung bietet eine Reihe von Vorteilen. Zum einen sparen wir Geld, da wir keine neuen Kleidungsstücke kaufen müssen. Stattdessen investieren wir in die Qualität und Langlebigkeit unserer vorhandenen Garderobe. Durch Reparaturen können wir zudem unseren persönlichen Stil bewahren und unseren Kleidungsstücken eine individuelle Note verleihen.  
Es gibt verschiedene Möglichkeiten, Kleidung zu reparieren. Manche Reparaturen können wir selbst durchführen, wie das Nähen von losen Knöpfen oder das Ausbessern kleiner Löcher. Für komplexere Reparaturen oder wenn wir selbst nicht über das nötige handwerkliche Geschick verfügen, können wir auf professionelle Nähereien oder Schneider zurückgreifen. Wenn Du Unterstützung bei der Reparatur Deiner Kleidung benötigst, stehen wir von Repair Rebels gerne zur Seite.

### Kleiderkammern ###

Eine wunderbare Möglichkeit, Kleidung sinnvoll weiterzugeben, ist die persönliche Abgabe bei verschiedenen Kleiderkammern. Anstatt die Kleidungsstücke einfach in einen Altkleidercontainer zu werfen und darauf zu hoffen, dass sie bei Bedürftigen ankommen, können wir sie direkt bei den Kleiderkammern abgeben. Diese Einrichtungen existieren schon seit der Nachkriegszeit, um Menschen insbesondere in kalten Jahreszeiten mit warmer Kleidung zu versorgen. Heutzutage gibt es immer noch einige Kleiderkammern, die Sozialhilfeempfänger*Innen, Obdachlosen, Geflüchteten und anderen Bedürftigen helfen. Es ist wichtig, vorher anzurufen und nachzufragen, ob tatsächlich Bedarf besteht (Utopia, 2022). Dadurch stellen wir sicher, dass unsere Kleidung an diejenigen gelangt, die sie wirklich benötigen.

### Deutsches Rotes Kreuz ###

Das Deutsche Rote Kreuz (DRK) versorgt jedes Jahr 1,2 Millionen benachteiligte Menschen mit Kleidung. Dies ist nur möglich, weil viele großzügige Menschen ihre Kleidung spenden. Das DRK hat zwei Modelle für Kleiderspenden entwickelt:  
Kleiderkammern: Wenn wir unsere Kleidung direkt bei den Kleiderkammern, Vereinen oder Ortsverbänden abgeben, besteht eine hohe Chance, dass sie tatsächlich den Bedürftigen zugutekommt. Auf diese Weise können wir sicherstellen, dass unsere Spenden direkt und unmittelbar Menschen in Not helfen.  
Weiterverkauf: Der Rest der Kleiderspenden wird zusammen mit dem gesamten Inhalt der Altkleider-Mülltonne an ein Recyclingunternehmen verkauft. Das erzielte Geld wird für die Unterstützung sozialer Projekte verwendet (Deutsches Rotes Kreuz, 2022).

[Mehr Informationen](https://www.drk.de/spenden/spenderservice/spendentransparenz-spenderservice/kleidersammlung/)

### Malteser ###

Die Malteser sind eine Organisation, die ähnlich wie das DRK verschiedene Ansätze zur Weitergabe von Kleidung verfolgt. Sie betreiben Kleiderkammern, arbeiten mit Recyclingunternehmen zusammen und haben sogar eigene Second-Hand-Läden. Von den gesammelten Kleiderspenden werden 55 % weiterverkauft, 35 % recycelt und 5 % gehen an die Kleiderkammer. Der Rest wird als Abfall behandelt. Bei den Maltesern gibt es insgesamt rund 47 Kleiderkammern, in denen ein Teil der gespendeten Kleidung sortiert und entweder kostenlos oder zu einem geringen Preis abgegeben wird.  
Besonders hochwertige Kleidung kann im Malteserladen in Dortmund abgegeben werden. Das Schöne daran ist, dass alle Erlöse aus dem Verkauf dieser Kleidungsstücke den Malteser-Hilfsprojekten zugutekommen - sei es in unserer unmittelbaren Umgebung oder an Orten auf der Welt, wo die Not besonders groß ist (Malteser, 2022).

[Mehr Informationen](https://www.malteser.de/spenden-helfen/online-spenden.html)

### Caritas ###

Der Caritasverband Düsseldorf setzt sich seit etwa 1985 dafür ein, gebrauchte und neuwertige Kleidung zu sammeln. Das Besondere daran ist, dass diese Kleidung in ihrer eigenen Textilwerkstatt weiterverarbeitet wird. Wenn gesammelte Kleidung nicht in ihren eigenen Läden verkauft werden kann, wird sie an eine Sortierfirma weiterverkauft, die mit FairWertung zusammenarbeitet. Dadurch wird sichergestellt, dass die Kleidung sinnvoll verwertet wird.  
Der Erlös aus dem Verkauf der Kleidung geht ausschließlich in den Aufbau von Arbeitsplätzen für benachteiligte Menschen im Rahmen des Sozialkaufhauses. Damit schafft die Caritas neue Chancen und Perspektiven für Menschen, die Unterstützung benötigen (Caritas, 2014).

[Mehr Informationen](https://www.google.com/url?q=https://www.caritas-muenster.de/content/kleidersammlung-der-caritas/403778.&sa=D&source=docs&ust=1695036187130461&usg=AOvVaw23KbVDIvLgbV50pO8mugID)

### FairWertung ###

FairWertung ist ein beeindruckender Zusammenschluss von Unternehmen, die mit Altkleidern arbeiten und sich für mehr Transparenz in diesem Bereich einsetzen. Was sie besonders auszeichnet, ist ihre strikte Ausrichtung auf gemeinnützige Organisationen. Bei FairWertung wird ein großer Teil der gesammelten Kleidung weiterverkauft, jedoch geht der gesamte Erlös zu 100 % an wohltätige Zwecke.  
Die Altkleidersäcke, die aus Containern oder Haustürsammlungen stammen, werden unsortiert an gewerbliche Textilverwerter geschickt. Die überschüssigen Kleidungsstücke aus sozialen Einrichtungen oder Altkleiderläden werden an Recyclingunternehmen weitergeleitet. Das Sortieren der unterschiedlichen Textilien und Qualitäten geschieht dabei komplett per Hand. Es erfordert viel Sorgfalt und Erfahrung, um die angelieferten Altkleider in bis zu 200 verschiedene Artikel und Qualitäten zu unterscheiden. Jedes einzelne Stück durchläuft mehrere Sortiervorgänge, bis es schließlich nach Art und Qualität klassifiziert und verpackt wird.  
Diese fachgerechte Sortierung stellt sicher, dass so viel wie möglich als Secondhand-Kleidung wiederverwendet werden kann. Selbst die nicht mehr tragbaren Stücke werden dem Recycling zugeführt und in Putzlappen oder Rohstoffe umgewandelt. Im Durchschnitt sind etwa 50 bis 55 Prozent der gesammelten Textilien für den Secondhand-Gebrauch geeignet. Die andere Hälfte findet dennoch eine sinnvolle Verwendung und wird zu anderen nützlichen Produkten verarbeitet oder ordnungsgemäß entsorgt.

[Mehr Informationen](https://fairwertung.de/themen-positionen/positionen/)

### Tafel ###

Die Tafel ist eine gemeinnützige Organisation, die sich deutschlandweit für bedürftige Menschen einsetzt. Ihr Hauptziel besteht darin, Lebensmittel an Menschen zu verteilen, die finanziell benachteiligt sind und sich keine ausreichende Ernährung leisten können. Neben Lebensmitteln und Geldspenden nimmt die Tafel auch Altkleider und Hausrat gerne an.  
Die Altkleiderspenden sind ein wichtiger Bestandteil der Unterstützung, welche die Tafel bietet. Viele Menschen haben Kleidung, die sie nicht mehr tragen oder die ihnen nicht mehr passt, aber immer noch in einem guten Zustand ist. Anstatt diese Kleidungsstücke wegzuwerfen, können sie an die Tafel gespendet werden. Diese sorgt dafür, dass die Kleidung gereinigt, sortiert und an bedürftige Menschen weitergegeben wird. Dadurch erhalten diese Menschen die Möglichkeit, sich angemessen zu kleiden und ihre finanziellen Mittel für andere lebenswichtige Bedürfnisse zu verwenden.

[Mehr Informationen](https://www.tafel.de/spenden)

### Awista ###

Awista ist das Unternehmen, welches im Raum Düsseldorf dafür sorgt, dass gut erhaltene Kleidung und Schuhe gesammelt werden. Im Auftrag der Stadt stellen sie Altkleidercontainer an öffentlichen Straßen und Recyclinghöfen auf. Die Einnahmen, die Awista durch den Verkauf der gesammelten Materialien erzielt, fließen in die städtischen Abfallgebühren und tragen dazu bei, dass sie stabil bleiben. Es gibt auch andere Altkleidersammler, die eigene Container aufstellen, zum Beispiel auf privaten Grundstücken. Allerdings gehen die Erlöse aus diesen Sammlungen nicht in die städtischen Abfallgebühren ein. Deshalb ist es besonders wichtig, die Altkleidercontainer im Auftrag der Stadt leicht erkennen zu können. Infolgedessen sind sie mit einem speziellen Aufkleber gekennzeichnet, was es einfacher macht, sie zu identifizieren.

[Mehr Informationen](https://www.awista.de/glossar/altkleidercontainer/)

### Wertstoffhof ###

Textilien, die noch tragbar sind, können bedenkenlos in den Altkleidercontainer geworfen werden. Falls jedoch der Stoff stark beschädigt ist, zum Beispiel durch Farbe oder Öl, sollte er zur Mülldeponie gebracht werden. Kaputte, nasse oder dreckige Stoffe finden am besten auf dem Wertstoffhof ihre richtige Entsorgungsstelle.

## WARUM ES WICHTIG IST SICH DAMIT AUSEINANDERZUSETZEN, WO DIE SPENDE HINGEHT ##

Kleidung zu spenden klingt für viele erst einmal nach einer guten Tat. Und zwar nach einer völlig unkomplizierten: Altkleidercontainer gibt es in jeder Stadt – sie stehen an Straßenkreuzungen, in Wohngebieten, auf Parkplätzen. Der Skandal um das Deutsche Rote Kreuz – aufgedeckt durch die NDR-Doku „Die Altkleiderlüge“ von 2011 (nicht mehr online verfügbar) – zeigte allerdings: Die gespendete Kleidung aus Altkleidercontainern landet nur in den wenigsten Fällen dort, wo die Spender:innen es vermuten, nämlich ohne Umwege und Kosten bei den Bedürftigen. Die Doku ging dabei auf Kleidercontainer karitativer Hilfsorganisationen und privater Unternehmen ein.

Was stattdessen meistens mit den Kleiderspenden passiert: Sie werden weiter verkauft. Gut erhaltene Kleidung wird an Secondhand-Läden in Deutschland oder Westeuropa verkauft – einer ausführlichen Recherche der „Deutschen Welle“ zufolge macht das den größten Teil des Umsatzes aus, obwohl es nur zwischen zwei und vier Prozent der Altkleider betrifft. Weitere 40 Prozent der gespendeten Kleider werden im Ausland weiterverkauft und landen – je nach Qualität – auf Märkten in Osteuropa, im Mittleren Osten, in Mittelasien und vor allem in Afrika.

Was gar nicht zu gebrauchen ist, muss laut dem Kreislaufwirtschaftsgesetz recycelt werden. Aus solchen Spenden werden dann Putzlappen oder Recyclingstoffe, oft auch Dachpappe, Malervlies oder Autoinnenverkleidungen. Nur was gar nicht anders verwertbar ist, darf als Müll entsorgt oder zur Wärmegewinnung verbrannt werden – das sind immer noch etwa fünf bis 15 Prozent der Kleiderspenden.

Da unbrauchbare Teile vorzugsweise recycelt werden und somit ein zweites Leben beginnen, sind Altkleidercontainer – zumindest wenn es um Umweltschutz geht – keine ganz schlechte Sache. Denn ein recycelter Pullover ist immer noch besser als einer, der im Müll landet und verbrannt werden muss.

Doch welchen Effekt der Teil der Kleiderspenden hat, der nach Afrika, Asien und Südamerika verkauft wird, ist zumindest umstritten. Es gibt die begründete Befürchtung, dass die billige Second-Hand-Kleidung zum Niedergang der lokalen Textilindustrie in Sub-Sahara-Afrika beigetragen hat.

Zur gleichen Zeit, als der NDR die „Altkleiderlüge“ zeigte, berichtete „Zeit Online“ 2011 in einem Artikel, dass allein in Tansania zehntausende Beschäftigte der (ehemals stattlichen) Textilindustrie aufgrund von Kleiderspenden ihre Arbeit verloren hätten. Gegen die günstigen Preise, zu denen die gespendete Kleidung auf den örtlichen Märkten verkauft wird, komme ein einheimisches Produkt nicht an.

Wie kann es sein, dass gemeinnützige Hilfsorganisationen wie das Deutsche Rote Kreuz (DRK) den Verkauf und Export der gespendeten Kleider zulassen? Zum Beispiel, weil die Hosen und Pullover nicht nur transportiert, sondern auch sortiert werden müssen – und das kostet die Organisationen Geld. Diese Kosten können durch den Weiterverkauf gedeckt werden. Nur rund zehn Prozent der Kleiderspenden an das DRK landet in den über 800 Kleiderkammern Deutschlands, wo sie kostenlos oder gegen eine kleine Schutzgebühr an Bedürftige ausgegeben werden. Heute gibt es strengere Maßnahmen, die auch die damalige Situation der Organisation verhindern und für Abhilfe schaffen sollen. Viele Fortschritte wurden in der Transparenz der Organisationen gesichert und mit konkreten Spendezielen kann man - bsp. bei dem DRK - die Distribution selbst als Privatspender auswählen.

[Mehr Informationen](https://utopia.de/ratgeber/gebrauchte-kleidung-sinnvoll-spenden-optionen-und-tipps/)

`;

export default donateClothesDe;