import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { OrderControllerRescheduleDeliveryParameter } from '../../parameters/order-controller-reschedule-delivery.parameter';
import { OrderControllerRescheduleDeliveryRequestBody } from '../../request-bodies/order-controller-reschedule-delivery.request-body';

interface OrderControllerRescheduleDeliveryOpenApiParameters {
  parameters: OrderControllerRescheduleDeliveryParameter;
  requestBody: OrderControllerRescheduleDeliveryRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.PATCH,
  apiName: 'customer',
  path: '/order/reschedule-delivery/{orderId}/customer/{customerId}',
  operationId: 'OrderController_rescheduleDelivery',
  parameters: [
    {
      name: 'orderId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
    {
      name: 'customerId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class OrderControllerRescheduleDeliveryMethod
  extends BaseOpenApiMethod<void>
  implements Method<void, OrderControllerRescheduleDeliveryOpenApiParameters>
{
  public call(parameters: OrderControllerRescheduleDeliveryOpenApiParameters): Promise<void> {
    return super.call(parameters);
  }
}
