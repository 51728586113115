import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { CartControllerRemoveCartItemParameter } from '../../parameters/cart-controller-remove-cart-item.parameter';

interface CartControllerRemoveCartItemOpenApiParameters {
  parameters: CartControllerRemoveCartItemParameter;
}

@OpenApiMethod({
  method: HttpMethods.DELETE,
  apiName: 'customer',
  path: '/cart/{itemId}',
  operationId: 'CartController_removeCartItem',
  parameters: [
    {
      name: 'itemId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class CartControllerRemoveCartItemMethod extends BaseOpenApiMethod<void> implements Method<void, CartControllerRemoveCartItemOpenApiParameters> {
  public call(parameters: CartControllerRemoveCartItemOpenApiParameters): Promise<void> {
    return super.call(parameters);
  }
}
