enum PaymentMethod {
  PAYPAL = 'PAYPAL',
  BANK_TRANSFER = 'BANK_TRANSFER',
  CASH = 'CASH',
}

enum PaymentStatus {
  UNPAID = 'UNPAID',
  REFUNDED = 'REFUNDED',
  TO_REFUND = 'TO_REFUND',
  COMPLETED = 'COMPLETED',
}

export { PaymentMethod, PaymentStatus };
