import { RouteComponentProps, useNavigate } from '@reach/router';
import React from 'react';
import { useCartApi } from '../../../Cart/utils';
import AppNotFound from '../../../components/NotFound/NotFound';
import VoucherStepForm from '../VoucherStepForm/VoucherStepForm';
import { getVoucherById } from 'Product/utils';
import { VoucherItem } from 'Voucher/intefaces/item';

interface VouchertDetailsStepGarmentRouteProps {
  garmentId: string;
  categoryId: string;
  typeId: string;
  repairId: string;
  itemId: string;
  targetId: string;
}
interface VoucherDetailsStepGarmentProps extends RouteComponentProps<VouchertDetailsStepGarmentRouteProps> {
  voucher: VoucherItem[];
}

/**
 * "Further Details" component for an existing garment.
 */
export default function VoucherDetailsStepGarment({ voucher, targetId }: VoucherDetailsStepGarmentProps) {
  const cartApi = useCartApi();
  const navigate = useNavigate();
  const voucherItem = getVoucherById(voucher, targetId ?? '');

  if (!voucherItem || !targetId) {
    return <AppNotFound />;
  }

  const handleSubmit = async () => {
    await cartApi.addVoucherToCart(voucherItem._id);
    navigate(`success/${voucherItem._id}`);
  };

  return <VoucherStepForm voucherItem={voucherItem} onSubmit={handleSubmit} backLinkHref={'../../../'} />;
}
