import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { OrderControllerGetByStatusParameter } from '../../parameters/order-controller-get-by-status.parameter';
import { OrderControllerGetByStatusResponse } from '../../responses/order-controller-get-by-status.response';

interface OrderControllerGetByStatusOpenApiParameters {
  parameters: OrderControllerGetByStatusParameter;
}

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/order/status/{status}',
  operationId: 'OrderController_getByStatus',
  parameters: [
    {
      name: 'status',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class OrderControllerGetByStatusMethod
  extends BaseOpenApiMethod<OrderControllerGetByStatusResponse>
  implements Method<OrderControllerGetByStatusResponse, OrderControllerGetByStatusOpenApiParameters>
{
  public call(parameters: OrderControllerGetByStatusOpenApiParameters): Promise<OrderControllerGetByStatusResponse> {
    return super.call(parameters);
  }
}
