import React from 'react';
import { Box } from '@material-ui/core';
import Categories from './components/Categories';
import HomepageClub from './components/Club';
import Contact from './components/Contact';
import Features from './components/Features';
import Posts from './components/Posts';
import HomepageSponsors from './components/Sponsors';
import Tailors from './components/Tailors';
import Testimonials from './components/Testimonials';
import Video from './components/Video';
import WhatsApp from '../components/WhatsApp';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import RunningLine from 'components/RunningLine';
import BannerSlider from './components/BannerSlider/BannerSlider';
import AwardsSlider from './components/Awards/AwardsSlider';
import Partnerships from './components/Partnerships/Partnerships';

export default function Homepage() {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>{t('landing-title')}</title>
        <meta name="description" content={t('landing-description')} />
      </Helmet>
      <RunningLine isHomePage/>
      <BannerSlider/>
      <AwardsSlider/>
      <Categories />
      <Box mb={7} />
      <WhatsApp />
      <div className="mb-5" />
      {/*<PlanetWallet />*/}
      <div className="mb-5" />
      <Video />
      <div className="mb-5" />
      <div className="d-none d-lg-block mb-5">
        <Features />
      </div>
      <div className="mb-5" />
      <HomepageClub />
      <div className="mb-5" />
      <Testimonials />
      <div className="mb-5" />
      <Posts />
      <div className="mb-5" />
      <Tailors />
      <div className="mb-5" />
      <Partnerships />
      <div className="mb-5" />
      <div className="d-none">
        <Contact />
      </div>
      <div className="mb-5" />
      <HomepageSponsors />
    </div>
  );
}
