import ProfileCityInfo from '../interfaces/city-info-data';

const profileCityInfoFixture: ProfileCityInfo[] = [
  {
    validZipCodes: [
      40210, 40211, 40212, 40213, 40215, 40217, 40219, 40221, 40223, 40225, 40227, 40229, 40231, 40233, 40235, 40237, 40239, 40468, 40470, 40472, 40474, 40476,
      40477, 40479, 40489, 40545, 40547, 40549, 40589, 40591, 40593, 40595, 40597, 40599, 40625, 40627, 40629,
    ],
    documentNumberPrefix: 'DUS',
    number: 'CITY-00001',
    id: 'Dusseldorf',
    textString: {
      de: 'Düsseldorf',
      en: 'Düsseldorf',
    },
  },
  {
    validZipCodes:
      [50667,50668,50670,50672,50674,50676,50677,50678,50679,50733,50735,50737,50739,50765,50767,50769,50823,50825,50827,50829,50858,50859,50931,50933,50935,50937,50939,50968,50969,50996,50997,50999,51061,51063,51065,51067,51069,51103,51105,51107,51109,51143,51145,51147,51149],
    documentNumberPrefix:"CGN",
    number:"CITY-00002",
    id:"Cologne",
    textString: {
      de:"Köln",
      en:"Cologne"
    }
  }
];

export default profileCityInfoFixture;
