/**
 * Markdown
 */
const zusammenarbeitZwischenMonoQuickUndRepairRebelsDe = `
  Modefans haben jetzt noch mehr Gründe, ihre Lieblingsstücke reparieren zu lassen, denn
  wir geben unsere Zusammenarbeit mit [Mono-Quick](https://www.monoquick.de) - dem größten Patch-Unternehmen in
  Deutschland - bekannt. Gemeinsam wollen wir die Reparaturkultur in Deutschland
  wiederbeleben und dazu beitragen, dass die individuellen Geschichten und Erinnerungen
  unserer Lieblingsstücke weiterleben!

  ## Die Geschichte und Herstellung von Patches
 
  Die Rolle von gestickten Aufnähern in der Mode hat eine lange Geschichte und einen
  reichen kulturellen Hintergrund. Ihre Verwendung lässt sich sogar bis ins 3. Jahrhundert v.
  Chr. in China, Südamerika und sogar in die Wikingerzeit zurückverfolgen (OldEnglischCo.,
  2021). Vor der viktorianischen Ära, als die mechanische Nähmaschine erfunden wurde,
  wurden Aufnäher von Hand aufgenäht. Kunsthandwerker nähen ihre Kunst direkt auf die
  Kleidung, wobei sie verschiedene Flick- und Nähtechniken anwenden. Die erste
  Stickmaschine wurde von Alphonse Kursheedt in den frühen 1800er Jahren entwickelt. Sie
  verwendete eine Kombination aus Hand- und Webstuhlstickerei. Einige Jahre später baute
  Issak Groebli die "Schiffli"-Stickmaschine mit einer kontinuierlich eingefädelten Nadel und
  einem Schiffchen, was den Prozess erheblich verbesserte (AmericanPatch, 2013).
  Heutzutage wird die Herstellung von Aufnähern digitalisiert und viele von ihnen können
  einfach auf die Kleidung aufgebügelt werden, das hat sicherlich jeder schon als Kind
  gemacht. Für Menschen, die nicht nähen können oder noch nie einen Faden und eine Nadel
  benutzt haben, ist das eine sehr bequeme und schnelle Möglichkeit, mit der Reparatur von
  Kleidung zu beginnen. Die Personalisierung hierbei ist die Kirsche auf der Torte.
  
  ## Von Punks und Hippies zur High Fashion
  
  Traditionell wurden Aufnäher verwendet, um Löcher zu stopfen und die Kleidung zu
  verschönern, damit sie länger getragen werden kann. Beim Militär hingegen wurden
  Aufnäher verwendet, um den Rang und die Leistung der Leute zu zeigen. Später wurden sie
  von vielen sozialen Bewegungen und Gruppen auf der ganzen Welt zu
  Identifikationszwecken verwendet. Aufnäher wurden bei Protesten gegen den Feminismus,
  für Bürgerrechte und für die Rechte von Homosexuellen verwendet. Das Tragen von
  Aufnähern an den Ärmeln oder auf dem Rücken gab den Menschen eine Stimme und ein
  Gefühl der Zugehörigkeit zu einer Gemeinschaft (Solomatina, 2016). Auch heute noch kann
  das Tragen eines Aufnähers eine Botschaft vermitteln und den sozialen Wandel
  unterstützen.
  
  Die Hippie-Gemeinschaft begann Mitte der sechziger Jahre zu wachsen, als eine Gruppe,
  die für ihre waffenlosen Protestbewegungen mit Liebe, Frieden und Harmonie bekannt war.
  Ihr Modestil verkörperte ihre Werte und politischen Aussagen. Die weiten, aber fließenden
  Hosen, bunten Oberteile, Kopftücher und auffälligen Accessoires wurden selbst hergestellt
  oder aus zweiter Hand gekauft, da der Kauf von Neuware eher unpopulär war, um auf die
  Probleme des Konsumverhaltens hinzuweisen. Aufnäher wurden zu einem festen
  Bestandteil ihrer Kleidung, mit frechen Statements, Regenbögen, Friedenszeichen und
  Smileys.
  
  In den späten siebziger Jahren entstanden schließlich neue Kulturen, die als Alternativkultur
  bekannt wurden. Sie waren das Gegenteil der Hippies, denn sie trugen dunkle Kleidung und
  hatten eine kantige Einstellung, da sie nicht daran glaubten, dass sich alles mit Liebe,
  Frieden und Harmonie lösen ließe. Auch sie spalteten sich in Subkulturen wie die Punks. Ihr
  Modestil war oder ist auch heute noch geprägt von Leder, Used Denim, Spikes und Ketten.
  Aufnäher zeigten die Lieblingsmarken und politische Statements (Mary, 2018). Was in der
  Punk-Community ebenfalls im Trend lag, waren großflächige Schulter- und Rückenpatches.
  Sie wurden in der Regel grob in die Kleidung eingenäht oder mit Sicherheitsnadeln
  angebracht, was ihnen einen noch frecheren Look verlieh. Diese Techniken beeinflussten
  den Bereich der Haute Couture und das Aufkommen von Designern wie Vivien Westwood,
  die ebenfalls gegen das "Anti-Mode-Phänomen" arbeitete (Solomatina, 2016).
  
  Der Designer Raf Simmons zeigte in seinen Kollektionen Aufnäher und beeinflusste weitere
  Designer, es ihm gleichzutun. Heute verwenden Luxusmarken wie Gucci, Moncler Burberry
  oder Marc Jacobs häufig Stickereipatches in ihren Kollektionen. Einige verkaufen ihre selbst
  entworfenen Aufnäher sogar separat, damit wir uns inspiriert fühlen diese für unsere
  Projekte zu verwenden.
  
  ## Deutsche Aufnäher-Pioniere – Mono-Quick
  
  Das Familienunternehmen wurde 1968 von Jörn Rauser gegründet, der seine Kleidung
  gerne mit persönlichen Initialen verzierte. Er begann 1966 in New York, personalisierte
  Hemden mit gestickten Monogrammen zu bestellen - damit war die Idee für Mono-Quick
  geboren. Doch sowohl die Philosophie als auch das Portfolio änderten sich schnell!
  Aufbügelbilder aller Art, um die Lebensdauer von Kleidung kreativ zu verlängern, waren und
  sind die Hauptargumente für Patch-Produkte. Das Familienunternehmen wendet sich
  bewusst nicht an eine bestimmte Kundenzielgruppe, sondern bietet jedem die Möglichkeit,
  Aufnäher je nach eigenem Stil und kultureller Zugehörigkeit auszuwählen. Deshalb bietet
  Mono-Quick heute über 800 Motive und 30 Lizenzen auf ihrer Website an. Seit 2005 führt
  Mirko Rauser in zweiter Generation das Unternehmen operativ durch die Digitalisierung und
  die Herausforderungen unserer Zeit (Mono-Quick, 2022).
  
  Das Herzstück der neuen Produktpalette sind RECYCLPatches, die aus recycelten
  PET-Flaschen hergestellt werden und eine starke Umweltbotschaft haben. Dabei werden 5
  Cent pro verkauftem Stück an den Schul-Klima-Wandel (ein Projekt des SV-Bildungswerks)
  gespendet. Generell ist Mono-Quick auf der Suche nach interessanten
  Kooperationspartnern für ihre Spenden. Im Rahmen ihrer Nachhaltigkeitsbemühungen
  engagiert sich Mono-Quick aktiv in Schulen, indem sie ihre Patches spenden und die
  Jugendlichen über Kleidungsreparatur aufklären.
  
  ## Mono-Quick x Repair Rebels Zusammenarbeit
  
  Mono-Quick und Repair Rebels haben ähnliche Werte, wenn es darum geht, den Abfall von
  Kleidung zu reduzieren und der Kleidung, die wir bereits besitzen, ein zweites Leben zu
  schenken. Durch das Anbringen eines Patches kann ein Kleidungsstück noch persönlicher
  werden - ganz nach dem Motto der Repair Rebels "Reparierte Kleidung wird stärker geliebt".
  Diese Zusammenarbeit hilft uns den Reparaturhorizont zu erweitern, da viele Menschen mit
  der Reparaturkultur noch nicht vertraut sind oder sie als zu kostspielig und unmodern
  ansehen. Zusammen wollen wir zeigen, dass dies nicht der Fall sein muss - ganz im
  Gegenteil! Mit dem einfachen und schnellen Aufbringen eines Patches, sowie der bequemen
  Bestellung einer Online-Reparatur wird die Reparaturkultur in Deutschland wiederbelebt!
  
  Um diese Zusammenarbeit in vollen Zügen zu genießen, benutze bis Ende September 2022 den Code
  **FashionRebels-10** an der Kasse, um 10% Rabatt auf deine Reparaturen zu erhalten und
  zwei Patches von Mono-Quick für dich und deinen Freund geschenkt zu bekommen!
  
  > "Wir glauben an die Mission von Repair Rebels, denn es geht nicht nur darum
  Kleidung zu ersetzen, sondern mit den lokalen Handwerkern zusammenzuarbeiten,
  um Ihrer Lieblingskleidung das lange Leben zu geben, das sie verdient. Gemeinsam
  wollen wir, dass die individuellen Geschichten der Kleidungsstücke weitergehen." - Tatjana Lilienthal, Mono-Quick
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/mq-collab-1.png)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/mq-collab-2.png)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/mq-collab-3.png)
`;

export default zusammenarbeitZwischenMonoQuickUndRepairRebelsDe;
