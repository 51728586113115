import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { CartConstantsControllerGetResponse } from '../../responses/cart-constants-controller-get.response';

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/cart-constants',
  operationId: 'CartConstantsController_get',
  parameters: [],
})
export class CartConstantsControllerGetMethod
  extends BaseOpenApiMethod<CartConstantsControllerGetResponse>
  implements Method<CartConstantsControllerGetResponse, void>
{
  public call(): Promise<CartConstantsControllerGetResponse> {
    return super.call();
  }
}
