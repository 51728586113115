import React, { useContext, useState } from 'react';
import { Box, Button, TextField, Typography, InputAdornment } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps, useLocation, useNavigate } from '@reach/router';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import classes from './Login.module.css';
import LoadingButton from '../../../components/LoadingButton';
import AppBackLink from '../../../components/BackLink';
import ApiContext from '../../../api/context';
import { useDispatch } from 'react-redux';
import { UserSlice } from '../../store';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Helmet } from 'react-helmet';
import { getPage } from '../../../utils/pages';

const LOGIN_USER_DISABLED_MSG = 'The user account has been disabled by an administrator.';

// schema
const schema = z.object({
  email: z.string().nonempty().email(),
  password: z.string().nonempty().min(12),
});
type Schema = z.infer<typeof schema>;

/**
 * Sign In component.
 */
export default function AuthLogin(props: RouteComponentProps) {
  const api = useContext(ApiContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { loginReducer } = UserSlice.actions;
  const [loading, setLoading] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const defaultValues: Schema = {
    email: '',
    password: '',
  };
  const { errors, getValues, handleSubmit, register } = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });
  const [globalError, setGlobalError] = useState<string | null>(null);

  function handleChange() {
    setGlobalError(null);
  }

  async function triggerSubmit() {
    setGlobalError(null);
    setLoading(true);
    const { email, password } = getValues();
    try {
      const user = await api.auth.login(email, password);

      if (!user) {
        return;
      }

      if (!user.active) {
        return setGlobalError(t('Your account has been blocked'));
      }

      dispatch(loginReducer(user));
      await navigate(`${getPage('profile')}/bookings`);
    } catch (err) {
      let { message } = err;
      if (message === LOGIN_USER_DISABLED_MSG) {
        message = t('The user account has been deleted. Use another email to sign up or contact our support team.');
      }
      setGlobalError(message); // TODO: i18n
    } finally {
      setLoading(false);
    }
  }

  const handlePasswordInputType = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  return (
    <div className={classes.container}>
      <Helmet>
        <title>{t('login-title')}</title>
        <meta name="description" content={t('login-description')} />
      </Helmet>
      {pathname === '/auth/login' && <AppBackLink isGoBackActivated />}
      <section className={classes.section}>
        <Box>
          <Typography id="AuthLogin-Heading" variant="h2" component="h1">
            {t('Login')}
          </Typography>
          <Box mb="1rem" />
          <form aria-labelledby="AuthLogin-Heading" onSubmit={handleSubmit(triggerSubmit)}>
            <TextField
              className={classes.textField}
              error={!!errors.email}
              fullWidth
              helperText={errors.email?.message && t(`${errors.email?.message}`)}
              inputRef={register}
              name="email"
              onChange={handleChange}
              placeholder={t('Email')}
              variant="outlined"
              autoComplete="email"
            />
            <TextField
              className={classes.textField}
              error={!!errors.password}
              fullWidth
              helperText={errors.password?.message && t(`${errors.password?.message}`)}
              inputRef={register}
              name="password"
              onChange={handleChange}
              placeholder={t('Password')}
              type={passwordVisibility ? 'text' : 'password'}
              variant="outlined"
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" classes={{ root: classes.inputBtnWrapper }}>
                    {!passwordVisibility && (
                      <Button variant="contained" color="primary" classes={{ root: classes.inputBtn }} onClick={handlePasswordInputType}>
                        <VisibilityOffIcon />
                      </Button>
                    )}
                    {passwordVisibility && (
                      <Button variant="contained" color="primary" classes={{ root: classes.inputBtn }} onClick={handlePasswordInputType}>
                        <VisibilityIcon />
                      </Button>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <Box mb=".25rem" />
            <Box hidden={!globalError} mb=".75rem" pl=".75rem">
              <Typography color="error" variant="body2">
                {t(`${globalError}`)}
              </Typography>
            </Box>
            <Box hidden={!!globalError} mb="1.5rem" />
            <Box textAlign="left">
              <LoadingButton color="secondary" loading={loading} type="submit" variant="contained" fullWidth>
                {t('Login')}
              </LoadingButton>
            </Box>
          </form>
        </Box>
        <Link className="base-link" to={`${getPage('auth')}${getPage('forgotPassword')}`}>
          {t('Forgot your password?')}
        </Link>
      </section>
    </div>
  );
}
