import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { OrderControllerCancelParameter } from '../../parameters/order-controller-cancel.parameter';

interface OrderControllerCancelOpenApiParameters {
  parameters: OrderControllerCancelParameter;
}

@OpenApiMethod({
  method: HttpMethods.DELETE,
  apiName: 'customer',
  path: '/order/{orderId}/customer/{customerId}',
  operationId: 'OrderController_cancel',
  parameters: [
    {
      name: 'orderId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
    {
      name: 'customerId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class OrderControllerCancelMethod extends BaseOpenApiMethod<void> implements Method<void, OrderControllerCancelOpenApiParameters> {
  public call(parameters: OrderControllerCancelOpenApiParameters): Promise<void> {
    return super.call(parameters);
  }
}
