import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, compose, createStore } from 'redux';
import appReducer from './reducers';

const appSaga = require('./sagas').default;

// init redux
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  appReducer,
  process.env.NODE_ENV !== 'development' ? applyMiddleware(sagaMiddleware) : composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(appSaga);

export { store };
