import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { TailorControllerGetAssignmentPaginatedParameter } from '../../parameters/tailor-controller-get-assignment-paginated.parameter';
import { TailorControllerGetAssignmentPaginatedResponse } from '../../responses/tailor-controller-get-assignment-paginated.response';

interface TailorControllerGetAssignmentPaginatedOpenApiParameters {
  parameters: TailorControllerGetAssignmentPaginatedParameter;
}

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/tailor/{tailorId}/assignment',
  operationId: 'TailorController_getAssignmentPaginated',
  parameters: [
    {
      name: 'tailorId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
    {
      name: 'lastEvaluatedKey',
      required: false,
      in: OpenApiParameterType.QUERY,
    },
    {
      name: 'limit',
      required: false,
      in: OpenApiParameterType.QUERY,
    },
    {
      name: 'status',
      required: false,
      in: OpenApiParameterType.QUERY,
    },
    {
      name: 'statusses',
      required: false,
      in: OpenApiParameterType.QUERY,
    },
  ],
})
export class TailorControllerGetAssignmentPaginatedMethod
  extends BaseOpenApiMethod<TailorControllerGetAssignmentPaginatedResponse>
  implements Method<TailorControllerGetAssignmentPaginatedResponse, TailorControllerGetAssignmentPaginatedOpenApiParameters>
{
  public call(parameters: TailorControllerGetAssignmentPaginatedOpenApiParameters): Promise<TailorControllerGetAssignmentPaginatedResponse> {
    return super.call(parameters);
  }
}
