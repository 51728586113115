import React from 'react';
import classes from './RunningLine.module.css';
import { useTranslation } from 'react-i18next';
import circle from '../../../images/svg/circle-carousel.svg';

export default function RunningLineItem() {
  const { t } = useTranslation();

  return (
    <>
      <li className={classes.runningItem}>
        <img src={circle} alt=''/>
        {t('TRANSITION TO CIRCULAR ECONOMY')}
      </li>
      <li className={classes.runningItem}>
        <img src={circle} alt=''/>
        {t('SET INDUSTRY STANDARDS')}
      </li>
      <li className={classes.runningItem}>
        <img src={circle} alt=''/>
        {t('EMBRACE COLLABORATIVE INNOVATION')}
      </li>
      <li className={classes.runningItem}>
        <img src={circle} alt=''/>
        {t('SUPPORT GERMAN CRAFTSMANSHIP')}
      </li>
    </>
  );
}
