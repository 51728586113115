import RatingApi from '../interfaces/api';
import RatingData from '../interfaces/data';
import appRequest from '../../utils/request';
import { Craftsman, Order } from '../../types';

const ratingApiProd: RatingApi = {
  sendRating: async function sendRating(rating: RatingData, orderId: Order['_id'], tailorId: Craftsman['_id']) {
    const { data, status } = await appRequest(`/tailor/${tailorId}/rating`, {
      method: 'POST',
      data: {
        orderId,
        tailorId,
        serviceRating: rating.serviceRating,
        serviceComment: rating.serviceComment,
        tailorRating: rating.tailorRating,
        tailorComment: rating.tailorComment,
      },
    });
    if (status === 500) {
      return null;
    }
    return data;
  },
};

export default ratingApiProd;
