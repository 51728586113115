import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';
import classes from './Menu.module.css';
import { getPage } from '../../utils/pages';
import { shallowEqual, useSelector } from 'react-redux';
import AppState from 'interfaces/app-state';

export default function Menu({ onLinkClick = () => {} }) {
  const currentPath = window.location.href;
  const { t } = useTranslation();
  const { voucher } = useSelector(
    (state: AppState) => ({
      voucher: state.cart.voucher,
    }),
    shallowEqual
  );

  return (
    <ul className={classes.menu}>
      {!voucher ? (
        <li className={classes.item}>
          <Link className={classes.link} to={`${getPage('product')}${getPage('select')}`} onClick={onLinkClick}>
            {t('Repairing')}
          </Link>
          <div className={`${classes.underline} ${currentPath.includes(`${getPage('product')}${getPage('select')}`) ? classes.active : ''}`} />
        </li>
      ) : (
        <li className={classes.item}>
          <span className={classes.link} style={{ opacity: 0.5, padding: '0.6rem' }}>
            {t('Repairing')}
          </span>
          <div style={{ height: '3px' }} />
        </li>
      )}
      <li className={classes.item}>
        <Link className={classes.link} to={getPage('faq')} onClick={onLinkClick}>
          {t('How It Works')}
        </Link>
        <div className={`${classes.underline} ${currentPath.includes(getPage('faq')) ? classes.active : ''}`} />
      </li>
      <li className={classes.item}>
        <Link className={classes.link} to={getPage('aboutUs')} onClick={onLinkClick}>
          {t('About Us')}
        </Link>
        <div className={`${classes.underline} ${currentPath.includes(getPage('aboutUs')) ? classes.active : ''}`} />
      </li>
      <li className={classes.item}>
        <Link className={classes.link} to={`${getPage('voucher')}${getPage('select')}`}>
          {t('MenuVouchers')}
        </Link>
        <div className={`${classes.underline} ${currentPath.includes(getPage('voucher')) ? classes.active : ''}`} />
      </li>
      <li className={classes.item}>
        <Link className={classes.link} to={`${getPage('brandPartnerships')}`}>
          {t('Partnerships')}
        </Link>
        <div className={`${classes.underline} ${currentPath.includes(getPage('brandPartnerships')) ? classes.active : ''}`} />
      </li>
      {/*<li className={classes.item}>*/}
      {/*  <Link className={classes.link} to="/contact" onClick={onLinkClick}>*/}
      {/*    {t('Contact Us')}*/}
      {/*  </Link>*/}
      {/*  <div className={`${classes.underline} ${currentPath.includes('contact') ? classes.active : ''}`} />*/}
      {/*</li>*/}
    </ul>
  );
}
