/**
 * Markdown
 */
const zusammenarbeitZwischenMonoQuickUndRepairRebelsEn = `
  Fashion lovers can now have even more reasons to repair their favorite pieces. Repair
  Rebels just announced a collaboration with [Monoquick](https://www.monoquick.de) - the biggest Patch company in
  Germany. Together, we want to revive the repair culture in Germany and help individual
  stories of the garments to continue!

  ## The History and Making of Patches
 
  The role of embroidered patches in fashion has an old history and a rich cultural
  background. Their use can be tracked back to the 3rd century BC in China, South America,
  and even the Viking times (OldEnglischCo., 2021). Before the Victorian era, when the
  mechanical sewing machine had been invented, patches were hand-stitched. Artisans sew
  their art directly on clothing using several mending and sewing techniques. The first
  embroidery machine was created by Alphonse Kursheedt in the early 1800s. It used a
  combination of hand and looms embroidery. Then a few years later, Issak Groebli built the
  “Schiffli” embroidery machine with a continuously threaded needle and shuttle which brought
  major improvements in the process (AmericanPatch, 2013). Nowadays, patch production is
  being digitalized and a lot of them can be simply ironed on clothing. For people who don’t
  know how to sew or never used a thread and a needle, this is a very convenient and fast
  option to get started with clothing repairs.
  
  ## From Punks and Hippies to High Fashion
  
  The traditional use for patches was to cover up holes and give clothing a clever upgrade,
  giving more years to wear. In the military, though, patches were used to show people’s rank
  and achievement. Later on, many social movements and groups throughout the world used
  them for identification purposes. Patches were used in protest of feminism, civil rights, and
  gay rights. Wearing patches on the sleeves or on the back gave people a voice and a sense
  of belonging to a community (Solomatina, 2016). To this day, wearing a patch, can
  communicate a message and support social change.
  
  The Hippie community started growing in the mid-sixties, as a group known for their
  non-weaponry protesting movements with love, peace, and harmony. Their fashion style
  embodied their values and political statements. Baggy but flowy pants, colorful tops,
  bandanas, and bold accessories were made themselves or bought second-hand, as buying
  new was rather unpopular to show the problems of consumerism. Patches became a staple
  piece in their outfits, with bold statements, rainbows, peace signs, and smileys.
  
  In the late seventies, eventually, new cultures were emerging, known as the alternative
  culture. They were the opposite of the hippies as they wore dark clothes and had an edgy
  attitude, as they did not believe that everything could be solved with love, peace, and
  harmony. This also split into subcultures like the punks. Their fashion style was or even is to
  this day filled with leather, used denim, spikes, and chains. Patches were playing the part of
  showcasing favorite brands and political statements (Mary, 2018). What also trended in their
  punk community were shoulder and back patches of large sizes. They were typically roughly
  stitched into clothes or applied by using safety pins, giving them even a bolder look. Such
  techniques influenced the high fashion area and the emergence of designers such asVivien
  Westwood, who also worked against “the anti-fashion phenomenon” (Solomatina, 2016).
  
  Designer Raf Simmons showed patches in his collections and influences further designers to
  do the same. Today, luxury brands like Gucci, Moncler Burberry, or Marc Jacobs frequently
  use embroidery patches in their collections. Some even sell their designed patches
  separately for consumers to use them at home.
  
  ## German Patch Pioneers - Mono-Quick
  
  In 1968, the family-run company was founded by Jörn Rauser, who liked to decorate clothing
  with personal initials. He started ordering personalized shirts with embroidered monograms
  in 1966 in New York – that is how the idea for Mono-Quick was born. However, the
  philosophy, as well as the portfolio, changed rapidly! Iron-on pictures of all kinds to creatively
  extend the life of clothing were and still are the main arguments for patch products. The
  family-owned company deliberately doe not address a specific customer target group, but
  rather offers everyone an opportunity to choose patches depending on their own style and
  cultural affiliation. That is why today Monoquick has over 800 motifs and 30 licenses on its
  website. Since 2005, Mirko Rauser has been the second generation, operational manager,
  leading the company through digitalization and the challenges of our time (Mono-Quick,
  2022).
  
  At the heart of the new product pallet, are RECYCLPatches, made from recycled PET
  bottles and with a strong environmental message. Hereby, 5 cents per piece sold will be
  donated to the Schule-Klima-Wandel (a project of the SV-Bildungswerk). In general,
  Monoquick is looking for interesting cooperation partners for their donations. As part of their
  sustainability efforts, Mono-Quick is actively engaging with schools, by donating their
  patches and educating the youth about clothing repairs.
  
  ## Monoquick x Repair Rebels Collaboration
  
  Mono-Quick and Repair Rebels embrace similar values in their mission of reducing clothing
  waste and giving a second life to the clothing that we already have. By adding-up a patch, a
  piece of clothing can become even more personal – in line with our motto "Repaired clothes
  are loved stronger". This collaboration helps our young startup to expand the repair horizons,
  as many people are still unfamiliar with the repair culture or think of it as too costly and
  unfashionable. Together with Mono-Quick we show that this does not need to be the case -
  quite the opposite! With the simple and fast application of a patch, as well as the convenient
  ordering of an online repair, the repair culture in Germany is revived!
  
  To embrace this collaboration to the fullest, until the end of September 2022, use the code
  **FashionRebels-10** at the checkout to get a 10% discount on your repairs and receive two
  patches from Mono-Quick gifted to you and your friend!
  
  > "We believe in the mission of Repair Rebels, as it is not about replacing clothing, but
  about working with the local craftsmen to give your favorite clothing the long life they
  deserve. Together we want the individual stories of the garments to continue." - Tatjana Lilienthal, Mono-Quick
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/mq-collab-1.png)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/mq-collab-2.png)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/mq-collab-3.png)
`;

export default zusammenarbeitZwischenMonoQuickUndRepairRebelsEn;
