import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { SeedControllerOrderParameter } from '../../parameters/seed-controller-order.parameter';

interface SeedControllerOrderOpenApiParameters {
  parameters: SeedControllerOrderParameter;
}

@OpenApiMethod({
  method: HttpMethods.POST,
  apiName: 'customer',
  path: '/seed/order',
  operationId: 'SeedController_order',
  parameters: [
    {
      name: 'minDay',
      required: true,
      in: OpenApiParameterType.QUERY,
    },
    {
      name: 'maxDay',
      required: true,
      in: OpenApiParameterType.QUERY,
    },
    {
      name: 'week',
      required: true,
      in: OpenApiParameterType.QUERY,
    },
  ],
})
export class SeedControllerOrderMethod extends BaseOpenApiMethod<void> implements Method<void, SeedControllerOrderOpenApiParameters> {
  public call(parameters: SeedControllerOrderOpenApiParameters): Promise<void> {
    return super.call(parameters);
  }
}
