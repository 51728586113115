import TailorApi from '../interfaces/api';
import { Craftsman } from '../../types';

async function fetchTailors(): Promise<Craftsman[]> {
  return [];
}

const tailorApiDev: TailorApi = {
  fetchTailors,
};

export default tailorApiDev;
