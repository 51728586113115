import EditOrderPickupRequest from '../interfaces/editOrderPickupRequest';
import OrderEditPickupApi from '../interfaces/api';
import { OrderDetails } from '../../interfaces/order-details';
import { Timeframe } from '../../interfaces/customer-data';

async function editOrderPickup(orderId: string, customerId: string, editRequest: EditOrderPickupRequest): Promise<OrderDetails | null> {
  return {
    id: 'orderDto.id',
    customerId: 'orderDto.customerId',
    pickupContactDetails: {
      firstName: 'orderDto.deliveryAddress.contact.firstName',
      lastName: 'orderDto.deliveryAddress.contact.lastName',
      email: 'orderDto.deliveryAddress.contact.email',
      phoneNumber: 'orderDto.deliveryAddress.contact.phoneNumber',
    },
    deliveryContactDetails: {
      firstName: 'orderDto.pickupAddress.contact.firstName',
      lastName: 'orderDto.pickupAddress.contact.lastName',
      email: 'orderDto.pickupAddress.contact.email',
      phoneNumber: 'orderDto.pickupAddress.contact.phoneNumber',
    },
    pickupAddress: {
      street: 'Humboldtstrasse 101',
      zipCode: '40237',
    },
    deliveryAddress: {
      street: 'Scheibenstrasse 49',
      zipCode: '40479',
    },
    pickupTime: {
      date: 'Not sure how this should look',
      timeframe: Timeframe.EVENING,
    },
    deliveryTime: {
      date: 'Not sure how this should look',
      timeframe: Timeframe.MORNING,
    },
  };
}

/**
 * Module API definitions for automated testing.
 *
 * The definitions of this API are used in automated tests as defaults.
 */
const orderEditPickupApiTest: OrderEditPickupApi = {
  editOrderPickup,
};

export default orderEditPickupApiTest;
