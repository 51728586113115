import React from 'react';
import { Link } from '@reach/router';
import classes from './CartButton.module.css';
import { useSelector } from 'react-redux';
import { selectCartVoucherItems } from '../../Cart/store/selectors';
import { ReactComponent as CartIcon } from './cart.svg';
import { getPage } from '../../utils/pages';

const VoucherCartButton = ({ location }) => {
  const cartItems = useSelector(selectCartVoucherItems);
  const isVisible = !!cartItems && cartItems.length > 0;
  const display = isVisible ? 'block' : 'none';
  const numItems = cartItems?.length.toLocaleString('de-DE', {
    style: 'decimal',
    minimumIntegerDigits: 1,
  });
  const { pathname } = location;
  if (pathname === '/cart' || pathname.includes('/checkout')) {
    return null;
  }
  return (
    <div className={classes.container}>
      <div className={`container ${classes.main}`}>
        <Link to={getPage('cart')} style={{ display }}>
          <button className={classes.button} aria-label="Cart">
            <CartIcon className={classes.image} />
            <div className={classes.badge}>{numItems}</div>
          </button>
        </Link>
      </div>
    </div>
  );
};
export default VoucherCartButton;
