import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { TransportControllerGetStuckResponse } from '../../responses/transport-controller-get-stuck.response';

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/transport/stuck',
  operationId: 'TransportController_getStuck',
  parameters: [],
})
export class TransportControllerGetStuckMethod
  extends BaseOpenApiMethod<TransportControllerGetStuckResponse>
  implements Method<TransportControllerGetStuckResponse, void>
{
  public call(): Promise<TransportControllerGetStuckResponse> {
    return super.call();
  }
}
