import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { CartControllerMigrateParameter } from '../../parameters/cart-controller-migrate.parameter';

interface CartControllerMigrateOpenApiParameters {
  parameters: CartControllerMigrateParameter;
}

@OpenApiMethod({
  method: HttpMethods.POST,
  apiName: 'customer',
  path: '/cart/migrate',
  operationId: 'CartController_migrate',
  parameters: [
    {
      name: 'previousUserId',
      required: false,
      in: OpenApiParameterType.QUERY,
    },
  ],
})
export class CartControllerMigrateMethod extends BaseOpenApiMethod<void> implements Method<void, CartControllerMigrateOpenApiParameters> {
  public call(parameters?: CartControllerMigrateOpenApiParameters): Promise<void> {
    return super.call(parameters);
  }
}
