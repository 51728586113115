import { call, take } from '@redux-saga/core/effects';
import CartApi from '../../interfaces/api';
import { SET_NUM_BOXES } from '../types';

export default function* cartUpdateNumBoxesSaga(api: CartApi) {
  const action = yield take(SET_NUM_BOXES);
  const { numBoxes } = action.payload;
  yield call(api.updateNumBoxes, numBoxes);
  yield call(cartUpdateNumBoxesSaga, api); // recursion: instead of a loop
}
