import OrderStatusApi from '../interfaces/api';
import {Order, ShippingOrder} from '../../types';

async function fetchOrder(): Promise<Order | null> {
  return {} as Order;
}
async function fetchShippingOrder(): Promise<ShippingOrder | null> {
  return {} as ShippingOrder;
}

/**
 * Order-Status API definitions for automated testing.
 *
 * The definitions of this API are used in automated tests as defaults.
 */
const orderStatusApiTest: OrderStatusApi = {
  fetchOrder,
  fetchShippingOrder,
};

export default orderStatusApiTest;
