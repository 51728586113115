import React, { useEffect } from 'react';
import { Box, Button, Container, makeStyles, Paper, Typography } from '@material-ui/core';
import { Link, RouteComponentProps, useNavigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ProductLoading from '../Loading';
import CartGarment from '../../../Cart/components/Garment';
import NotFound from '../../../components/NotFound';
import AppBackLink from '../../../components/BackLink';
import { selectCartItems } from '../../../Cart/store/selectors';
import { ReactComponent as ArrowIcon } from '../../../images/svg/arrow.svg';
import { Repair } from '../../../types';
import { getPage } from '../../../utils/pages';
import RunningLine from 'components/RunningLine';

// styles
const useStyles = makeStyles((theme) => {
  const s = (x) => theme.spacing(x);
  return {
    headingBlock: {
      position: 'relative',
    },
    arrowIcon: {
      position: 'absolute',
      top: s(0.5),
      left: -s(17),
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
      [theme.breakpoints.up('xl')]: {
        top: s(1),
      },
    },
  };
});

// props
interface ProductSuccessRouteProps {
  seo: string;
}

/**
 * Product add-to-cart-success component.
 */
export default function ProductSuccess({ seo }: RouteComponentProps<ProductSuccessRouteProps>) {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const cartItems = useSelector(selectCartItems);
  const headingHtmlId = 'ProductSuccess-heading';
  // const buttonLabelHtmlId = 'ProductSuccess-addRepairButtonLabel';
  let items: Repair[];

  useEffect(() => {
    if (items?.length === 0) {
      navigate('..');
    }
  });

  if (!seo) {
    return <NotFound />;
  }

  if (!cartItems) {
    return <ProductLoading />;
  }

  items = cartItems;

  if (!items.length) {
    return null;
  }

  return (
    <Box>
      <RunningLine />
      <Container maxWidth="sm">
        <AppBackLink isGoBackActivated />
        <Box className={classes.headingBlock} px={{ xs: 0, sm: 2 }}>
          <Typography className="with-bar" id={headingHtmlId} variant="h1">
            {t('Successfully added to cart')}
          </Typography>
          <ArrowIcon className={classes.arrowIcon} />
        </Box>
        <Box mb={3} />
        <Paper aria-labelledby={headingHtmlId}>
          <CartGarment items={items} />
        </Paper>
        <Box mb={4} />
        <Box textAlign="center">
          <Button color="secondary" component={Link} to="../../" variant="contained">
            {t('Add another product')}
          </Button>
        </Box>
        <Box textAlign="center">
          <Button color="secondary" component={Link} to={getPage('cart')} variant="contained">
            {t('Go to cart')}
          </Button>
        </Box>
      </Container>
    </Box>
  );
}
