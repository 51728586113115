import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { MailerControllerSupportRequestBody } from '../../request-bodies/mailer-controller-support.request-body';

interface MailerControllerSupportOpenApiParameters {
  requestBody: MailerControllerSupportRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.POST,
  apiName: 'customer',
  path: '/mailer/support',
  operationId: 'MailerController_support',
  parameters: [],
})
export class MailerControllerSupportMethod extends BaseOpenApiMethod<void> implements Method<void, MailerControllerSupportOpenApiParameters> {
  public call(parameters: MailerControllerSupportOpenApiParameters): Promise<void> {
    return super.call(parameters);
  }
}
