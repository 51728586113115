import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { EstimateControllerGetByIdParameter } from '../../parameters/estimate-controller-get-by-id.parameter';
import { EstimateControllerGetByIdResponse } from '../../responses/estimate-controller-get-by-id.response';

interface EstimateControllerGetByIdOpenApiParameters {
  parameters: EstimateControllerGetByIdParameter;
}

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'estimation',
  path: '/estimation/{estimateId}',
  operationId: 'EstimateController_getById',
  parameters: [
    {
      name: 'estimateId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class EstimateControllerGetByIdMethod
  extends BaseOpenApiMethod<EstimateControllerGetByIdResponse>
  implements Method<EstimateControllerGetByIdResponse, EstimateControllerGetByIdOpenApiParameters>
{
  public call(parameters: EstimateControllerGetByIdOpenApiParameters): Promise<EstimateControllerGetByIdResponse> {
    return super.call(parameters);
  }
}
