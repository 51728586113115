import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { OrderControllerUpdatePickupParameter } from '../../parameters/order-controller-update-pickup.parameter';
import { OrderControllerUpdatePickupRequestBody } from '../../request-bodies/order-controller-update-pickup.request-body';
import { OrderControllerUpdatePickupResponse } from '../../responses/order-controller-update-pickup.response';

interface OrderControllerUpdatePickupOpenApiParameters {
  parameters: OrderControllerUpdatePickupParameter;
  requestBody: OrderControllerUpdatePickupRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.PATCH,
  apiName: 'customer',
  path: '/order/update-pickup/{orderId}',
  operationId: 'OrderController_updatePickup',
  parameters: [
    {
      name: 'orderId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class OrderControllerUpdatePickupMethod
  extends BaseOpenApiMethod<OrderControllerUpdatePickupResponse>
  implements Method<OrderControllerUpdatePickupResponse, OrderControllerUpdatePickupOpenApiParameters>
{
  public call(parameters: OrderControllerUpdatePickupOpenApiParameters): Promise<OrderControllerUpdatePickupResponse> {
    return super.call(parameters);
  }
}
