import { combineReducers } from 'redux';
import { reducer as ToastrReducer } from 'react-redux-toastr';
import cart from '../Cart/store';
import product from '../Product/store';
import checkout from '../Checkout/store';
import tailor from '../Tailor/store';
import estimation from '../Estimation/store';
import voucher from '../Voucher/store';
import { UserReducer } from '../Auth/store';
import global from './global-reducer';
import profile from '../Profile/store';
import platformConfig from '../PlatformConfig/store';

const appReducer = combineReducers({
  cart,
  product,
  voucher,
  checkout,
  tailor,
  estimation,
  auth: UserReducer,
  orderEdit: global,
  profile,
  platformConfig,
  toastr: ToastrReducer,
});

export default appReducer;
