import pitch from './logos/pitch.png';
import post from './logos/post.png';
import iiwk from './logos/iiwk.png';
import hessnatur from './logos/hessnatur.png';
import fashionnet from './logos/fashionnet.png';
import fashionnetwork from './logos/fashionnetwork.png';
import world from './logos/world.png';
import handelsblatt from './logos/handelsblatt.png';
import wdr from './logos/wdr.svg';
import vivid from './logos/vivid.png';
import max from './logos/max.png';
import shuhkurier from './logos/schuhkurier.png';
import ecodesign from './logos/ecodesign.png'

export const awardsItems = [
    {
      id: 0,
      logo: post,
      link: 'https://rp-online.de/nrw/staedte/duesseldorf/start-up-in-duesseldorf-reparieren-statt-wegwerfen_aid-85565341',
      size: 180
    },
    {
      id: 1,
      logo: wdr,
      link: '',
      size: 130
    },
    {
      id: 2,
      logo: handelsblatt,
      link: 'https://app.handelsblatt.com/technik/forschung-innovation/initiative-vision-lab-von-ki-bis-kleiderreparatur-diese-start-ups-zeigen-das-tech-potenzial-von-migranten/29075332.html',
      size: 180
    },
    {
      id: 3,
      logo: ecodesign,
      link: 'https://bundespreis-ecodesign.de/en/winners/2023-repair-rebels',
      size: 220
    },
    {
      id: 4,
      logo: vivid,
      link: 'https://www.vivid-magazin.de/story/en/fresh-ideas-from-duesseldorf',
      size: 200
    },
    {
      id: 5,
      logo: max,
      link: '',
      size: 150
    },
    {
      id: 6,
      logo: shuhkurier,
      link: 'https://www.schuhkurier.de/repair-rebels/',
      size: 200
    },
    {
      id: 7,
      logo: pitch,
      link: 'https://www.linkedin.com/feed/update/urn:li:activity:7066512831378022402?utm_source=share&utm_medium=member_desktop',
      size: 150
    },
    {
      id: 8,
      logo: hessnatur,
      link: 'https://www.hessnatur.com/de/repair-and-care',
      size: 180
    },
    {
      id: 9,
      logo: iiwk,
      link: 'https://www.hwk-duesseldorf.de/artikel/werkstatt-2023-31,0,5755.html#9',
      size: 210
    },
    {
      id: 10,
      logo: fashionnet,
      link: 'https://www.fashion-net-duesseldorf.de/de/news-43,37164.htm',
      size: 140
    },
    {
      id: 11,
      logo: world,
      link: 'https://www.worldfootwear.com/news/repair-rebels-making-repairing-the-new-normal-for-every-fashion-consumer/8712.html',
      size: 180
    },
    {
      id: 12,
      logo: fashionnetwork,
      link: 'https://de.fashionnetwork.com/news/Hessnatur-verkundet-funf-finalisten-fur-den-innovations-for-tomorrow-award,1407366.html',
      size: 180
    },
  ];