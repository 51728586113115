import { call, put } from 'redux-saga/effects';
import ProductApi from '../../interfaces/api';
import { setItems } from '../actions';

export default function* productSaga(api: ProductApi) {
  try {
    const items = yield call(api.fetchProducts);
    yield put(setItems(items));
  } catch (err) {
    if (process.env.NODE_ENV === 'development') {
      console.error(err);
    }
    // TODO: handle error
  }
}
