import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { ProductControllerGetProductGridResponse } from '../../responses/product-controller-get-product-grid.response';

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/product/grid',
  operationId: 'ProductController_getProductGrid',
  parameters: [],
})
export class ProductControllerGetProductGridMethod
  extends BaseOpenApiMethod<ProductControllerGetProductGridResponse>
  implements Method<ProductControllerGetProductGridResponse, void>
{
  public call(): Promise<ProductControllerGetProductGridResponse> {
    return super.call();
  }
}
