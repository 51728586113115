import appRequest from '../../utils/request';
import VoucherApi from 'Voucher/intefaces/api';

/**
 * Product API definitions for production use.
 *
 * These definitions can only be covered by end-to-end (e2e) tests.
 */
const voucherApiProd: VoucherApi = {
  fetchProducts: async () => {
    const { data } = await appRequest('/voucher-config');
    return data;
  },
  fetchProductById: async (_id) => {
    const { data } = await appRequest(`voucher-config/${_id}`);
    return data?.data ?? data;
  },
};

export default voucherApiProd;
