import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';

@OpenApiMethod({
  method: HttpMethods.POST,
  apiName: 'customer',
  path: '/auth/register-with-email',
  operationId: 'AuthController_registerWithEmail',
  parameters: [],
})
export class AuthControllerRegisterWithEmailMethod extends BaseOpenApiMethod<void> implements Method<void, void> {
  public call(): Promise<void> {
    return super.call();
  }
}
