// import { EstimateControllerCreateWithCustomerMethod } from '../methods/estimate-controller/create-with-customer.method';
import { EstimateControllerCreateMethod } from '../methods/estimate-controller/create.method';
import { EstimateControllerGetByIdMethod } from '../methods/estimate-controller/get-by-id.method';
// import { EstimateControllerGetPaginatedMethod } from '../methods/estimate-controller/get-paginated.method';
import { EstimateControllerUploadImageMethod } from '../methods/estimate-controller/upload-image.method';

export class EstimateController {
  public readonly create = new EstimateControllerCreateMethod();
  // public readonly getPaginated = new EstimateControllerGetPaginatedMethod();
  // public readonly createWithCustomer = new EstimateControllerCreateWithCustomerMethod();
  public readonly getById = new EstimateControllerGetByIdMethod();
  public readonly uploadImage = new EstimateControllerUploadImageMethod();
}
