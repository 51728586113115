import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import { useElementOnScreen } from '../../../hooks/useElementOnScreen';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import classes from './Video.module.css';
import frame from '../../../images/png/frame.png';

const VIDEO_DE = 'tZKmepZHmp8';
const VIDEO_EN = '4VRypJCPSzw';

/**
 * Homepage "The Repair Revolution" video component.
 */
export default function Video() {
  const [divRef, cameOnScreen] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  });
  const [videoId, setVideoId] = useState(VIDEO_DE);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (i18next.language === 'de') {
      setVideoId(VIDEO_DE);
    } else if (i18next.language === 'en') {
      setVideoId(VIDEO_EN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.language]);

  return (
    <div ref={divRef} id="video" className={`container ${classes.container} ${cameOnScreen ? classes.animation : ''}`}>
      <div className="mx-lg-1 px-lg-2">
        <h2 className="h4 with-bar mb-4">The Repair Revolution!</h2>
        <div className={classes.videoWrapper}>
          <div className={classes.frame}>
            <img alt="Video" src={frame} />
          </div>
          <LiteYouTubeEmbed
            id={videoId}
            opts={{ width: '560', height: '315' }}
            title="Ignition Demo Night #10: Repair Rebels"
            params="rel=0"
            aspectWidth={width < 768 && 1}
            aspectHeight={width < 768 && 1}
            poster={width < 768 ? 'maxresdefault' : 'hqdefault'}
          />
        </div>
      </div>
    </div>
  );
}
