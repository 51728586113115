import firebase from 'firebase/app';
// import 'firebase/auth';
import 'firebase/performance';
import 'firebase/app-check';

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_FIREBASE_PERFORMANCE_DATA_COLLECTION_ENABLED,
  REACT_APP_FIREBASE_PERFORMANCE_INSTRUMENTATION_ENABLED,
  REACT_APP_FIREBASE_APP_CHECK_SITE_KEY,
  REACT_APP_FIREBASE_APP_CHECK_TOKEN_AUTO_REFRESH_ENABLED,
} = process.env;

export default async function initializeFirebase() {
  if (firebase.apps.length) {
    return firebase.apps[0];
  }

  let options = {
    apiKey: REACT_APP_FIREBASE_API_KEY,
    authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: REACT_APP_FIREBASE_APP_ID,
    measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
  };

  if (REACT_APP_FIREBASE_PROJECT_ID === 'repair-rebels-prod') {
    options = {
      apiKey: 'AIzaSyA2wDdL7_Nsyy1Ik-lQldY1ytLcMmoBS2Q',
      authDomain: 'repair-rebels-prod.firebaseapp.com',
      projectId: 'repair-rebels-prod',
      storageBucket: 'repair-rebels-prod.appspot.com',
      messagingSenderId: '493328471066',
      appId: '1:493328471066:web:222bbf0046533b76a36da3',
      measurementId: 'G-QCT5PZHE9P',
    };
  }

  // try {
  //   const response = await fetch('/__/firebase/init.json');
  //   options = await response.json();
  // } catch (e) {
  //   console.warn(`Could not load dynamic firebase options. Fall back to the default options: ${e.message}`);
  // }

  const app = firebase.initializeApp(options);

  if (REACT_APP_FIREBASE_PERFORMANCE_DATA_COLLECTION_ENABLED === 'true' || REACT_APP_FIREBASE_PERFORMANCE_INSTRUMENTATION_ENABLED === 'true') {
    const performance = firebase.performance(app);
    performance.dataCollectionEnabled = REACT_APP_FIREBASE_PERFORMANCE_DATA_COLLECTION_ENABLED === 'true';
    performance.instrumentationEnabled = REACT_APP_FIREBASE_PERFORMANCE_INSTRUMENTATION_ENABLED === 'true';
  }

  if (REACT_APP_FIREBASE_APP_CHECK_SITE_KEY) {
    const appCheck = firebase.appCheck(app);
    appCheck.activate(REACT_APP_FIREBASE_APP_CHECK_SITE_KEY);
    appCheck.setTokenAutoRefreshEnabled(REACT_APP_FIREBASE_APP_CHECK_TOKEN_AUTO_REFRESH_ENABLED === 'true');
  }

  return app;
}
