import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { HealthControllerEstimateRequestStorageResponse } from '../../responses/health-controller-estimate-request-storage.response';

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/health/storage/estimate-request',
  operationId: 'HealthController_estimateRequestStorage',
  parameters: [],
})
export class HealthControllerEstimateRequestStorageMethod
  extends BaseOpenApiMethod<HealthControllerEstimateRequestStorageResponse>
  implements Method<HealthControllerEstimateRequestStorageResponse, void>
{
  public call(): Promise<HealthControllerEstimateRequestStorageResponse> {
    return super.call();
  }
}
