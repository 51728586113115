/**
 * Markdown
 */
const aufraeumenMitSystemBodyDe = `

Mit ein paar einfachen Tricks kann sogar das Aufräumen Spaß machen. Wir verraten Dir, wie Dein überfüllter Kleiderschrank Geschichte wird.

Schon seit einer gefühlten Ewigkeit haben wir Büros und Universitäten nicht mehr von innen gesehen- die Realität von vielen, die derzeit im Homeoffice arbeiten. Momentan verbringen wir mehr Zeit in unseren eigenen vier Wänden, als wir es jemals zuvor getan haben. Am Anfang eines jeden brandneuen Tages im Homeoffice, wenn wir unsere nächste Jogginghose aus dem Kleiderschrank fischen, bekommt der eine oder andere schnell mal die Idee, seinen Kleiderschrank neu zu ordnen und auszumisten.

## Aber wie gehe ich das jetzt am besten an?

Instagram und Co. platzen förmlich vor Tipps und Tricks, wie man es am besten hinter sich bringt- und bei der Suche nach der perfekten Vorgehensweise ist es gar nicht so ungewöhnlich, über ein Video von Marie Kondo zu stolpern. Sie lässt das Aufräumen durch ihre sogenannte „KonMari Methode“ wie ein Kinderspiel aussehen. Aber bei aller Liebe, seinen Kleiderschrank neu zu sortieren hört sich wirklich nicht so spannend an wie ein Fußballabend mit Freunden- aber weil dieser ja zurzeit sowieso nicht möglich ist, könnte man dem ganzen ja Mal eine Chance geben oder nicht?

## Bevor wir damit anfangen- wer ist Marie Kondo überhaupt?

Marie Kondo hat die Idee der KonMari Methode ins Leben gerufen und räumt leidenschaftlich gerne auf, seitdem sie 19 Jahre alt ist. Sie hat vier Bücher über ihre Aufräummethode geschrieben, die weltweit über 4 Millionen Mal verkauft wurden. Zudem ist sie die Gründerin von KonMari Media Inc. und hat ihre eigene Netflixserie, „Tidying up with Marie Kondo,“ in der sie Menschen, die mit dem Aufräumen ihres Hauses überfordert sind, auf den Weg der Ordnung bringt. Durch ihre Leidenschaft für Ordnung hat sie ein beeindruckendes Imperium aufgebaut- ein Vermögen von etwa 8 Millionen US-Dollar. Der Grund dafür, dass sich ihre Bücher so gut verkaufen und die Netflixserie hohe Einschaltquoten genießt, ist wahrscheinlich, dass sie damit ein so alltägliches Problem thematisiert, mit dem wir alle zu kämpfen haben. Ein wenig überfordert mit dem Aufräumen zu sein hat wahrscheinlich jeder von uns schon einmal erlebt, und die Gründe dafür sind meistens das Fehlen einer effektiven Strategie oder die Ratlosigkeit, wo man anfangen soll. Aber diese Probleme lassen sich ganz leicht beheben, sobald man einen ungefähren Plan vor Augen hat.

## Wie funktioniert die Methode?

Schauen wir uns mal die KonMari Methode etwas genauer an: Die KonMari Methode rät zu folgendem Ablauf: Starte am besten direkt bei Deinem Kleiderschrank und mach anschließend mit Büchern und Büropapieren weiter. Später folgt Komono, also eine Art Mischkategorie. Mit anderen Worten: Diese Kategorie hängt von deinem Besitz ab und ist für jeden individuell, jedoch solltest Du auf eine Sache besonders achten: Gegenstände von emotionalem Wert solltest Du stets als aller letztes aussortieren. Ein fundamentaler Unterschied zwischen dem, wie wir normalerweise aufräumen und Marie Kondos Methode ist, dass die Methode dazu rät, nach Kategorie statt nach Zimmern aufzuräumen. In anderen Worten, man konzentriert sich in dieser Methode auf die einzelnen Teile, welche man gerne ordnen möchte, anstatt auf die Orte, an denen sich diese befinden.  In der Praxis kann das so aussehen, dass man zunächst mit T-Shirts anfängt, sich dann zu den Jeans durchkämpft und dann zur nächsten Kategorie übergeht. Ein kleiner Tipp am Rande: Nachdem Du Deinen Kleiderschrank erfolgreich ausgemistet hast, könntest Du direkt zum Frühjahrsputz Deines ganzen Zuhauses übergehen, da der Kleiderschrank laut der KonMari Methode der perfekte Startpunkt ist. Während des Ausmistens fragst Du Dich sicherlich wiederholt, ob Du bestimmte Dinge entsorgen solltest oder nicht- hier soll die zentrale Frage der KonMari Methode Aufschluss geben:

> „Does it spark Joy?“
>
> zu deutsch, löst es Freude aus?

Wenn die Antwort ja ist, super! Dann solltest Du das Kleidungsstück behalten.

## Aber was sollen wir tun, wenn die Antwort nein ist?

Im Schnitt ziehen wir etwa 64 % der Kleidungsstücke aus unserem Schrank nicht an. Damit ist es naheliegend, dass wir uns immer wieder von einzelnen Kleidungsstücken trennen möchten. Während des Ausmistens des Kleiderschrankes werden dabei im Durchschnitt 19 Kleidungstücke aussortiert. Nachdem wir die Kleidungstücke aus unserem Kleiderschrank werfen, verschwinden sie allerdings nicht einfach. Viele Menschen wissen nicht, wie sie ihre Kleidung richtig entsorgen, was dazu führt, dass der Mülleimer leider immer noch für viele die erste Wahl ist. Eine Studie in Großbritannien zeigte, dass 82 % der Männer und 69 % der Frauen (n=2000) (The Guardian, 2017) ihre Kleidung in den Müll werfen. Weltweit führt dieses Verhalten dazu, dass in jeder Sekunde ein ganzer Müllabfuhrlaster Kleidung auf der Deponie landet oder verbrannt wird! (Fashion Revolution, 2020) Und da täglich mehr und mehr Bekleidung produziert wird, ist zu erwarten, dass es zukünftig noch sehr viel mehr sein wird.  Die Menge jährlich hergestellter Kleidung verdoppelte sich seit dem Jahre 2000 und übertraft 100 Milliarden zum ersten Mal im Jahre 2014. So wird geschätzt, dass durch die Modeindustrie jährlich 92 Millionen Tonnen Textilabfall entstehen. (Fashion Revolution, 2020).

Deswegen haben wir uns zwei Fragen gestellt, damit Du Deinen Kleiderschrank nachhaltig ausmisten kannst. Denn die Kleidung einfach unbenutzt in unserem Schank zu lassen, hilft uns auch nicht weiter.

## 1. Wieso bereitet dir das Kleidungsstück keine Freude mehr?

Die Gründe dazu lassen sich grundsätzlich in zwei Kategorien einteilen: Impulskäufe, wie stark saisonale Kleiderstücke, Teile die Du in gefühlt zig Farben hast oder einfach nicht zum Rest Deines Kleiderschranks passen, lassen sich in die Kategorie der schlechten Entscheidungen einordnen. Auch ein Teil, dass Du trotz seiner schlechten Verarbeitung und Qualität gekauft, aber absolut ungemütlich findest, gehört in diese Kategorie. Aber wie wir alle wissen, aus Fehler kann man lernen! Sie helfen uns, in der Zukunft bessere Entscheidungen beim Kauf von Kleidung zu treffen. So können wir gezielt Overbuying vermeiden- und das wird die nächste Ausmistaktion Deines Kleiderschranks sicher leichter machen. ☺ Zur zweiten Kategorie gehören Teile, die Dir beispielsweise aufgrund einer Gewichtsveränderung nicht mehr passen, kaputt oder ausgetragen sind- alles rund um den Zustand und Sitz Deines Kleidungsstücks. Aber hier haben wir gute Neuigkeiten; wir von Repair Rebels können diese Probleme im Nu beheben!

Bei den Kleidungsstücken, wo wir Dir nicht helfen können neue Freunde zu verspüren, solltest Du Dir diese Frage stellen:

## 2. Kann es denn jemand anderem Freude bringen?

Je nach Zustand und Qualität des Kleidungsstückes, bleiben Dir drei Möglichkeiten: Du könntest es teilen oder tauschen, verkaufen oder vermieten und zu guter Letzt spenden oder recyceln. Wenn Du mehr darüber erfahren möchtest, stay tuned! Neue Tipps und Infos folgen bald.

`;

export default aufraeumenMitSystemBodyDe;
