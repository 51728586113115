import * as z from 'zod';
import { schema as contactSchema } from '../../interfaces/contact-data';

const passwordSchema = z
  .object({
    password: z.string().nonempty().min(12),
    confirm: z.string(),
  })
  .refine((data) => data.password === data.confirm, {
    message: 'Passwords do not match',
    path: ['confirm'],
  });

const flagSchema = z.object({
  joinClub: z.boolean().default(false).optional(),
  acceptTerms: z
    .boolean()
    .default(false)
    .refine((x) => !!x, 'Please accept our terms'),
});

const schema = z.object({
  contact: contactSchema,
  password: passwordSchema,
  flags: flagSchema,
});

type AuthRegisterData = z.infer<typeof schema>;

export { schema, flagSchema };
export type { AuthRegisterData };
export default AuthRegisterData;
