import ImageState from '../interfaces/state';

const imageInitialState: ImageState = {
  sizeError: [],
  images: [],
  submitState: 'NONE',
  uploadState: 'NONE',
};

export default imageInitialState;
