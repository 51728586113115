/**
 * Markdown
 */
const animalDayDe = `

  Gerade in den 90er Jahren galten fellüberzogende Mäntel und die Verarbeitung von Wolle,
  Federn, Ledern etc. als Luxussymbol. Auch wenn heutzutage einige Länder, Fashion Weeks
  und auch Marken die Nutzung von tierischem Material für die Herstellung der Kleidung
  untersagen, so bleibt die Ausbeutung von Tieren in der Modebranche leider eine harte
  Realität. Am 04.Oktober, dem Welttierschutztag, wollen wir uns deshalb daran erinnern,
  dass Fashion nicht grausam sein muss...
  
  ## DIE GESCHICHTE- WELTTIERSCHUTZTAG
  
  Franz von Assisi - Gründer des Ordens der Franziskaner und Schutzpatron der Tiere - sah
  alle Tiere, ganz gleich ob groß oder klein, als gottgewollt und schützenswert an. Der
  Ursprung des Tierschutzgedanken findet sich somit schon recht früh, um 1226, in der Zeit
  wieder. Seit 1931, wird der Welttierschutztag auch auf internationaler Ebene am 04. Oktober,
  dem Gedenktag des Heiligen Franz von Assisi, zelebriert. Seitdem nutzen
  Tierschutzverbände diesen Tag, um auf Misshandlungen von Tieren aufmerksam zu
  machen. Denn einige Tiere werden immer noch nicht artgerecht gehalten, sprich sie
  bekommen keine medizinische Versorgung, haben nicht genügend Platz und werden
  ungesund ernährt. Darunter fallen auch Haustiere ([Bundesministerium für Ernährung und
  Landwirtschaft](https://www.tierwohl-staerken.de/aktuelles/news-details/am-4-oktober-ist-welttierschutztag), 2022).
  
  ## TIERMISSHANDLUNG IN DER MODEBRANCHE
  
  Milliarden von Tieren werden jedes Jahr für die Modebranche geopfert. Darunter Nerze,
  Füchse, Wölfe, Waschbären, Luchse, Opossums, Biber, Otter und andere Pelztiere
  ([Ecolookbook](https://ecolookbook.de/nachhaltigkeit/tierschutz/), 2022). Die große Mehrheit von ihnen erleidet die Maßnahmen der
  Massentierhaltung und wird für Haut, Wolle, Federn oder Pelze getötet. Auf beengtem und
  schmutzigem Raum wird ihnen ihre Freiheit geraubt und sie werden ein Leben lang gequält.
  Zerstückelungen, lebendige Häutungen, anale Stromschläge, Vergasungen... - die Liste der
  grausamen Taten nimmt hierbei kein Ende. Das Schlimme daran: Diese Tötungsmethoden
  richten sich nicht nach der Schonung des Tieres. Stattdessen ist die wichtigste Priorität die
  Qualität des Felles, die für den Verkauf erhalten bleiben muss. Alleine in China werden
  jährlich bis zu 70 Millionen Tiere für die Pelzindustrie getötet. Von diesen wird schließlich
  über die Hälfte für den europäischen Markt importiert ([Tierleid in der Mode. Geschichte und
  Herstellung von Pelz](https://www.grin.com/document/314890#:~:text=3.-,Herkunft%20und%20Herstellung%20von%20Pelz,den%20europ%C3%A4ischen%20Markt%20importiert%20werden.), 2015).
  
  ## LEDER
  
  Oftmals fehlen die Kennzeichnungen beim Leder, weshalb man nie genau weiß, welche
  Tierart benutzt wurde und aus welchem Land es stammt. Meistens stammt das Leder aus
  Entwicklungsländern, weil es dort keine Tierschutzgesetze gibt. Dabei werden Kühe, Ziegen,
  Schafe und Schweine, aber auch exotische Tiere wie beispielsweise Kängurus, Alligatoren
  oder Strauße für die Gewinnung von Leder geopfert. In China werden außerdem Katzen und
  Hunde geschlachtet und weltweit exportiert ([Ecolookbook](https://ecolookbook.de/nachhaltigkeit/tierschutz/), 2022).
  
  ## PELZ
  
  Für Bekleidung in Form von Pelzbommel, Pelzkragen oder als Accessoire an Jacken und
  Schals - jedes kleinste Stückchen Pelz ist mit Tierquälerei verbunden. In vielen Zuchtfarmen
  werden Pelztiere in winzigen Drahtgitter-Käfigen gehalten. Darunter fallen Füchse, Nerze,
  Waschbären, Luchse, aber auch die typischen Haustiere wie Hamster, Hunde und Katzen
  ([Peta Schweiz](https://www.peta-schweiz.ch/pelz), 2022). Das Problem ist, dass viele EU-Staaten keine zusätzlichen
  Vorschriften für die Pelztierzucht vorgeben. Allerdings entscheiden sich zum Glück immer
  mehr Länder dafür, strengere nationale Gesetze einzuführen oder Pelzfarmen ganz zu
  verbieten ([Vier Pfoten](https://www.vier-pfoten.de/kampagnen-themen/themen/mode-und-tierschutz/pelz-industrie/die-wahrheit-ueber-pelz), 2022).
  
  ## WOLLE
  
  Schafe werden oft von Arbeitern geschlagen und verstümmelt, um an die Wolle zu kommen.
  Ebenso werden Ziegen zur Gewinnung von Kaschmir und Mohair missbraucht. Denn
  Scherer werden in der Regel nicht nach Stundenanzahl bezahlt, sondern nach der Menge
  an Wolle, weshalb das Schaf durch die schnelle und fahrlässige Scherung verletzt wird. Und
  nicht zu vergessen: Der Trend “Shearling”. Viele Verbraucher denken, dass es sich dabei um
  geschorene Wolle handelt, doch das ist leider ein Irrtum: Es ist die Schafshaut, die mit der
  Wolle abgezogen wird. Es werden leider Dutzende Schafshäute benötigt, um ein einziges
  Shearling-Stück wie eine Jacke, Stiefel oder Wohnaccessoir herzustellen.
  
  ## WIE KANN ICH MEINEN BEITRAG ZUM WELTTIERSCHUTZTAG BEZÜGLICH MODE LEISTEN?
  
  Es ist immer gut, bewusster zu konsumieren und auch mal wirklich zu hinterfragen, ob Du
  mit deinem Kauf von echtem Pelz zu Tierleid und im Übrigen auch somit zur
  Umweltverschmutzung beitragen möchtest. Falls deine Antwort mit “Nein” ausfällt, solltest
  du dich bei deinen bereits vertrauten Marken informieren, ob diese bereits tierfreundliche
  Produkte herstellen.
  
  Alternativ kannst du dich auch bei anderen Marken umsehen, die tier- und umweltfreundlich
  sind. Denn Dank der Fortschritte im Bereich Textilien sind vegane Materialien kaum von
  tierischen Substanzen zu unterscheiden und sind ihnen sogar weit überlegen. Die
  bekanntesten veganen Stoffe sind wohl Polyester und Baumwolle. Gleichermaßen sieht es
  bei Bambus, Hanf oder anderen pflanzlichen Materialien aus: Sie boomen in unserer
  heutigen Zeit! Auch die Gewinnung aus Kork, Pilzen, Obstabfällen oder recyceltem
  Kunststoff wird immer mehr etabliert. Ein Pendant zu einem der edelsten Textilmaterialien,
  der Naturfaser Seide, gibt es auch schon: Pflanzenseide, die aus Nebenprodukten von
  Lebensmittelabfällen, wie zum Beispiel Sojabohnen, besteht. Denn bei einem tierischen Kilo
  Seide werden ungefähr 6.600 Raupen getötet. Die pflanzlichen Seidenstoffe sind also
  tierfreundlich und zusätzlich biologisch abbaubar und lange haltbar ([Ecolookbook](https://ecolookbook.de/nachhaltigkeit/tierschutz/), 2022).
  
  Alternativen gibt es somit heutzutage viel, weshalb es immer einen Weg gibt, um Tierleid zu
  vermeiden. Falls du dich nun fragst, auf welche Marken Du nun vertrauen kannst, wirf
  einfach einen Blick nach unten auf unsere Top 8 tierfreundlicher Marken.
  
  ## TOP 8 DER TIERFREUNDLICHSTEN UNTERNEHMEN
  
  ### 1. STELLA MCCARTNEY
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/d6603996-e757-41d7-b69c-0b573dfe0a8f.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/fb3ba3b2-d742-436b-bfda-7eb7e8995b82.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/462624ac-1656-4e90-840d-f0789d6cb4d0.jpg)
  
  **FOTOS** [@stellamccartney](https://www.instagram.com/stellamccartney/)\n
  **WEBSITE:** [https://www.stellamccartney.com/de/de](https://www.stellamccartney.com/de/de/)
  
  Die anormale Luxusmarke Stella McCartney boykottiert die Verwendung von Tierleder,
  Federn oder Pelze und keines ihrer Klebemittel enthält tierische Nebenprodukte. Die
  vegetarische Marke plädiert auch gegen Tierversuche und giftige Materialien, die der
  Umwelt schaden können, wie zum Beispiel Azofarbstoffe. Des Weiteren wird beim Versand
  der Kleidung, Handtaschen und Schuhen auf nachhaltige Verpackungsmaterialien, die aus
  FSC-zertifiziertem Holz und Papier stammen, Wert gelegt.
  
  ### 2. SAVE THE DUCK
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/d650dfd6-f68a-4a48-a207-bf4ddad8dc16.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/ff408e63-8bf8-4936-b264-602ab4e0d74c.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/f3839b86-52df-4677-995e-211a63fff797.jpg)
  
  **FOTOS** [@save_the_duck](https://www.instagram.com/save_the_duck/)\n
  **WEBSITE:** [https://www.savetheduck.it/ce_en](https://www.savetheduck.it/ce_en/)
  
  Als innovative italienische Modemarke hat es sich Save The Duck zur Mission gemacht,
  modische und gleichzeitig tierfreundliche Jacken zu designen. Sie verwenden, wie der
  Markenname schon verrät, keine tierischen Materialien für ihre Produkte. Dadurch werden
  Tiere, wie Gänse und Enten gerettet.
  
  ### 3. FLAMINGOS LIFE
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/c6232560-b7d9-45cc-9791-3bf410c37cad.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/c7af2b54-5cea-4fc6-a59c-b636b80dfd5b.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/1d65e9ba-97d7-46dd-b615-ab9716d9882e.jpg)
  
  **FOTOS** [@flamingoslife](https://www.instagram.com/flamingoslife/)\n
  **WEBSITE:** [https://www.en.flamingoslife.com](https://www.en.flamingoslife.com/)
  
  Das spanische Schuhunternehmen engagiert sich für Tierrechte, Umwelt und nachhaltiges
  Wirtschaften. Bei der Herstellung der lässigen Sneaker wird deshalb auf nachhaltige
  Materialien geachtet. Sie bestehen aus 100% recyceltem Polyester aus bereits genutzten
  PET-Flaschen, die aus dem Meer gefischt und recycelt werden. Des Weiteren werden keine
  tierischen Bestandteile wie Leder verwendet, weshalb die Marke mit dem “PETA approved
  Vegan”-Zertifikat ausgezeichnet wurde.
  
  ### 4. ICEBREAKER
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/736f1892-9ad7-4b32-a552-14c2729c5456.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/0162d8f3-5855-4513-8566-95dcbc0140e9.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/0a93dbfa-097d-4dbc-a040-f032c8e38f1c.jpg)
  
  **FOTOS** [@icebreakernz](https://www.instagram.com/icebreakernz/)\n
  **WEBSITE:** [https://www.icebreaker.com/de-ch/home](https://www.icebreaker.com/de-ch/home)
  
  Merinowolle- dafür ist das neuseeländische Label Icebreaker bekannt. Beim Gewinnen der
  Wolle werden die Tiere nicht verletzt und sie werden gut mit Futter, Getränken und bei
  Bedarf auch Medizin versorgt. Was die Nachhaltigkeit betrifft, müssen alle Zulieferbetriebe
  einen Umweltplan vorweisen. Hier findest Du zweckgerichtete Produkte, die den
  Anforderungen eines aktiven Lebensstils gerecht werden.
  
  ### 5. MARA HOFFMAN
    
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/0e06888c-586c-4135-8d02-a677d2cce692.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/45683939.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/96170377-8da4-4f47-8b21-c9f48aacd22c.jpg)
  
  **FOTOS** [@marahoffman](https://www.instagram.com/marahoffman/)\n
  **WEBSITE:** [https://marahoffman.com](https://marahoffman.com/)
  
  Das Ziel des New Yorker Modelabels Mara Hoffman ist klar definiert: Die Herstellung von
  Streetstyle Kleidungsstücken, welche die Umweltbelastung reduzieren. Um das
  Umweltbewusstsein zu stärken, arbeitet die Marke mit Organisationen wie Earth Matter,
  Fabscrap, Fashion Positive und Queen of Raw zusammen. Zu der sorgfältigen Produktion
  gehört auch, dass weder Pelze und Leder noch Schafwolle und Federn verwendet werden.
  
  ### 6. NIKIN
    
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/887263284911271.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/89246895.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/1329087923.jpg)
  
  **FOTOS** [@nikinclothing](https://www.instagram.com/nikinclothing/)\n
  **WEBSITE:** [https://www.nikinclothing.com](https://www.nikinclothing.com)
  
  Nachhaltige und tierfreundliche Mode für den Alltag findest Du bei dem schweizer
  Unternehmen Nikin. Hierbei werden vegane Materialien verwendet und Leder sowie Wolle
  außen vor gelassen. Bei Knöpfen oder Reißverschlüssen nutzt das Label recycelte
  Materialien, wie beispielsweise Bio-Baumwolle, Leinen, Zuckerrohr, Korkeiche oder Bambus.
  
  ### 7. WILDLING SHOES
    
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/868698032341.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/8937493.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/c4c5ca8c-acd0-4ef3-8fb8-5f02b8d57ff7.jpg)
  
  **FOTOS** [@wildling.shoes](https://www.instagram.com/wildling.shoes/)\n
  **WEBSITE:** [https://www.wildling.shoes](https://www.wildling.shoes/)
  
  Wildling Shoes ist eine deutsche Marke, die ein besonderes Minimalschuh-Konzept
  entwickelt hat und für jeden Geschmack etwas bieten kann. Das Unternehmen achtet auf
  nachhaltige Lieferketten, so werden die Schuhe von Hand in Portugal gefertigt. Außerdem
  sind fast alle Schuhe vegan und es werden zertifizierte Naturmaterialien wie
  (Bio-)Baumwolle, Leinen, Hanf, Kork oder Wollwalk als Alternative zu Kunststoff oder Leder
  benutzt.
  
  ### 8. MELINA BUCHER
    
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/3868c462-8203-4ae8-b5c6-397bc6b42c5a.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/23038204.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/881f0de1-54e8-4115-8810-38134cc5885f.jpg)
  
  **FOTOS** [@bucherbags](https://www.instagram.com/bucherbags/)\n
  **WEBSITE:** [https://melinabucher.com](https://melinabucher.com/)
  
  Auch Melina Buchers Mission setzt ein klares Statement: Die Modewelt zu einem
  ethischeren und nachhaltigeren Ort machen - und das gelingt mit ihren veganen Designer
  Handtaschen. Dabei wird jede Tasche in einer ausgewählten europäischen Manufakturen
  handgefertigt. Wichtig sind dem Label außerdem noch zukunftsweisende Materialien und
  nachvollziehbare Lieferketten.

`;

export default animalDayDe;
