import { ProfileControllerAddAddressMethod } from '../methods/profile-controller/add-address.method';
import { ProfileControllerCreateMethod } from '../methods/profile-controller/create.method';
import { ProfileControllerDeleteMethod } from '../methods/profile-controller/delete.method';
import { ProfileControllerGetActiveBookingListMethod } from '../methods/profile-controller/get-active-booking-list.method';
import { ProfileControllerGetAddressListMethod } from '../methods/profile-controller/get-address-list.method';
import { ProfileControllerGetBookingMethod } from '../methods/profile-controller/get-booking.method';
import { ProfileControllerGetCompletedBookingListMethod } from '../methods/profile-controller/get-completed-booking-list.method';
import { ProfileControllerGetUserRoleMethod } from '../methods/profile-controller/get-user-role.method';
import { ProfileControllerGetMethod } from '../methods/profile-controller/get.method';
import { ProfileControllerRegisterMethod } from '../methods/profile-controller/register.method';
import { ProfileControllerRemoveAddressMethod } from '../methods/profile-controller/remove-address.method';
import { ProfileControllerUpdateAddressMethod } from '../methods/profile-controller/update-address.method';
import { ProfileControllerUpdateMethod } from '../methods/profile-controller/update.method';

export class ProfileController {
  public readonly getActiveBookingList = new ProfileControllerGetActiveBookingListMethod();
  public readonly getBooking = new ProfileControllerGetBookingMethod();
  public readonly getCompletedBookingList = new ProfileControllerGetCompletedBookingListMethod();
  public readonly getAddressList = new ProfileControllerGetAddressListMethod();
  public readonly addAddress = new ProfileControllerAddAddressMethod();
  public readonly get = new ProfileControllerGetMethod();
  public readonly create = new ProfileControllerCreateMethod();
  public readonly update = new ProfileControllerUpdateMethod();
  public readonly delete = new ProfileControllerDeleteMethod();
  public readonly removeAddress = new ProfileControllerRemoveAddressMethod();
  public readonly updateAddress = new ProfileControllerUpdateAddressMethod();
  public readonly register = new ProfileControllerRegisterMethod();
  public readonly getUserRole = new ProfileControllerGetUserRoleMethod();
}
