import _ from 'lodash';
import CartItem from '../Cart/interfaces/item';
import { Product, ProductCategory, ProductType } from '../types';
import { VoucherItem } from 'Voucher/intefaces/item';
import i18n from 'i18n';

export function groupByCategory(products: Product[]): ProductCategory[] {
  const filteredProducts = _.uniqBy(products, 'productCategory._id');
  const categories = filteredProducts.map((x) => x.productCategory);
  const numberedCategories = categories.filter((x) => !!x.displayRank).sort((a, b) => (a.displayRank && b.displayRank && a.displayRank > b.displayRank ? 1 : -1));
  const unnumberedCategories = categories.filter((x) => !x.displayRank);
  return [...numberedCategories, ...unnumberedCategories];
  // (Anton): had to complicate this logic a bit because the `sort` predicate didn't work quite well
}

export function groupByType(products: Product[], categoryId: string): ProductType[] {
  const filteredByCategory = products.filter((x) => x.productCategory.seo?.[i18n.language] === categoryId);
  const types = _.uniqBy(filteredByCategory, 'productType._id').map((x) => x.productType);
  const numberedTypes = types.filter((x) => !!x.displayRank).sort((a, b) => (a.displayRank && b.displayRank && a.displayRank > b.displayRank ? 1 : -1));
  const unnumberedTypes = types.filter((x) => !x.displayRank);
  return [...numberedTypes, ...unnumberedTypes];
}

export function groupByRepair(products: Product[], categoryId: string, typeId: string): Product[] {
  const filtered = products.filter((x) => x.productCategory.seo?.[i18n.language] === categoryId && x.productType.seo?.[i18n.language] === typeId);
  const numberedRepairs = filtered.filter((x) => !!x.displayRank).sort((a, b) => (a.displayRank && b.displayRank && a.displayRank > b.displayRank ? 1 : -1));
  const unnumberedRepairs = filtered.filter((x) => !x.displayRank);
  return [...numberedRepairs, ...unnumberedRepairs];
}

export function getProduct(products: Product[], categoryId: string, typeId: string, repairId: string): Product | undefined {
  return products.find((x) => x.productCategory.seo?.[i18n.language] === categoryId && x.productType.seo?.[i18n.language] === typeId && x.seo?.[i18n.language] === repairId);
}

export function getVoucherById(vouchers: VoucherItem[], targetId: string): VoucherItem | undefined {
  return vouchers.find((voucher) => voucher._id === targetId);
}

// used in tests
interface ProductToCartItemParams {
  product: Product;
  garmentId?: string;
  id?: string;
  comment?: string;
}
export function convertToCartItem({ product, garmentId = 'garment-id', id = 'cart-item-id', comment }: ProductToCartItemParams): CartItem {
  return {
    id,
    garmentId,
    categoryName: product.productCategory.title.de,
    category: {
      id: product.productCategory._id,
      name: product.productCategory.title.de,
    },
    typeName: product.productType.title.de,
    type: {
      id: product.productType._id,
      name: product.productType.title.de,
    },
    repairName: product.name.de,
    repair: {
      id: product._id,
      name: product.name.de,
    },
    priceCent: product.priceCent,
    comment,
  };
}
