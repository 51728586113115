import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps, useLocation, useNavigate } from '@reach/router';
import { toastr } from 'react-redux-toastr';
import { parse } from 'query-string';
import AppBackLink from '../../../components/BackLink';
import Loading from '../../../components/Loading';
import ApiContext from '../../../api/context';
import { getPage } from '../../../utils/pages';
import classes from './SignUpConfirm.module.css';
import { shallowEqual, useSelector } from 'react-redux';
import AppState from '../../../interfaces/app-state';

/**
 * Confirm Sign-Up component.
 */
export default function AuthSignUpConfirm(props: RouteComponentProps) {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { user } = useSelector(
    (state: AppState) => ({
      user: state.auth,
    }),
    shallowEqual
  );
  const navigate = useNavigate();
  const api = useContext(ApiContext);
  const [requestState, setRequestState] = useState<'success' | 'loading' | 'error'>('loading');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (user._id) {
      navigate(`${getPage('profile')}/bookings`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const abortController = new AbortController();
    const isDuplicatedRequest = localStorage.getItem('activationRequest');
    const { token } = parse(search);

    if (!token) {
      setRequestState('error');
      return;
    }

    if (requestState === 'success' || isDuplicatedRequest) {
      navigate(`${getPage('auth')}${getPage('login')}`);
      return;
    }

    localStorage.setItem('activationRequest', '1');

    api.auth
      .confirmSignUp(token as string, abortController)
      .then((isAlreadyVerified) => {
        if (isAlreadyVerified) {
          navigate(`${getPage('auth')}${getPage('login')}`);
          return;
        }

        toastr.success('Success', 'Account activated!');
        setRequestState('success');
        setErrorMessage('');
        navigate(`${getPage('auth')}${getPage('login')}`);
      })
      .catch((err) => {
        toastr.error('Error', err.message);
        setErrorMessage(err.message);
        localStorage.removeItem('activationRequest');
        return () => abortController.abort();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <div>
      {requestState === 'error' && (
        <div className={classes.container}>
          <Box mb={2}>
            <AppBackLink isGoBackActivated />
          </Box>
          <Box textAlign="center" className={classes.section}>
            <Typography variant="h2" component="h1" color="error">
              Error!
            </Typography>
            <Box mb="1rem" />
            <Typography variant="body1" color="error">
              {errorMessage}
            </Typography>
          </Box>
        </div>
      )}
      {requestState === 'success' && (
        <div className={classes.container}>
          <Box mb={2}>
            <AppBackLink isGoBackActivated />
          </Box>
          <Box textAlign="center" className={classes.section}>
            <Typography variant="h2" component="h1">
              Success!
            </Typography>
            <Box mb="1rem" />
            <Typography variant="body1">You can now sign in with your email.</Typography>
            <Box mb="1rem" />
            <Button color="secondary" component={Link} to={`${getPage('auth')}${getPage('login')}`} variant="contained">
              {t('Sign In')}
            </Button>
          </Box>
        </div>
      )}
      {requestState === 'loading' && (
        <div className={classes.container}>
          <Box mb={2}>
            <AppBackLink isGoBackActivated />
          </Box>
          <section className={classes.section}>
            <Typography variant="h2" component="h1">
              {t('Activating your account')}
            </Typography>
            <Box mb="1rem" />
            <Loading />
            <Box mb="1.5rem" />
          </section>
        </div>
      )}
    </div>
  );
}
