import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { PlatformConfigControllerGetAllCityConfigResponse } from '../../responses/platform-config-controller-get-all-city-config.response';

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/platform-config/city',
  operationId: 'PlatformConfigController_getAllCityConfig',
  parameters: [],
})
export class PlatformConfigControllerGetAllCityConfigMethod
  extends BaseOpenApiMethod<PlatformConfigControllerGetAllCityConfigResponse>
  implements Method<PlatformConfigControllerGetAllCityConfigResponse, void>
{
  public call(): Promise<PlatformConfigControllerGetAllCityConfigResponse> {
    return super.call();
  }
}
