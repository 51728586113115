import { useEffect } from 'react';
import { useLocation } from '@reach/router';
import ReactPixel from 'react-facebook-pixel';

// https://www.npmjs.com/package/react-facebook-pixel
export default function FacebookRouterAnalytics() {
  const location = useLocation();

  // const keyMap = {
  //   C0001: 'required',
  //   C0002: 'performance',
  //   C0003: 'functional',
  //   C0004: 'targeting',
  //   C0005: 'social media',
  // };
  //
  // function getCookie(name) {
  //   const value = `; ${document.cookie}`;
  //   const parts = value.split(`; ${name}=`);
  //   if (parts.length === 2) return parts.pop().split(';').shift();
  // }
  //
  // function getConsentConfig() {
  //   const consentCookie = getCookie('OptanonConsent');
  //   return decodeURIComponent(consentCookie?.split('&').filter((subString) => subString.startsWith('groups'))[0])
  //     .replace('groups=', '')
  //     .split(',')
  //     .reduce(
  //       (consentConfig2, str) => {
  //         const config = str.split(':');
  //         consentConfig2[keyMap[config[0]]] = config[1] === '1';
  //         return consentConfig2;
  //       },
  //       { required: false, performance: false, functional: false, targeting: false, 'social media': false }
  //     );
  // }
  //
  // function updateConsent() {
  //   const consentConfig = getConsentConfig();
  //   // Cookie Pro finds only required and targeting cookies right now
  //   if (consentConfig.required && consentConfig.targeting) {
  //     ReactPixel.grantConsent();
  //   } else {
  //     ReactPixel.revokeConsent();
  //     document.cookie = '_fbp=; Path=/; Max-Age=0; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  //   }
  // }

  // https://community.cookiepro.com/s/article/UUID-69162cb7-c4a2-ac70-39a1-ca69c9340046
  // var otConsentSdk = document.getElementById("onetrust-consent-sdk");
  useEffect(() => {
    ReactPixel.init('1455761568121951');
    ReactPixel.revokeConsent();
    // updateConsent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ReactPixel.pageView();
  }, [location]);

  return null;
}
