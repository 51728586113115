import checkoutInitialState from './initial-state';
import { SET_CUSTOMER_DATA, SET_CUSTOMER_SHIPPING_DATA } from './types';

export default function checkoutReducer(state = checkoutInitialState, action) {
  switch (action.type) {
    case SET_CUSTOMER_DATA: {
      const { customerData } = action.payload;
      return { ...state, customerData };
    }
    case SET_CUSTOMER_SHIPPING_DATA: {
      const { customerShippingData } = action.payload;
      return { ...state, customerShippingData };
    }
    default:
      return state;
  }
}
