import React, { createContext, FC, PropsWithChildren, useContext, useState } from 'react';

type RepairImage = {
  file: File;
  creationTime: number;
};

type UtilsContextType = {
  images: RepairImage[];
  setImages: React.Dispatch<React.SetStateAction<RepairImage[]>>;
  repairCreationTimes: number;
  setRepairCreationTimes: React.Dispatch<React.SetStateAction<number>>;
};

const initialFunction = () => {};

export const UtilsContext = createContext<UtilsContextType>({
  images: [],
  setImages: initialFunction,
  repairCreationTimes: 0,
  setRepairCreationTimes: initialFunction,
});


export const UtilsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [images, setImages] = useState<RepairImage[]>([]);
  const [repairCreationTimes, setRepairCreationTimes] = useState<number>(0);

  const value = {
    images,
    setImages,
    repairCreationTimes,
    setRepairCreationTimes,
  };

  return <UtilsContext.Provider value={value}>{children}</UtilsContext.Provider>;
};

export const useUtilsContext = () => {
  return useContext(UtilsContext);
};
