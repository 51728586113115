import ProductApi from '../interfaces/api';
import appRequest from '../../utils/request';

/**
 * Product API definitions for production use.
 *
 * These definitions can only be covered by end-to-end (e2e) tests.
 */
const productApiProd: ProductApi = {
  fetchProducts: async () => {
    const { data } = await appRequest('product/list');
    return data;
  },
  fetchProductById: async (_id) => {
    const { data } = await appRequest(`product/by-id/${_id}`);
    return data?.data ?? data;
  },
};

export default productApiProd;
