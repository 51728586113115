import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import orangeBackground from '../../assets/orange-background.svg';
import MonikaPicture from '../../assets/picture 1 - monika.png';

const useStyles = makeStyles((theme) => {
  const s = (x) => theme.spacing(x);
  return {
    introBlock: {
      marginBottom: '40px',
    },
    repairLink: {
      width: '100%',
      maxWidth: '272px',
      textAlign: 'center',
      fontSize: s(2),
      fontWeight: 700,
      color: '#ffffff',
      backgroundImage: `url(${orangeBackground})`,
      backgroundSize: 'cover',
      padding: `${s(1.5)}px ${s(0)}px`,
      marginTop: 'auto',
      transition: '0.4s ease-in-out',
      '&:hover': {
        color: '#ffffff',
        scale: 1.1,
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        margin: '70px 0'
      },
    },
    textBlock: {
      marginTop: '20px',
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    rightPicture: {
      width: '100%',
      height: '440px',
      maxWidth: '100%',
      objectFit: 'contain',
    },
    leftBlock: {
      flex: '0 0 60%',
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
    },
    leftText: {
      color: '#ffffff',
      lineHeight: '30.5px',
    },
    brandTitle: {
      fontSize: s(3.5),
      marginBottom: '15px'
    },
    subTitle: {
      maxWidth: '700px',
      fontSize: s(3.5),
      color: '#ffffff',
      marginBottom: '20px',
    },
    rightBlock: {
      flex: '0 0 50%',
    },
  };
});

const IntroBlock = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.introBlock}>
      <div className={classes.textBlock}>
        <div className={classes.leftBlock}>
          <Typography component={'h2'} className={`${classes.brandTitle} with-bar`}>
            {t('BRAND PARTNERSHIPS')}
          </Typography>
          <Typography component={'h2'} className={classes.subTitle}>
            {t('Brand subtitle')}
          </Typography>
          <Typography component={'p'} className={classes.leftText}>
            {t('Brand text')}
          </Typography>
          <a href="mailto:partnerships@repair-rebels.com" className={classes.repairLink}>
            {t('GetInTouch')}
          </a>
        </div>
        <div className={classes.rightBlock}>
          <img src={MonikaPicture} alt="Monika" className={classes.rightPicture} />
        </div>
      </div>
    </div>
  );
};

export default IntroBlock;
