import platformConfigInitialState from './initial-state';
import { SET_CITY_CONFIG, SET_SERVICE_PRICES } from './types';

export default function platformConfigReducer(state = platformConfigInitialState, action) {
  switch (action.type) {
    case SET_CITY_CONFIG: {
      const { cityConfigs } = action.payload;
      return { ...state, cityConfigs };
    }
    case SET_SERVICE_PRICES: {
      const { servicePrices } = action.payload;
      return { ...state, servicePrices };
    }
    default:
      return state;
  }
}
