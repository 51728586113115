import { toastr } from 'react-redux-toastr';
import { EstimationApi, UploadedFileData } from '../interfaces/api';
import EstimationRequestPost from '../interfaces/estimationRequestPost';
import API_SERVICE from '../../api/api.service';
import appRequest from '../../utils/request';
import { unexpectedServerError } from '../../utils/network-error-msg';

async function createRequest(requestBody: EstimationRequestPost): Promise<void> {
  const response = await appRequest(`estimation/new`, {
    method: 'POST',
    data: { requestBody },
  });

  if (!response) {
    unexpectedServerError();
    return;
  }

  const { status, data: errorResponse } = response;

  if (status !== 201) {
    return toastr.error('Server error', "Can't create estimation. Try again later.");
  }

  if (errorResponse) {
    return toastr.error('Error', errorResponse);
  }
}

async function uploadImage(clientName: string, creationTime: number, file: UploadedFileData): Promise<void> {
  await API_SERVICE.estimateController.uploadImage.call({
    requestBody: file,
    parameters: { clientName, creationTime },
  });
}

async function uploadImageOrder(clientName: string, creationTime: number, file: UploadedFileData): Promise<void> {
  await API_SERVICE.cartController.uploadOrderImage.call({
    requestBody: file,
    parameters: { clientName, creationTime },
  });
}

/**
 * Estimation API definitions for production use.
 *
 * These definitions can only be covered by end-to-end (e2e) tests.
 */
const estimationApiProd: EstimationApi = {
  createRequest,
  uploadImage,
  uploadImageOrder
};

export default estimationApiProd;
