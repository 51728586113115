import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { TransportConstantsControllerGetTimeResponse } from '../../responses/transport-constants-controller-get-time.response';

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/transport-constants/time',
  operationId: 'TransportConstantsController_getTime',
  parameters: [],
})
export class TransportConstantsControllerGetTimeMethod
  extends BaseOpenApiMethod<TransportConstantsControllerGetTimeResponse>
  implements Method<TransportConstantsControllerGetTimeResponse, void>
{
  public call(): Promise<TransportConstantsControllerGetTimeResponse> {
    return super.call();
  }
}
