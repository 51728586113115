const seoI18nDe = {
  'landing-title': 'Repair Rebels',
  'landing-description':
    'Repair Rebels ist Dein Online Rundum-Service für Modereparaturen. Unsere erfahrenen Schuhmacher und Änderungsschneider reparieren Taschen, Schuhe und Textilien. Sichere Dir ein Abhol- und Zustelldatum für unseren persönlichen Kurier oder schicke uns Deine Reparaturen per Post zu.',
  'faq-title': 'Repair Rebels - wie funktioniert es?',
  'faq-description':
    'Alle wichtigen Fragen zu unserem Online Reparaturservice sind in den Repair Rebels FAQs aufgelistet. Finde heraus wie unsere Buchung oder unser Kurierservice funktionieren. Hier geht es zum Kontakt.',
  'about-title': 'Über uns',
  'about-description':
    'Gründerin Dr. Monika Hauck erzählt die Entstehungsgeschichte vom Mode Technologie Startup Repair Rebels, Deinem Online Reparaturservice für Textil- und Schuhreparaturen aus Düsseldorf.',
  'blog-title': 'Unser Blog',
  'blog-description':
    'Auf unserem Repair Rebels Blog findest Du Blogartikel aus der deutschlandweiten Reparaturszene, Tipps und Tricks für Textilpflege und aktuelle Neuigkeiten von Deinem Online Service für Modereparaturen aller Art.',
  'tailor-title': 'Unsere Schuhmacher, Schneider und Goldschmiede ',
  'tailor-description':
    "Lerne Repair Rebels' erfahrene Künstler des traditionellen Textilhandwerks kennen. Unsere Schuhmacher, Schneider und Goldschmiede freuen sich auf Deine herausfordernden Bekleidungs- und Schuhreparaturen!",
  'register-title': 'Registriere dich bei Repair Rebels',
  'register-description': 'Registriere Dich bei Repair Rebels, um in Deinem Profil Deine Reparaturen einzusehen.',
  'login-title': 'Login',
  'login-description': 'Logge Dich in Deinen Account von Repair Rebels ein, um all Deine Kleidungsreparaturen einzusehen.',
  'forgot-password-title': 'Passwort vergessen',
  'forgot-password-description': "Klicke hier, um Dein Passwort für Deinen Repair Rebels' Account zurückzusetzen, wenn Du es vergessen hast.",
  'reset-password-title': 'Aktualisieren Sie Ihr Passwort',
  'reset-password-description': "Erstellen Sie hier ein neues Passwort für ein Repair Rebels-Konto.",
  'create-password-title': "Erstellen Sie Ihr Passwort",
  'create-password-description': "Erstellen Sie hier ein neues Passwort für ein Repair Rebels-Konto.",
  'club-title': "Repair Rebels' Super Rebel Loyalty Club",
  'club-description':
    "Trete dem Repair Rebels' Super Rebel Loyalty Club bei, um aktuelle Neuigkeiten zu erhalten und von exklusiven Rabatten zu profitieren. Hier kannst Du all Deine Kleidungsreparaturen in Deinem Profil einsehen.",
  'imprint-title': 'Impressum',
  'imprint-description': 'Hier findest Du alle rechtlichen Hintergründe zur Repair Rebels GmbH.',
  'privacy-policy-title': 'Datenschutzerklärung',
  'privacy-policy-description': 'Hier findest Du alles über die Repair Rebels Datenschutzerklärung.',
  'terms-title': 'Allgemeinen Geschäftsbedinungen',
  'terms-description': 'Hier kommst Du zu den allgemeinen Geschäftsbedinungen von Repair Rebels, dem Online Service für Modereparaturen aller Art.',
  'jobs-title': 'Deine Karriere bei Repair Rebels',
  'partnership-title': 'Repair Rebels - Marken Partnerschaften',
  'partnership-description': 'Unser preisgekrönter Mode-Kreislauf Service bietet anpassbare und skalierbare End-to-End Lösungen für Ihre Marke. Werden Sie Teil unseres Netzwerks von Modeexpert:innen und Pionier:innen.',
  'jobs-description':
    'Du möchtest Teil des Repair Rebels Teams werden? Beginne Deine Karriere bei unserem Modereparatur-Startup und schaue Dir unsere Jobangebote an.',
};

export default seoI18nDe;
