import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { ProfileControllerGetAddressListResponse } from '../../responses/profile-controller-get-address-list.response';

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/profile/address-list',
  operationId: 'ProfileController_getAddressList',
  parameters: [],
})
export class ProfileControllerGetAddressListMethod
  extends BaseOpenApiMethod<ProfileControllerGetAddressListResponse>
  implements Method<ProfileControllerGetAddressListResponse, void>
{
  public call(): Promise<ProfileControllerGetAddressListResponse> {
    return super.call();
  }
}
