/**
 * Markdown
 */
const aufraeumenMitSystemBodyEn = `

 With a few simple tricks, even tidying up can be fun. We'll tell you how to make your overstuffed closet a thing of the past.
 
 It's been ages since we've seen the inside of offices and universities - a reality for many of us who currently work from home. Right now, we spend more time in our own homes than we ever have done before. At the start of each brand new day in home office, as we fetch our next pair of sweatpants out of the closet, some of us quickly get the idea to re-organize and clean out our closet.
 
 ## But what's the best way to start?
 
 Instagram and co. are literally exploding from tips and tricks on how to best get it done- and in the search for the perfect approach, it's not at all uncommon to stumble across a video by Marie Kondo. She makes decluttering look like child's play through what she calls her "KonMari Method." But by all means, reorganizing your closet doesn't sound as exciting as a soccer game with friends - but since that's not possible at the moment anyway, you might as well give it a chance, right?
 
 ## Before we start- who is Marie Kondo anyway?
 
 Marie Kondo originated the idea of the KonMari Method and has been passionate about tidying up since she was 19 years old. She has written four books about her tidying method, selling over 4 million copies worldwide. In addition, she is the founder of KonMari Media Inc. and has her own Netflix series, "Tidying up with Marie Kondo," in which she helps people who are overwhelmed with tidying their homes to get on the path to tidying. Through her passion for decluttering, she has built an impressive empire- a fortune of about US$8 million. The reason her books sell so well and her Netflix series enjoys high ratings is probably because it addresses such an everyday problem we all struggle with. Being a little overwhelmed with tidying up has probably been experienced by all of us. The reasons for it are usually not having an effective strategy or being at a loss where to start. But these problems can be fixed quite easily once you have an outlined plan in mind.
 
 ## How does the method work?
 
 Let's take a closer look at the KonMari method: The KonMari method advises the following procedure: Start directly with your garments and then continue with books and office papers. Later follows Komono, a sort of mixed category. In other words, this category depends on your possessions and is individual for everyone, but you should pay special attention to one thing: Items of emotional value should always be sorted out last. A fundamental difference between how we normally declutter and Marie Kondo's method is that the method advises decluttering by category rather than by room. In other words, this method focuses on the individual items that you would like to organize, rather than the places where they are located. In practice, this might look like starting with t-shirts, then working your way to jeans, and then moving on to the next category. A side tip: After you've successfully cleaned out your closet, you could go straight into spring cleaning your entire home, as the closet is the perfect starting point, according to the KonMari Method. While cleaning out your closet, you will surely ask yourself several times whether you should dispose certain things or not - this is where the central question of the KonMari Method should shed some light:
 
 > "Does it spark joy?"
 
 If the answer is yes, great! Then you should keep the garment.
 
 ## But what should we do if the answer is no?
 
 On average, we don't wear about 64% of the clothes in our closet. With that being said, it is obvious that we will want to get rid of a few pieces of clothing over and over again. During the process of cleaning out our closet, we sort out an average of 19 pieces of clothing. However, after we throw the clothes out of our closet, they do not simply disappear. Many people don't know how to properly dispose their clothes, which unfortunately means that the trash can is still the first choice for many. A study in the UK revealed that 82% of men and 69% of women (n=2000) (The Guardian, 2017) throw their clothes in the trash. Globally, this behavior results in an entire garbage truck of clothing ending up in landfill or being incinerated every second! (Fashion Revolution, 2020) And with more and more clothing being produced every day, it is expected that there will be much more in the future. The amount of clothing produced annually doubled since 2000 and surpassed 100 billion for the first time in 2014, so it is estimated that the fashion industry generates 92 million tons of textile waste annually. (Fashion Revolution, 2020).
 
 That's why we asked ourselves two questions to help you clean out your closet in a sustainable way. After all, simply leaving clothes unused in our cupboard doesn't help either.
 
 ## 1. Why don't you like the clothes anymore?
 
 Basically, the reasons can be divided into two categories: Impulse purchases, such as highly seasonal pieces of clothing. Pieces that you have in what feels like a zillion different colors or that simply don't fit in with the rest of your closet, can be placed in the category of bad decisions. Even a piece that you bought despite its poor quality, yet you find absolutely uncomfortable, belongs in this category. But as we all know, you can learn from mistakes! They help us to make better decisions when buying clothes in the future. This way, we can purposefully avoid overbuying- and that will certainly make the next clean-out of your closet easier. ☺ The second category includes pieces that no longer fit you due to a weight change, for example, or are damaged or worn out- anything to do with the condition and fit of your garment. But here we have good news; we at Repair Rebels can fix these problems in no time!
 
 For the garments where we can't help you regain your joy, you should ask yourself this question:
 
 ## 2. Can it bring joy to someone else?
 
 Depending on the condition and quality of the garment, you have three options: You could share or trade it, sell or rent it, and last but not least, donate or recycle it. If you want to learn more, stay tuned! New tips and info will follow soon.
 
 `;

export default aufraeumenMitSystemBodyEn;
