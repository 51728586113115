/**
 * Markdown
 */
const repairRebelsRepairEventEn = `

  Shockingly, in today's world, many products are simply thrown away and unfortunately not
  repaired or recycled. To counteract this growing environmental disaster, [International Repair Day](https://openrepair.org/international-repair-day/)
  took place on October 15, 2022. With over 400 official events, the world celebrated the 24
  repair hours this year under the slogan "Repair Everywhere". Since 2017, the initiative, in
  collaboration with the Open Repair Alliance, has already been advocating for highlighting the
  relevance of repairs of all kinds and thereby saving CO2 emissions. Repair Day aims to call
  for any repairs -whether electronics, furniture or fashion repairs- to be tackled and to remind
  people how significant local craftsmanship is.
  
  Of course, we from [Repair Rebels](https://www.repair-rebels.com/) celebrated this day with
  our community in our Düsseldorf co-working space
  on Lorettostraße 9. Between a glass of wine or organic spritzer and vegetarian appetizers,
  there was the opportunity to meet our craft artists in person. Guests were also able to drop
  off their cherished, broken clothes, shoes or bags at our repair acceptance point and thus
  learn more about our service and the diverse possibilities of fashion repair - because
  Repaired Clothes are Loved Stronger!
  
  The whole event started with a warm welcome from Repair Rebels founder Dr. Monika
  Hauck. Repair Rebels' vision that day became clearer than ever: people should be proud to
  repair their clothes. Textile and shoe repairs, with the help of the digitalization of the local
  craft industry, should be as fashionable and easy as buying new items. And especially on
  this day, repairers - enthusiasts and professionals - should be motivated to share their repair
  skills to encourage others to join in. It was a complete success, as the faces of all the
  present Rebels reflected anticipation of a future in line with the motto: "We repair our clothes,
  and in doing so, we also repair our world a little bit."
  
  Afterwards, the [mayor of Düsseldorf](https://www.duesseldorf.de/ob.html) Dr. Stephan Keller encouraged the Repair Revolution with the words: "In
  a world where we have countered fast food with a slow food movement, it is time that we
  also counter fast fashion with slow fashion."
  The statement of his words was supported on the same day by the fact that he himself
  commissioned a repair for Repair Rebels, which we will of course very gladly send back to
  City Hall like new!
  
  Curious, the Repair Rebels community also followed the thoughts of Jan Matzoll from the
  parliamentary group [B90/ DIE GRÜNEN NRW](https://gruene-fraktion-nrw.de/personen/jan-matzoll/), who also
  shared his knowledge with us: "A right to repair, a fair and transparent supply chain law,
  sustainable procurement in all public institutions and a real circular economy are the key to
  change. Politics in the country and also in the federation Europe made themselves there on
  the way, but if one runs for decades in the wrong direction, then is not sufficient evenly, if one
  makes itself comfortably on the way, there we must really insert a step in the direction of the
  cycle economy, in the direction of the repair culture ". True words that needed to be said, we
  think!
  
  The meeting was rounded off with a tribute to all our talented [craft artists](https://www.repair-rebels.com/tailor), who really deserved a big
  "thank you" on the occasion of International Repair Day.
  
  After a short break, during which the guests could exchange their newly gained input, we
  continued with the presentation of Anne-Marie Morschek from [Brautkleidatelier DSigns](https://www.brautkleidatelier.de/). As a trained tailor
  and studied fashion designer, she had been able to gather vast amounts of knowledge and
  experience over the course of two decades. She shared this knowledge in her field with us
  that day in the form of a free sewing machine workshop for absolute beginners. Whether
  young or old, enthusiastic beginner or experienced sewing machine professional - everyone
  was allowed to try their hand at the sewing machine. From the rattle of sewing machines,
  inspiring lectures and magical atmosphere, characterized by relaxed music, good snacks
  and the laughter of satisfied guests, a Blue, Orange & Black festive garland was finally
  created, which we proudly attached to our wall in Loretto in the name of the International
  Repair Day!
    
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Repair-Event+by+Repair+Rebels/IMG_6186.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Repair-Event+by+Repair+Rebels/IMG_6402.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Repair-Event+by+Repair+Rebels/IMG_6442.jpg)
 

`;

export default repairRebelsRepairEventEn;
