import ProfileState from '../interfaces/state';
// import profileActiveBookingsFixture from "../fixtures/active-bookings";
// import profilePreviousBookingsFixture from "../fixtures/previous-booking";
// import profileMyAddressesFixture from "../fixtures/my-addresses";
// import profileUserInfoFixture from '../fixtures/user-info';

const profileInitialState: ProfileState = {
  // TODO: to check profile data for now - uncomment code
  userInfo: null /*profileUserInfoFixture*/,
  userAddresses: null /*profileMyAddressesFixture*/,
  activeBookingItems: null /*profileActiveBookingsFixture*/,
  previousBookingItems: null /*profilePreviousBookingsFixture*/,
  cityInfo: null,
  downloadingError: null,
};

export default profileInitialState;
