import { createMuiTheme } from '@material-ui/core';
import buttonMask from './images/svg/button-secondary.svg';
import sheet from './images/svg/sheet2.svg';

const fontFamily = '"Work Sans", Arial, sans-serif';
const colorPrimary = '#5BBCCC';
const colorSecondary = '#EC672B';

const muiTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  palette: {
    primary: {
      main: colorPrimary,
    },
    secondary: {
      main: colorSecondary,
    },
  },
  props: {
    MuiContainer: {
      fixed: true,
      maxWidth: 'xl',
    },
    MuiPaper: {
      variant: 'outlined',
    },
  },
  typography: {
    fontFamily,
    button: {
      fontWeight: 800,
    },
    caption: {
      lineHeight: 1.25,
    },
  },
});

// spacing utility
const s = (x: number) => muiTheme.spacing(x);

// typography
muiTheme.typography.body1 = {
  fontSize: '1rem',
  lineHeight: 1.2,
  [muiTheme.breakpoints.up('xl')]: {
    fontSize: '1.2rem',
  },
};
muiTheme.typography.body2 = {
  fontSize: '.9rem',
  lineHeight: 1.2,
  [muiTheme.breakpoints.up('xl')]: {
    fontSize: '1rem',
  },
};
muiTheme.typography.h1 = {
  fontSize: '2rem',
  fontWeight: 800,
  lineHeight: 1.2,
  textTransform: 'uppercase',
  [muiTheme.breakpoints.up('xl')]: {
    fontSize: '2.5rem',
  },
};
muiTheme.typography.h2 = {
  fontSize: '1.5rem',
  fontWeight: 800,
  lineHeight: 1.2,
  textTransform: 'uppercase',
  [muiTheme.breakpoints.up('xl')]: {
    fontSize: '2rem',
  },
};
muiTheme.typography.h3 = {
  fontSize: '1.25rem',
  fontWeight: 800,
  lineHeight: 1.2,
  textTransform: 'uppercase',
  [muiTheme.breakpoints.up('xl')]: {
    fontSize: '1.5rem',
  },
};
muiTheme.typography.h4 = {
  fontSize: '1.1rem',
  fontWeight: 800,
  lineHeight: 1.2,
  textTransform: 'uppercase',
  [muiTheme.breakpoints.up('xl')]: {
    fontSize: '1.25rem',
  },
};
muiTheme.typography.h5 = {
  fontSize: '1rem',
  fontWeight: 800,
  lineHeight: 1.2,
  textTransform: 'uppercase',
  [muiTheme.breakpoints.up('xl')]: {
    fontSize: '1.1rem',
  },
};
muiTheme.typography.h6 = {
  fontSize: '.9rem',
  fontWeight: 800,
  lineHeight: 1.2,
  textTransform: 'uppercase',
  [muiTheme.breakpoints.up('xl')]: {
    fontSize: '1rem',
  },
};

// overrides
muiTheme.overrides = {
  MuiPaper: {
    root: {
      padding: s(3),
      backgroundColor: 'transparent',
      backgroundImage: `url(${sheet})`,
      backgroundSize: '100% 100%',
      [muiTheme.breakpoints.up('lg')]: {
        padding: s(5),
      },
    },
    outlined: {
      border: 'none',
    },
  },
  MuiButton: {
    contained: {
      border: 'none',
      borderRadius: 0,
      padding: '1rem 2rem', // TODO: mui spacing
      backgroundColor: colorSecondary,
      maskImage: `url(${buttonMask})`,
      maskSize: '100% 100%',
      fontSize: '1rem',
      '&.Mui-disabled': {
        backgroundColor: muiTheme.palette.secondary.light,
      },
    },
    containedSecondary: {
      color: 'white !important', // for button links
    },
    containedPrimary: {
      color: 'white !important', // for button links
    },
    text: {
      textDecoration: 'underline !important',
    },
  },
  MuiInputBase: {
    root: {
      fontFamily,
    },
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: 0,
      '& fieldset': {
        borderColor: colorPrimary,
      },
    },
  },
  MuiInputAdornment: {
    root:{
      cursor: 'pointer'
    }
  }
};

export default muiTheme;
