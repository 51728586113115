import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { DiscountControllerCreateRequestBody } from '../../request-bodies/discount-controller-create.request-body';
import { DiscountControllerCreateResponse } from '../../responses/discount-controller-create.response';

interface DiscountControllerCreateOpenApiParameters {
  requestBody: DiscountControllerCreateRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.POST,
  apiName: 'customer',
  path: '/discount',
  operationId: 'DiscountController_create',
  parameters: [],
})
export class DiscountControllerCreateMethod
  extends BaseOpenApiMethod<DiscountControllerCreateResponse>
  implements Method<DiscountControllerCreateResponse, DiscountControllerCreateOpenApiParameters>
{
  public call(parameters: DiscountControllerCreateOpenApiParameters): Promise<DiscountControllerCreateResponse> {
    return super.call(parameters);
  }
}
