import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import WhatsApp from './WhatsApp.png';

// styles
const useStyles = makeStyles((theme) => {
  const s = (x: number) => theme.spacing(x);
  return {
    link: {
      display: 'inline-block',
      width: s(12),
    },
    image: {
      width: '100%',
    },
  };
});

export default function AppWhatsAppButton() {
  const classes = useStyles();
  const { t } = useTranslation();
  const whatsappLink = 'https://wa.me/491735204782/?text=' + encodeURI(t('Hi Repair Rebels, könnt ihr meine Lieblingsstücke retten?'));
  return (
    <a className={classes.link} href={whatsappLink} rel="noopener noreferrer" target="_blank" aria-label="Contact via WhatsApp">
      <LazyLoadImage
        className={classes.image}
        alt="WhatsApp"
        src={WhatsApp}
        effect="blur"
        // height={image.height}
        // width={image.width}
      />
    </a>
  );
}
