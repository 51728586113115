/**
 * Markdown
 */
const repairRebelsWonEn = `
  ## Repair Rebels Won the German Federal Ecodesign Award!

  The German Ecodesign Award is Germany's highest state award for ecological design. It 
  recognizes outstanding entrepreneurial achievements in the field of sustainability and design. 
  Repair Rebels team is delighted to be chosen as the winner in the [Service Category](https://bundespreis-ecodesign.de/en/winners/2023-repair-rebels), which is 
  an amazing recognition and a great encouragement for our young startup.
  
  ## ABOUT BUNDESPREIS ECODESIGN 

  Since 2012, the Award has been granted annually by the Federal Ministry for the Environment (BMUV) and the German Environment Agency (UBA) 
  in cooperation with the International Design Center Berlin (IDZ).

  The German Federal Ecodesign Award aims to promote awareness of environmental and sustainable design practices and to encourage designers, 
  companies, and organizations to integrate ecological considerations into their products, services, and concepts. The award covers a broad 
  range of categories, including product design, service design, concept design, and young talent.

  Winning projects often showcase a holistic approach to design, considering not only the end product but also the entire life cycle, from raw 
  material extraction to disposal. The key and distinguishing aspect of the German Federal Ecodesign Award is its independent and 
  interdisciplinary jury, which includes experts from design, business, research, and environmental organizations. This diverse panel 
  ensures a comprehensive evaluation of the entries.

  ## AWARD CEREMONY IN BERLIN  
  
  On December 4th, Monika Hauck, founder of Repair Rebels, traveled to Berlin to receive the award with a personalized certificate from 
  Steffi Lemke, the Federal Minister for Environment and Consumer Protection. In her welcome speech, the Minister advocated for the 
  **#RightToRepair** movement and stressed the important role that the local repair industry plays in moving towards a more Circular Economy 
  approach to business in Germany.

  As a homage to her Lithuanian heritage, Monika was wearing her grandmother's amber necklace, which fell apart but was wonderfully 
  repaired by our goldsmith, [Katja Voscht](https://www.repair-rebels.com/our-partners/katja-voscht). This award is not only a great recognition for our team but also for our network of talented 
  craftsmen, who helped us to put digital fashion repair services on the map.

  For the year 2024, we are looking forward to even more opportunities to advocate for the **#SlowFashion** movement and promote **#RepairCulture** 
  in Germany. We extend our gratitude to the Bundespreis Ecodesign Jury for believing in our vision and to Prof. Anna Berkenbusch for a 
  wonderful laudation and a clear message: 

  > "It’s always better to repair or alter an item than to recycle it."

  ## THE TOURING EXHIBITION
   
  Do you want to learn more about the work of the nominated and award-winning companies and their projects? Then, visit a Touring 
  Exhibition [Thinking Ahead](https://bundespreis-ecodesign.de/en/events/exhibition). Starting in Berlin it goes on tour, appearing at trade fairs, museums and design festivals. Other 
  stations include Vienna, Innsbruck and Paris. 

  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Repair-rebels-won/Bundespreis+Ecodesign+Gewinner.jpeg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Repair-rebels-won/Bundespreis+Ecodesign+2023.jpeg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Repair-rebels-won/Bundespreis+Ecodesign.jpeg)

  Picture credit: Sandra Kühnapfel 
`;

export default repairRebelsWonEn;
