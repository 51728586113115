import React from 'react';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import AppWhatsApp from '../../../components/WhatsApp/WhatsApp';
import ProductCategoryStepList from './List';
import { ReactComponent as ArrowIcon } from '../../../images/svg/arrow.svg';
import AppBackLink from '../../../components/BackLink';
import { Product } from '../../../types';
import RunningLine from 'components/RunningLine';
import i18n from 'i18n';

// styles
const useStyles = makeStyles((theme) => {
  const s = (x) => theme.spacing(x);
  return {
    headingBlock: {
      position: 'relative',
    },
    arrowIcon: {
      position: 'absolute',
      top: s(0.5),
      left: -s(17),
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
      [theme.breakpoints.up('xl')]: {
        top: s(1),
      },
    },
  };
});

// props
interface ProductCategoryStepProps extends RouteComponentProps {
  products: Product[];
}

/**
 * Product category step component.
 */
export default function ProductCategoryStep({ products }: ProductCategoryStepProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const headingHtmlId = 'ProductCategoryStep-heading';

  return (
    <Box>
      <RunningLine />
      <Container maxWidth="sm">
        <AppBackLink to="/" />
        <Box className={classes.headingBlock} px={{ xs: 0, sm: 2 }}>
          <Typography className="with-bar" id={headingHtmlId} variant="h1">
            {t('Choose the item to repair')}
          </Typography>
          <ArrowIcon className={classes.arrowIcon} />
        </Box>
        <Box mb={3} />
        <ProductCategoryStepList products={products} onCategorySelect={(x) => navigate(`./${x.seo?.[i18n.language]}`)} labelledBy={headingHtmlId} />
        <Box mb={6} />
        <AppWhatsApp />
      </Container>
    </Box>
  );
}
