import AppApi from '../interfaces/api';
import productApi from '../Product/api';
import cartApi from '../Cart/api';
import checkoutApi from '../Checkout/api';
import orderStatusApi from '../OrderStatus/api';
import contactApi from '../Contact/api';
import orderEditPickupApi from '../OrderEditPickup/api';
import orderEditDeliveryApi from '../OrderEditDelivery/api';
import estimationApi from '../Estimation/api';
import tailorApi from '../Tailor/api';
import clubApi from '../Club/api';
import authApi from '../Auth/api';
import profileApi from '../Profile/api';
import ratingApi from '../Rating/api';
import orderApi from '../Order/api';
import platformConfigApi from '../PlatformConfig/api';
import voucherApi from 'Voucher/api';
import voucherCartApi from 'Cart/voucherApi';

/*
 * App API definitions for production use.
 */
const appApiProd: AppApi = {
  product: productApi,
  cart: cartApi,
  voucher: voucherApi,
  checkout: checkoutApi,
  orderStatus: orderStatusApi,
  orderEditPickup: orderEditPickupApi,
  orderEditDelivery: orderEditDeliveryApi,
  contact: contactApi,
  estimation: estimationApi,
  tailor: tailorApi,
  club: clubApi,
  auth: authApi,
  profile: profileApi,
  rating: ratingApi,
  order: orderApi,
  platformConfig: platformConfigApi,
  voucherCart: voucherCartApi
};

export default appApiProd;
