import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { AppControllerStatusResponse } from '../../responses/app-controller-status.response';

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/',
  operationId: 'AppController_status',
  parameters: [],
})
export class AppControllerStatusMethod extends BaseOpenApiMethod<AppControllerStatusResponse> implements Method<AppControllerStatusResponse, void> {
  public call(): Promise<AppControllerStatusResponse> {
    return super.call();
  }
}
