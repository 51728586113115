import React from 'react';
import { RouteComponentProps, useNavigate } from '@reach/router';
import ProductRepairStepView from './View';
import { groupByRepair } from '../../utils';
import { Product } from '../../../types';
import i18n from 'i18n';

interface ProductRepairStepRouteProps {
  seo: string;
  typeId: string;
}
interface ProductRepairStepProps extends RouteComponentProps<ProductRepairStepRouteProps> {
  products: Product[];
}

export default function ProductRepairStep({ products, seo, typeId }: ProductRepairStepProps) {
  const navigate = useNavigate();
  const repairs = groupByRepair(products, seo ?? '', typeId ?? '');

  function handleRepairSelect(x: Product) {
    navigate(`./${typeId}/${x.seo?.[i18n.language]}`);
  }

  return <ProductRepairStepView repairs={repairs} onRepairSelect={handleRepairSelect} categoryId={seo} typeId={typeId} />;
}
