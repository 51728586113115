import * as z from 'zod';
import i18next from 'i18next';
import { schema as contactSchema } from './contact-data';
import { flagSchema } from '../Auth/interfaces/register-data';
import {dynamicAddressSchema, dynamicShippingAddressSchema} from '../utils/schema-address';

enum Timeframe {
  MORNING = 'MORNING',
  AFTERNOON = 'AFTERNOON',
  EVENING = 'EVENING',
}
enum PickUpTimeframeLabels {
  MORNING = '12:00-14:00',
  AFTERNOON = '14:00-16:00',
  EVENING = '16:00-18:00',
}
enum DeliveryTimeframeLabels {
  MORNING = '14:00-16:00',
  AFTERNOON = '16:00-18:00',
  EVENING = '18:00-20:00',
}

const PickUpRadioOptions = [
  {
    label: PickUpTimeframeLabels.MORNING,
    value: Timeframe.MORNING,
  },
  {
    label: PickUpTimeframeLabels.AFTERNOON,
    value: Timeframe.AFTERNOON,
  },
  {
    label: PickUpTimeframeLabels.EVENING,
    value: Timeframe.EVENING,
  },
];
const DeliveryRadioOptions = [
  {
    label: DeliveryTimeframeLabels.MORNING,
    value: Timeframe.MORNING,
  },
  {
    label: DeliveryTimeframeLabels.AFTERNOON,
    value: Timeframe.AFTERNOON,
  },
  {
    label: DeliveryTimeframeLabels.EVENING,
    value: Timeframe.EVENING,
  },
];

const addressSchema = z.object({
  city: z.string().nonempty({ message: i18next.t('City required') }).optional(),
  street: z.string().nonempty({ message: i18next.t('Address required') }),
  zipCode: z.string().nonempty({ message: i18next.t('Zip code required') }),
});
const fullAddressSchema = z.object({
  street: z.string().nonempty({ message: i18next.t('Address required') }),
  zipCode: z.string().nonempty({ message: i18next.t('Zip code required') }),
  city: z.string().nonempty({ message: i18next.t('City required') }),
  country: z.string().nonempty({ message: i18next.t('Country required') }),
});
const timeframeEnum = z.nativeEnum(Timeframe);
const datetimeSchema = z.object({
  date: z.string().nonempty().nullable(),
  timeframe: timeframeEnum,
});
const customerDataSchema = z.object({
  contact: contactSchema,
  pickupAddress: addressSchema,
  deliveryAddress: addressSchema,
  pickupTime: datetimeSchema,
  deliveryTime: datetimeSchema,
  flags: flagSchema,
});
const customerShippingDataSchema = z.object({
  contact: contactSchema,
  address: fullAddressSchema,
  flags: flagSchema,
});
const dynamicDataSchema = () =>
  z.object({
    contact: contactSchema,
    pickupAddress: dynamicAddressSchema(),
    deliveryAddress: dynamicAddressSchema(),
    pickupTime: datetimeSchema,
    deliveryTime: datetimeSchema,
    flags: flagSchema,
  });

const dynamicShippingDataSchema = () =>
  z.object({
    contact: contactSchema,
    address: dynamicShippingAddressSchema(),
    flags: flagSchema,
  });

export {
  Timeframe,
  PickUpTimeframeLabels,
  DeliveryTimeframeLabels,
  PickUpRadioOptions,
  DeliveryRadioOptions,
  addressSchema,
  timeframeEnum,
  datetimeSchema,
  customerDataSchema,
  customerShippingDataSchema,
  dynamicDataSchema,
  dynamicShippingDataSchema,
};
export default CustomerData;
export type CustomerData = z.infer<typeof customerDataSchema>;
export type CustomerShippingData = z.infer<typeof customerShippingDataSchema>;
export type Address = z.infer<typeof addressSchema>;
export type ShippingAddress = z.infer<typeof fullAddressSchema>;
export type DateTime = z.infer<typeof datetimeSchema>;
