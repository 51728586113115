import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { ProductControllerGetByIdParameter } from '../../parameters/product-controller-get-by-id.parameter';
import { ProductControllerGetByIdResponse } from '../../responses/product-controller-get-by-id.response';

interface ProductControllerGetByIdOpenApiParameters {
  parameters: ProductControllerGetByIdParameter;
}

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/product/{productId}',
  operationId: 'ProductController_getById',
  parameters: [
    {
      name: 'productId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class ProductControllerGetByIdMethod
  extends BaseOpenApiMethod<ProductControllerGetByIdResponse>
  implements Method<ProductControllerGetByIdResponse, ProductControllerGetByIdOpenApiParameters>
{
  public call(parameters: ProductControllerGetByIdOpenApiParameters): Promise<ProductControllerGetByIdResponse> {
    return super.call(parameters);
  }
}
