import React from 'react';
import { Box, Button, ButtonProps, CircularProgress } from '@material-ui/core';

interface Props extends ButtonProps {
  loading: boolean;
  loaderColor?: 'inherit' | 'primary' | 'secondary' | undefined;
}

export default function LoadingButton({ children, loading = false, loaderColor = 'inherit', ...buttonProps }: Props) {
  return (
    <Button {...buttonProps} disabled={buttonProps.disabled || loading}>
      <span>{children}</span>
      <Box display="inline-flex" align-items="center" hidden={!loading} color="white" ml=".5rem">
        <CircularProgress size="1.5rem" color={loaderColor} />
      </Box>
    </Button>
  );
}
