import CartApi from '../interfaces/api';
// import cartItemsFixture from '../fixtures/items';
// import cartAddedItemFixture from '../fixtures/added-item';
import CartState from '../interfaces/state';
import { DiscountResponse } from '../interfaces/discount-responses';
// import { DiscountStatus } from '../interfaces/discountStatus';
import { Repair } from '../../types';

async function fetchItems() {
  return [];
}

async function addItem(productId: string, quantity?: number, comment?: string) {
  return {} as Repair;
}

async function addItemToGarment(garmentId: string, productId: string, quantity?: number, comment?: string) {
  return {} as Repair;
}

async function clearCart() {}
async function fetchCartConstants() {}
// TODO figure out if more complicated logic is needed here
async function migrateCart(previousUserId: string | null = null) {}
async function deleteItem(id: string) {}

async function updateNumBoxes(numBoxes: number): Promise<void> {}

async function fetchCart(): Promise<CartState> {
  return {
    items: [],
    discount: null,
    numBoxes: 0,
    servicePrices: {
      boxPriceCent: 200,
      courierPriceCent: 1300,
      shippingExemptionThreshold: 3400,
    },
    delivery: {
      shippingOrder: true,
      doorToDoorOrder: false,
      city: null,
    }
  };
}

async function getDiscount(code: string): Promise<DiscountResponse> {
  return {
    id: '1231223123',
    code: '123123123',
    deadline: 'Wed Sep 22 2021 17:08:54 GMT+0300 ',
    percent: 10,
    quantity: 100,
  };
}
async function putDiscount(): Promise<void> {}

/**
 * Cart API definitions for automated testing.
 *
 * The definitions of this API are used in automated tests as defaults.
 */
const cartApiTest: CartApi = {
  fetchItems,
  addItem,
  addItemToGarment,
  clearCart,
  fetchCartConstants,
  deleteItem,
  migrateCart,
  updateNumBoxes,
  fetchCart,
  getDiscount,
  putDiscount,
};

export default cartApiTest;
