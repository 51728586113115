import React from 'react';
import ReactDOM from 'react-dom';
import initializeFirebase from './firebase';
import { compose } from 'redux';
import { Provider } from 'react-redux';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import ApiContext from './api/context';
import './i18n';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { store } from './store';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import PopUpWindow from 'Homepage/components/PopUpWindow/PopUpWindow';

const paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID as string;

// https://www.mydatahack.com/getting-redux-devtools-to-work-with-typescript/
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

Sentry.init({
  dsn: 'https://4453b0dcb97349e2a390c95655038708@o1072532.ingest.sentry.io/6071525',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

initializeFirebase().then(() => {
  const appApi = require('./api').default;

  // render app
  ReactDOM.render(
    <>
      <PayPalScriptProvider
        options={{
          'client-id': paypalClientId,
          currency: 'EUR',
          'disable-funding': 'bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo',
        }}
      >
        <Provider store={store}>
          <ApiContext.Provider value={appApi}>
            <App />
            <PopUpWindow/>
          </ApiContext.Provider>
        </Provider>
      </PayPalScriptProvider>
    </>,
    document.getElementById('root')
  );
});

// serviceWorker.register();
