import React, { ElementType, useState } from 'react';
import { Box, CircularProgress, IconButton, makeStyles, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { clearCart, clearVoucherCart, deleteVoucher, setVoucherCart } from '../../store/actions';
import formatPriceCent from '../../../utils/format-price-cent';
import { removePayPalGuard } from '../../../utils/remove-paypal-guard';
import cookies from 'js-cookie';
import { VoucherItem } from 'Voucher/intefaces/item';
import { selectCartVoucherItems } from 'Cart/store/selectors';

const useStyles = makeStyles((theme) => {
  const s = (x) => theme.spacing(x);
  return {
    repair: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginBottom: s(1.5),
    },
    attribute: {
      paddingLeft: s(2),
      textIndent: -s(2),
    },
    price: {
      fontSize: '20px',
    },
  };
});

interface CartRepairProps {
  component?: ElementType;
  disableRemove?: boolean;
  voucherItem: VoucherItem;
  voucherIndex: number;
}

/**
 * Cart garment repairs list item component.
 */
export default function CartVoucher({ component = 'div', disableRemove = false, voucherItem, voucherIndex }: CartRepairProps) {
  const dispatch = useDispatch();
  const voucherItems = useSelector(selectCartVoucherItems);
  const classes = useStyles();
  const langCookie = cookies.get('language') || '';
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const htmlId = `CartRepair_${voucherItem._id}-${!langCookie ? voucherItem.title.de : voucherItem.title[langCookie]}`;
  const priceHtmlId = `${htmlId}-price`;

  async function handleDelete() {
    setLoading(true);

    if (voucherItems.length === 1) {
      setTimeout(() => {
        const setVoucherItem = false;
        dispatch(setVoucherCart(setVoucherItem));
      }, 4000);
      dispatch(clearVoucherCart());
      dispatch(clearCart());
    }

    try {
      // await api.cart.deleteItem(_id);
      setLoading(false);
      dispatch(deleteVoucher(voucherItem._id));
    } catch (err) {
      setLoading(false);
      if (process.env.NODE_ENV === 'development') {
        console.error(err);
      }
      // TODO: handle error
    } finally {
      removePayPalGuard();
    }
  }

  return (
    <>
      <Box component={component} aria-labelledby={htmlId} className={classes.repair}>
        <Box pt={0.5} pr={1.5}>
          <Typography variant="h3">
            {!langCookie ? voucherItem.title.de : voucherItem.title[langCookie]}
          </Typography>
          <Typography className={classes.attribute}>
            <span id={priceHtmlId} className="font-weight-600">
              {t('Price')}
            </span>
            : <span aria-labelledby={priceHtmlId}>{formatPriceCent(voucherItem.priceCent)}</span>
          </Typography>
        </Box>
        {!disableRemove && (
          <IconButton aria-label={t('Delete')} disabled={loading} onClick={handleDelete} size="small">
            {loading ? <CircularProgress color="secondary" size={20} /> : <Close color="secondary" />}
          </IconButton>
        )}
      </Box>
      <Typography aria-label="Garment Price" variant="h3" component="div" align="right" className={classes.price}>
        {formatPriceCent(voucherItem.priceCent)}
      </Typography>
    </>
  );
}
