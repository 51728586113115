import ContactApi from '../interfaces/api';
import ContactRequest from '../interfaces/contactRequest';

const delay = (ms: number) => new Promise((r) => setTimeout(r, ms));

async function postMessage(contactRequest: ContactRequest): Promise<void> {
  await delay(1000);
}

/**
 * Contact API definitions for automated testing.
 *
 * The definitions of this API are used in automated tests as defaults.
 */
const contactApiTest: ContactApi = {
  postMessage,
};

export default contactApiTest;
