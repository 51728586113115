import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { DiscountControllerSetByIdParameter } from '../../parameters/discount-controller-set-by-id.parameter';
import { DiscountControllerSetByIdRequestBody } from '../../request-bodies/discount-controller-set-by-id.request-body';
import { DiscountControllerSetByIdResponse } from '../../responses/discount-controller-set-by-id.response';

interface DiscountControllerSetByIdOpenApiParameters {
  parameters: DiscountControllerSetByIdParameter;
  requestBody: DiscountControllerSetByIdRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.PUT,
  apiName: 'customer',
  path: '/discount/{discountId}',
  operationId: 'DiscountController_setById',
  parameters: [
    {
      name: 'discountId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class DiscountControllerSetByIdMethod
  extends BaseOpenApiMethod<DiscountControllerSetByIdResponse>
  implements Method<DiscountControllerSetByIdResponse, DiscountControllerSetByIdOpenApiParameters>
{
  public call(parameters: DiscountControllerSetByIdOpenApiParameters): Promise<DiscountControllerSetByIdResponse> {
    return super.call(parameters);
  }
}
