import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { ProfileControllerGetResponse } from '../../responses/profile-controller-get.response';

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/profile',
  operationId: 'ProfileController_get',
  parameters: [],
})
export class ProfileControllerGetMethod extends BaseOpenApiMethod<ProfileControllerGetResponse> implements Method<ProfileControllerGetResponse, void> {
  public call(): Promise<ProfileControllerGetResponse> {
    return super.call();
  }
}
