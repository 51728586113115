import { HealthControllerEstimateRequestStorageMethod } from '../methods/health-controller/estimate-request-storage.method';
import { HealthControllerFirestoreMethod } from '../methods/health-controller/firestore.method';
import { HealthControllerMjmlStorageMethod } from '../methods/health-controller/mjml-storage.method';
import { HealthControllerStorageMethod } from '../methods/health-controller/storage.method';

export class HealthController {
  public readonly firestore = new HealthControllerFirestoreMethod();
  public readonly mjmlStorage = new HealthControllerMjmlStorageMethod();
  public readonly estimateRequestStorage = new HealthControllerEstimateRequestStorageMethod();
  public readonly storage = new HealthControllerStorageMethod();
}
