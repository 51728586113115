import OrderApi from '../interfaces/api';

/**
 * Module API definitions for automated testing.
 *
 * The definitions of this API are used in automated tests as defaults.
 */
const orderApiTest: OrderApi = {
  fetchOrderWithAssignmentListById: async (customerId: string, orderId: string) => null,
  fetchOrder: async (orderId: string) => null,
  fetchShippingOrder: async (orderId: string) => null,
  fetchVoucherOrder: async (orderId: string) => null,
};

export default orderApiTest;
