import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { TailorControllerGetPaginatedParameter } from '../../parameters/tailor-controller-get-paginated.parameter';
import { TailorControllerGetPaginatedResponse } from '../../responses/tailor-controller-get-paginated.response';

interface TailorControllerGetPaginatedOpenApiParameters {
  parameters: TailorControllerGetPaginatedParameter;
}

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/tailor',
  operationId: 'TailorController_getPaginated',
  parameters: [
    {
      name: 'lastEvaluatedKey',
      required: false,
      in: OpenApiParameterType.QUERY,
    },
    {
      name: 'limit',
      required: false,
      in: OpenApiParameterType.QUERY,
    },
  ],
})
export class TailorControllerGetPaginatedMethod
  extends BaseOpenApiMethod<TailorControllerGetPaginatedResponse>
  implements Method<TailorControllerGetPaginatedResponse, TailorControllerGetPaginatedOpenApiParameters>
{
  public call(parameters?: TailorControllerGetPaginatedOpenApiParameters): Promise<TailorControllerGetPaginatedResponse> {
    return super.call(parameters);
  }
}
