enum TransportType {
  PICK_UP = 'PICK_UP',
  DELIVERY = 'DELIVERY',
  TAILOR = 'TAILOR',
}

enum TransportStatus {
  SCHEDULED = 'SCHEDULED',
  DONE = 'DONE',
  STUCK = 'STUCK',
  RESOLVED = 'RESOLVED',
  CANCELLED = 'CANCELLED',
}

export { TransportType, TransportStatus };
