import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { EstimateControllerCreateRequestBody } from '../../request-bodies/estimate-controller-create.request-body';
import { EstimateControllerCreateResponse } from '../../responses/estimate-controller-create.response';

interface EstimateControllerCreateOpenApiParameters {
  requestBody: EstimateControllerCreateRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.POST,
  apiName: 'estimation',
  path: '/estimation',
  operationId: 'EstimateController_create',
  parameters: [],
})
export class EstimateControllerCreateMethod
  extends BaseOpenApiMethod<EstimateControllerCreateResponse>
  implements Method<EstimateControllerCreateResponse, EstimateControllerCreateOpenApiParameters>
{
  public call(parameters: EstimateControllerCreateOpenApiParameters): Promise<EstimateControllerCreateResponse> {
    return super.call(parameters);
  }
}
