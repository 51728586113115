const seoI18nEn = {
  'landing-title': 'Repair Rebels - Platform for Fashion Repairs',
  'landing-description':
    'Repair Rebels is your online service for fashion repairs. Our experienced shoemakers and tailors repair bags, shoes, and textiles of all materials and types. Secure pick-up and delivery dates with our personal courier service within Düsseldorf or send us your repairs by post.',
  'faq-title': 'Repair Rebels - How it Works?',
  'faq-description':
    'All important questions about our online fashion repair service are listed in the Repair Rebels FAQs. Find out how our booking and courier service work. Click here to contact us.',
  'about-title': 'Repair Revels - About the Startup',
  'about-description':
    "Our Founder, Dr. Monika Hauck, reveals the genesis of the Repair Rebels fashion tech startup, Germany's first online platfrom for textile and shoe repairs.",
  'blog-title': 'Repair Rebels - Blog Posts',
  'blog-description':
    'On our Repair Rebels blog, you can read inspiring stories from the German repair community, get usefull tips and tricks for textile care and be the first to hear the hottest news about sustainable brands and slow fashion movement!',
  'tailor-title': 'Meet our Craftsmanship',
  'tailor-description':
    "Meet Repair Rebels' experts of traditional craftsmanship. Our shoemakers, tailors and goldsmiths are looking forward to repair your favourite fashion pieces.",
  'register-title': 'Registration',
  'register-description': 'Register with Repair Rebels to check your repairs in your profile.',
  'login-title': 'Your Repair Rebels Account - Login',
  'login-description': 'Login to your Repair Rebels account to view all your fashion repairs.',
  'forgot-password-title': 'Reset your Password',
  'forgot-password-description': "Reset your Repair Rebels' account password here, if you forgot it.",
  'reset-password-title': 'Update your Password',
  'reset-password-description': "Create a new Repair Rebels' account password here.",
  'create-password-title': "Create a Password",
  'create-password-description': "Create a new Repair Rebels' account password here.",
  'club-title': 'Join the Super Rebel Loyalty Club',
  'club-description': "Join Repair Rebels' Super Rebel Loyalty Club to get the latest news and enjoy exclusive discounts. ",
  'imprint-title': 'Legal Information',
  'imprint-description': 'Here you can find all legal information about the Repair Rebels GmbH.',
  'privacy-policy-title': 'Privacy Policy',
  'privacy-policy-description': 'Here you can find everything about the Repair Rebels privacy policy.',
  'terms-title': 'Repair Rebels - Terms and Conditions',
  'terms-description': 'Here you can find the general terms and conditions of the Repair Rebels online service for fashion repairs.',
  'jobs-title': 'Join the Repair Rebels Team',
  'jobs-description': 'Want to become part of the Repair Rebels team? Start your career at our fashion repair startup and check out our job offers.',
  'partnership-title': 'Repair Rebels - Brand Partnership',
  'partnership-description': 'Join our network of fashion experts, innovators, and change-makers, and explore new business opportunities with our digital repair booking portal and local in-store solutions.',
};

export default seoI18nEn;
