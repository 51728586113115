/**
 * Markdown
 */
const repairRebelsWonDe = `
  ## Repair Rebels Gewinnt den Bundespreis Ecodesign

  Der Bundespreis Ecodesign ist Deutschlands höchste staatliche Auszeichnung für ökologisches Design. Er 
  würdigt herausragende unternehmerische Leistungen im Bereich Nachhaltigkeit und Design. Das Team von 
  Repair Rebels freut sich, als Gewinner in der [Kategorie Service](https://bundespreis-ecodesign.de/en/winners/2023-repair-rebels) ausgewählt worden zu sein. Diese großartige 
  Anerkennung ist eine große Ermutigung für unser junges Startup.
  
  ## BUNDESPREIS ECODESIGN
 
  Der Bundespreis Ecodesign ist eine renommierte Auszeichnung, die herausragende Leistungen im Bereich Ecodesign würdigt. 
  Der Preis wird seit 2012 jährlich vom Bundesumweltministerium (BMUv) und dem Umweltbundesamt (UBA) in Kooperation mit dem 
  Internationalen Design Zentrum Berlin (IDZ) verliehen.

  Ziel des Bundespreises Ecodesign ist es, das Bewusstsein für umweltgerechte und nachhaltige Gestaltung zu fördern und Designer, 
  Unternehmen und Organisationen zu ermutigen, ökologische Aspekte in ihre Produkte, Dienstleistungen und Konzepte zu integrieren.
  Der Preis deckt ein breites Spektrum von Kategorien ab, darunter Produktdesign, Dienstleistungsdesign, Konzeptdesign und Nachwuchsdesign.

  Ein wesentlicher Aspekt des Bundespreises Ökodesign ist die interdisziplinäre Jury, die sich aus Experten aus Design, Wirtschaft, 
  Forschung und Umweltorganisationen zusammensetzt. Dieses vielfältige Gremium gewährleistet eine umfassende Bewertung der eingereichten 
  Beiträge.

  ## PREISVERLEIHUNG IN BERLIN  
  
  Am 4. Dezember reiste Monika Hauck, die Gründerin von Repair Rebels, nach Berlin, um den Preis mit einer personalisierten Urkunde von 
  Steffi Lemke, der Bundesministerin für Umwelt und Verbraucherschutz, entgegenzunehmen. In ihrer Begrüßungsrede setzte sich die Ministerin 
  für die **#RightToRepair** Bewegung ein und betonte die wichtige Rolle, die die lokale Reparaturindustrie bei der Umstellung auf einen zirkulären 
  Wirtschafts Ansatz in Deutschland spielt.

  Als Hommage an ihre litauische Herkunft trug Monika die Bernstein-Halskette ihrer Großmutter, die zwar auseinandergefallen war, aber 
  wunderbar von unserem Goldschmiedin [Katja Voscht](https://www.repair-rebels.com/our-partners/katja-voscht) repariert wurde. Dieser Preis ist nicht nur eine großartige Anerkennung für unser Team,
  sondern auch für unser Netzwerk talentierter Handwerkerkünstler, die uns geholfen haben, digitale Mode-Reparaturdienste bekannt zu machen.

  Vielen  Dank an die Jury des Bundespreises Ecodesign, die an unsere Vision geglaubt hat und an Prof. Anna Berkenbusch für die wunderbare
  Laudatio und die klare Botschaft "Es ist immer besser, einen Gegenstand zu reparieren oder zu verändern, als ihn zu recyceln:
  
  > "Es ist immer besser, etwas zu reparieren oder zu verändern, als es zu recyceln."

  ## WANDERAUSSTELLUNG
   
  Möchtest du mehr über die Arbeit der nominierten und preisgekrönten Unternehmen und ihre Projekte erfahren? Dann besuche 
  die Wanderausstellung [Thinking Ahead](https://bundespreis-ecodesign.de/en/events/exhibition). Die Ausstellung ist in Berlin gestartet und geht auf Tournee. Weitere Stationen sind Wien, 
  Coburg, Innsbruck und Paris. 

  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Repair-rebels-won/Bundespreis+Ecodesign+Gewinner.jpeg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Repair-rebels-won/Bundespreis+Ecodesign+2023.jpeg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Repair-rebels-won/Bundespreis+Ecodesign.jpeg)

  Fotos: Sandra Kühnapfel 
`;

export default repairRebelsWonDe;
