import { DiscountControllerCreateMethod } from '../methods/discount-controller/create.method';
import { DiscountControllerGetAllMethod } from '../methods/discount-controller/get-all.method';
import { DiscountControllerGetByIdMethod } from '../methods/discount-controller/get-by-id.method';
import { DiscountControllerGetPaginatedMethod } from '../methods/discount-controller/get-paginated.method';
import { DiscountControllerSetByIdMethod } from '../methods/discount-controller/set-by-id.method';

export class DiscountController {
  public readonly create = new DiscountControllerCreateMethod();
  public readonly getPaginated = new DiscountControllerGetPaginatedMethod();
  public readonly getAll = new DiscountControllerGetAllMethod();
  public readonly getById = new DiscountControllerGetByIdMethod();
  public readonly setById = new DiscountControllerSetByIdMethod();
}
