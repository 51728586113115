import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Typography } from '@material-ui/core';
import bgSmall from '../../images/svg/background-small.svg';
import bgLarge from '../../images/svg/background-large.svg';
import AppWhatsAppButton from '../WhatsApp/Button';

// styles
const useStyles = makeStyles((theme) => {
  return {
    frameDisableNote: {
      textAlign: 'center',
      backgroundImage: `url(${bgSmall})`,
      backgroundSize: '100% 100%',
      color: 'white',
      [theme.breakpoints.up('md')]: {
        backgroundImage: `url(${bgLarge})`,
      },
    },
    frameParagraph: {
      display: 'inline-block',
      width: '70%',
      margin: '0 auto',
    },
  };
});

export default function AppCartDisabledFrame() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box className={classes.frameDisableNote} px={{ xs: 4, md: 8 }} py={{ xs: 8, xl: 10 }}>
      <Typography
        component="div"
        dangerouslySetInnerHTML={{
          __html: t('product_further_details_frame_heading', ''),
        }}
        variant="h2"
      />
      <Box mb={3} />
      <Box textAlign="center" mb={3}>
        <Typography className={classes.frameParagraph}>{t('product_further_details_frame_hint', '')}</Typography>
      </Box>
      <AppWhatsAppButton />
    </Box>
  );
}
