import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { TailorControllerGetGooglePlaceParameter } from '../../parameters/tailor-controller-get-google-place.parameter';
import { TailorControllerGetGooglePlaceResponse } from '../../responses/tailor-controller-get-google-place.response';

interface TailorControllerGetGooglePlaceOpenApiParameters {
  parameters: TailorControllerGetGooglePlaceParameter;
}

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/tailor/{tailorId}/google-place',
  operationId: 'TailorController_getGooglePlace',
  parameters: [
    {
      name: 'tailorId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class TailorControllerGetGooglePlaceMethod
  extends BaseOpenApiMethod<TailorControllerGetGooglePlaceResponse>
  implements Method<TailorControllerGetGooglePlaceResponse, TailorControllerGetGooglePlaceOpenApiParameters>
{
  public call(parameters: TailorControllerGetGooglePlaceOpenApiParameters): Promise<TailorControllerGetGooglePlaceResponse> {
    return super.call(parameters);
  }
}
