import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Container, InputAdornment, Paper, TextField, Typography } from '@material-ui/core';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useLocation, useNavigate } from '@reach/router';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { parse } from 'query-string';
import { Helmet } from 'react-helmet';
import ApiContext from '../../../api/context';
import LoadingButton from '../../../components/LoadingButton';
import AppBackLink from '../../../components/BackLink';
import { getPage } from '../../../utils/pages';
import { useDispatch } from 'react-redux';
import { UserSlice } from '../../store';
import classes from '../Login/Login.module.css';

// schema
const schema = z
  .object({
    password: z.string().nonempty().min(12),
    passwordRepeat: z.string().nonempty().min(12),
  })
  .refine((data) => data.password === data.passwordRepeat, {
    message: 'Passwords do not match',
    path: ['passwordRepeat'],
  });
type Schema = z.infer<typeof schema>;

/**
 * Create-Password component for users created manually in the Admin Panel.
 */
export default function AuthCreatePassword(props: RouteComponentProps) {
  const dispatch = useDispatch();
  const { loginReducer } = UserSlice.actions;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { token } = parse(search, { parseBooleans: true });
  const api = useContext(ApiContext);
  const [loading, setLoading] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [passwordRepeatVisibility, setPasswordRepeatVisibility] = useState(false);
  const [globalError, setGlobalError] = useState<string | null>(null);
  const defaultValues: Schema = {
    password: '',
    passwordRepeat: '',
  };
  const { errors, getValues, handleSubmit, register } = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    if (!token) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleChange = () => {
    setGlobalError(null);
  };

  const triggerSubmit = async () => {
    setGlobalError(null);
    setLoading(true);
    try {
      const { password } = getValues();
      const user = await api.auth.createPassword(token as string, password);

      if (!user) {
        return setGlobalError(t('The user account has been deleted. Use another email to sign up or contact our support team.'));
      }

      if (!user.active) {
        return setGlobalError(t('Your account has been blocked'));
      }

      dispatch(loginReducer(user));
      await navigate(`${getPage('profile')}/bookings`);
    } catch (err) {
      setGlobalError(err.message); // TODO: i18n
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordInputType = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const handlePasswordRepeatInputType = () => {
    setPasswordRepeatVisibility(!passwordRepeatVisibility);
  };

  return (
    <Container maxWidth="sm">
      <Helmet>
        <title>{t('reset-password-title')}</title>
        <meta name="description" content={t('create-password-description')} />
      </Helmet>
      <Box mb={2}>
        <AppBackLink isGoBackActivated />
      </Box>
      <Paper>
        <Typography variant="h2" component="h1">
          {t('Create password')}
        </Typography>
        <Box mb="1.5rem" />
        <form onSubmit={handleSubmit(triggerSubmit)}>
          <TextField
            className={classes.textField}
            error={!!errors.password}
            fullWidth
            helperText={errors.password?.message && t(`${errors.password?.message}`)}
            inputRef={register}
            name="password"
            onChange={handleChange}
            placeholder={`${t('Password')}*`}
            type={passwordVisibility ? 'text' : 'password'}
            variant="outlined"
            autoComplete="new-password"
            InputProps={{
              classes: { root: classes.textFieldWrapper },
              endAdornment: (
                <InputAdornment position="end" classes={{ root: classes.inputBtnWrapper }}>
                  {!passwordVisibility && (
                    <Button variant="contained" color="primary" classes={{ root: classes.inputBtn }} onClick={handlePasswordInputType}>
                      <VisibilityOffIcon />
                    </Button>
                  )}
                  {passwordVisibility && (
                    <Button variant="contained" color="primary" classes={{ root: classes.inputBtn }} onClick={handlePasswordInputType}>
                      <VisibilityIcon />
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Box mb="1rem" />
          <TextField
            className={classes.textField}
            error={!!errors.passwordRepeat}
            fullWidth
            helperText={errors.passwordRepeat?.message && t(`${errors.passwordRepeat?.message}`)}
            inputRef={register}
            name="passwordRepeat"
            onChange={handleChange}
            placeholder={`${t('Confirm Password')}*`}
            type={passwordRepeatVisibility ? 'text' : 'password'}
            variant="outlined"
            autoComplete="current-password"
            InputProps={{
              classes: { root: classes.textFieldWrapper },
              endAdornment: (
                <InputAdornment position="end" classes={{ root: classes.inputBtnWrapper }}>
                  {!passwordRepeatVisibility && (
                    <Button variant="contained" color="primary" classes={{ root: classes.inputBtn }} onClick={handlePasswordRepeatInputType}>
                      <VisibilityOffIcon />
                    </Button>
                  )}
                  {passwordRepeatVisibility && (
                    <Button variant="contained" color="primary" classes={{ root: classes.inputBtn }} onClick={handlePasswordRepeatInputType}>
                      <VisibilityIcon />
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Box mb="1rem" />
          <Box hidden={!globalError} ml=".75rem" mb="1rem">
            <Typography color="error" variant="body1">
              {globalError}
            </Typography>
          </Box>
          <Box textAlign="center">
            <LoadingButton color="secondary" loading={loading} type="submit" variant="contained">
              {t('Send')}
            </LoadingButton>
          </Box>
        </form>
      </Paper>
    </Container>
  );
}
