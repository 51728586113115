import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { TransportControllerGetUnscheduledParameter } from '../../parameters/transport-controller-get-unscheduled.parameter';
import { TransportControllerGetUnscheduledResponse } from '../../responses/transport-controller-get-unscheduled.response';

interface TransportControllerGetUnscheduledOpenApiParameters {
  parameters: TransportControllerGetUnscheduledParameter;
}

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/transport/unscheduled/{year}/{month}/{day}/{timeframe}',
  operationId: 'TransportController_getUnscheduled',
  parameters: [
    {
      name: 'year',
      required: true,
      in: OpenApiParameterType.PATH,
    },
    {
      name: 'month',
      required: true,
      in: OpenApiParameterType.PATH,
    },
    {
      name: 'day',
      required: true,
      in: OpenApiParameterType.PATH,
    },
    {
      name: 'timeframe',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class TransportControllerGetUnscheduledMethod
  extends BaseOpenApiMethod<TransportControllerGetUnscheduledResponse>
  implements Method<TransportControllerGetUnscheduledResponse, TransportControllerGetUnscheduledOpenApiParameters>
{
  public call(parameters: TransportControllerGetUnscheduledOpenApiParameters): Promise<TransportControllerGetUnscheduledResponse> {
    return super.call(parameters);
  }
}
