import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from '@reach/router';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useElementOnScreen } from '../../../hooks/useElementOnScreen';
import { ReactComponent as Shield } from './shield.svg';
import { ReactComponent as Arrow } from './arrow.svg';
import classes from './Club.module.css';
import woman from './woman.png';
import { getPage } from '../../../utils/pages';
import { ReactComponent as LeftArrow } from './left-arrow.svg';
import { ReactComponent as RightArrow } from './right-arrow.svg';

export default function HomepageClub() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [titleRef, titleOnScreen] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  });
  const [bodyRef, bodyOnScreen] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  });
  const [headerRef, headerOnScreen] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  });
  const [tryButtonRef, tryButtonOnScreen] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  });
  const [arrowRef, arrowOnScreen] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  });
  const [underlayRef, underlayOnScreen] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  });
  const [loyaltyClubRef, loyaltyClubOnScreen] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  });

  function goToClub() {
    navigate(getPage('club'));
  }

  return (
    <div className={classes.main}>
      <div ref={underlayRef} className={`${classes.underlay} ${underlayOnScreen ? classes.underlayAnimation : ''}`}>
        <LazyLoadImage
          className="h-70"
          alt="Repair Rebels Club"
          src={woman}
          // height={image.height}
          // width={image.width}
          effect="blur"
          visibleByDefault
        />
      </div>
      <div className="text-center mb-3 mb-md-0">
        <div ref={headerRef} className={`h4 mb-3 ${classes.header} ${headerOnScreen ? classes.headerAnimation : ''}`}>
          <Trans>
            <div>Want to become a</div>
            <div className={classes.repairRebel}>Repair Rebel?</div>
          </Trans>
        </div>
        <div ref={tryButtonRef} className={`${classes.tryButton} ${tryButtonOnScreen ? classes.tryButtonAnimation : ''}`}>
          <Link to={getPage('club')} className="btn btn-secondary">
            {t('Try it now!')}
          </Link>
        </div>
      </div>
      <div className="container mb-5">
        <div className="row">
          <div className="col-9 col-lg-6 text-lg-left col-xl-8">
            <div ref={titleRef} className={`${classes.title} ${titleOnScreen ? classes.titleAnimation : ''}`}>
              <h2 className="h4 d-inline-block with-bar mb-4">
                <Trans>
                  Why <br className="d-sm-none" /> Repair Rebels?
                </Trans>
              </h2>
            </div>
            <div ref={bodyRef} className={`${classes.body} ${bodyOnScreen ? classes.bodyAnimation : ''}`}>
              <p className="body1 text-white">{t('The idea of Repair Rebels was born from')}</p>
            </div>
          </div>
        </div>
      </div>
      <div ref={loyaltyClubRef} className={`container ${classes.loyaltyClub} ${loyaltyClubOnScreen ? classes.loyaltyClubAnimation : ''}`}>
        <div className={classes.frame}>
          <div className={classes.frameContent}>
            <Shield className="mb-3" title="Repair Rebels Club" />
            <RightArrow className={classes.rightArrow} />
            <LeftArrow className={classes.leftArrow} />
            <div className="h4 mb-4">
              <div className={`${classes.superRebel}`}>Super Rebel</div>
              <div>Loyalty Club</div>
            </div>
            <p className={classes.frameParagraph}>{t('homePageLoyaltyClubBody')}</p>
            <button onClick={goToClub} className={`btn btn-secondary ${classes.button}`}>
              {t('Join the club')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
