import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { CartControllerCheckoutRequestBody } from '../../request-bodies/cart-controller-checkout.request-body';
import { CartControllerCheckoutResponse } from '../../responses/cart-controller-checkout.response';

interface CartControllerCheckoutOpenApiParameters {
  requestBody: CartControllerCheckoutRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.POST,
  apiName: 'customer',
  path: '/cart/checkout',
  operationId: 'CartController_checkout',
  parameters: [],
})
export class CartControllerCheckoutMethod
  extends BaseOpenApiMethod<CartControllerCheckoutResponse>
  implements Method<CartControllerCheckoutResponse, CartControllerCheckoutOpenApiParameters>
{
  public call(parameters: CartControllerCheckoutOpenApiParameters): Promise<CartControllerCheckoutResponse> {
    return super.call(parameters);
  }
}
