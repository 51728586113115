import { MailerControllerAcceptMethod } from '../methods/mailer-controller/accept.method';
import { MailerControllerGetTemplateMethod } from '../methods/mailer-controller/get-template.method';
import { MailerControllerInviteMethod } from '../methods/mailer-controller/invite.method';
import { MailerControllerRenderTemplateMethod } from '../methods/mailer-controller/render-template.method';
import { MailerControllerSupportMethod } from '../methods/mailer-controller/support.method';

export class MailerController {
  public readonly renderTemplate = new MailerControllerRenderTemplateMethod();
  public readonly support = new MailerControllerSupportMethod();
  public readonly getTemplate = new MailerControllerGetTemplateMethod();
  public readonly invite = new MailerControllerInviteMethod();
  public readonly accept = new MailerControllerAcceptMethod();
}
