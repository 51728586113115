import * as z from 'zod';
import { PhoneNumberUtil } from 'google-libphonenumber';
import i18next from 'i18next';

const phoneUtil = PhoneNumberUtil.getInstance();
// eslint-disable-next-line no-useless-escape
const VALID_EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const STARTS_WITH_PLUS_REGEX = /^\+/;
export const schema = z.object({
  firstName: z.string().refine((data) => data.trim(), i18next.t('First name required')),
  lastName: z.string().refine((data) => data.trim(), i18next.t('Last name required')),
  email: z.string().regex(VALID_EMAIL_REGEX, { message: i18next.t('Please enter a valid email address') }),
  phoneNumber: z
    .string()
    .nonempty({ message: i18next.t('Phone number cannot be empty') })
    .regex(STARTS_WITH_PLUS_REGEX, { message: 'The phone number is not valid, please use a valid format' })
    .refine((val) => {
      try {
        const parsedNumber = phoneUtil.parse(val);
        const region = phoneUtil.getRegionCodeForNumber(parsedNumber);
        return !(!phoneUtil.isValidNumber(parsedNumber) || !phoneUtil.isValidNumberForRegion(parsedNumber, region));
      } catch (err) {
        return false;
      }
    }, 'The phone number is not valid, please use a valid format'),
});

type AppContactData = z.infer<typeof schema>;

export default AppContactData;
