import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { AuthControllerSendLoginLinkByOrderIdRequestBody } from '../../request-bodies/auth-controller-send-login-link-by-order-id.request-body';
import { AuthControllerSendLoginLinkByOrderIdResponse } from '../../responses/auth-controller-send-login-link-by-order-id.response';

interface AuthControllerSendLoginLinkByOrderIdOpenApiParameters {
  requestBody: AuthControllerSendLoginLinkByOrderIdRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.POST,
  apiName: 'customer',
  path: '/auth/login-link-by-order-id',
  operationId: 'AuthController_sendLoginLinkByOrderId',
  parameters: [],
})
export class AuthControllerSendLoginLinkByOrderIdMethod
  extends BaseOpenApiMethod<AuthControllerSendLoginLinkByOrderIdResponse>
  implements Method<AuthControllerSendLoginLinkByOrderIdResponse, AuthControllerSendLoginLinkByOrderIdOpenApiParameters>
{
  public call(parameters: AuthControllerSendLoginLinkByOrderIdOpenApiParameters): Promise<AuthControllerSendLoginLinkByOrderIdResponse> {
    return super.call(parameters);
  }
}
