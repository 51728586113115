const ADD_IMAGES = 'app/estimate/ADD_IMAGES';
const REMOVE_IMAGE = 'app/estimate/REMOVE_IMAGE';
const CLEAR_IMAGES = 'app/estimate/CLEAR_IMAGES';
const UPDATE_IMAGE = 'app/estimate/UPDATE_IMAGE';
const UPLOAD_IMAGES = 'app/estimate/UPLOAD_IMAGES';
const ADD_FIRST_NAME = 'app/estimate/ADD_FIRST_NAME';
const ADD_LAST_NAME = 'app/estimate/ADD_LAST_NAME';
const MARK_FILE_UPLOAD_COMPLETED = 'app/estimate/MARK_FILE_UPLOAD_COMPLETED';
const MARK_FILE_UPLOAD_FAILED = 'app/estimate/MARK_FILE_UPLOAD_FAILED';
const MARK_FILE_UPLOAD_STARTED = 'app/estimate/MARK_FILE_UPLOAD_STARTED';
const MARK_ALL_UPLOADS_STARTED = 'app/estimate/MARK_ALL_UPLOADS_STARTED';
const MARK_SOME_UPLOADS_FAILED = 'app/estimate/MARK_SOME_UPLOADS_FAILED';
const MARK_ALL_UPLOADS_COMPLETED = 'app/estimate/MARK_ALL_UPLOADS_COMPLETED';
const CLEAR_STATE = 'app/estimate/CLEAR_STATE';
const MARK_SUBMIT_FAILED = 'app/estimate/MARK_SUBMIT_FAILED';
const MARK_SUBMIT_STARTED = 'app/estimate/MARK_SUBMIT_STARTED';
const SET_SIZE_ERROR = 'app/estimate/SET_SIZE_ERROR';
const REMOVE_SIZE_ERROR = 'app/estimate/CLEAR_SIZE_ERROR';
const RETRY_UPLOAD = 'app/estimate/RETRY_UPLOAD';

export {
  RETRY_UPLOAD,
  REMOVE_SIZE_ERROR,
  REMOVE_IMAGE,
  CLEAR_IMAGES,
  UPDATE_IMAGE,
  UPLOAD_IMAGES,
  MARK_FILE_UPLOAD_COMPLETED,
  MARK_FILE_UPLOAD_FAILED,
  MARK_FILE_UPLOAD_STARTED,
  MARK_ALL_UPLOADS_STARTED,
  MARK_SOME_UPLOADS_FAILED,
  MARK_ALL_UPLOADS_COMPLETED,
  CLEAR_STATE,
  MARK_SUBMIT_FAILED,
  MARK_SUBMIT_STARTED,
  SET_SIZE_ERROR,
  ADD_FIRST_NAME,
  ADD_LAST_NAME,
  ADD_IMAGES,
};
