import React from 'react';
import Marquee from 'react-fast-marquee';
import classes from './RunningLine.module.css';
import RunningLineItem from './RunningLineItem';

export default function RunningPartnershipsLine({ isHomePage = false }) {

  return (
    <Marquee className={isHomePage ? classes.homepageLine : classes.runningLine}>
      <ul className={classes.runningList}>
        <RunningLineItem/>
        <RunningLineItem/>
      </ul>
    </Marquee>
  );
}
