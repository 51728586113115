import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { MailerControllerRenderTemplateRequestBody } from '../../request-bodies/mailer-controller-render-template.request-body';
import { MailerControllerRenderTemplateResponse } from '../../responses/mailer-controller-render-template.response';

interface MailerControllerRenderTemplateOpenApiParameters {
  requestBody: MailerControllerRenderTemplateRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.POST,
  apiName: 'customer',
  path: '/mailer/render',
  operationId: 'MailerController_renderTemplate',
  parameters: [],
})
export class MailerControllerRenderTemplateMethod
  extends BaseOpenApiMethod<MailerControllerRenderTemplateResponse>
  implements Method<MailerControllerRenderTemplateResponse, MailerControllerRenderTemplateOpenApiParameters>
{
  public call(parameters: MailerControllerRenderTemplateOpenApiParameters): Promise<MailerControllerRenderTemplateResponse> {
    return super.call(parameters);
  }
}
