import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { ProductControllerGetProductListResponse } from '../../responses/product-controller-get-product-list.response';

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/product/list',
  operationId: 'ProductController_getProductList',
  parameters: [],
})
export class ProductControllerGetProductListMethod
  extends BaseOpenApiMethod<ProductControllerGetProductListResponse>
  implements Method<ProductControllerGetProductListResponse, void>
{
  public call(): Promise<ProductControllerGetProductListResponse> {
    return super.call();
  }
}
