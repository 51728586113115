import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { OrderControllerReschedulePickupParameter } from '../../parameters/order-controller-reschedule-pickup.parameter';
import { OrderControllerReschedulePickupRequestBody } from '../../request-bodies/order-controller-reschedule-pickup.request-body';

interface OrderControllerReschedulePickupOpenApiParameters {
  parameters: OrderControllerReschedulePickupParameter;
  requestBody: OrderControllerReschedulePickupRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.PATCH,
  apiName: 'customer',
  path: '/order/reschedule-pickup/{orderId}/customer/{customerId}',
  operationId: 'OrderController_reschedulePickup',
  parameters: [
    {
      name: 'orderId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
    {
      name: 'customerId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class OrderControllerReschedulePickupMethod extends BaseOpenApiMethod<void> implements Method<void, OrderControllerReschedulePickupOpenApiParameters> {
  public call(parameters: OrderControllerReschedulePickupOpenApiParameters): Promise<void> {
    return super.call(parameters);
  }
}
