import { AuthControllerClearAnonymousUsersMethod } from '../methods/auth-controller/clear-anonymous-users.method';
import { AuthControllerCreateGuestUserMethod } from '../methods/auth-controller/create-guest-user.method';
import { AuthControllerGetUserRoleByEmailMethod } from '../methods/auth-controller/get-user-role-by-email.method';
import { AuthControllerRegisterWithEmailMethod } from '../methods/auth-controller/register-with-email.method';
import { AuthControllerSendLoginLinkByOrderIdMethod } from '../methods/auth-controller/send-login-link-by-order-id.method';

export class AuthController {
  public readonly getUserRoleByEmail = new AuthControllerGetUserRoleByEmailMethod();
  public readonly sendLoginLinkByOrderId = new AuthControllerSendLoginLinkByOrderIdMethod();
  public readonly clearAnonymousUsers = new AuthControllerClearAnonymousUsersMethod();
  public readonly registerWithEmail = new AuthControllerRegisterWithEmailMethod();
  public readonly createGuestUser = new AuthControllerCreateGuestUserMethod();
}
