import Cookies from 'js-cookie';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import appI18nDe from './de';
import appI18nEn from './en';

// the translations
const resources = {
  en: { translation: appI18nEn },
  de: { translation: appI18nDe },
};

const defaultLanguage = Cookies.get('language') || process.env.REACT_APP_DEFAULT_LANGUAGE || 'de';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: defaultLanguage,
    debug: false,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
