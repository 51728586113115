import { OrderControllerCancelMethod } from '../methods/order-controller/cancel.method';
import { OrderControllerCreateMethod } from '../methods/order-controller/create.method';
import { OrderControllerGetByIdMethod } from '../methods/order-controller/get-by-id.method';
import { OrderControllerGetByStatusMethod } from '../methods/order-controller/get-by-status.method';
import { OrderControllerGetOrderByIdMethod } from '../methods/order-controller/get-order-by-id.method';
import { OrderControllerGetPaginatedMethod } from '../methods/order-controller/get-paginated.method';
import { OrderControllerMarkAsPaidMethod } from '../methods/order-controller/mark-as-paid.method';
import { OrderControllerRescheduleDeliveryMethod } from '../methods/order-controller/reschedule-delivery.method';
import { OrderControllerReschedulePickupMethod } from '../methods/order-controller/reschedule-pickup.method';
import { OrderControllerSetByIdMethod } from '../methods/order-controller/set-by-id.method';
import { OrderControllerUpdateDeliveryMethod } from '../methods/order-controller/update-delivery.method';
import { OrderControllerUpdatePickupAndDeliveryMethod } from '../methods/order-controller/update-pickup-and-delivery.method';
import { OrderControllerUpdatePickupMethod } from '../methods/order-controller/update-pickup.method';

export class OrderController {
  public readonly getByStatus = new OrderControllerGetByStatusMethod();
  public readonly getById = new OrderControllerGetByIdMethod();
  public readonly setById = new OrderControllerSetByIdMethod();
  public readonly getOrderById = new OrderControllerGetOrderByIdMethod();
  public readonly create = new OrderControllerCreateMethod();
  public readonly getPaginated = new OrderControllerGetPaginatedMethod();
  public readonly reschedulePickup = new OrderControllerReschedulePickupMethod();
  public readonly markAsPaid = new OrderControllerMarkAsPaidMethod();
  public readonly rescheduleDelivery = new OrderControllerRescheduleDeliveryMethod();
  public readonly updatePickupAndDelivery = new OrderControllerUpdatePickupAndDeliveryMethod();
  public readonly updateDelivery = new OrderControllerUpdateDeliveryMethod();
  public readonly updatePickup = new OrderControllerUpdatePickupMethod();
  public readonly cancel = new OrderControllerCancelMethod();
}
