/**
 * Markdown
 */
const kannEsEinemAnderenFreudeBereitenBodyDe = `

Optionen zum Teilen, Tauschen und Vermieten Deiner Kleidung. Deine ungenutzten Schätze haben eine 2. Chance verdient! Wir verraten Dir, welche Möglichkeiten es gibt.

Nachdem wir unseren Kleiderschrank erfolgreich ausgemistet haben, ist das Erste, was uns in den Kopf kommt, für gewöhnlich unsere ungewollten Teile zu spenden oder zu verkaufen. Die Idee des Teilens außerhalb des eigenen Haushaltes wird hier meistens außen vor gelassen. Dabei teilen wir heutzutage so viel- Wohnungen, Autos und sogar Haustiere!

Die sogenannte Sharing Economy ist ein wichtiger Bestandteil unseres Konsumverhaltens geworden und es wird erwartet, dass sie bis 2025 einen Wert von 335 Billionen US-Dollar erreicht (Statista, 2020). Sharing wird somit immer beliebter und gerade in den letzten Jahren haben sich auch so einige coole Möglichkeiten für Deine Kleidung ergeben. Wir möchten Dir zwei noch recht neue Business Modelle vorstellen. Diese kannst Du zukünftig nutzen, um Deine Kleidung verantwortungsvoll in neue Hände zu geben: Die Idee von Swap Partys und das Vermieten Deiner Kleidung.

Das Schöne an beiden Möglichkeiten ist, dass Du so jemand anderes glücklich machen kannst und gleichzeitig etwas Gutes für die Umwelt tust. Durch das Teilen Deiner Kleidung gibst Du ihr eine neue Chance, wieder getragen zu werden und verlängerst so ihren Lebenszyklus. Auf diese Weise rettest Du das Kleidungsstück auch davor, auf der nächsten Mülldeponie zu landen.

## Vermieten

Gerade wenn Du Dir nicht so sicher bist, ob Du bestimmte Kleidungsstücke behalten möchtest, lohnt es sich, diese zu vermieten. Den Anzug, den Du schon seit mehr als einem Jahr nicht mehr getragen hast, könntest Du also ganz einfach temporär in andere Hände geben! So vermeidest Du es, dass Deine Kleidung ungenutzt im Schrank liegt und verdienst gleichzeitig ein wenig dazu. 

Kleidung zu vermieten scheint vielleicht auf den ersten Blick fremd, aber nur weil wir es hier in Europa nicht anders gewöhnt sind. In Nordamerika ist dieses recht neue Businessmodell sehr beliebt. Aber auch wenn es sich bei uns in Deutschland noch nicht so durchgesetzt hat wie in der USA, wird der Markt für vermietbare Kleidung stetig größer. Der globale Markt hat zurzeit eine Größe von circa 1,18 Billionen USD und steigt höchstwahrscheinlich mit einer Wachstumsrate von 10.76 % im Jahre 2023 auf ganze 1,96 Billionen USD. Der größte Anteil von vermieteter Kleidung ist Party- und Hochzeitskleidung. Diese Kategorien machen etwa 40 % des Marktes aus (Research and Markets, 2019).  Diese Kleidungsstücke sind meistens besonders teuer und sie zu mieten spart eine Menge Geld. Aber auch in Zeiten von Social Media, in denen viele versuchen, auf Partys besonders schicke Teile zu tragen und auf Instagram und Co. zu posten, kann es wirtschaftlicher sein, ein Outfit auszuleihen.  Formelle Kleidung wie Anzüge machen etwa 22 % des Business aus (Research and Markets, 2019). Eine Umfrage bestätigte diesen Bedarf der Kunden. 35 % der Befragten mietet Kleidung für formelle Anlässe. 31 % wollten einmal neue Marken und Stile ausprobieren. 27 % empfand das Mieten von Kleidung als eine gute Möglichkeit, um Geld zu sparen. 23 % hatten das Bedürfnis, einmal Luxus Kleidung zu tragen, was ihnen durch Mieten möglich gemacht wurde.  18 % empfand das Mieten als eine Option, umweltfreundlicher und nachhaltiger ihre Outfitoptionen auszuweiten und 12 % mietet, um Platz im Kleiderschrank zu sparen (Santi, 2020). Die Gründe, weshalb Kleidung gemietet wird, sind also sehr verschieden, genauso wie die Teile, die Du auf Sharing Plattformen finden kannst.  Heutzutage kannst Du fast jedes Kleidungsstück vermieten.

## Tauschen- Swap Partys

Swap Partys dienen dazu, Kleidung untereinander zu tauschen. Die Regeln sind vor jedem Swap klar definiert. Für jedes Teil, das Du swapst, erhältst Du eins zurück. Um das ganze gerecht zu machen, wird auf die Qualität und Marke des Teils geachtet, damit ein Tausch eines ungefähr gleichen Wertes entsteht.  Die Idee lebte im Zeitraum der Finanzkrise 2007 auf, in der die Menschen eher weniger Geld hatten und sich so dabei halfen, das Shopping Erlebnis nicht missen zu müssen. Swap Partys wurden im Laufe der Zeit immer beliebter und haben das Image „Shoppen für Menschen mit wenig Geld“ längst abgelegt. Die Idee wurde sogar so gemocht, dass Swap Events in größeren Formaten mit hunderten Teilnehmern entstanden (Matthews & Hodges, 2016).

Aber auch in einer kleinen Runde unter Freunden lässt sich eine Swap Party organisieren. Alles, was Du dafür brauchst, ist ein ruhiger, gemütlicher Ort, Deine ungenutzte Kleidung und Freunde. Es ist sinnvoll, die Menge der Kleidungsstücke pro Teilnehmer zu limitieren und alle Gäste darauf hinzuweisen, dass Qualität über Quantität steht. Man sollte die Swap Party als eine Alternative zum gewöhnlichen Shoppen sehen und nicht einen Ort, um seine alte Kleidung abzuladen und dafür andere gratis zu bekommen.

Das Schöne am Swappen und auch gleich der Unterschied zu Second Hand Geschäften ist, dass Du die Person, die das Teil vor Dir getragen hat, persönlich triffst. So könnt ihr einander Tipps und Tricks geben, wie ihr das Teil am besten kombiniert und Du hörst vielleicht sogar die ein oder andere Story, die mit Deinem neuen Kleidungsstück verbunden ist (Matthews & Hodges, 2016).

Natürlich gibt es noch andere Wege, wie es mit Deiner ungenutzten Kleidung weitergehen kann. Stay tuned! Der nächste Artikel ist schon auf dem Weg!

`;

export default kannEsEinemAnderenFreudeBereitenBodyDe;
