import React from 'react';
import { useTranslation } from 'react-i18next';
import { useElementOnScreen } from '../../../hooks/useElementOnScreen';
import { ReactComponent as Planet } from './planet.svg';
import { ReactComponent as Wallet } from './wallet.svg';
import { ReactComponent as Economy } from './economy.svg';
import classes from './Features.module.css';

export default function Features() {
  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });

  const { t } = useTranslation();
  return (
    <div ref={containerRef} className={`container ${classes.container} ${isVisible ? classes.containerAnimation : ''}`}>
      <div className={classes.list}>
        <div className={classes.feature}>
          <div className={`${classes.featureImageBlock} mr-2`}>
            <Planet className="h-100" style={{ width: '5rem' }} title="Planet" />
          </div>
          <div className="h5 mb-0" dangerouslySetInnerHTML={{ __html: t('Good for the planet') }} />
        </div>
        <div className={classes.feature}>
          <div className={classes.featureImageBlock}>
            <Wallet className="h-100" title="Wallet" />
          </div>
          <div className="h5 mb-0" dangerouslySetInnerHTML={{ __html: t('Good for your wallet') }} />
        </div>
        <div className={classes.feature}>
          <div className={classes.featureImageBlock}>
            <Economy className="h-100" title="Economy" />
          </div>
          <div
            className="h5 mb-0"
            dangerouslySetInnerHTML={{
              __html: t('Good for the local economy'),
            }}
          />
        </div>
      </div>
    </div>
  );
}
