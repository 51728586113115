import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { TailorControllerGetByIdParameter } from '../../parameters/tailor-controller-get-by-id.parameter';
import { TailorControllerGetByIdResponse } from '../../responses/tailor-controller-get-by-id.response';

interface TailorControllerGetByIdOpenApiParameters {
  parameters: TailorControllerGetByIdParameter;
}

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/tailor/{tailorId}',
  operationId: 'TailorController_getById',
  parameters: [
    {
      name: 'tailorId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class TailorControllerGetByIdMethod
  extends BaseOpenApiMethod<TailorControllerGetByIdResponse>
  implements Method<TailorControllerGetByIdResponse, TailorControllerGetByIdOpenApiParameters>
{
  public call(parameters: TailorControllerGetByIdOpenApiParameters): Promise<TailorControllerGetByIdResponse> {
    return super.call(parameters);
  }
}
