import { call, put } from 'redux-saga/effects';
import platformConfigApi from '../../interfaces/api';
import { setCityConfig, setServicePrices } from '../actions';

export default function* platformConfigFetchSaga(api: platformConfigApi) {
  try {
    const { cartConfig, cityConfig } = yield call(api.getAllPlatformConfig);

    cityConfig.forEach((element) => window.localStorage.setItem(`${element.id}.validZipCodes`, element.validZipCodes));

    yield put(setCityConfig(cityConfig));
    yield put(setServicePrices(cartConfig));
  } catch (err) {
    if (process.env.NODE_ENV === 'development') {
      console.error(err);
    }
    // TODO: handle error
  }
}
