import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { TransportControllerAssignTransportRequestBody } from '../../request-bodies/transport-controller-assign-transport.request-body';

interface TransportControllerAssignTransportOpenApiParameters {
  requestBody: TransportControllerAssignTransportRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.POST,
  apiName: 'customer',
  path: '/transport',
  operationId: 'TransportController_assignTransport',
  parameters: [],
})
export class TransportControllerAssignTransportMethod
  extends BaseOpenApiMethod<void>
  implements Method<void, TransportControllerAssignTransportOpenApiParameters>
{
  public call(parameters: TransportControllerAssignTransportOpenApiParameters): Promise<void> {
    return super.call(parameters);
  }
}
