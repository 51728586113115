import React from 'react';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ProductHeader from '../Header';
import ProductDetailedButton, { ProductDetailedButtonLink } from '../DetailedButton/DetailedButton';
import AppNotFound from '../../../components/NotFound';
import AppWhatsApp from '../../../components/WhatsApp/WhatsApp';
import { sendEvent } from '../../../utils/google-analytics';
import { Product } from '../../../types';
import RunningLine from 'components/RunningLine';

// styles
const useStyles = makeStyles(() => {
  return {
    list: {
      listStyle: 'none',
      padding: 0,
    },
  };
});

// props; exporting to tests
export interface ProductRepairStepViewProps {
  repairs: Product[];
  onRepairSelect(repair: Product): void;
  categoryId?: string;
  typeId?: string;
  backLinkHref?: string;
}

/**
 * Product repair step presentational component.
 */
export default function ProductRepairStepView({ repairs, onRepairSelect, categoryId, typeId, backLinkHref = '..' }: ProductRepairStepViewProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const headingHtmlId = 'ProductRepairStep-heading';

  if (repairs.length === 0) {
    return <AppNotFound />;
  }

  const onProductDetailClick = (x: Product) => {
    onRepairSelect(x);
    //@ts-ignore
    sendEvent('repair', `repair_garment_selected-garment_${x.name}`, x.name);
  };

  function renderButtons() {
    const buttons = repairs.map((x, i) => (
      <Box component="li" key={i} mb={2}>
        <ProductDetailedButton onClick={() => onProductDetailClick(x)} description={x.shortDescription} {...x} />
      </Box>
    ));
    buttons.push(<ProductDetailedButtonLink key={repairs.length} />);
    return buttons;
  }

  return (
    <Box>
      <RunningLine />
      <Container maxWidth="sm">
        <ProductHeader categoryId={categoryId} typeId={typeId} backLinkHref={backLinkHref} />
        <Box mb={2} />
        <Typography className="with-bar" id={headingHtmlId} variant="h1">
          {t('Select the type of repair')}
        </Typography>
        <Box mb={4} />
        <ul aria-labelledby={headingHtmlId} className={classes.list}>
          {renderButtons()}
        </ul>
        <Box mb={6} />
        <AppWhatsApp />
      </Container>
    </Box>
  );
}
