import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { MailerControllerAcceptParameter } from '../../parameters/mailer-controller-accept.parameter';

interface MailerControllerAcceptOpenApiParameters {
  parameters: MailerControllerAcceptParameter;
}

@OpenApiMethod({
  method: HttpMethods.POST,
  apiName: 'customer',
  path: '/mailer/accept/{emailToken}',
  operationId: 'MailerController_accept',
  parameters: [
    {
      name: 'emailToken',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class MailerControllerAcceptMethod
  extends BaseOpenApiMethod<{ success: boolean; message: string }>
  implements Method<{ success: boolean; message: string }, MailerControllerAcceptOpenApiParameters>
{
  public call(parameters: MailerControllerAcceptOpenApiParameters): Promise<{ success: boolean; message: string }> {
    return super.call(parameters);
  }
}
