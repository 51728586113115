import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { SeedControllerCustomerParameter } from '../../parameters/seed-controller-customer.parameter';

interface SeedControllerCustomerOpenApiParameters {
  parameters: SeedControllerCustomerParameter;
}

@OpenApiMethod({
  method: HttpMethods.POST,
  apiName: 'customer',
  path: '/seed/customer',
  operationId: 'SeedController_customer',
  parameters: [
    {
      name: 'count',
      required: true,
      in: OpenApiParameterType.QUERY,
    },
  ],
})
export class SeedControllerCustomerMethod extends BaseOpenApiMethod<void> implements Method<void, SeedControllerCustomerOpenApiParameters> {
  public call(parameters: SeedControllerCustomerOpenApiParameters): Promise<void> {
    return super.call(parameters);
  }
}
