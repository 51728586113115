import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { ProductControllerGetPaginatedParameter } from '../../parameters/product-controller-get-paginated.parameter';
import { ProductControllerGetPaginatedResponse } from '../../responses/product-controller-get-paginated.response';

interface ProductControllerGetPaginatedOpenApiParameters {
  parameters: ProductControllerGetPaginatedParameter;
}

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/product',
  operationId: 'ProductController_getPaginated',
  parameters: [
    {
      name: 'lastEvaluatedKey',
      required: false,
      in: OpenApiParameterType.QUERY,
    },
    {
      name: 'limit',
      required: false,
      in: OpenApiParameterType.QUERY,
    },
  ],
})
export class ProductControllerGetPaginatedMethod
  extends BaseOpenApiMethod<ProductControllerGetPaginatedResponse>
  implements Method<ProductControllerGetPaginatedResponse, ProductControllerGetPaginatedOpenApiParameters>
{
  public call(parameters?: ProductControllerGetPaginatedOpenApiParameters): Promise<ProductControllerGetPaginatedResponse> {
    return super.call(parameters);
  }
}
