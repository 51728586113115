import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import whiteBg from '../../assets/white-element-with-shadow.png';

const useStyles = makeStyles((theme) => {
  return {
    brandItem: {
      textAlign: 'center',
      height: '250px',
      backgroundImage: `url(${whiteBg})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer',
      transition: 'scale 0.4s ease-in-out',
      '&:hover': {
        scale: 1.1,
      },
      [theme.breakpoints.down('sm')]: {
        height: '400px',
        marginBottom: '-130px'
      },
    },
    title: {
      maxWidth: '450px',
      textAlign: 'center',
      margin: '0 auto',
      fontSize: '18px',
    },
    icon: {
      maxWidth: '100%',
      width: '50px',
      height: '50px',
      objectFit: 'contain',
      marginBottom: '10px',
    },
    text: {
      margin: '0 auto',
      padding: '15px 40px 10px 5px',
      [theme.breakpoints.down('sm')]: {
        height: '380px',
        padding: '15px 30px 10px 5px',
      },
    },
  };
});

interface BrandsItemProps {
  icon: string;
  title: string;
  textZone: number;
  text: string;
}

const BrandsItem: FC<BrandsItemProps> = ({ icon, title, text, textZone }) => {
  const classes = useStyles();
  return (
    <li className={classes.brandItem}>
      <div>
        <img src={icon} alt="features" className={classes.icon} />
        <h5 className={classes.title}>{title}</h5>
        <p className={classes.text} style={{ maxWidth: textZone }}>
          {text}
        </p>
      </div>
    </li>
  );
};

export default BrandsItem;
