import OrderEditPickupApi from '../interfaces/api';
import EditOrderPickupRequest from '../interfaces/editOrderPickupRequest';
import appRequest from '../../utils/request';
import { Order, Transport } from '../../types';
import { toastr } from 'react-redux-toastr';

/**
 * Module API definitions for production use.
 *
 * These definitions can only be covered by end-to-end (e2e) tests.
 */
const orderEditPickupApiProd: OrderEditPickupApi = {
  editOrderPickup: async (orderId: Order['_id'], customerId: Order['customerId'], editRequest: EditOrderPickupRequest, transportId: Transport['_id']) => {
    if (!editRequest.pickupTime.date || !editRequest.deliveryTime.date) {
      return toastr.error('Error', 'Please enter a valid date both for pick-up and delivery');
    }

    const response = await appRequest(`order/reschedule-pickup-and-delivery?orderId=${orderId}&customerId=${customerId}&transportId=${transportId}`, {
      method: 'PATCH',
      data: {
        pickup: { address: editRequest.pickupAddress, time: editRequest.pickupTime },
        delivery: { address: editRequest.deliveryAddress, time: editRequest.deliveryTime },
      },
    });

    if (!response) {
      return;
    }

    const { status, data } = response;

    if (status !== 200 || data) {
      toastr.error('Server error', "Can't update data. Try again later.");
      return null;
    }

    return data;
  },
};

export default orderEditPickupApiProd;
