import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { HealthControllerFirestoreResponse } from '../../responses/health-controller-firestore.response';

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/health/firestore',
  operationId: 'HealthController_firestore',
  parameters: [],
})
export class HealthControllerFirestoreMethod
  extends BaseOpenApiMethod<HealthControllerFirestoreResponse>
  implements Method<HealthControllerFirestoreResponse, void>
{
  public call(): Promise<HealthControllerFirestoreResponse> {
    return super.call();
  }
}
