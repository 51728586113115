const appProductsI18nDe = {
  'Zip Zip und ready to go!': 'Zip Zip und bereit zu gehen!',
  '99% are still perfect, right?': '99% sind immer noch perfekt, oder?',
  'Too good to go, fix it!': 'Zu gut zu gehen, fix it!',
  'Quick Fix für die Absätze and you are ready to dance again!': 'Quick Fix für die Absätze und schon kann es wieder losgehen.',
  'Quick Fix für die Absätze and you are ready to go!': 'Quick Fix für die Absätze und schon kann es losgehen!',
  'Like tailormade': 'Wie maßgeschneidert',
};

export default appProductsI18nDe;
