import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { Redirect, RouteComponentProps, Router, useNavigate } from '@reach/router';
import { shallowEqual, useSelector } from 'react-redux';
import AuthLogin from '../Login';
import AuthSignUpConfirm from '../SignUpConfirm';
import AuthRegister from '../Register';
import AuthLogout from '../Logout';
import AuthForgotPassword from '../ForgotPassword';
import AuthResetPassword from '../ResetPassword';
import AuthCreatePassword from '../CreatePassword';
import NotFound from '../../../components/NotFound';
import AppState from 'interfaces/app-state';
import { CustomerAuthRole } from '../../../enums';
import { getPage, getRoute } from '../../../utils/pages';

const isCartEnabled = process.env.REACT_APP_CART_ENABLED === 'true';

function AuthGuestOnly(props: RouteComponentProps) {
  const navigate = useNavigate();
  const { user } = useSelector(
    (state: AppState) => ({
      user: state.auth,
    }),
    shallowEqual
  );

  useEffect(() => {
    return () => localStorage.removeItem('activationRequest');
  }, []);

  if (user.role === CustomerAuthRole.CUSTOMER) {
    navigate('/');
    return null;
  }

  return (
    <Router>
      <Redirect from="/" to={`${getPage('auth')}${getPage('login')}`} />
      <AuthLogin path={getRoute('login')} />
      <AuthRegister path={getRoute('register')} />
      <AuthSignUpConfirm path={getRoute('verifyEmail')} />
      <AuthForgotPassword path={getRoute('forgotPassword')} />
      <AuthResetPassword path={getRoute('resetPassword')} />
      <AuthCreatePassword path={getRoute('createPassword')} />
      <NotFound default />
    </Router>
  );
}

/**
 * Auth module root component.
 */
export default function AuthLayout() {
  if (!isCartEnabled) {
    return <NotFound />;
  }
  return (
    <Box className="container">
      <Router>
        <AuthLogout path={getRoute('logout')} />
        <AuthGuestOnly path="/*" />
      </Router>
    </Box>
  );
}
