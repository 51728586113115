/**
 * Markdown
 */
const internationalRepairDayEn = `

  The pants that get an accidental hole, or the fridge that unfortunately runs down after 10
  years - all this ends up in landfills faster than ever before. Shockingly, in today's world, many
  items are simply thrown away. Thereby, with the help of repairs and recycling, this growing
  environmental catastrophe could be counteracted. To create more presence for this
  sustainable way, there is the International Repair Day, which will take place this year on
  October 15th under the motto "Repair Everywhere." ([Open Repair Alliance](https://openrepair.org/international-repair-day/get-involved), 2022). We from
  Repair Rebels think: This is a great opportunity to repair our old treasures!
  
  ## ABOUT INTERNATIONAL - REPAIR-DAY - THE STORY
  
  The relatively young initiative was launched in collaboration with the Open Repair Alliance
  and has been advocating since 2017 to make repairs visible as a crucial activity in our
  civilization and to make them more sustainable ([Reparaturen Initiativen](https://www.reparatur-initiativen.de/post/reparieren-senkt-treibhausgasemissionen-international-repair-day-2021), 2022). So-called
  Repair Cafés provide sustainable input by repairing broken devices locally. These Cafés
  were originally founded by electricians, craftsmen and do-it-yourselfers. At some point the
  idea boomed and so did the need to bring sustainable methods for repairs to the world. So,
  the basic idea of this is to show the benefits of careful treatments and repairs of our devices.
  It is also increasingly reported on social media as a way of repairing defects and getting the
  message out to the world as far as possible ([ersatzteilshop.de](https://www.ersatzteilshop.de/blog/international-repair-day/), 2022).

  The "Right to repair" campaign, which was created by an alliance of various European
  organizations, also plays a major role on this day. The campaign aims at a universal right to
  repair, free access to instructions and tools, as well as the reduction of global resource
  consumption ([Right to Repair](https://repair.eu/de/ueber-uns/#:~:text=Mit%20der%20Right%20to%20Repair%20Kampagne%20fordern%20wir%3A&text=Aufkl%C3%A4rung%20von%20B%C3%BCrgerInnen%20und%20VerbraucherInnen,Planeten%20und%20die%20Gesellschaft%20herbeizuf%C3%BChren), 2022).
  
  ## WHICH IMPACT DOES REPAIRING HAVE ON THE ENVIRONMENT?
  
  For many devices that we hand in for repair, most of the impact on global warming has
  already occurred before its first use. This is because carbon emissions, also known as
  "consumption-related emissions," are created during production. ([Reparaturen Initiativen](https://www.reparatur-initiativen.de/post/reparieren-senkt-treibhausgasemissionen-international-repair-day-2021),
  2022) In addition, research by the prestigious Ellen MacArthur Foundation discovered that in
  the apparel industry, less than 1% of the clothing produced worldwide is recycled into new
  fibers in a closed loop. 73% of 53 million metric tons (Mta) of clothing produced globally each
  year ends up in landfills or is incinerated. Another 12% is reprocessed into low-quality
  products such as cleaning rags. The proportion of products that are lost in production as
  offcuts or are unsaleable also relates to around 12%. The bad thing is that almost 1% of the
  total amount enters the ocean as microplastics through washing processes of synthetic
  textiles ([recovery](https://www.recovery-worldwide.com/de/artikel/die-grenzen-des-textilrecyclings_3411755.html#:~:text=Letztlich%20werden%20nur%20insgesamt%2018,und%2030%20%25%20in%20die%20Verbrennung), 2019).
  By extending the life cycle of a product, consumption-related emissions can be reduced, and
  by avoiding the "throwaway culture," fewer emissions reach our waters.
  
  ## HOW CAN I MAKE MY CONTRIBUTION TO REPAIR DAY?
  
  There are many ways you can get involved with International Repair Day. For that reason,
  we've put 5 simple tips together that are sure to help you become a Repair Rebel!
  
  1. Look around for local events or Repair Cafes in your area. Repair Cafés offer tools
  and spare parts for clothing, furniture, electrical appliances and much more. Visit
  [https://www.reparatur-initiativen.de](https://www.reparatur-initiativen.de) to find locations near you.
  
  2. Face your ever-growing household to-do list! There's always something around the
  house that can be fixed. Now's your chance to finally get ahead of the repairs you
  need!
  
  3. Do you have special handicraft skills? Then go ahead and help others repair things,
  maybe even as a volunteer at a Repair Café! Especially young people could learn
  more about how to create something new out of something broken
  ([ersatzteilshop.de](https://www.ersatzteilshop.de/blog/international-repair-day/)), 2022)!
  
  4. Are you pretty clumsy yourself when it comes to fashion repairs? Then take a look at
  our website ([https://www.repair-rebels.com/product/select](https://www.repair-rebels.com/product/select)) and bring your clothes
  and shoes to Repair Rebels! Here we will take care of your repairs with love!
  
  5. Sharing is caring! If you share your repair successes on social media with your
  friends and families, the feeds are crowded with repairs and thus it has a bigger
  impact in general. After all, by talking more about repairing, we can also encourage
  other people to see broken things differently ([Open Repair Alliance](https://openrepair.org/international-repair-day/get-involved), 2022).

`;

export default internationalRepairDayEn;
