import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { AssignmentControllerGetDoneParameter } from '../../parameters/assignment-controller-get-done.parameter';
import { AssignmentControllerGetDoneResponse } from '../../responses/assignment-controller-get-done.response';

interface AssignmentControllerGetDoneOpenApiParameters {
  parameters: AssignmentControllerGetDoneParameter;
}

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/assignment/done/{year}/{month}/{day}',
  operationId: 'AssignmentController_getDone',
  parameters: [
    {
      name: 'year',
      required: true,
      in: OpenApiParameterType.PATH,
    },
    {
      name: 'month',
      required: true,
      in: OpenApiParameterType.PATH,
    },
    {
      name: 'day',
      required: true,
      in: OpenApiParameterType.PATH,
    },
    {
      name: 'byDate',
      required: false,
      in: OpenApiParameterType.QUERY,
    },
  ],
})
export class AssignmentControllerGetDoneMethod
  extends BaseOpenApiMethod<AssignmentControllerGetDoneResponse>
  implements Method<AssignmentControllerGetDoneResponse, AssignmentControllerGetDoneOpenApiParameters>
{
  public call(parameters: AssignmentControllerGetDoneOpenApiParameters): Promise<AssignmentControllerGetDoneResponse> {
    return super.call(parameters);
  }
}
