const appProductsI18nEn = {
  'Gürtelschleife annähen': 'Sew back the belt loop',
  'Preis je Gürtelschleife': 'Price per belt loop',
  Jacken: 'Jackets',
  Hosen: 'Pants',
  Taschen: 'Bags',
  'Leder und Kunstleder': 'Leather and Artificial Leather',
  Stoff: 'Fabric',
  Boots: 'Boots',
  'Rahmengenähte Schuhe': 'Welted shoes',
  Jeans: 'Jeans',
  Mantel: 'Coats',
  'Blazer oder Sakko': 'Blazers or jackets',
  Lederjacke: 'Leather Jackets',
  Stoffhose: 'Fabric pants',
  Kleider: 'Dresses',
  Kleid: 'Dresses',
  Oberteile: 'Top parts',
  Pullover: 'Sweaters',
  Pumps: 'Pumps',
  Sneaker: 'Sneakers',
  Leder: 'Leather',
  Stiefeletten: 'Ankle boots',
  'Boots und Normale Schuhe': 'Boots and Regular Shoes',
  Rock: 'Skirts',
  Jacke: 'Jackets',
  Ballerinas: 'Ballet Flats',
  Hemd: 'Shirts',
  'Stoff und Kunstleder': 'Fabric and Artificial Leather',
  'Griff oder Gurt nähen': 'Sew handle or strap',
  'Erfrischung von Lederoberflächen und Imprägnierung': 'Refreshment of leather surfaces and impregnation',
  'Wähle das Material': 'Choose the material',
  Cobbler: 'Cobbler',
  'Polieren von Ledertaschen': 'Polishing of leather bags',
  'Premium Reparaturen für Ledertaschen, Rucksäcke und Portemonnaies.': 'Premium repairs for leather bags, backpacks and wallets.',
  'Reißverschluss austauschen': 'Replace the zipper',
  'Wir können Deine Taschen, Rucksäcke und Portemonnaies reparieren.': 'We can repair your bags, backpacks and wallets.',
  Stück: 'Amount',
  'Deine Sohlen verabschieden sich? Dann lass sie uns kleben! Preis je Schuh.': "Your soles say goodbye? Then let's glue them! Price per shoe.",
  'Wähle die Art deines Schuhs': 'Choose the type of your shoe',
  'Beachte, dass sich der Preis auf eine Schuhsohle bezieht. Buche den Service bei einer Reparatur für zwei Schuhe also auch zwei Mal. Bei gerissenen Sohlen oder größeren Reparaturen bitten wir Dich eine Schätzung anzufordern.':
    'Note that the price refers to a shoe sole. So book the service for a repair for two shoes twice. In case of cracked soles or major repairs, we ask you to request an estimate.',
  'Kleben der Sohle': 'Gluing the sole',
  'Diese lassen sich an der Naht an den Rändern der Sohle erkennen.': 'These can be seen on the seam at the edges of the sole.',
  'Hier stopfen wir gleich beide Seiten.': 'Here we stuff both sides right away.',
  Cutter: 'Cutters',
  'Schritt stopfen': 'Step stuffing',
  'Der Klassiker. Repariere Deine Lieblingsjeans.': 'Classic game. Fix your favorite jeans.',
  'Wähle Dein Kleidungsstück': 'Choose your garment',
  'Reißverschluss ersetzen': 'Replace zipper',
  'Für Deinen Lieblingsmantel der Dich an kalten Tagen schützt.': 'For your favorite coat that protects you on cold days.',
  'Gerissene Naht schließen': 'Close the torn seam',
  'Just in time, rette Deinen Blazer oder Sakko.': 'Just in time, save your blazer or jacket.',
  'Gerissenes Futter schließen': 'Close torn lining',
  'Mit einem passenden Blazer oder Sakko mit allen Knöpfen hinterlässt du einen super ersten Eindruck.':
    'With a matching blazer or jacket with all buttons you will leave a great first impression.',
  Bereiche: 'Amount',
  Stückanzahl: 'Amount',
  Länger: 'Length',
  'Preis je Bereich der gestopft werden soll.': 'Price per area that should be stuffed.',
  'Löcher oder abgenutzte Bereiche stopfen': 'Plug holes or worn areas',
  Stückzahl: 'Amount',
  'Wir suchen ein passendes Lederpatch.': 'We are looking for a suitable leather patch.',
  Patch: 'Patch',
  'Deine Lederjacke ist für mehr als eine Saison gemacht. Bleibt euch treu!': 'Your leather jacket is made for more than one season. Stay true to you!',
  'Keine Jeans? Dann bist Du hier richtig!': 'No jeans? Then you are in the right place!',
  Länge: 'Length',
  'Die passende Alternative zum Hochkrempeln: Kürzen!': 'The suitable alternative to rolling up: shortening!',
  'Lass Dir beim Abmessen am besten helfen. Schaue dabei grade in den Spiegel und überprüfe, bevor Du Deine Angabe machst, ob Dir die gekürzte Länge zusagt.':
    'Let us help you with the measurement. Just look in the mirror and check, before you make your statement, whether the shortened length suits you.',
  'Ärmel kürzen': 'Shortening sleeves',
  'Just in time, rette Dein Kleid': 'Just in time, save your dress',
  'Damit Dein Lieblingskleid immer einsatzbereit bleibt.': 'So that your favorite dress always remains ready for use.',
  'Wenn Dein geliebter Pullover ein Fresh-up braucht.': 'When your beloved sweater needs a fresh-up.',
  Reinigung: 'Clean',
  'Für alle Lieblingspullover die uns an kalten Tagen wärmen.': 'For all favorite sweaters that warm us on cold days.',
  'Für Pumps mit denen ein glamouröser Auftritt garantiert ist.': 'For pumps with which a glamorous appearance is guaranteed.',
  'Dir drückt der Schuh? Dann braucht er einen Stretch! Preis je Paar': 'Shoes are too tight? Then they need a stretch! Prices per pair.',
  'Hier stretchen wir Deine etwas zu engen Schuhe.': 'Here we stretch your slightly too tight shoes.',
  Stretch: 'Stretch',
  'Deine geliebten Sneaker sind bei uns in besten Händen.': 'Your beloved sneakers are in the best hands with us.',
  'Reinigung, Polierung, kleine Farbanpassungen.': 'Cleaning, polishing, small color adjustments.',
  Polieren: 'Polish',
  'Neue Sohlen für Deine Liebsten.': 'New soles for your loved ones.',
  'Sohlen austauschen Leder': 'Soles exchange leather',
  'Absätze austauschen Leder': 'Replace leather heels',
  '2 Leder Patches ansetzen': 'Apply 2 Leather patches',
  'Ellbogen patchen': 'Patching elbows',
  'Dein Lieblingspaar hat kleine Makel? Nicht mit uns!': 'Your favorite couple has little flaws? Not with us!',
  'Hierzu zählen alle Schuhe außer rahmengenähte Schuhe.': 'This includes all shoes except welted shoes.',
  'Preis pro Stück.': 'Price per piece.',
  'Original nur mit vorhandenem Knopf möglich.': 'Original only possible with an existing button.',
  'Knöpfe annähen': 'Sewing buttons',
  'Dein Rock ist zu lang? Lasse es durch uns kürzen.': 'Is your skirt too long? Let us shorten it.',
  'Länge kürzen': 'Shorten the length',
  'Damit Du Deinen Rock weiter rocken kannst.': 'So that you can continue to rock your skirt.',
  'Rundum Pflegeprogramm für Deine geliebten Sneaker.': 'All-round care program for your beloved sneakers.',
  'Das ist im Premium Programm enthalten\\u003a Säubern des Außen- und Innenschuhs sowie eine anschließende Farbauffrischung Deiner Sneaker. Dabei werden verblasste oder abgesplitterte Farbe in Handarbeit ausgebessert. Auch die Schnürsenkel Reinigung, eine Imprägnierung damit Dein Schuh länger geschützt ist und sauber bleibt und eine antibakterielle Desodorierung für neue Frische sind inklusive. Abhängig vom Material bügeln wir auch Gehfalten aus. Alles in Handarbeit. Preis je Paar.':
    'This is included in the Premium program\\u003a Cleaning the outer and inner shoe as well as a subsequent color refresh of your sneakers. Faded or chipped paint is repaired by hand. Also the laces cleaning, an impregnation so that your shoe is protected longer and stays clean and an antibacterial deodorization for new freshness are included. Depending on the material, we also iron out walking folds. All handmade. Price per pair.',
  'Premium Reinigung': 'Premium Cleaning',
  'Sohlen austauschen Gummi': 'Replacing soles with rubber',
  'Absätze austauschen Gummi': 'Replacing heels with rubber',
  'Dehnung zu enger Bereiche. Preis je Paar.': 'Stretching too narrow areas. Price per pair.',
  'Absätze austauschen': 'Heel Tips',
  'Just in time, rette Deine Jacke.': 'Just in time, save your jacket.',
  'Jeansjacken, Daunenjacken und weitere.': 'Denim jackets, down jackets and more.',
  'Gepflegt und repariert sehen sie besonders schick aus.': 'Well-groomed and repaired, they look especially chic.',
  'Schnipp Schnapp Saum ab.': 'Snap off hem.',
  'Hosen kürzen': 'Shorten pants',
  'Dein Kleid ist zu lang? Lasse es durch uns kürzen.': 'Is your dress too long? Let us shorten it.',
  'Dein Oberteil ist zu lang? Lasse es durch uns kürzen.': 'Is your top too long? Let us shorten it.',
  'Blusen, Hemden, T-Shirts und weitere.': 'Blouses, shirts, T-shirts and more.',
  'Fetten / Wachsen und Farbkorrektur.': 'Greasing / waxing and color correction.',
  'Classic Lederjacken Facelift': 'Classic leather jackets facelift',
  'Für einen Pullover der viel gesehen hat': 'For a sweater that has seen a lot',
  'Reinigung Deiner leicht verschmutzten Sneaker.': 'Cleaning your slightly soiled sneakers.',
  'Das ist im Classic Programm enthalten\\u003a Säubern des Außenschuhs, Schnürsenkel Reinigung, Imprägnierung damit Dein Schuh länger geschützt ist und sauber bleibt und antibakterielle Desodorierung für neue Frische. Alles in Handarbeit. Preis je Paar.':
    'This is included in the Classic program\\u003a Cleaning the outer shoe, laces cleaning, impregnation so that your shoe is protected longer and stays clean and antibacterial deodorization for new freshness. All handmade. Price per pair.',
  'Classic Reinigung': 'Classic Cleaning',
  'Deine Lieblingssneaker haben es verdient zu strahlen.': 'Your favorite sneakers deserve to shine.',
  'Stück / Bereiche': 'Pieces / Areas',
  'Deine Sohle verabschieden sich? Dann lass sie uns kleben! Preis je Schuh.': "Your sole say goodbye? Then let's glue them! Price per shoe.",
  'Deinen Blazer oder Dein Sakko ist zu lang? Lasse ihn durch uns kürzen.': 'Is your blazer or jacket too long? Let us shorten it.',
  'Wie viel enger? Gib die Gesamtmenge an.': 'How much tighter? Specify the total amount.',
  'Klar, oversized ist cool, aber passend ist noch besser!': 'Sure, oversized is cool, but fitting is even better!',
  'Enger nähen': 'Sew more tightly',
  'Just in time, rette Deine Lederjacke.': 'Just in time, save your leather jacket.',
  'Quick Fix für die Absätze and you are ready to dance again!': 'Quick fix for the heel tips and you are ready to dance again.',
  'Quick Fix für die Absätze and you are ready to go!': 'Quick Fix for your heels and you are ready to go!',
  'Kleine Löcher stopfen, Trockenreinigung und Pilling': 'Plug small holes, dry cleaning and pilling',
  'Facelift für Deinen Pullover': 'A facelift for your sweater',
  'Wenn Motten in Deinem Kleiderschrank ein Festmahl veranstaltet haben…': 'If moths have arranged a feast in your wardrobe…',
  'Like tailormade': 'For that perfect fit!',
  'Taille enger nähen': 'Sew the waist more tightly',
  'Lass Dir beim Abmessen am besten helfen. Schaue dabei grade in den Spiegel und überprüfe, bevor Du Deine Zentimeterangabe machst, ob Dir die Änderung zusagt.':
    'Let us help you with the measurement. Just look in the mirror and check, before you make your centimeter information, whether the change suits you.',
  'Gummiabsätze erneuern': 'Renew Rubber Heels',
  'Erneuere die verschlissenen Gummiabsätze. Preis pro Paar.': 'Replace the worn out rubber sole heels. Price per pair.',
  'Gummisohlen erneuern': 'Renew Rubber Soles',
  'Für Deine lieblings Sneaker. Erneuere die kompletten Gummisohlen. Preis pro Paar.':
    'For your favorite sneakers. Renew the complete rubber soles. Price per pair.',
  'Fersen reparieren': 'Repair Heels',
  'Wenn das Sneakerfutter von innen abgenutzt ist. Preis je Schuh.': 'When the sneakers lining is worn out from the inside. Price per shoe.',
  'Loch stopfen': 'Plug a Hole',
  'Lass den kleinen Riss stopfen. Preis pro Loch.': 'Get that small crack fixed! Price per hole.',
  'Reissvarschluss ersetzen': 'Replace Zipper',
  'Retten Deine Lieblinge und ersetze den kaputten Reißverschluss. Preis je Schuh.': 'Save your favourites and replace that broken zipper. Price per shoe.',
  'Rette Deinen Lieblingsblazer!': 'Just in time, save your favorite blazer!',
  'Rette Dein Lieblingshemd!': 'Just in time, save your favorite shirt!',
  'Taille Enger Nähen': 'Sew The Waiste More Tight',
  'Wie maßgeschneidert': 'For that perfect fit!',
  'Dein Mantel ist zu lang, lass ihn kürzen!': 'Your coat is too long, have it shortened!',
  'Rette Deine Lieblingsjacke!': 'Just in time, save your favorite jacket!',
  'Spitzen verstärken': 'Reinforce Sole Tips',
  'Eine kleine Investition, um die Lebensdauer Deiner Pumps zu verlängern.': 'A small investment to extend the life of your favorite pumps.',
  'Für zu lange Ärmel.': 'For too long sleeves.',
};

export default appProductsI18nEn;
