import AuthApi from '../interfaces/api';
import AuthRegisterData from '../interfaces/register-data';
import EmailTaken from '../errors/email-taken';
import appRequest from '../../utils/request';
import { store } from '../../store';
import { UserState } from '../store/type';
import cookies from 'js-cookie';
import i18next from 'i18next';
import { toastr } from 'react-redux-toastr';
import { PlaceDto } from '../../api/components/place-dto';
import { Address } from '../../interfaces/customer-data';
import { unexpectedServerError } from 'utils/network-error-msg';

const authApiProd: AuthApi = {
  confirmSignUp: async (token, abortController) => {
    const response = await appRequest(`customer/activate-account?activationToken=${token}&isEmailVerification=true`, {
      method: 'PATCH',
      signal: abortController.signal,
    });

    if (!response) {
      abortController.abort();
      unexpectedServerError();
      return;
    }

    const { data } = response;

    if (data?.message) {
      if (data.message !== 'You already verified your email') {
        return toastr.error('Error', data.message);
      }

      toastr.info('Info', data.message);
      return true;
    }
  },
  verifyAddress: async (address: Address): Promise<(PlaceDto & { message?: string }) | null> => {
    const response = await appRequest('customer/verify-address', {
      method: 'POST',
      data: address,
    });

    if (!response) {
      unexpectedServerError();
      return null;
    }

    const { status, data } = response;

    if (status !== 200 || !data) {
      toastr.error('Server error', "Can't verify address. Try again later.");
    }

    return data;
  },
  changePreferredLanguage: async (lang: string) => {
    const reduxState = store.getState();
    const { auth: user } = reduxState;

    await appRequest(`customer/lang/${user?._id}`, {
      method: 'PUT',
      data: { lang },
    });
  },
  changePassword: async (customerId, newPassword) => {
    const response = await appRequest(`customer/update-password/${customerId}`, {
      method: 'PATCH',
      data: { customerId, password: newPassword },
    });

    if (!response) {
      unexpectedServerError();
      return null;
    }

    const { data, status } = response;

    if (status !== 200) {
      toastr.error('Server error', "Can't update password. Try again later.");
    }

    return data as UserState;
  },
  forgotPassword: async (email: string) => {
    const response = await appRequest('customer/reset-password', {
      method: 'PATCH',
      data: { email },
    });

    if (!response) {
      unexpectedServerError();
      return false;
    }

    const { status, data: errorResponse } = response;

    if (status !== 200 && Object.keys(errorResponse).length) {
      toastr.error('Server error', errorResponse.message);
      return false;
    }
    return true;
  },
  login: async (email: string, password: string): Promise<UserState | null> => {
    const response = await appRequest('customer/login', {
      method: 'POST',
      data: { email, password },
    });

    if (!response) {
      unexpectedServerError();
      return null;
    }

    const { data } = response;

    if (!data.message) {
      return data;
    }

    if (Array.isArray(data.message)) {
      data.message.map((msg) => toastr.error('Error', msg));
      return null;
    } else {
      toastr.error('Error', data.message);
      return null;
    }
  },
  logout: async () => {
    document.cookie = 'cartId=;Max-Age=0;Expires=Thu, 01 Jan 1970 00:00:01 GMT;Path=/'; // clear `cartId` cookie
  },
  register: async ({ contact, password }: AuthRegisterData) => {
    const { email, firstName, lastName, phoneNumber } = contact;
    const { password: passwordString } = password;
    const body = {
      email,
      password: passwordString,
      firstName,
      lastName,
      phoneNumber,
      preferredLanguage: cookies.get('language') || i18next.language,
    };
    const response = await appRequest('customer/register', {
      method: 'POST',
      data: body,
    });

    if (!response) {
      unexpectedServerError();
      return;
    }

    const { status, data: errorResponse } = response;

    if (status === 409) {
      throw new EmailTaken();
    }

    if (!errorResponse) return;

    return errorResponse.message;
  },
  resetPassword: async (token, password) => {
    const response = await appRequest(`customer/create-password?verificationToken=${token}`, {
      method: 'PATCH',
      data: { password },
    });

    if (!response) {
      unexpectedServerError();
      return null;
    }

    const { data } = response;

    return data as UserState;
  },
  createPassword: async (token, password) => {
    const response = await appRequest(`customer/create-password?verificationToken=${token}`, {
      method: 'PATCH',
      data: { password },
    });

    if (!response) {
      unexpectedServerError();
      return null;
    }

    const { data } = response;

    return data as UserState;
  },
  deleteUser: async () => {
    const response = await appRequest('customer/remove', {
      method: 'POST',
    });

    if (!response) {
      unexpectedServerError();
      return;
    }

    const { data, status } = response;

    if (status !== 201) {
      return toastr.error('Server error', "Can't delete your account. Try again later.");
    }
    return data;
  },
};

export default authApiProd;
