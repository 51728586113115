import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { TransportControllerGetOpenParameter } from '../../parameters/transport-controller-get-open.parameter';
import { TransportControllerGetOpenResponse } from '../../responses/transport-controller-get-open.response';

interface TransportControllerGetOpenOpenApiParameters {
  parameters: TransportControllerGetOpenParameter;
}

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/transport/open/{year}/{month}/{day}/{timeframe}',
  operationId: 'TransportController_getOpen',
  parameters: [
    {
      name: 'year',
      required: true,
      in: OpenApiParameterType.PATH,
    },
    {
      name: 'month',
      required: true,
      in: OpenApiParameterType.PATH,
    },
    {
      name: 'day',
      required: true,
      in: OpenApiParameterType.PATH,
    },
    {
      name: 'timeframe',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class TransportControllerGetOpenMethod
  extends BaseOpenApiMethod<TransportControllerGetOpenResponse>
  implements Method<TransportControllerGetOpenResponse, TransportControllerGetOpenOpenApiParameters>
{
  public call(parameters: TransportControllerGetOpenOpenApiParameters): Promise<TransportControllerGetOpenResponse> {
    return super.call(parameters);
  }
}
