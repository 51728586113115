import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import './AwardsSlider.css';
import AwardsSliderItem from './AwardsSliderItem';
import SwiperCore, { Mousewheel, Autoplay, Navigation } from 'swiper';
import leftArrow from './left-arrow.svg';
import rightArrow from './right-arrow.svg';
import { useMediaQuery } from '@material-ui/core';
import { awardsItems } from './LogoData';

const AwardsSlider = () => {
  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

  SwiperCore.use([Mousewheel, Autoplay, Navigation]);

  return (
    <div className='awards'>
      <div className='header-awards'>
        <img src={rightArrow} alt="" className='arrow' />
        <h2 className='title'>PRESS & AWARDS</h2>
        <img src={leftArrow} alt="" className='arrow' />
      </div>
      <div className="awards-container">
        <Swiper
          loop
          slidesPerView={isMobile ? 1 : 4}
          pagination={{
            clickable: true,
          }}
          spaceBetween={70}
          navigation
          className='swiper'
        >
          {awardsItems.map((item) => (
            <SwiperSlide key={item.id}>
              <AwardsSliderItem logo={item.logo} link={item.link} size={item.size}/>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default AwardsSlider;
