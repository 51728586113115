import TailorPlaceDetails from '../interfaces/tailorPlaceDetail';
import Tailor from '../interfaces/tailor';
import { SET_TAILORS, ADD_TAILOR_PLACE_DETAILS } from './types';

export function setTailors(tailors: Tailor[]) {
  return {
    type: SET_TAILORS,
    payload: {
      tailors,
    },
  };
}

export function addTailorPlaceDetails(tailorPlaceDetail: TailorPlaceDetails[]) {
  return {
    type: ADD_TAILOR_PLACE_DETAILS,
    payload: {
      tailorPlaceDetail,
    },
  };
}
