import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { OrderControllerGetOrderByIdParameter } from '../../parameters/order-controller-get-order-by-id.parameter';
import { OrderControllerGetOrderByIdResponse } from '../../responses/order-controller-get-order-by-id.response';

interface OrderControllerGetOrderByIdOpenApiParameters {
  parameters: OrderControllerGetOrderByIdParameter;
}

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/order/by-id/{orderId}',
  operationId: 'OrderController_getOrderById',
  parameters: [
    {
      name: 'orderId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class OrderControllerGetOrderByIdMethod
  extends BaseOpenApiMethod<OrderControllerGetOrderByIdResponse>
  implements Method<OrderControllerGetOrderByIdResponse, OrderControllerGetOrderByIdOpenApiParameters>
{
  public call(parameters: OrderControllerGetOrderByIdOpenApiParameters): Promise<OrderControllerGetOrderByIdResponse> {
    return super.call(parameters);
  }
}
