import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { DiscountControllerGetByIdParameter } from '../../parameters/discount-controller-get-by-id.parameter';
import { DiscountControllerGetByIdResponse } from '../../responses/discount-controller-get-by-id.response';

interface DiscountControllerGetByIdOpenApiParameters {
  parameters: DiscountControllerGetByIdParameter;
}

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/discount/{discountId}',
  operationId: 'DiscountController_getById',
  parameters: [
    {
      name: 'discountId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class DiscountControllerGetByIdMethod
  extends BaseOpenApiMethod<DiscountControllerGetByIdResponse>
  implements Method<DiscountControllerGetByIdResponse, DiscountControllerGetByIdOpenApiParameters>
{
  public call(parameters: DiscountControllerGetByIdOpenApiParameters): Promise<DiscountControllerGetByIdResponse> {
    return super.call(parameters);
  }
}
