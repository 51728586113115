import BlogItem from '../interfaces/item';
import kannEsEinemImage from './images/kann-es-einem-anderen-freude-bereiten.png';
import kannEsEinemAnderenFreudeBereitenBodyDe from './posts/kann-es-einem-anderen-freude-bereiten-de';
import kannEsEinemAnderenFreudeBereitenBodyEn from './posts/kann-es-einem-anderen-freude-bereiten-en';
import diesesMalMacheIchAllesRichtigImage from './images/dieses-mal-mache-ich-alles-richtig.png';
import diesesMalMacheIchAllesRichtigBodyDe from './posts/dieses-mal-mache-ich-alles-richtig-de';
import diesesMalMacheIchAllesRichtigBodyEn from './posts/dieses-mal-mache-ich-alles-richtig-en';
import weilWirIhrTreuBleibenWollenBodyEn from './posts/weil-wir-ihr-treu-bleiben-wollen-en';
import weilWirIhrTreuBleibenWollenBodyDe from './posts/weil-wir-ihr-treu-bleiben-wollen-de';
import weilWirDenUsedLookLiebenBodyDe from './posts/weil-wir-den-used-look-lieben-de';
import weilWirDenUsedLookLiebenBodyEn from './posts/weil-wir-den-used-look-lieben-en';
import aufraeumenMitSystemImage from './images/aufraeumen-mit-system.png';
import aufraeumenMitSystemBodyDe from './posts/aufraeumen-mit-system-de';
import aufraeumenMitSystemBodyEn from './posts/aufraeumen-mit-system-en';
import reparierenIstEineHaltungBodyDe from './posts/reparieren-ist-eine-haltung-de';
import reparierenIstEineHaltungBodyEn from './posts/reparieren-ist-eine-haltung-en';
import repairRebelsLaunchEventDe from './posts/repair-rebels-launch-event-de';
import repairRebelsLaunchEventEn from './posts/repair-rebels-launch-event-en';
import secondHandSeptemberDe from './posts/second-hand-september-de';
import secondHandSeptemberEn from './posts/second-hand-september-en';
import zusammenarbeitZwischenMonoQuickUndRepairRebelsDe from './posts/zusammenarbeit-zwischen-mono-quick-und-repair-rebels-de';
import zusammenarbeitZwischenMonoQuickUndRepairRebelsEn from './posts/zusammenarbeit-zwischen-mono-quick-und-repair-rebels-en';
import internationalRepairDayDe from './posts/international-repair-day-de';
import internationalRepairDayEn from './posts/international-repair-day-en';
import animalDayEn from './posts/animal-day-en';
import animalDayDe from './posts/animal-day-de';
import repairRebelsRepairEventDe from './posts/repair-rebels-repair-event-de';
import repairRebelsRepairEventEn from './posts/repair-rebels-repair-event-en';
import donateYourClothesEn from './posts/donate-your-clothes-en';
import donateYourClothesDe from './posts/donate-your-clothes-de';
import repeirRebelsWonEn from './posts/repair-rebels-won-en';
import repeirRebelsWonDe from './posts/repair-rebels-won-de';

const blogItemsEn: BlogItem[] = [
  {
    id: 'repair-rebels-won-the-german-ecodesign-award',
    title: 'Repair Rebels Won the German Ecodesign Award',
    summary:
      "The German Ecodesign Award is Germany's highest state award for ecological design, highlighting the intersection of sustainability and innovation. We are excited to announce that the Repair Rebels team has been selected as the winner in the Service Category.",
    date: '20.12.2023',
    youtubeVideoUrl: 'https://www.youtube.com/embed/RqjVD-CC1rg',
    body: repeirRebelsWonEn,
    seoTitle: 'Repair Rebels Won the German Ecodesign Award – Service Categor',
    seoDescription:
      "The German Ecodesign Award is Germany's highest state award for ecological design. Repair Rebels team has been selected as the winner in the Service Category.",
  },
  {
    id: 'donate-your-clothes-properly',
    title: 'DONATE YOUR CLOTHES PROPERLY',
    summary:
      'Learn why clothing donations are often exploited. Learn from Repair Rebels how to donate your clothes properly and where your donation is safe.',
    date: '25.08.2023',
    imageUrl: 'https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/DONATE-CLOTHES/pexels-rodnae-productions-8581019.jpg',
    body: donateYourClothesEn,
    seoTitle: 'Clean out your closet and do good.',
    seoDescription:
      "Clean out your closet and do good. Repair Rebels shows you how to donate your clothes properly and where your donation is best kept.",
  },
  {
    id: 'repair-rebels-repair-event',
    title: 'REPAIR-EVENT BY REPAIR REBELS',
    summary:
      'From the rattle of sewing machines, inspiring lectures and magical atmosphere, a Blue, Orange & Black festive garland was finally created, which we proudly placed on our wall at Loretto 9 in the name of International Repair Day!',
    date: '15.10.2022',
    imageUrl: 'https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Repair-Event+by+Repair+Rebels/IMG_1466.jpg',
    body: repairRebelsRepairEventEn,
    seoTitle: 'The International Repair Day - Düsseldorf',
    seoDescription:
      "On the International Repair Day, the Düsseldorf repair community gathered for a beginner's workshop at the sewing machine. Together with the Düsseldorf's Mayor Dr. Stephan Keller and Green Party politician Jan Matzoll, the Repair Rebels team honoured their partner shoemakers, tailors and goldsmiths.",
  },
  {
    id: 'world-animal-protection-day',
    title: 'WORLD ANIMAL PROTECTION DAY',
    summary:
      'Even if nowadays some countries and brands ban the use of animal material for the production of clothing, the exploitation of animals in the fashion industry unfortunately remains a harsh reality.',
    date: '04.10.2022',
    imageUrl: 'https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/Lama.jpg',
    body: animalDayEn,
    seoTitle: 'World Animal Protection Day',
    seoDescription:
      "The exploitation of animals in the fashion industry is unfortunately still a harsh reality. On October 4th, the World Animal Protection Day, we want to remind ourselves that fashion doesn't have to be cruel. The Repair Rebels team has hand-picked 8 fashion brands that care about animal welfare and offer vegan alternatives. ",
  },
  {
    id: 'international-repair-day',
    title: 'INTERNATIONAL-REPAIR-DAY',
    summary:
      "The pants that get an accidental hole, or the fridge that unfortunately runs down after 10 years - all this ends up in landfills faster than ever before. Shockingly, in today's world, many items are simply thrown away.",
    date: '30.09.2022',
    imageUrl: 'https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Sewing+machine.jpg',
    body: internationalRepairDayEn,
    seoTitle: 'world-animal-protection-day',
    seoDescription:
      'The International Repair Day, which takes place every year in October, is a great opportunity to repair our old treasures and celebrate the value of fixing. Our team has listed 5 simple tips on how you can get involved and which can help you become a real Repair Rebel!',
  },
  {
    id: 'second-hand-september',
    title: 'Second-hand-September',
    summary:
      'Nowadays, Second-Hand is gaining more and more popularity. For example, 19% of the German population buy their clothes via various internet portals, such as ebay, upup.com or rebelle.com, and around 15% buy clothes in retail stores.',
    date: '18.09.2022',
    imageUrl: 'https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/89CCC4F8-833C-4F03-ABD6-D48C730EEF3D.jpeg',
    body: secondHandSeptemberEn,
    seoTitle: 'Secondhand September ',
    seoDescription:
      'The Second-Hand September campaign, was started in the UK by the aid and development organization Oxfam. For sustainability reasons, the organisation calls on fashion lovers to shop for nothing new except second-hand goods for 30 days. Our team has compiled a list of our favourite second-hand stores in Düsseldorf - for every style and every budget! ',
  },
  {
    id: 'repair-rebels-launch-event',
    title: 'The Repair Rebels Launch Event',
    summary: '"The atmosphere today was extraordinary and you can tell that people here want to make a difference" - Rolf Rainer master shoemaker',
    date: '12.09.2022',
    youtubeVideoUrl: 'https://www.youtube.com/embed/tGEJ2nyoxpU',
    body: repairRebelsLaunchEventEn,
    seoTitle: 'Repair Rebels Launch Event in Düsseldorf',
    seoDescription:
      'At the Repair Rebels launch event we celebrated the launch of our online booking system for fashion repairs. As a fashion tech startup we want to digitalise the traditional repair craftsmanship and make repairing fashion items as easy and as fun as buying new!',
  },
  {
    id: 'mono-quick-and-repair-rebels-collaboration',
    title: 'Monoquick & Repair Rebels Collaboration!',
    summary:
      'Fashion lovers can now have even more reasons to repair their favorite pieces. Repair Rebels just announced a collaboration with Monoquick - the biggest Patch company in Germany.',
    date: '22.08.2022',
    imageUrl: 'https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/mq-collab-placeholder.png',
    body: zusammenarbeitZwischenMonoQuickUndRepairRebelsEn,
    seoTitle: 'Mono-Quick and Repair Rebels Collaboration',
    seoDescription:
      "Repair Rebels announces their latest collaboration with Germany's largest patch company Mono-Quick. This article shows the history and production of patches and how you can repair your clothes with them.",
  },
  {
    id: 'can-your-clothes-bring-joy-to-someone-else',
    title: 'Can it bring joy to someone else?',
    summary: "Options on sharing, swapping and renting your clothes. Your unused treasures deserve a 2nd chance! We'll tell you what options are out there.",
    date: '25.04.2022',
    imageUrl: kannEsEinemImage,
    body: kannEsEinemAnderenFreudeBereitenBodyEn,
    seoTitle: 'Your unused clothing deserve a 2nd chance!',
    seoDescription:
      "Your unused clothing deserve a 2nd chance! We'll tell you what options are out there for sharing, swapping and renting your clothes. The beauty of these options is that you can make someone else happy and do something good for the environment at the same time. ",
  },
  {
    id: 'decluttering-with-a-system-how-to-make-tidying-up-like-a-kids-play',
    title: "Decluttering with a system- how to make tidying up a kids' play.",
    summary: "With a few simple tricks, even tidying up can be fun. We'll tell you how to make your overstuffed closet a thing of the past.",
    date: '15.04.2022',
    imageUrl: aufraeumenMitSystemImage,
    body: aufraeumenMitSystemBodyEn,
    seoTitle: "How to Spring Clean your Closet Marie Kondo's Way",
    seoDescription:
      "On average, we don't wear about 64% of the clothes in our closet. With that being said, it is obvious that we will want to get rid of a few pieces of clothing over and over again. Repair Rebels shows you how to spring clean your closet with Marie Kondo's system.",
  },
  {
    id: 'how-to-take-care-of-cashmere-wool',
    title: "This time I'm doing everything right!",
    summary:
      'Are you afraid to wash your cashmere sweater? Understandable, because if you wash it the wrong way, something can quickly go wrong. To keep your beloved cashmere sweater soft and in shape, we share our best tricks for proper care and washing!',
    date: '15.03.2022',
    //imageUrl: 'https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Wolle_richtig_waschen.jpg',
    imageUrl: diesesMalMacheIchAllesRichtigImage,
    body: diesesMalMacheIchAllesRichtigBodyEn,
    seoTitle: 'How to Wash your Cashmere Sweaters',
    seoDescription:
      'Cashmere is known to be one of the noblest wools in the world. However, it is also one of the most sensitive ones. Repair Rebels shows you how to properly take care of and wash your cashmere sweaters so that they last for many years. "',
  },
  {
    id: 'the-origin-of-denim-fabric-and-how-to-repair-your-jeans',
    title: 'Because we love the used look',
    summary:
      'Jeans become more gorgeous just by wearing them - in most cases, at least. In case a tear appears, we share four practical tips with which you can not only repair your favourite pair of jeans, but also make them more beautiful at the same time.',
    date: '20.02.2022',
    imageUrl: 'https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Jeans-Repair.jpg',
    body: weilWirDenUsedLookLiebenBodyEn,
    seoTitle: 'How to Repair your Favorite Jeans',
    seoDescription:
      'In this Repair Rebels blog article you will learn about the history of denim and what options you have to repair your favorite jeans. Our online fashion repair service offers you high-quality jeans repairs, which can be sent over to us by post or picked-up from your home or office by our currier service. ',
  },
  {
    id: 'washing-and-caring-for-denim-properly',
    title: 'Because we want to stay true to them',
    summary:
      'Jeans are like our second skin. They live and age with us. Below are five simple tips on how to care for your jeans and how to wash them to extend the life of your beloved pants.',
    date: '25.01.2022',
    imageUrl: 'https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/How_to_wash_jeans.jpg',
    body: weilWirIhrTreuBleibenWollenBodyEn,
    seoTitle: 'How to Wash your Jeans',
    seoDescription:
      'We show you how to wash your jeans properly and give you care tips for your denim. Use the Repair Rebels online service to get your jeans repaired, holes plugged or rips sewn.',
  },
  {
    id: 'repairs-as-a-solution-for-the-circular-economy',
    title: 'Repairing is an attitude!',
    summary:
      'Nowadays, repairing is more important than ever and one thing is for certain - we need a cultural change! Our founder Monika explains in a TED Talk video why this is urgently needed.',
    date: '25.10.2021',
    youtubeVideoUrl: 'https://www.youtube.com/embed/9OV6UmMTjzA',
    body: reparierenIstEineHaltungBodyEn,
    seoTitle: 'TED Talk - Will Repairs be the Next Big Thing in Fashion? ',
    seoDescription:
      'In her TED talk, Dr. Monika Hauck, Founder of Repair Rebels, talks about the importance of fashion repairs and explains why working on extending the life spam of clothing offers a great opportunity to move the fashion industry towards more sustainability. ',
  },
];

const blogItemsDe: BlogItem[] = [
  {
    id: 'repair-rebels-gewinnt-den-bundespreis-ecodesign',
    title: 'Repair Rebels Gewinnt den Bundespreis Ecodesign',
    summary:
      "Der Bundespreis Ecodesign ist Deutschlands höchste staatliche Auszeichnung für ökologisches Design. Wir freuen uns sehr, dass das Team von Repair Rebels als Gewinner in der Service Kategorie ausgewählt wurde.",
    date: '20.12.2023',
    youtubeVideoUrl: 'https://www.youtube.com/embed/RqjVD-CC1rg',
    body: repeirRebelsWonDe,
    seoTitle: 'Repair Rebels Gewinnt den Bundespreis Ecodesign',
    seoDescription:
      "Bundespreis Ecodesign ist die höchste staatliche Auszeichnung Deutschlands für ökologisches Design. Das Team von Repair Rebels ist der Gewinner in der Kategorie Service.",
  },
  {
    id: 'erne-deine-kleidung-richtig-zu-spenden',
    title: 'LERNE DEINE KLEIDUNG RICHTIG ZU SPENDEN',
    summary:
      'Erfahre warum Kleiderspenden häufig ausgebeutet werden. Lerne von Repair Rebels, wie Du Deine Kleidung richtig spenden kannst und wo Deine Spende sicher ist.',
    date: '25.08.2023',
    imageUrl: 'https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/DONATE-CLOTHES/pexels-rodnae-productions-8581019.jpg',
    body: donateYourClothesDe,
    seoTitle: 'Kleiderschrank ausmisten und Gutes tun',
    seoDescription:
      "Kleiderschrank ausmisten und Gutes tun. Repair Rebels zeigt Dir, wie Du Deine Kleidung richtig spenden kannst und wo Deine Spende am besten aufgehoben ist. ",
  },
  {
    id: 'repair-rebels-reparatur-event',
    title: 'REPAIR-EVENT BY REPAIR REBELS',
    summary:
      'Aus dem Rattern der Nähmaschinen, anregenden Vorträgen und magischer Atmosphäre entstand schließlich eine Blaue, Orange & Schwarze Festgirlande, die wir im Namen des Internationalen Reparaturtags stolz an unsere Wand auf der Lorettostraße anbrachten!',
    date: '15.10.2022',
    imageUrl: 'https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Repair-Event+by+Repair+Rebels/IMG_1466.jpg',
    body: repairRebelsRepairEventDe,
    seoTitle: 'Repair-Event by Repair Rebels',
    seoDescription:
      'Am internationalen Reparaturtag hat sich die Reparatur Community in Düsseldorf, im Rahmen eines Reparatur-Events bei uns, zu einem Anfänger Workshop an der Nähmaschine versammelt. Repair Rebels hat sein Team der Schuhmacher, Schneider und Goldschmiede geehrt.',
  },
  {
    id: 'welttierschutztag',
    title: 'WELTTIERSCHUTZTAG',
    summary:
      'Auch wenn heutzutage einige Länder und Marken die Nutzung von tierischem Material für die Herstellung der Kleidung untersagen, so bleibt die Ausbeutung von Tieren in der Modebranche leider eine harte Realität.',
    date: '04.10.2022',
    imageUrl: 'https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/Lama.jpg',
    body: animalDayDe,
    seoTitle: 'Welttierschutztag',
    seoDescription:
      'Zum Welttierschutztag präsentierte unser Team die tierfreundlichsten Unternehmen, die auf Leder, Pelz und Wolle tierischen Ursprungs verzichten.',
  },
  {
    id: 'internationaler-reparaturtag',
    title: 'INTERNATIONALER REPARATURTAG',
    summary:
      'Die Hose, die ein ungewolltes Loch bekommt, oder der Kühlschrank, der nach 10 Jahren dann doch leider seinen Geist aufgibt- all das landet heutzutage schneller als je zuvor auf der Mülldeponie. Erschreckenderweise werden in unserer heutigen Welt viele Gegenstände einfach weggeschmissen.',
    date: '30.09.2022',
    imageUrl: 'https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Sewing+machine.jpg',
    body: internationalRepairDayDe,
    seoTitle: 'Internationalen Reparaturtag',
    seoDescription:
      'Für den internationalen Reparaturtag hat unser Team Reparaturinitiativen aufgelistet, die Du schnell und einfach in Deiner Nähe oder sogar Zuhause finden kannst.',
  },
  {
    id: 'second-hand-september',
    title: 'Second-hand-September',
    summary:
      'Second-Hand-Mode gewinnt heutzutage immer mehr an Beliebtheit. So kauft 19% der deutschen Bevölkerung ihre Kleidung über diverse Internetportale, wie zum Beispiel ebay, upup.com oder rebelle.com, und rund 15% bedienen sich der Kleidung im Ladengeschäft.',
    date: '18.09.2022',
    imageUrl: 'https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/89CCC4F8-833C-4F03-ABD6-D48C730EEF3D.jpeg',
    body: secondHandSeptemberDe,
    seoTitle: 'Secondhand Shops in Düsseldorf',
    seoDescription:
      'Der Secondhand September appelliert zum nachhaltigen Konsumbewusstsein. Das Repair Rebels Team hat Dir lokale Secondhand Shops in Düsseldorf aufgelistet für mehr nachhaltige Luxusmode in Deinem Schrank.',
  },
  {
    id: 'repair-rebels-launch-event',
    title: 'Das Repair Rebels Launch Event',
    summary: '“Die Stimmung heute war extraordinär und man merkt, dass die Menschen hier was bewirken wollen” - Rolf Rainer Schuhmachermeister',
    date: '12.09.2022',
    youtubeVideoUrl: 'https://www.youtube.com/embed/tGEJ2nyoxpU',
    body: repairRebelsLaunchEventDe,
    seoTitle: 'Repair Rebels Launch Event',
    seoDescription:
      'Beim Repair Rebels Launch Event haben wir unser Online Buchungssystem für Modereparaturen mit der Reparatur Community gefeiert. Wir revolutionieren als Tech-Startup das traditionelle Reparaturhandwerk und bieten mit unserem eigenen Kurierdienst in Düsseldorf jedem das Reparieren von Kleidung einfach und bequem an.',
  },
  {
    id: 'zusammenarbeit-zwischen-mono-quick-und-repair-rebels',
    title: 'Zusammenarbeit zwischen Mono-Quick und Repair Rebels!',
    summary:
      'Modefans haben jetzt noch mehr Gründe, ihre Lieblingsstücke reparieren zu lassen, denn wir geben unsere Zusammenarbeit mit Mono-Quick - dem größten Patch-Unternehmen in Deutschland - bekannt.',
    date: '22.08.2022',
    imageUrl: 'https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/mq-collab-placeholder.png',
    body: zusammenarbeitZwischenMonoQuickUndRepairRebelsDe,
    seoTitle: 'Mono-Quick und Repair Rebels Kollaboration',
    seoDescription:
      'Repair Rebels kündigt ihre neuste Kollaboration an mit Deutschlands größtem Patch-Unternehmen Mono-Quick. Der Blogartikel erzählt die Geschichte und Herstellung von Aufnähern und wie Du mit ihnen Deine Kleidung reparieren kannst.',
  },
  {
    id: 'kann-deine-kleidung-jemand-anderem-freude-bereiten',
    title: 'KANN DEINE KLEIDUNG JEMAND ANDEREM FREUDE BEREITEN?',
    summary:
      'Optionen zum Teilen, Tauschen und Vermieten Deiner Kleidung. Deine ungenutzten Schätze haben eine 2. Chance verdient! Wir verraten Dir, welche Möglichkeiten es gibt.',
    date: '25.04.2022',
    imageUrl: kannEsEinemImage,
    body: kannEsEinemAnderenFreudeBereitenBodyDe,
    seoTitle: 'Kann deine Kleidung jemand anderem Freude bereiten?',
    seoDescription:
      'Erfahre wie Du auf Tausch- und Swapparties, oder Online Deine Kleidung zur Vermietung anbieten kannst. So kannst Du Deine Kleidung davor retten, alleine im Schrank zu hängen, oder im Müll zu landen.',
  },
  {
    id: 'aufraeumen-mit-system-wie-das-ausmisten-zum-kinderspiel-wird',
    title: 'AUFRÄUMEN MIT SYSTEM- WIE DAS AUSMISTEN ZUM KINDERSPIEL WIRD.',
    summary: 'Mit ein paar einfachen Tricks kann sogar das Aufräumen Spaß machen. Wir verraten Dir, wie Dein überfüllter Kleiderschrank Geschichte wird.',
    date: '15.04.2022',
    imageUrl: aufraeumenMitSystemImage,
    body: aufraeumenMitSystemBodyDe,
    seoTitle: 'Kleiderschrank aufräumen mit System',
    seoDescription: "Repair Rebels zeigt dir, wie Du mit Marie Kondo's System Deinen Kleiderschrank, passend zum Frühjahrsputz, ausmisten kannst.",
  },
  {
    id: 'wie-geht-man-mit-kaschmirwolle-um',
    title: 'WIE GEHT MAN MIT KASCHMIRWOLLE UM?',
    summary: 'Damit Dein geliebter Kaschmirpullover weich und in Form bleibt, teilen wir unsere besten Tricks zum richtigen Pflegen und Waschen!',
    date: '15.03.2022',
    //imageUrl: 'https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Wolle_richtig_waschen.jpg',
    imageUrl: diesesMalMacheIchAllesRichtigImage,
    body: diesesMalMacheIchAllesRichtigBodyDe,
    seoTitle: 'Kaschmirpullover richtig waschen ',
    seoDescription:
      'Repair Rebels zeigt Dir, wie Du Deinen Kaschmirpullover richtig wäschst und gibt Dir Pflegetipps für den Umgang mit Kaschmirwolle, damit Du mit Deinen Textilpflege-Skills Kleidung retten kannst.',
  },
  {
    id: 'herkunft-des-denimstoffes-und-wie-du-deine-jeans-reparieren-kannst',
    title: 'HERKUNFT DES DENIMSTOFFES UND WIE DU DEINE JEANS REPARIEREN KANNST',
    summary: 'Eine Jeans wird durchs Tragen nur schöner- meistens zumindest. Wenn doch mal etwas reißt, ist das aber auch kein Weltuntergang.',
    date: '20.02.2022',
    imageUrl: 'https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Jeans-Repair.jpg',
    body: weilWirDenUsedLookLiebenBodyDe,
    seoTitle: 'Wie du deine Jeans reparieren kannst',
    seoDescription:
      'In diesem Repair Rebels Blogartikel erfährst Du die Geschichte des Denim und wie Du Deine Jeans reparieren kannst. Unser Online Modereparatur Service bietet Dir innerhalb Düsseldorfs an, Deine Jeansreparatur bequem nach Hause zu liefern.',
  },
  {
    id: 'jeansstoff-richtig-waschen-und-pflegen',
    title: 'jeansstoff-richtig-waschen-und-pflegen',
    summary: 'Jeans sind wie unsere zweite Haut. Sie leben und altern mit uns. Und egal, wie alt sie sind, sie haben immer eine zweite Chance verdient',
    date: '25.01.2022',
    imageUrl: 'https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/How_to_wash_jeans.jpg',
    body: weilWirIhrTreuBleibenWollenBodyDe,
    seoTitle: 'Jeans richtig waschen ',
    seoDescription:
      'Wir zeigen Dir, wie Du Deine Jeans richtig wäschst und geben Dir Pflegetipps für Deinen Denim. Nutze den Repair Rebels Online Service, um Deine Jeans reparieren zu lassen und Löcher zu stopfen oder Risse zu nähen.',
  },
  {
    id: 'reparaturen-als-loesung-fuer-die-kreislaufwirtschaft',
    title: 'REPARATUREN ALS LÖSUNG FÜR DIE KREISLAUFWIRTSCHAFT',
    summary: 'Warum Reparaturen heute wichtiger denn je sind, erklärt euch unsere Gründerin Monika in einem TED Talk Video.',
    date: '25.10.2021',
    youtubeVideoUrl: 'https://www.youtube.com/embed/9OV6UmMTjzA',
    body: reparierenIstEineHaltungBodyDe,
    seoTitle: 'TED Talk Dr. Monika Hauck, Gründerin von Repair Rebels',
    seoDescription:
      'Dr. Monika Hauck, Gründerin von Repair Rebels, informiert in einem Ted-Talk über die Bedeutung von Kleidungsreparaturen und dessen Chancen für eine verbesserte Kreislaufwirtschaft in der Modeindustrie.',
  },
];

export { blogItemsEn, blogItemsDe };
