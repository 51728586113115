import tailorInitialState from './initial-state';
import { ADD_TAILOR_PLACE_DETAILS, SET_TAILORS } from './types';

export default function cartReducer(state = tailorInitialState, action) {
  switch (action.type) {
    case SET_TAILORS: {
      const { tailors } = action.payload;
      return { ...state, tailors };
    }
    case ADD_TAILOR_PLACE_DETAILS: {
      const { tailorPlaceDetail } = action.payload;

      if (tailorPlaceDetail === undefined) {
        return state;
      }

      if (!state.tailorPlaceDetails) {
        const tailorPlaceDetails = [tailorPlaceDetail];
        return { ...state, tailorPlaceDetails };
      } else {
        const tailorPlaceDetails = [...state.tailorPlaceDetails, tailorPlaceDetail];
        return { ...state, tailorPlaceDetails };
      }
    }
    default:
      return state;
  }
}
