import ProfilePaymentData from '../interfaces/payment-data';

const profilePaymentMethodsFixture: ProfilePaymentData[] = [
  {
    id: '123qweasd',
    cardNumber: '1234123412341234',
    expireMonth: '3',
    expireYear: '2023',
    cvv2: '123',
  },
  {
    id: '456qweasd',
    cardNumber: '5674123412341765',
    expireMonth: '5',
    expireYear: '2027',
    cvv2: '567',
  },
];

export default profilePaymentMethodsFixture;
