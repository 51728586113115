import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { ProfileControllerGetCompletedBookingListParameter } from '../../parameters/profile-controller-get-completed-booking-list.parameter';
import { ProfileControllerGetCompletedBookingListResponse } from '../../responses/profile-controller-get-completed-booking-list.response';

interface ProfileControllerGetCompletedBookingListOpenApiParameters {
  parameters: ProfileControllerGetCompletedBookingListParameter;
}

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/profile/completed-booking-list',
  operationId: 'ProfileController_getCompletedBookingList',
  parameters: [
    {
      name: 'lastEvaluatedKey',
      required: false,
      in: OpenApiParameterType.QUERY,
    },
    {
      name: 'limit',
      required: false,
      in: OpenApiParameterType.QUERY,
    },
  ],
})
export class ProfileControllerGetCompletedBookingListMethod
  extends BaseOpenApiMethod<ProfileControllerGetCompletedBookingListResponse>
  implements Method<ProfileControllerGetCompletedBookingListResponse, ProfileControllerGetCompletedBookingListOpenApiParameters>
{
  public call(parameters?: ProfileControllerGetCompletedBookingListOpenApiParameters): Promise<ProfileControllerGetCompletedBookingListResponse> {
    return super.call(parameters);
  }
}
