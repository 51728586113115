import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { CartConstantsControllerGetCourierPriceCentResponse } from '../../responses/cart-constants-controller-get-courtier-price-cent.response';

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/cart-constants/courtier-price-cent',
  operationId: 'CartConstantsController_getCourierPriceCent',
  parameters: [],
})
export class CartConstantsControllerGetCourierPriceCentMethod
  extends BaseOpenApiMethod<CartConstantsControllerGetCourierPriceCentResponse>
  implements Method<CartConstantsControllerGetCourierPriceCentResponse, void>
{
  public call(): Promise<CartConstantsControllerGetCourierPriceCentResponse> {
    return super.call();
  }
}
