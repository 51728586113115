import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { selectProductItems } from '../../../Product/store/selectors';
import classes from './Categories.module.css';
import ProductCategoryStepList from '../../../Product/components/CategoryStep/List';
import i18n from 'i18n';

// styles
const useStyles = makeStyles({
  circularProgress: {
    color: 'white',
  },
});

export default function Categories() {
  const muiClasses = useStyles();
  const navigate = useNavigate();
  const products = useSelector(selectProductItems);
  const { t } = useTranslation();

  function renderButtonList() {
    if (products) {
      return <ProductCategoryStepList products={products} onCategorySelect={(x) => navigate(`/product/${x.seo?.[i18n.language]}`)} labelledBy="homepage__categories-heading" />;
    }
    return (
      <div className="d-flex justify-content-center py-5">
        <CircularProgress className={muiClasses.circularProgress} />
        <div className="sr-only">{t('Loading...')}</div>
      </div>
    );
  }

  return (
    <div id="categories" className={classes.container}>
      <div className="container">
        <h2 className="h4 with-bar" id="homepage__categories-heading">
          {t("Let's get started!")}
        </h2>
        <div className="mb-3">
          {/*<p className="body1">{t('Choose the item you need to repair')}</p>*/}
          <p className="body1 mb-1">{t('Select the item for which you need a repair.')}</p>
          <p className="body1">{t('Pick-up and delivery is free of charge for orders over 125€.')}</p>
        </div>
        <div className={classes.gridContainer}>{renderButtonList()}</div>
      </div>
    </div>
  );
}
