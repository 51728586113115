import React from 'react';
import { useTranslation } from 'react-i18next';
import { useElementOnScreen } from '../../../hooks/useElementOnScreen';
import classes from './Testimonials.module.css';

const testimonials = [
  {
    firstName: 'Jana',
    lastName: 'Wemhöner',
    comment: 'One piece that was waiting in my closet changed...',
  },
  {
    firstName: 'Michael',
    lastName: 'Geke',
    comment: 'My old leather briefcases look like new...',
  },
  {
    firstName: 'Anja',
    lastName: 'Gräf',
    comment: 'So pleased with the excellent repair of my...',
  },
];

const animationDelay = 0.3;

export default function Testimonials() {
  const [testimonialRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  });

  const { t } = useTranslation();

  const testimonialElements = testimonials.map((item, i) => {
    return (
      <div key={i} className="col-12 col-md-6 col-lg-4">
        <div style={{ animationDelay: `calc(${animationDelay * i}s)` }} className={`${classes.testimonial} ${isVisible ? classes.animation : ''}`}>
          <div className={classes.feedbackContainer}>
            <div className={classes.feedback}>
              <div className={classes.feedbackText}>
                <span>{t(item.comment)}</span>
              </div>
              <div className={classes.feedbackName}>
                <div className={classes.feedbackFirstName}>- {item.firstName}</div>
                <div className={classes.feedbackLastName}>{item.lastName}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div ref={testimonialRef} className="container">
      <h2 className={`h4 with-bar mb-3 ${classes.title} ${isVisible ? classes.titleAnimation : ''}`}>{t('Our Rebels')}</h2>
      <div className="row">{testimonialElements}</div>
    </div>
  );
}
