import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { ProductControllerGetProductTypeListResponse } from '../../responses/product-controller-get-product-type-list.response';

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/product/product-type',
  operationId: 'ProductController_getProductTypeList',
  parameters: [],
})
export class ProductControllerGetProductTypeListMethod
  extends BaseOpenApiMethod<ProductControllerGetProductTypeListResponse>
  implements Method<ProductControllerGetProductTypeListResponse, void>
{
  public call(): Promise<ProductControllerGetProductTypeListResponse> {
    return super.call();
  }
}
