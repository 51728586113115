import { Box } from '@material-ui/core';
import { Redirect, Router } from '@reach/router';
import React from 'react';
import { useSelector } from 'react-redux';
import AppNotFound from '../../../components/NotFound';
import { selectProductItems } from '../../store/selectors';
import ProductCategoryStep from '../CategoryStep';
import ProductDetailsStep from '../DetailsStep';
import ProductDetailsStepGarment from '../DetailsStep/Garment';
import ProductLoading from '../Loading';
import ProductRepairStep from '../RepairStep';
import ProductRepairStepGarment from '../RepairStep/Garment';
import ProductSuccess from '../Success';
import ProductTypeStep from '../TypeStep';

export default function ProductMain() {
  const products = useSelector(selectProductItems);
  if (!products) {
    return <ProductLoading />;
  }
  return (
    <Box component={Router}>
      <ProductCategoryStep path="select" products={products} />
      <Redirect from="/" to="select" noThrow />
      <ProductCategoryStep path="garment" products={products} />
      {/* deprecated route. TODO: remove */}
      <ProductSuccess path="success/:seo" />
      <ProductTypeStep path=":seo" products={products} />
      <ProductRepairStep path=":seo/:typeId" products={products} />
      <ProductDetailsStep path=":seo/:typeId/:repairId" products={products} />
      <ProductRepairStepGarment path="garment/:seo/:itemId" products={products} />
      <ProductDetailsStepGarment path="garment/:seo/:categoryId/:typeId/:seo/:itemId" products={products} />
      <AppNotFound default />
    </Box>
  );
}
