import ClubApi from '../interfaces/api';

async function invite(email: string): Promise<{ success: boolean; message: string }> {
  return { success: true, message: '' };
}

async function acceptInvite(emailToken: string): Promise<{ success: boolean; message: string }> {
  return { success: true, message: '' };
}

async function registerWithDiscount(email: string): Promise<{ success: boolean; message: string }> {
  return { success: true, message: '' };
}

/**
 * Club API definitions for automated testing.
 *
 * The definitions of this API are used in automated tests as defaults.
 */
const clubApiTest: ClubApi = {
  invite,
  acceptInvite,
  registerWithDiscount
};

export default clubApiTest;
