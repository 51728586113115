import ProfileApi from '../interfaces/api';
import ProfilePaymentData from '../interfaces/payment-data';
import profilePaymentMethodsFixture from '../fixtures/payment-methods';
import profileUserInfoFixture from '../fixtures/user-info';
import ProfileContactData from '../interfaces/contact-data';
import ProfileCityInfo from '../interfaces/city-info-data';
import profileCityInfoFixture from '../fixtures/city-info-data';
import { Address, Customer, Order } from '../../types';
import { UserState } from '../../Auth/store/type';

async function fetchActiveBookings(customerId: Customer['_id']): Promise<Order[]> {
  return [];
}

async function fetchPreviousBookings(customerId: Customer['_id']): Promise<Order[]> {
  return [];
}

async function fetchMyAddresses(): Promise<Address[]> {
  return [];
}

async function addAddressItem(address: Address): Promise<UserState | null> {
  return null;
}

async function updateAddressItem(address: Address, oldAddressString: string): Promise<UserState | null> {
  return null;
}

async function removeAddressItem(id: string): Promise<void> {}

async function fetchPaymentMethods(): Promise<ProfilePaymentData[]> {
  return profilePaymentMethodsFixture;
}

async function addPaymentMethod(paymentMethod: ProfilePaymentData): Promise<ProfilePaymentData> {
  return paymentMethod;
}

async function removePaymentMethod(id: string): Promise<void> {}

async function fetchUserInfo(): Promise<ProfileContactData | null> {
  return profileUserInfoFixture;
}

async function editUserInfo(userInfo: ProfileContactData): Promise<ProfileContactData | null> {
  return userInfo;
}

async function getCityZipCodes(): Promise<ProfileCityInfo[]> {
  return profileCityInfoFixture;
}

/**
 * Profile API definitions for automated testing.
 *
 * The definitions of this API are used in automated tests as defaults.
 */
const profileApiTest: ProfileApi = {
  fetchActiveBookings,
  fetchPreviousBookings,
  fetchMyAddresses,
  addAddressItem,
  updateAddressItem,
  removeAddressItem,
  fetchPaymentMethods,
  addPaymentMethod,
  removePaymentMethod,
  fetchUserInfo,
  editUserInfo,
  getCityZipCodes,
};

export default profileApiTest;
