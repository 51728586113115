import React, { FC } from 'react';
import RunningLine from 'components/RunningLine';
import { Container } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import VoucherItemComponent from '../VoucherItem/VoucherItem';
import { VoucherItem } from 'Voucher/intefaces/item';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import imagePreview from './Kopie-von-Kopie-von-Kopie-von-IMG_4806.jpeg';
import howItWorkImage from './Kopie-von-Kopie-von-Kopie-von-IMG_4864.jpeg';
import sheet from '../../../images/svg/sheet-large.svg';
import rightArrow from './left-arrow.svg';
import Testimonials from 'Homepage/components/Testimonials';
import { useElementOnScreen } from 'hooks/useElementOnScreen';
import classesModule from './Voucher.module.css';

interface VoucherProps extends RouteComponentProps {
  voucher: VoucherItem[];
}

const useStyles = makeStyles((theme) => {
  return {
    voucherItem: {
      listStyle: 'none',
      width: '100%',
      marginBottom: '16px',
      '&:last-child': {
        marginBottom: '0',
      },
    },
    mainTitle: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '25px!important',
      },
    },
    voucherList: {
      listStyle: 'none',
      width: '100%',
      padding: 0,
      marginBottom: 0,
    },
    voucherSubtitle: {
      fontSize: '18px',
      fontWeight: 'bold',
      marginBottom: '25px',
    },
    voucherWrraper: {
      display: 'flex',
      gap: '60px',
      marginBottom: '70px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    leftColumn: {
      flex: '0 1 50%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    rightColumn: {
      flex: '0 1 50%',
      backgroundImage: `url(${sheet})`,
      backgroundSize: '100% 100%',
    },
    contentText: {
      marginBottom: '20px',
      lineHeight: '25px',
      textAlign: 'justify',
    },
    previewImage: {
      width: '100%',
      height: '100%',
      padding: '15px 20px',
    },
    arrowBlock: {
      position: 'relative',
      marginBottom: '70px',
    },
    rightArrow: {
      position: 'absolute',
      right: '-500px',
      height: '100%',
      top: '25%',
      [theme.breakpoints.down('md')]: {
        height: 'unset',
        top: '120%',
        left: '100px',
      },
    },
    informationBlock: {
      display: 'flex',
      gap: '50px',
      marginBottom: '70px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    infoImageBlock: {
      flex: '0 1 50%',
      backgroundImage: `url(${sheet})`,
      backgroundSize: '100% 100%',
    },
    infoContentBlock: {
      flex: '0 1 50%',
    },
    howItWorkImageStyle: {
      width: '100%',
      height: '100%',
      padding: '15px 20px',
    },
    workTitle: {
      marginBottom: '30px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '25px!important',
      },
    },
    workText: {
      lineHeight: '25px',
      marginBottom: '15px',
      maxWidth: '385px',
    },
  };
});

const Voucher: FC<VoucherProps> = ({ voucher }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [bodyRefRightImg, rightImgOnScreen] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  });
  const [bodyRefLeftImg, leftImgOnScreen] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  });
  const [textRef, textOnScreen] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  });

  return (
    <>
      <RunningLine />
      <Container maxWidth="xl">
        <div>
          <h3 className={`${classes.mainTitle} with-bar`}>{t('GiftVouchers')}</h3>
        </div>
        <div className={classes.voucherWrraper}>
          <div className={classes.leftColumn}>
            <p className={classes.voucherSubtitle}>{t('Looking for a unique and meaningful')}</p>
            <div>
              <p className={classes.contentText}>{t('A small gift with')}</p>
              <ul className={classes.voucherList}>
                {voucher.map((item) => (
                  <li key={item._id} className={classes.voucherItem}>
                    <VoucherItemComponent voucher={item} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {
            //@ts-ignore
            <div ref={bodyRefRightImg} className={`${classesModule.bodyImgBlock} ${rightImgOnScreen ? classesModule.imgAnimation : ''}`}>
              <img src={imagePreview} alt="repair-image" className={classes.previewImage} />
            </div>
          }
        </div>
        <div className={classes.arrowBlock}>
          <h3 className={classes.mainTitle}>{t('A GIFT OF REPAIR SHOWS THAT YOU CARE!')}</h3>
          <img src={rightArrow} alt="arrow" className={classes.rightArrow} />
        </div>
        <div className={classes.informationBlock}>
          {
            //@ts-ignore
            <div ref={bodyRefLeftImg} className={`${classesModule.bodyImgBlockLeft} ${leftImgOnScreen ? classesModule.imgAnimationLeft : ''}`}>
              <img src={howItWorkImage} alt="how-it-work" className={classes.howItWorkImageStyle} />
            </div>
          }
          {
            //@ts-ignore
            <div ref={textRef} className={`${classesModule.body} ${textOnScreen ? classesModule.bodyAnimation : ''}`}>
              <h3 className={classes.workTitle}>{t('How does It Work?')}</h3>
              <p className={classes.workText}>{t('The Repair Rebels repair voucher')}</p>
              <p className={classes.workText}>{t('By the way')}</p>
            </div>
          }
        </div>
        <div className={classes.arrowBlock}>
          <h3 className={classes.mainTitle}>{t('LET’S REPAIR THE FUTURE TOGETHER!')}</h3>
          <img src={rightArrow} alt="arrow" className={classes.rightArrow} />
        </div>
        <div className="mb-5" />
        <Testimonials />
      </Container>
    </>
  );
};

export default Voucher;
