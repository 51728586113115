import { call, put } from 'redux-saga/effects';
import { setVoucherItems } from '../actions';
import VoucherCartApi from 'Cart/interfaces/voucherApi';

// utility, unit-testable
export default function* voucherCartFetchSaga(api: VoucherCartApi) {
  try {
    const { voucherItems } = yield call(api.fetchCart);
    yield put(setVoucherItems(voucherItems));
  } catch (err) {
    if (process.env.NODE_ENV === 'development') {
      console.error(err);
    }
    // TODO: handle error
  }
}
