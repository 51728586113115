import { UserState } from './type';
import { CustomerAuthRole, SupportedLanguages } from '../../enums';

const initialState: UserState = {
  _id: '',
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  role: CustomerAuthRole.GUEST,
  token: '',
  refreshToken: '',
  addressList: [],
  preferredLanguage: SupportedLanguages.de,
  active: false,
};

export { initialState };
