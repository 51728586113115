/**
 * Markdown
 */
const internationalRepairDayDe = `

  Die Hose, die ein ungewolltes Loch bekommt, oder der Kühlschrank, der nach 10 Jahren
  dann doch leider seinen Geist aufgibt- all das landet heutzutage schneller als je zuvor auf
  der Mülldeponie. Erschreckenderweise werden in unserer heutigen Welt viele Gegenstände
  einfach weggeschmissen. Dabei könnte mit Hilfe von Reparaturen und Recycling dieser
  heranwachsenden Umweltkatastrophe entgegengewirkt werden. Um mehr Präsenz für
  diesen nachhaltigeren Weg zu schaffen, gibt es den internationalen Reparaturtag, der dieses
  Jahr am 15.Oktober, unter dem Motto “Repair Everywhere”, stattfindet ([Open Repair
  Alliance](https://openrepair.org/international-repair-day/get-involved), 2022). Wir von Repair Rebels finden: Das ist eine super Gelegenheit, unsere alten
  Schätze mal zu reparieren!
  
  ## ÜBER INTERNATIONAL - REPAIR-DAY - DIE GESCHICHTE
  Die relativ junge Initiative wurde in Zusammenarbeit mit der Open Repair Alliance ins Leben
  gerufen und plädiert seit 2017 dafür, Reparaturen als eine entscheidende Aktivität in unserer
  Zivilisation sichtbar zu machen und diese nachhaltiger zu gestalten ([Reparaturen Initiativen](https://www.reparatur-initiativen.de/post/reparieren-senkt-treibhausgasemissionen-international-repair-day-2021),
  2022). Sogenannte Repair Cafés geben nachhaltigen Input wieder, indem vor Ort defekte
  
  Geräte repariert werden. Diese Cafés wurden ursprünglich von Elektrikern, Hand- und
  Heimwerkern gegründet. Die Idee boomte irgendwann und somit wuchs auch das Bedürfnis,
  nachhaltige Methoden für Reparaturen in die Welt zu tragen. Der Grundgedanke dieser Idee
  ist es also, die Vorteile sorgfältiger Behandlungen und Reparaturen unserer Geräte
  aufzuzeigen. Auch in den sozialen Medien wird immer mehr davon berichtet, um Defekte zu
  reparieren und die Botschaft so weit wie möglich in die Welt hinaus zu tragen
  ([ersatzteilshop.de](https://www.ersatzteilshop.de/blog/international-repair-day/), 2022).
  
  Auch die “Right to Repair” Kampagne, die durch ein Bündnis verschiedener europäischer
  Organisationen entstanden ist, spielt an diesem Tag eine große Rolle. Die Kampagne zielt
  auf ein universelles Recht auf Reparaturen, freien Zugang zu Anleitungen und Werkzeugen,
  sowie die Senkung des weltweiten Ressourcenverbrauchs ab ([Right to Repair](https://repair.eu/de/ueber-uns/#:~:text=Mit%20der%20Right%20to%20Repair%20Kampagne%20fordern%20wir%3A&text=Aufkl%C3%A4rung%20von%20B%C3%BCrgerInnen%20und%20VerbraucherInnen,Planeten%20und%20die%20Gesellschaft%20herbeizuf%C3%BChren), 2022).
  
  ## WELCHEN EINFLUSS HAT DAS REPARIEREN AUF DIE UMWELT?
  
  Bei vielen Geräten, die wir zur Reparatur abgeben, sind die meisten Auswirkungen auf die
  globale Erwärmung bereits vor dessen ersten Verwendung entstanden. Denn bei der
  Produktion entstehen Kohlenstoffemissionen, die auch als "konsumbezogene Emissionen"
  bezeichnet werden ([Reparaturen Initiativen](https://www.reparatur-initiativen.de/post/reparieren-senkt-treibhausgasemissionen-international-repair-day-2021), 2022). Hinzu kommt, dass eine Forschung der
  renommierten Ellen MacArthur Foundation herausgefunden hat, dass in der
  Bekleidungsindustrie weniger als 1 % der weltweit produzierten Kleidung in einem
  geschlossenen Kreislauf zu neuen Fasern verarbeitet wird. 73% von 53 Millionen Tonnen
  (Mta) Kleidung, die jährlich weltweit produziert werden, landen auf Mülldeponien oder
  werden verbrannt. Weitere 12 % werden zu minderwertigen Produkten, wie zum Beispiel
  Putzlappen, aufbereitet. Der Anteil der Produkte, die in der Produktion als Verschnitt
  verloren gehen oder unverkäuflicher sind, bezieht sich ebenfalls auf rund 12%. Das
  Schlimme daran: Fast 1 % der Gesamtmenge gelangt durch Waschvorgänge synthetischer
  Textilien als Mikroplastik in den Ozean ([recovery](https://www.recovery-worldwide.com/de/artikel/die-grenzen-des-textilrecyclings_3411755.html#:~:text=Letztlich%20werden%20nur%20insgesamt%2018,und%2030%20%25%20in%20die%20Verbrennung), 2019).
  Durch das Verlängern des Lebenszyklusses eines Produktes können konsumbezogene
  Emissionen verringert werden und durch das Vermeiden der “Wegwerfkultur” gelangen
  weniger Schadstoffe in unsere Gewässer.
  
  ## WIE KANN ICH MEINEN BEITRAG ZUM REPAIR DAY BEITRAGEN?
  
  Es gibt viele Möglichkeiten, wie Du dich beim Internationalen Reparaturtag engagieren
  kannst. Dafür haben wir dir hier 5 einfache Tipps zusammengestellt, mit denen Du sicherlich
  ganz schnell zum Repair Rebel wirst!
  
  1. Schaue dich nach lokalen Veranstaltungen, Workshops oder Repair Cafés in deiner
  Nähe um. Repair Cafés bieten Werkzeuge und Ersatzteile für Kleidung, Möbel,
  elektrische Geräte und vieles mehr an. Unter [https://www.reparatur-initiativen.de](https://www.reparatur-initiativen.de)
  findest du Standorte in deiner Nähe.
  
  2. Stell’ dich deiner immer länger werdenden To-do-Liste im Haushalt! Es gibt immer
  etwas im Haus, was repariert werden kann. Jetzt hast Du die Gelegenheit, Dir
  endlich die nötigen Reparaturen vor zu knüpfen!
  
  3. Du hast besondere Handwerks Skills? Dann nichts wie ran und hilf anderen beim
  Reparieren, vielleicht ja sogar ehrenamtlich in einem Repair Café! Gerade junge
  Menschen könnten mehr darüber lernen, wie man aus etwas Kaputten wieder Neues
  kreieren kann(vgl. [ersatzteilshop.de](https://www.ersatzteilshop.de/blog/international-repair-day/), 2022)!
  
  4. Du bist selber ziemlich ungeschickt in Sachen Mode-Reparaturen? Dann schau doch
  mal auf unserer Website ([https://www.repair-rebels.com/product/select](https://www.repair-rebels.com/product/select)) vorbei und
  bring deine Klamotten und Schuhe einfach zu Repair Rebels! Hier kümmern wir uns
  liebevoll um deine Reparaturen!
  
  5. Teilen bedeutet Fürsorge! Wenn Du Deine Reparatur-Erfolge auf Social Media mit
  deinen Freunden und deiner Familie teilst, werden die Feeds mit Reparaturen
  überfüllt und das hat eine größere Wirkung im Allgemeinen. Indem wir mehr über
  Reparieren sprechen, können wir auch andere Menschen dazu ermutigen, kaputte
  Dinge anders zu sehen (vgl. [Open Repair Alliance](https://openrepair.org/international-repair-day/get-involved), 2022)!

`;

export default internationalRepairDayDe;
