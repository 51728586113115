import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { EstimateControllerUploadImageParameter } from '../../parameters/estimate-controller-upload-image.parameter';
import { UploadedFileData } from '../../../Estimation/interfaces/api';

interface EstimateControllerUploadImageOpenApiParameters {
  requestBody: UploadedFileData;
  parameters: EstimateControllerUploadImageParameter;
}

@OpenApiMethod({
  method: HttpMethods.POST,
  apiName: 'customer',
  path: '/order/upload-image/{clientName}/{creationTime}',
  operationId: 'EstimateController_uploadImage',
  parameters: [
    {
      name: 'clientName',
      required: true,
      in: OpenApiParameterType.PATH,
    },
    {
      name: 'creationTime',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class CartControllerUploadImageMethod extends BaseOpenApiMethod<void> implements Method<void, EstimateControllerUploadImageOpenApiParameters> {
  public call(parameters: EstimateControllerUploadImageOpenApiParameters): Promise<void> {
    return super.call(parameters);
  }
}
