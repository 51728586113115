import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { ProfileControllerGetBookingParameter } from '../../parameters/profile-controller-get-booking.parameter';
import { ProfileControllerGetBookingResponse } from '../../responses/profile-controller-get-booking.response';

interface ProfileControllerGetBookingOpenApiParameters {
  parameters: ProfileControllerGetBookingParameter;
}

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/profile/order/{orderId}/customer/{customerId}',
  operationId: 'ProfileController_getBooking',
  parameters: [
    {
      name: 'customerId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
    {
      name: 'orderId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class ProfileControllerGetBookingMethod
  extends BaseOpenApiMethod<ProfileControllerGetBookingResponse>
  implements Method<ProfileControllerGetBookingResponse, ProfileControllerGetBookingOpenApiParameters>
{
  public call(parameters: ProfileControllerGetBookingOpenApiParameters): Promise<ProfileControllerGetBookingResponse> {
    return super.call(parameters);
  }
}
