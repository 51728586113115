import orderEditEnitialState from '../interfaces/order-edit-initial-state';
import { SET_EDIT_ORDER_DETAILS, CLEAR_EDIT_ORDER_DETAILS, CLEAR_EDIT_ORDER_DETAILS_AND_OTP } from './types';

export default function globalReducer(state = orderEditEnitialState, action) {
  switch (action.type) {
    case SET_EDIT_ORDER_DETAILS: {
      const { orderDetails } = action.payload;
      return { ...state, orderDetails };
    }
    case CLEAR_EDIT_ORDER_DETAILS: {
      const orderDetails = undefined;
      return { ...state, orderDetails };
    }
    case CLEAR_EDIT_ORDER_DETAILS_AND_OTP: {
      const orderDetails = undefined;
      return { ...state, orderDetails };
    }
    default:
      return state;
  }
}
