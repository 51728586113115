import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import background from './white-background.svg';
import './AwardsSlider.css';

const useStyles = makeStyles((theme) => {
  const s = (x) => theme.spacing(x);
  return {
    logoBlock: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundImage: `url(${background})`,
      backgroundSize: '100% 100%',
      padding: `${s(3)}px ${s(3)}px`,
      height: '85px',
      position: 'relative',
    },
    buttonLink: {
      position: 'absolute',
      background: theme.palette.secondary.main,
      padding: `${s(0.6)}px ${s(2)}px`,
      color: "#ffffff",
      fontWeight: 700,
      bottom: '-17px',
      transition: '0.4s ease-in-out',
      '&:hover': {
        color: '#ffffff',
        scale: 1.1,
      }
    },
    logo: {
      objectFit: 'contain'
    }
  };
});

interface AwardsProps {
  logo: string;
  link: string;
  size: number;
}

const AwardsSliderItem: FC<AwardsProps> = ({ logo, link, size }) => {
  const classes = useStyles();
  return (
    <div className={classes.logoBlock}>
      <img src={logo} alt="logo" className={classes.logo} style={{ width: size, maxWidth: '100%' }}/>
      <a href={link} className={classes.buttonLink} rel="noopener noreferrer" target="_blank">
        READ MORE
      </a>
    </div>
  );
};

export default AwardsSliderItem;
