import { TailorControllerAssignAssignmentMethod } from '../methods/tailor-controller/assign-assignment.method';
import { TailorControllerCreateMethod } from '../methods/tailor-controller/create.method';
import { TailorControllerGetAllMethod } from '../methods/tailor-controller/get-all.method';
import { TailorControllerGetAssignmentPaginatedMethod } from '../methods/tailor-controller/get-assignment-paginated.method';
import { TailorControllerGetByIdMethod } from '../methods/tailor-controller/get-by-id.method';
import { TailorControllerGetGooglePlaceMethod } from '../methods/tailor-controller/get-google-place.method';
import { TailorControllerGetPaginatedMethod } from '../methods/tailor-controller/get-paginated.method';
import { TailorControllerSetByIdMethod } from '../methods/tailor-controller/set-by-id.method';

export class TailorController {
  public readonly create = new TailorControllerCreateMethod();
  public readonly getPaginated = new TailorControllerGetPaginatedMethod();
  public readonly getAll = new TailorControllerGetAllMethod();
  public readonly getGooglePlace = new TailorControllerGetGooglePlaceMethod();
  public readonly getById = new TailorControllerGetByIdMethod();
  public readonly setById = new TailorControllerSetByIdMethod();
  public readonly getAssignmentPaginated = new TailorControllerGetAssignmentPaginatedMethod();
  public readonly assignAssignment = new TailorControllerAssignAssignmentMethod();
}
