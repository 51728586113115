/**
 * Markdown
 */
const kannEsEinemAnderenFreudeBereitenBodyEn = `

 Options on sharing, swapping and renting your clothes. Your unused treasures deserve a 2nd chance! We'll tell you what options are out there.
 
 After we have successfully cleaned out our closet, the first thing that comes to mind is usually to donate or sell our unwanted pieces. The concept of sharing outside of our own households is usually left out of the equation. Yet we share so much these days- homes, cars, and even pets!
 
 The so-called sharing economy has become an important part of our consumption patterns and is expected to reach a value of $335 trillion by 2025 (Statista, 2020). Sharing is therefore becoming more and more popular and especially in the last few years some cool opportunities for your clothes have emerged. We would like to introduce you to two still quite new business models. You can use them in the future to pass your clothes into new hands in a responsible way: The idea of swap parties and renting out your clothes.
 
 The beauty of both options is that you can make someone else happy and do something good for the environment at the same time. By sharing your clothes, you give them another shot at being worn again and extend their life cycle. This way you also save the garment from ending up in the nearest landfill.
 
 ## Rent
 
 Especially if you are not so sure whether you want to keep certain pieces of clothing, it is well worth to rent them out. The suit that you haven't worn for more than a year can easily be given to someone else on a temporary basis! This way, you avoid your clothes lying unused in the closet and earn a little extra at the same time.
 
 Renting out clothes might seem strange at first glance, but that's only because we're not used to it here in Europe. In North America, this fairly new business model is very popular. But even if it has not yet caught on as much here in Germany as it has in the U.S., the market for rentable clothing is steadily growing. The global market currently has a size of approximately 1.18 trillion USD and will most likely increase with a growth rate of 10.76 % in 2023 to a full 1.96 trillion USD. The largest share of rented clothing is party and wedding wear. These categories account for about 40% of the market (Research and Markets, 2019). These garments are usually particularly expensive and renting them can save a lot of money. However, even in the age of social media, when many try to wear particularly chic pieces to parties and post them on Instagram and co, it can be more cost-effective to rent an outfit. Formal wear like suits represents about 22% of business (Research and Markets, 2019). A survey confirmed this need among customers. 35% of respondents rented clothing for formal occasions. 31% wanted to try new brands and styles for a change. 27% felt renting clothes was a good way to save money. 23% felt the need to wear luxury clothing on one occasion, which was made possible for them by renting. 18% felt renting was an option to be more environmentally friendly and sustainable to expand their outfit options and 12% rented to save closet space (Santi, 2020). In other words, the reasons why people rent clothes are very versatile, just like the pieces you can find on sharing platforms. Nowadays you can rent almost any piece of clothing.
 
 ## Swap Parties
 
 Swap parties are used to exchange clothes with each other. The rules are clearly defined before each swap. For every piece you swap, you get one back. To make the whole thing fair, attention is paid to the quality and brand of the part, so that a swap of approximately equal value is created. The idea came to life during the financial crisis of 2007, when people tended to have less money and thus helped themselves from missing out on the shopping experience. Swap parties became more popular over time and have long since shed the image of "shopping for people with little money". In fact, the idea became so popular that swap events sprang up in larger formats with hundreds of participants (Matthews & Hodges, 2016).
 
 However, a swap party can also be organized in a small group among friends. All you need is a quiet, cozy place, your unused clothes, and your friends. It's a good idea to limit the amount of clothes per participant and to remind all guests that quality is more important than quantity. You should see the swap party as an alternative to regular shopping, not a place to unload your old clothes and get others for free in return.
 
 The beauty of swapping, and the difference between swapping and second hand stores, is that you get to meet the person who wore the piece before you. This allows you to give each other tips and tricks on how to best combine the piece and you may even hear a story or two associated with your new garment (Matthews & Hodges, 2016).
 
 Of course, there are other ways to go with your unused clothing. Stay tuned! The next post is already on the way!
 
 `;

export default kannEsEinemAnderenFreudeBereitenBodyEn;
