import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { CartControllerAddCartItemRequestBody } from '../../request-bodies/cart-controller-add-cart-item.request-body';
import { CartControllerAddCartItemResponse } from '../../responses/cart-controller-add-cart-item.response';

interface CartControllerAddCartItemOpenApiParameters {
  requestBody: CartControllerAddCartItemRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.POST,
  apiName: 'customer',
  path: '/cart',
  operationId: 'CartController_addCartItem',
  parameters: [],
})
export class CartControllerAddCartItemMethod
  extends BaseOpenApiMethod<CartControllerAddCartItemResponse>
  implements Method<CartControllerAddCartItemResponse, CartControllerAddCartItemOpenApiParameters>
{
  public call(parameters: CartControllerAddCartItemOpenApiParameters): Promise<CartControllerAddCartItemResponse> {
    return super.call(parameters);
  }
}
