import ProfileContactData from '../interfaces/contact-data';

const profileUserInfoFixture: ProfileContactData = {
  id: '321123321qwe',
  firstName: 'Chiara',
  lastName: 'Rodriguez',
  email: 'chridella@gmail.com',
  phoneNumber: '+49 654 321 987',
};

export default profileUserInfoFixture;
