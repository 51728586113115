import profileInitialState from './initial-state';
import { SET_ACTIVE_BOOKINGS_ITEMS, SET_CITY_INFO, SET_DOWNLOADING_ERROR, SET_PREVIOUS_BOOKINGS_ITEMS, SET_USER_ADDRESSES, SET_USER_INFO } from './types';

export default function profileReducer(state = profileInitialState, action) {
  switch (action.type) {
    case SET_USER_INFO: {
      const { userInfo } = action.payload;
      return {
        ...state,
        userInfo,
      };
    }
    case SET_USER_ADDRESSES: {
      const { userAddresses } = action.payload;
      return {
        ...state,
        userAddresses,
      };
    }
    case SET_ACTIVE_BOOKINGS_ITEMS: {
      const { activeBookingItems } = action.payload;
      return {
        ...state,
        activeBookingItems,
      };
    }
    case SET_PREVIOUS_BOOKINGS_ITEMS: {
      const { previousBookingItems } = action.payload;
      return {
        ...state,
        previousBookingItems,
      };
    }
    case SET_CITY_INFO: {
      const { cityInfo } = action.payload;
      return {
        ...state,
        cityInfo,
      };
    }
    case SET_DOWNLOADING_ERROR: {
      const { downloadingError } = action.payload;
      return {
        ...state,
        downloadingError,
      };
    }
    default:
      return state;
  }
}
