import { RouteComponentProps } from '@reach/router';
import React, { FC } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import sheet from '../../../images/svg/sheet-large.svg';
import cookies from 'js-cookie';
import formatPriceCent from 'utils/format-price-cent';
import { useTranslation } from 'react-i18next';
import { VoucherItem } from 'Voucher/intefaces/item';
import RedeemOutlinedIcon from '@material-ui/icons/RedeemOutlined';

interface ProductCategoryStepProps extends RouteComponentProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  vouhcerExtract: VoucherItem;
}

const useStyles = makeStyles((theme) => {
  return {
    voucherButton: {
      width: '100%',
      padding: '1rem',
      backgroundImage: `url(${sheet})`,
      backgroundSize: '100% 100%',
      backgroundColor: 'inherit',
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      transition: "0.4s ease-in-out",
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: '12px',
      },
      '&:hover': {
        transform: 'scale(1.05)'
      }
    },
    voucherImg: {
      width: '64px',
      height: '64px',
      padding: '0.5rem',
    },
    voucherTitle: {
      textAlign: 'left',
    },
    voucherImgBlock: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'start',
      },
    },
    priceBlock: {
      flex: '0 1 35%',
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'end',
      },
    },
    mobileText: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px!important',
      },
    },
    boxIcon: {
      width: '50px',
      height: '50px',
      color: '#EB672C',
      [theme.breakpoints.down('sm')]: {
        width: '45px',
        height: '45px',
      },
    },
  };
});

const VoucherStep: FC<ProductCategoryStepProps> = ({ vouhcerExtract, onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const langCookie = cookies.get('language') || '';
  const handleClick = () => {
    const id = vouhcerExtract._id;
    //@ts-ignore
    onClick(id);
  };

  return (
    <>
      <button onClick={handleClick} className={classes.voucherButton}>
        <div className={classes.voucherImgBlock}>
          <RedeemOutlinedIcon className={classes.boxIcon} />
          <Typography align="left" color="textPrimary" variant="h5" className={classes.mobileText}>
            {!langCookie ? vouhcerExtract.title.de : vouhcerExtract.title[langCookie]}
          </Typography>
        </div>
        <div className={classes.priceBlock}>
          <Typography align="left" color="textPrimary" variant="h5" className={classes.mobileText}>
            {t('Value')}: {formatPriceCent(vouhcerExtract.priceCent)}
          </Typography>
        </div>
      </button>
    </>
  );
};

export default VoucherStep;
