/**
 * Markdown
 */
const weilWirDenUsedLookLiebenBodyEn = `

 ## Where does the term denim actually come from?
 
 The term denim is an American abbreviation of the French expression "Serge de Nîmes", translated as "fabric from the Nîmes". The reason for the term is that the specially woven cotton fabric, tinted with indigo, originated in the French city of Nîmes at the beginning of its time.
 
 ## Denim- stronger than you think
 
 Denim is one of the most robust fabrics we can find in our closet. Originally, this fabric was used for the working pants of gold miners in the USA, as it was considered to be very resistant and tear-proof. But unfortunately, these days the fabric is often not quite as durable as it once was. That's why it's crucial when buying your new favorite jeans to make sure that the fabric has the right thickness - this will save you from sudden, unwanted rips and your jeans will look like new for longer!
 
 The irony behind this is that we also quite like to buy jeans in a used look nowadays. In other words, we buy jeans that are artificially aged and look like we've been wearing them for years. Hence, a pair of jeans that has been damaged for us for a surcharge. However, there can also occur holes due to frequent wearing, which in the end do not bother us at all. This can look quite stylish - a view that grandma in particular often can't really agree with. So if you can stand up to the question "kid, can't you buy a new pair of pants?" you can throw together some really cool outfits with your used look jeans. Even if the holes are too big or appear in inappropriate places (e.g. in the crotch), the jeans don't have to be thrown away!
 
 ## How to repair your favorite jeans
 
 You guessed it. Your favorite jeans can be repaired in a number of ways! In most cases, a suitable piece of fabric is placed under the hole and sewn from the outside. For this, a thread that matches the fabric well is chosen to repair the spot as invisible as possible. This is most easily done with a sewing machine.
 
 Alternatively, you can iron on visible patches and sew them down. This works especially well if the jeans are torn in places that are not directly on a seam. With this method, the patch acts like a band-aid and strongly reminds us of our childhood, when mom repaired our favorite pants with colorful patches after a small accident on the playground. Patches can save our jeans today as well, and they don't look bad at all. There are numerous designs and styles. The sky's the limit for your creativity! There is only one rule you have to follow. Make sure that the patch is large enough to completely cover the tear.
 
 Further, you can also use the traditional Japanese technique, the so-called "Sashiko", with which you can not only repair your favorite jeans, but make them truly unique. For this you will need a cotton backing, some yarn, a marking pen and a needle. If freestyle is not your thing and you want to create geometric shapes, an embroidery hoop can help. For this technique, multiple stitches are placed in a parallel position, which prevents the tear from opening further. Most often, with the help of this special stitching geometric designs are made, which are more decorative than just a pure repair. After all, when you check out how beautiful  the repair turns out to look, you might almost be glad that the jeans ripped before.
 
 The mending technique "Boro" also originated in Japan and is just like "Sashiko" super suitable for decorative repair of tears. The technique consists of sewing on small pieces of fabric that are attached to the damaged area, hiding holes and heavy wear. At first you may think, "Hmm is this just the patchwork method with another name?" But no! The individual pieces of fabric, unlike the patchwork method, are placed on top of each other and overlapping, instead of neatly against each other.
 
 Now you know a few tricks to save your favorite pair of jeans the next time they rip, and making them even more beautiful and unique. But even if you don't trust yourself to do it all on your own or just don't have the time to do it, it's absolutely no problem!
 
 **Our #LocalHero knows how to repair your favorite jeans in no time.**
 
 [I need a jeans repair!](/product/Hosen/Jeans)
 
 `;

export default weilWirDenUsedLookLiebenBodyEn;
