import React, { FC } from 'react';
import VoucherStep from '../VoucherStep/VoucherStep';
import { navigate } from '@reach/router';
import { VoucherItem } from 'Voucher/intefaces/item';

interface VoucherItemProps {
  voucher: VoucherItem;
}

const VoucherItemComponent: FC<VoucherItemProps> = ({ voucher }) => {
  return <VoucherStep vouhcerExtract={voucher} onClick={() => navigate(`./${voucher._id}`)} />;
};

export default VoucherItemComponent;
