import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useElementOnScreen } from '../../hooks/useElementOnScreen';
import WhatsApp from './WhatsApp.png';
import classes from './WhatsApp.module.css';

export default function AppWhatsApp() {
  const { t } = useTranslation();
  const whatsappLink = `https://wa.me/491735204782/?text=${encodeURI(t('Hi Repair Rebels, könnt ihr meine Lieblingsstücke retten?'))}`;

  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  });

  return (
    <div className="container">
      <div ref={containerRef} className={`${classes.main} ${isVisible ? classes.mainAnimation : ''}`}>
        <div className={classes.image}>
          <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
            <LazyLoadImage className={classes.image} alt="WhatsApp" src={WhatsApp} width={96} height={96} effect="blur" />
          </a>
        </div>
        <Box mb={2} />
        <div className="h4 mb-4">
          <div className={classes.title}>
            <span className={classes.titleText}>{t('send us your question(s)')}</span>
          </div>
        </div>
        <div className={classes.paragraph}>{t("Send us a WhatsApp message and we'll see what we can do for you!")}</div>
      </div>
    </div>
  );
}
