import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import classes from './Loading.module.css';

// styles
const useStyles = makeStyles({
  circularProgress: {
    color: 'white',
  },
});

/**
 * Common loading component.
 */
export default function Loading() {
  const muiClasses = useStyles();
  return (
    <div className={classes.main}>
      <CircularProgress className={muiClasses.circularProgress} />
      <div className="sr-only">Loading...</div>
    </div>
  );
}
