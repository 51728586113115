/**
 * Markdown
 */
const secondHandSeptemberEn = `
  Nowadays, Second-Hand is gaining more and more popularity. For example, 19% of the
  German population buy their clothes via various internet portals, such as ebay, upup.com or
  rebelle.com, and around 15% buy clothes in retail stores (Modeverband Deutschland, 2020).
  However, in order to offer second-hand goods a larger platform, the
  "Second-Hand-September" was introduced.

  ## ABOUT SECOND-HAND-SEPTEMBER - THE STORY
 
  Since 2019, this campaign, created by the aid and development organization Oxfam in the
  UK, inspires people around the world to a more sustainable approach to fashion. Oxfam is
  known for its global commitment to human rights and the fight against inequality in terms of
  poverty and injustice. With the campaign, the organization calls on people to buy nothing
  new apart from second-hand goods for 30 days and also to donate (Oxfam, 2022).
  
  Now you might be wondering what the purpose of this is. We can answer this question quite
  simply: It is for a better environment and to boycott fast fashion. More details later!
  And let's be honest: Your closet will also thank you if you sort it out properly. Because on
  average, each of us doesn't wear 64% of his clothes - an extreme waste (Statista, 2022)!
  To prevent the waste, you have the opportunity in September to become a part of this
  movement. According to the motto: Doing good, sorting out old loads and at the same time
  finding new unique items to fall in love with - we think that sounds pretty cool!
  
  ## WHY IS SECOND-HAND SO IMPORTANT?
  
  According to the European Commission (2022), European consumption of textiles has the
  fourth highest impact on the environment and climate change, after food, housing and
  mobility. As a consequence, heat waves, melting polar ice caps and other natural disasters
  are the reactions of nature due to the worldwide increase in greenhouse gas emissions
  (European Commission, 2022).
  
  To understand the issue even better, let's look at an example: The production of a new pair
  of jeans is estimated to emit 16.2 kg of CO2. This corresponds to a car journey of 58 miles/
  93 km- a frightening result (Oxfam, 2022)! Hence, climate change is an issue that has
  affected all of humanity for years, which is why various sustainability measures must be
  taken. So there could be no better time to act now.
  
  By donating and wearing clothes several times, we can contribute to reducing the demand
  for new clothes, which means we do less damage to the environment. In particular,
  supporting aid organizations, like Oxfam, can help tackle global inequalities and poverty, as
  they invest the proceeds of sold clothing in social projects. Even though we don't believe that
  the responsibility for the solution to a more sustainable environment lies with us as
  consumers, Second-Hand September can play a part in helping us live a more
  environmentally conscious life.
  
  ## HOW CAN I CONTRIBUTE MY PART TO SUSTAINABLE FASHION?
  
  Do you feel like adding sustainable value to the world? - Then welcome to the Fashion
  Rebels team! You'll see, it's easier than you think! To make sure you succeed in your
  mission, we'll give you a few tips.
    
  You have a full closet and only wear half of it anyway? Then give yourself a push and sell
  your clothes on providers such as Ebay Kleinanzeigen, Vinted or Vestiare Collective -from
  our own experience, we can say it works great! Or donate your mountain of clothes directly
  to charitable organizations!
    
  No clothes to sell at home? And you want to invest sustainably in used fashion? Then you
  can go shopping sustainably. For this purpose, your Repair Rebels team has compiled a
  Favoriteslist of second-hand stores in Düsseldorf - for every style and every budget!
    
  And don't forget to socialize! In the past year alone, more than 50,000 people posted
  pictures on social media with the caption #SecondHandSeptember. So when you grab a new
  favorite second-hand-piece, you can promote the campaign with the hashtag and generate
  more attention and awareness for sustainable fashion (marie claire, 2022).
  
  ## SECOND HAND-SHOPS IN DÜSSELDORF - RECOMMENDATION REPAIR REBELS
  
  ### BROKE
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/c0075175-9984-4b77-963c-b37a9df60d5f.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/81209a33-9509-41bb-96d5-3dc580d224be.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/e479e038-b032-490e-9ca2-aace676117d8+(1).jpg)

  **PICTURES** [@broke.thestore](https://www.instagram.com/broke.thestore/)\n
  **ADDRESS::** Tußmannstr. 5, Düsseldorf

  This little centerpiece has a lot to offer especially for men, but also women will certainly find
  some treasures here. Here you can find bags, cool oversized shirts and sneakers, but also
  new goods are occasionally there. The style goes more in the direction of street and
  sportswear with brands like Vans, Adidas, G-Star, etc..
  
  ### FRAUENZIMMER VINTAGE
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/50A4062E-371C-4696-8672-04E047EAE158_1_201_a.jpeg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/79A7FA78-384C-474A-8EDF-78E47DBCCED2_1_201_a.jpeg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/9F135E4A-DE63-4F73-8D78-0AB298EE8644_1_201_a.jpeg)

  **PICTURES** [@frauenzimmer_secondhandfashion](https://www.instagram.com/frauenzimmer_secondhandfashion/)\n
  **ADDRESS::** Bilker Allee 24, Düsseldorf
  
  You can find high quality vintage clothes for women in "Frauenzimmer Vintage". Their
  concept is defined by french fashion and attractive prices. As well you can find there jewelry,
  accessories and furniture!
  
  ### OXFAM
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/76f9ac3a-5f6b-4e3a-8d9a-c357d5a12725.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/b6e9d703-4f4a-4ec6-bf91-ea8999f9ff5c.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/0a8e0702-e2b9-4216-9daa-f553b2cb9c04.jpg)

  **PICTURES** [@oxfam_de](https://www.instagram.com/oxfam_de/)\n
  **ADDRESS::** Nordstraße 9, Düsseldorf

  If you want to do something social at the same time with your second-hand-purchase, we
  can recommend Oxfam. The globally active development organization fights against poverty
  with the help of donated goods. For example, you can buy clothes, books and decorations.
  
  ### THE MINTAGE
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/b5dffa33-cdd5-4d8f-b6a5-b0f123ab5b80.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/4c02427a-1369-454b-8497-c59d57921939.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/ee2579b0-0d67-4fbe-9d1b-178d53123592.jpg)

  **PICTURES** [@the_mintage](https://www.instagram.com/the_mintage/)\n
  **ADDRESS::** Wallstraße 27, 40213 Düsseldorf

  Classic vintage goods from the U.S. and looks from the 60s to 80s are neat to buy here.
  Between fancy indie designer brands, cowboy boots and funny retro details, you can of
  course also find second-hand-designer-clothes here. A visit is always worthwhile in any case!
    
  ### UNTOLD-UNISEX
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/3d3b8582-3a83-47ce-aa6f-fd25993dc1ef.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/9b41ded7-b6b7-4601-ba54-22358072d629.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/426df73f-c481-4097-8ab4-2a5211d1cca0.jpg)

  **PICTURES** [@untoldshop__](https://www.untoldshop.de/about)\n
  **ADDRESS::** Currently only the [Onlineshop](https://www.untoldshop.de/)
  
  Untold's online concept offers uniysex-vintage- and second-hand-clothing, as well as
  accessories. For them, clothing is defined by personality rather than being tied to a gender.
  With the help of other designers and companies, they create sustainable collections that can
  be easily integrated into everyday life.
      
  ### KOKO SELECTED
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/2f1d05ce-d79c-4f26-b248-d6be28557374.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/5329176e-8228-4a0c-b082-3c88a5be0f44.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/523900e2-e797-45b5-a099-a1361695ac79.jpg)

  **PICTURES** [@koko.selected](https://www.instagram.com/koko.selected/)\n
  **ADDRESS::** Hermannstraße 47, 40233 Düsseldorf

  Also in this store, located in Düsseldorf, you will find second-hand-clothes of good quality
  and modern look. There are no specific brands. Instead, there's a mix of curated fast fashion
  and vintage-clothing.In addition, clothing for children, as well as a small new goods
  assortment of sustainable fashion, accessories and decorative items are offered (Textilwirtschaft, 2021).

  ### OLIVAS VINTAGE
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/cd3499a4-3ed6-4ac0-9d51-1594ad85e485.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/bd67b2a3-0891-4105-87fc-bbf606e418c0.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/1b7c076a-bb18-4db7-8f89-e45085a70af0.jpg)

  **PICTURES** [@olivas_vintage](https://www.instagram.com/olivas_vintage/)\n
  **ADDRESS::** Bilker Allee 62, 40219 Düsseldorf

  Olivas Vintage is one of the oldest vintage stores in Düsseldorf and you will feel like you are
  in a time machine there. Anyone who is interested in fashion with a past, most of which is
  even hand-sewn, will find what they are looking for here. By the way: There is a large
  assortment selection for men here!
      
  ### SUPERBRANDS
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/170e418a-96f6-4b49-a587-bc47d487fd16.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/f5e3627d-5e50-4727-b466-05bdc14e4d4b.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Second-Hand-September/b0e28172-f5ca-4716-b0c3-d5a98c6511a7.jpg)

  **PICTURES** [@superbrands_secondhand](https://www.instagram.com/superbrands_secondhand/?hl=en#)\n
  **ADDRESS::** Bastionstr. 10, 40213 Düsseldorf

  This is a luxury designer store with excellent quality. There is both a great store in
  Düsseldorf (Carlstadt) and a location in Frankfurt. Those who are looking for second-hand
  bags, clothing and accessories from luxury brands will definitely find what they are looking for here.
`;

export default secondHandSeptemberEn;
