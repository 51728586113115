import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { HealthControllerStorageResponse } from '../../responses/health-controller-storage.response';

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/health/storage',
  operationId: 'HealthController_storage',
  parameters: [],
})
export class HealthControllerStorageMethod extends BaseOpenApiMethod<HealthControllerStorageResponse> implements Method<HealthControllerStorageResponse, void> {
  public call(): Promise<HealthControllerStorageResponse> {
    return super.call();
  }
}
