import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { OrderControllerSetByIdParameter } from '../../parameters/order-controller-set-by-id.parameter';
import { OrderControllerSetByIdResponse } from '../../responses/order-controller-set-by-id.response';

interface OrderControllerSetByIdOpenApiParameters {
  parameters: OrderControllerSetByIdParameter;
}

@OpenApiMethod({
  method: HttpMethods.PUT,
  apiName: 'customer',
  path: '/order/{orderId}',
  operationId: 'OrderController_setById',
  parameters: [
    {
      name: 'orderId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class OrderControllerSetByIdMethod
  extends BaseOpenApiMethod<OrderControllerSetByIdResponse>
  implements Method<OrderControllerSetByIdResponse, OrderControllerSetByIdOpenApiParameters>
{
  public call(parameters: OrderControllerSetByIdOpenApiParameters): Promise<OrderControllerSetByIdResponse> {
    return super.call(parameters);
  }
}
