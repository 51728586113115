import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { CustomerControllerCreateRequestBody } from '../../request-bodies/customer-controller-create.request-body';
import { CustomerControllerCreateResponse } from '../../responses/customer-controller-create.response';

interface CustomerControllerCreateOpenApiParameters {
  requestBody: CustomerControllerCreateRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.POST,
  apiName: 'customer',
  path: '/customer',
  operationId: 'CustomerController_create',
  parameters: [],
})
export class CustomerControllerCreateMethod
  extends BaseOpenApiMethod<CustomerControllerCreateResponse>
  implements Method<CustomerControllerCreateResponse, CustomerControllerCreateOpenApiParameters>
{
  public call(parameters: CustomerControllerCreateOpenApiParameters): Promise<CustomerControllerCreateResponse> {
    return super.call(parameters);
  }
}
