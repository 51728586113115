import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from '@reach/router';
import i18next from 'i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { selectTailors } from '../../../Tailor/store/selectors';
import classes from './Tailors.module.css';
import { getPage } from '../../../utils/pages';

const useStyles = makeStyles({
  circularProgress: {
    color: 'white',
  },
});

export default function Tailors() {
  const { t } = useTranslation();
  const tailors = useSelector(selectTailors);
  const activeTailors = tailors.filter((tailor) => tailor.active)
  const muiClasses = useStyles();

  function renderTailorView() {
    if (!tailors || !tailors.length) {
      return (
        <div className="d-flex justify-content-center py-5">
          <CircularProgress className={muiClasses.circularProgress} />
          <div className="sr-only">{t('Loading...')}</div>
        </div>
      );
    }

    const tailorChildren = activeTailors
      .sort(() => 0.5 - Math.random())
      .slice(0, 3)
      .map((tailor, i) => {
       
        const { contact } = tailor.addressSecondary;
        const tailorName = contact?.fullName ?? `${contact?.firstName} ${contact?.lastName}`;

        return (
          <div key={i} className={classes.card}>
            <div className={classes.imageBlock}>
              <LazyLoadImage
                className={classes.image}
                alt="Tailor"
                src={tailor.profilePicUrl}
                effect="blur"
                // height={image.height}
                // width={image.width}
              />
              <div className={classes.name}>{tailorName}</div>
            </div>
            <div className={classes.textBlock}>
              <div>
                <div className={classes.title}>{tailor.slogan[i18next.language]}</div>
                <div className={classes.text}>{`“${tailor.quote[i18next.language]}“`}</div>
              </div>
              <Link className="link text-secondary" to={`${getPage('tailorList')}/${tailor.seo?.url}`}>
                + {t('See More')}
              </Link>
            </div>
          </div>
        );
      });

    return (
      <div>
        <h2 className="h4 with-bar mb-4">{t('OurRepairHeroes')}</h2>
        <p className="body1 text-white mb-4">Repaired with Love!</p>
        <p className="body1 text-white mb-4">{t('homePageBlogIntro', 'default translation not provided')}</p>
        <div className={`${classes.grid} mb-4`}>{tailorChildren}</div>
        <div className="text-center">
          <Link className="link text-white" to={getPage('tailorList')}>
            {t('Meet our local partners')}
          </Link>
        </div>
      </div>
    );
  }

  return <div className="container">{renderTailorView()}</div>;
}
