import AppApi from '../interfaces/api';
import productApiTest from '../Product/api/test';
import cartApiTest from '../Cart/api/test';
import checkoutApiTest from '../Checkout/api/test';
import orderStatusApiTest from '../OrderStatus/api/test';
import contactApiTest from '../Contact/api/test';
import estimationApiTest from '../Estimation/api/test';
import orderEditPickupApiTest from '../OrderEditPickup/api/test';
import orderEditDeliveryApiTest from '../OrderEditDelivery/api/test';
import tailorApiTest from '../Tailor/api/test';
import clubApiTest from '../Club/api/test';
import profileApiTest from '../Profile/api/test';
import ratingApiTest from '../Rating/api/test';
import authApiTest from '../Auth/api/test';
import orderApiTest from '../Order/api/test';
import platformConfigApi from '../PlatformConfig/api';

/**
 * App API definitions for automated testing.
 *
 * The definitions of this API are used in automated tests as defaults.
 */
const appApiTest: AppApi = {
  product: productApiTest,
  cart: cartApiTest,
  checkout: checkoutApiTest,
  orderStatus: orderStatusApiTest,
  orderEditPickup: orderEditPickupApiTest,
  orderEditDelivery: orderEditDeliveryApiTest,
  contact: contactApiTest,
  estimation: estimationApiTest,
  tailor: tailorApiTest,
  club: clubApiTest,
  auth: authApiTest,
  profile: profileApiTest,
  rating: ratingApiTest,
  order: orderApiTest,
  platformConfig: platformConfigApi,
};

export default appApiTest;
