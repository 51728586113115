import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { AssignmentControllerMarkAsDoneParameter } from '../../parameters/assignment-controller-mark-as-done.parameter';

interface AssignmentControllerMarkAsDoneOpenApiParameters {
  parameters: AssignmentControllerMarkAsDoneParameter;
}

@OpenApiMethod({
  method: HttpMethods.PATCH,
  apiName: 'customer',
  path: '/assignment/{assignmentId}/done',
  operationId: 'AssignmentController_markAsDone',
  parameters: [
    {
      name: 'assignmentId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class AssignmentControllerMarkAsDoneMethod extends BaseOpenApiMethod<void> implements Method<void, AssignmentControllerMarkAsDoneOpenApiParameters> {
  public call(parameters: AssignmentControllerMarkAsDoneOpenApiParameters): Promise<void> {
    return super.call(parameters);
  }
}
