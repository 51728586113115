import {CustomerData, CustomerShippingData} from '../../interfaces/customer-data';
import {SET_CUSTOMER_DATA, SET_CUSTOMER_SHIPPING_DATA} from './types';

export function setCustomerData(customerData: CustomerData) {
  return {
    type: SET_CUSTOMER_DATA,
    payload: {
      customerData,
    },
  };
}

export function setCustomerShippingData(customerShippingData: CustomerShippingData) {
  return {
    type: SET_CUSTOMER_SHIPPING_DATA,
    payload: {
      customerShippingData,
    },
  };
}
