import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { TailorControllerGetAllResponse } from '../../responses/tailor-controller-get-all.response';

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/tailor/all',
  operationId: 'TailorController_getAll',
  parameters: [],
})
export class TailorControllerGetAllMethod extends BaseOpenApiMethod<TailorControllerGetAllResponse> implements Method<TailorControllerGetAllResponse, void> {
  public call(): Promise<TailorControllerGetAllResponse> {
    return super.call();
  }
}
