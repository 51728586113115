import { fork } from 'redux-saga/effects';
import CartApi from '../../interfaces/api';
// import cartFetchSaga from './fetch-saga';
import cartUpdateNumBoxesSaga from './update-num-boxes-saga';

export default function* cartSaga(api: CartApi) {
  // order of the forked sub-sagas doesn't matter
  // yield fork(cartFetchSaga, api);
  yield fork(cartUpdateNumBoxesSaga, api);
}
