import { useEffect, useState } from 'react';

export default function useMarkdown(markdown: string | string[], deps: any[] = []) {
  const [content, setContent] = useState<string[]>([]);

  useEffect(() => {
    const markdownArr = Array.isArray(markdown) ? markdown : [markdown];
    (async () => {
      const result = await Promise.all(markdownArr.map((i) => fetch(i)));
      const contentData = await Promise.all(result.map((i: any) => i.text()));
      setContent(contentData);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);

  return content;
}
