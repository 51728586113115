import { useEffect } from 'react';
import { useLocation } from '@reach/router';

export default function GoogleAnalytics() {
  // const { pathname, search } = useLocation();
  const location = useLocation();

  // useEffect(() => {
  //   pageView(pathname + search);
  // }, [pathname, search]);

  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
  }, [location]);

  return null;
}
