import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { OrderControllerGetByIdParameter } from '../../parameters/order-controller-get-by-id.parameter';
import { OrderControllerGetByIdResponse } from '../../responses/order-controller-get-by-id.response';

interface OrderControllerGetByIdOpenApiParameters {
  parameters: OrderControllerGetByIdParameter;
}

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/order/{orderId}',
  operationId: 'OrderController_getById',
  parameters: [
    {
      name: 'orderId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class OrderControllerGetByIdMethod
  extends BaseOpenApiMethod<OrderControllerGetByIdResponse>
  implements Method<OrderControllerGetByIdResponse, OrderControllerGetByIdOpenApiParameters>
{
  public call(parameters: OrderControllerGetByIdOpenApiParameters): Promise<OrderControllerGetByIdResponse> {
    return super.call(parameters);
  }
}
