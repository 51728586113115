/**
 * Markdown
 */
const animalDayEn = `

  Especially in the 90s, fur-covered coats, feathers, exotic leathers, etc. were considered as a
  luxury symbol. Even if nowadays some countries and brands ban the use of animal material
  for the production of clothing, the exploitation and torture of animals in the fashion industry
  unfortunately remains a harsh reality. On October 4th, World Animal Day, we want to
  remember that fashion does not have to be cruel...
  
  ## THE HISTORY- WORLD ANIMAL PROTECTION DAY
  
  Francis of Assisi - founder of the order of the Franciscans and patron saint of animals - saw
  all animals, no matter whether large or small, as God-willed and worthy of protection. Thus,
  the origin of the animal protection movement started already quite some time ago, around
  1226. Since 1931, the world animal protection day has been celebrated also on an
  international level, on 4th of October, the memorial day of the holy Franz of Assisi. Since
  then, animal protection organizations use this day to draw attention to mistreatment of
  animals because some animals are still not kept species-appropriate, which means that they
  do not get medical care and enough space, as well as are fed unhealthy. This also includes
  pets ([Bundesministerium für Ernährung und Landwirtschaft](https://www.tierwohl-staerken.de/aktuelles/news-details/am-4-oktober-ist-welttierschutztag), 2022).
  
  ## ANIMAL ABUSE IN THE FASHION INDUSTRY
  
  Billions of animals are sacrificed each year for the fashion industry. These include mink,
  foxes, wolves, raccoons, bobcats, opossums, beavers, otters and other fur-bearing animals
  ([Ecolookbook](https://ecolookbook.de/nachhaltigkeit/tierschutz/), 2022). The vast majority of them suffer the measures of factory farming and
  are killed for skin, wool, feathers, or fur. In cramped and filthy spaces, they are robbed of
  their freedom and tortured for life. Live-skinning, anal electrocution, gassing... - the list of
  cruel acts is endless. The worst thing about it is that these killing methods are not based on
  the protection of the animal. Instead, the main priority is the quality of the fur, which must be
  preserved for sale. In China alone, up to 70 million animals are killed annually for the fur
  industry. Of these, over half is imported for the European market ([Tierleid in der Mode.
  Geschichte und Herstellung von Pelz](https://www.grin.com/document/314890#:~:text=3.-,Herkunft%20und%20Herstellung%20von%20Pelz,den%20europ%C3%A4ischen%20Markt%20importiert%20werden.), 2015).
  
  ## LEATHER
  
  Often there are no markings on the leather, so you never know exactly which species was
  used and from which country it comes. Mostly the leather comes from developing countries
  because there are no animal welfare laws. Cows, goats, sheep and pigs, but also exotic
  animals such as kangaroos, alligators or ostriches are sacrificed for the production of
  leather. In China, cats and dogs are also slaughtered and exported worldwide ([Ecolookbook](https://ecolookbook.de/nachhaltigkeit/tierschutz/),
  2022).
  
  ## FUR
  
  Used for clothing in the form of fur pom-poms, fur collars or as accessories on jackets and
  scarves - every last bit of fur involves animal cruelty. In many breeding farms, fur animals are
  kept in tiny wire mesh cages. This includes foxes, minks, raccoons, lynxes, but also typical
  pets such as hamsters, dogs and cats ([Peta Schweiz](https://www.peta-schweiz.ch/pelz), 2022). The problem is that many EU
  countries do not specify additional regulations for fur farming. However, fortunately, more
  and more countries are choosing to introduce stricter national laws or ban fur farms
  altogether ([Vier Pfoten](https://www.vier-pfoten.de/kampagnen-themen/themen/mode-und-tierschutz/pelz-industrie/die-wahrheit-ueber-pelz), 2022).
  
  ## WOOL
  
  Sheep and goats are often abused to obtain cashmere and mohair. In fact, shearers are
  usually not paid by the number of hours, but by the amount of wool, which is why the sheep
  is injured by the fast and negligent shearing. And not to forget: The "shearling" trend. Many
  consumers think that this is sheared wool, but unfortunately this is a mistake: it is the sheep
  skin that is used with the wool. Unfortunately, it takes dozens of sheepskins to make a single
  shearling piece such as a jacket, boots or home accessories.
  
  ## HOW CAN I MAKE MY CONTRIBUTION TO WORLD ANIMAL PROTECTION DAY REGARDING FASHION?
  
  It is always good to consume more consciously and really question whether you want to
  contribute to animal suffering and, by the way, to environmental pollution too with your
  purchase of real fur. If your answer is "no", you should check for trusted brands to see if they
  make animal-friendly products.
  
  After all, thanks to advancements in textiles, vegan materials are nearly indistinguishable
  from animal substances and are actually far superior to them. The most well-known vegan
  fabrics are probably polyester and cotton. The same applies to bamboo, hemp or other
  plant-based materials: They boom in our modern times! Extraction from cork, mushrooms,
  fruit waste or recycled plastic is also becoming more and more established as an alternative
  to animal leather. A counterpart to one of the noblest textile materials, the natural fiber silk,
  also exists: Plant based silk, which is made from by-products of food waste, such as
  soybeans. In fact, for one animal kilo of silk, about 6,600 caterpillars are killed. So, the
  vegetable silks are animal-friendly and additionally biodegradable and long-lasting
  ([Ecolookbook](https://ecolookbook.de/nachhaltigkeit/tierschutz/), 2022).
  
  Alternatives abound these days, which is why there is always a way to avoid animal suffering
  and still dress fashionably. If you're wondering which brands to shop for, take a look at our
  top 8 animal-friendly brands from every taste and size of the wallet.
  
  ## TOP 8 MOST ANIMAL FRIENDLY COMPANIES
  
  ### 1. STELLA MCCARTNEY
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/d6603996-e757-41d7-b69c-0b573dfe0a8f.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/fb3ba3b2-d742-436b-bfda-7eb7e8995b82.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/462624ac-1656-4e90-840d-f0789d6cb4d0.jpg)
  
  **PHOTOS** [@stellamccartney](https://www.instagram.com/stellamccartney/)\n
  **WEBSITE:** [https://www.stellamccartney.com/de/de](https://www.stellamccartney.com/de/de/)
  
  The anomalous luxury brand Stella McCartney boycotts the use of animal leather, feathers
  or fur and none of its adhesives contain animal by-products. The vegetarian brand also
  advocates against animal testing and toxic materials that can harm the environment, such as
  azo dyes. Furthermore, when shipping the clothes, handbags and shoes, the brand
  emphasizes sustainable packaging materials that come from FSC-certified wood and paper.
  
  ### 2. SAVE THE DUCK
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/d650dfd6-f68a-4a48-a207-bf4ddad8dc16.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/ff408e63-8bf8-4936-b264-602ab4e0d74c.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/f3839b86-52df-4677-995e-211a63fff797.jpg)
  
  **PHOTOS** [@save_the_duck](https://www.instagram.com/save_the_duck/)\n
  **WEBSITE:** [https://www.savetheduck.it/ce_en](https://www.savetheduck.it/ce_en/)
  
  An innovative Italian fashion brand, Save The Duck has turned it into their mission to design
  jackets that are both fashionable and animal-friendly. As the brand name suggests, they do
  not use animal materials for their products. This saves animals, such as geese and ducks.
  
  ### 3. FLAMINGOS LIFE
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/c6232560-b7d9-45cc-9791-3bf410c37cad.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/c7af2b54-5cea-4fc6-a59c-b636b80dfd5b.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/1d65e9ba-97d7-46dd-b615-ab9716d9882e.jpg)
  
  **PHOTOS** [@flamingoslife](https://www.instagram.com/flamingoslife/)\n
  **WEBSITE:** [https://www.en.flamingoslife.com](https://www.en.flamingoslife.com/)
  
  The Spanish shoe company is committed to animal rights, the environment and sustainable
  business. Therefore, sustainable materials are used in the production of the casual
  sneakers. They are made of 100% recycled polyester from already used PET bottles, which
  are fished from the sea and recycled. Furthermore, no animal components such as leather
  are used, which is why the brand has been awarded the "PETA approved Vegan" certificate.
  
  ### 4. ICEBREAKER
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/736f1892-9ad7-4b32-a552-14c2729c5456.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/0162d8f3-5855-4513-8566-95dcbc0140e9.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/0a93dbfa-097d-4dbc-a040-f032c8e38f1c.jpg)
  
  **PHOTOS** [@icebreakernz](https://www.instagram.com/icebreakernz/)\n
  **WEBSITE:** [https://www.icebreaker.com/de-ch/home](https://www.icebreaker.com/de-ch/home)
  
  Merino wool - this is what the New Zealand label Icebreaker is known for. The animals are
  not harmed in the process of extracting the wool, and they are well supplied with food, drinks
  and, if necessary, medicine. In terms of sustainability, all suppliers must have an
  environmental plan. Here you'll find purpose-built products that meet the demands of an
  active lifestyle.
  
  ### 5. MARA HOFFMAN
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/0e06888c-586c-4135-8d02-a677d2cce692.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/45683939.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/96170377-8da4-4f47-8b21-c9f48aacd22c.jpg)
  
  **PHOTOS** [@marahoffman](https://www.instagram.com/marahoffman/)\n
  **WEBSITE:** [https://marahoffman.com](https://marahoffman.com/)
  
  The goal of the New York fashion label Mara Hoffman is clearly defined: To produce street
  style garments that reduce environmental impact. To raise environmental awareness, the
  brand works with organizations such as Earth Matter, Fabscrap, Fashion Positive and Queen
  of Raw. The careful production also includes that neither furs and leather nor sheep wool
  and feathers are used.
  
  ### 6. NIKIN
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/887263284911271.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/89246895.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/1329087923.jpg)
  
  **PHOTOS** [@nikinclothing](https://www.instagram.com/nikinclothing/)\n
  **WEBSITE:** [https://www.nikinclothing.com](https://www.nikinclothing.com)
  
  You can find sustainable and animal-friendly fashion for everyday life at the Swiss company
  Nikin. Here, vegan materials are used and leather and wool are left out. For buttons or
  zippers, the label uses recycled materials such as organic cotton, linen, sugar cane, cork
  oak or bamboo.
  
  ### 7. WILDLING SHOES
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/868698032341.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/8937493.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/c4c5ca8c-acd0-4ef3-8fb8-5f02b8d57ff7.jpg)
  
  **PHOTOS** [@wildling.shoes](https://www.instagram.com/wildling.shoes/)\n
  **WEBSITE:** [https://www.wildling.shoes](https://www.wildling.shoes/)
  
  Wildling Shoes is a German brand that has developed a special minimal shoe concept and
  can offer something for every taste. The company pays attention to sustainable supply
  chains, so the shoes are handmade in Portugal. Furthermore, almost all shoes are vegan
  and certified natural materials like (organic) cotton, linen, hemp, cork or woolwalk are used
  as an alternative to plastic or leather.
  
  ### 8. MELINA BUCHER
  
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/3868c462-8203-4ae8-b5c6-397bc6b42c5a.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/23038204.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Animal-Day/881f0de1-54e8-4115-8810-38134cc5885f.jpg)
  
  **PHOTOS** [@bucherbags](https://www.instagram.com/bucherbags/)\n
  **WEBSITE:** [https://melinabucher.com](https://melinabucher.com/)
  
  Auch Melina Buchers Mission setzt ein klares Statement: Die Modewelt zu einem
  ethischeren und nachhaltigeren Ort machen - und das gelingt mit ihren veganen Designer
  Handtaschen. Dabei wird jede Tasche in einer ausgewählten europäischen Manufakturen
  handgefertigt. Wichtig sind dem Label außerdem noch zukunftsweisende Materialien und
  nachvollziehbare Lieferketten.

`;

export default animalDayEn;
