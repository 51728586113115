import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { MailerControllerInviteRequestBody } from '../../request-bodies/mailer-controller-invite.request-body';

interface MailerControllerInviteOpenApiParameters {
  requestBody: MailerControllerInviteRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.POST,
  apiName: 'customer',
  path: '/mailer/invite',
  operationId: 'MailerController_invite',
  parameters: [],
})
export class MailerControllerInviteMethod
  extends BaseOpenApiMethod<{ success: boolean; message: string }>
  implements Method<{ success: boolean; message: string }, MailerControllerInviteOpenApiParameters>
{
  public call(parameters: MailerControllerInviteOpenApiParameters): Promise<{ success: boolean; message: string }> {
    return super.call(parameters);
  }
}
