import React, { ElementType, useState } from 'react';
import { Box, Button, CircularProgress, IconButton, makeStyles, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { clearCart, clearVoucherCart, deleteItem } from '../../store/actions';
import formatPriceCent from '../../../utils/format-price-cent';
import { sendEvent } from '../../../utils/google-analytics';
import { removePayPalGuard } from '../../../utils/remove-paypal-guard';
import { Product, Repair } from '../../../types';
import { AmountType } from '../../interfaces/item';
import cookies from 'js-cookie';
import { selectCartItems } from 'Cart/store/selectors';

const useStyles = makeStyles((theme) => {
  const s = (x) => theme.spacing(x);
  return {
    repair: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginBottom: s(1.5),
    },
    attribute: {
      paddingLeft: s(2),
      textIndent: -s(2),
    },
    filesButton: {
      textDecoration: 'none!important',
      padding: '0',
    },
  };
});

interface CartRepairProps {
  component?: ElementType;
  disableRemove?: boolean;
  repair: Repair;
  product: Product;
  repairIndex: number;
}

/**
 * Cart garment repairs list item component.
 */
export default function CartRepair({ component = 'div', disableRemove = false, repair, product, repairIndex }: CartRepairProps) {
  const dispatch = useDispatch();
  const items = useSelector(selectCartItems);
  const classes = useStyles();
  const langCookie = cookies.get('language') || '';
  const { t } = useTranslation();
  const { _id: garmentId, name, priceCent, input, inputValue, inputComment, images } = product;
  const [loading, setLoading] = useState(false);
  const htmlId = `CartRepair_${garmentId}-${!langCookie ? name.de : name.en}`;
  const priceHtmlId = `${htmlId}-price`;

  async function handleDelete() {
    setLoading(true);

    if (items.length === 0) {
      dispatch(clearVoucherCart());
      dispatch(clearCart());
    }

    try {
      // await api.cart.deleteItem(_id);
      setLoading(false);
      dispatch(deleteItem(repair.product.itemId || '', repairIndex));
    } catch (err) {
      setLoading(false);
      if (process.env.NODE_ENV === 'development') {
        console.error(err);
      }
      // TODO: handle error
    } finally {
      //@ts-ignore
      sendEvent('cart', `remove_item-item_${name}`, name);
      removePayPalGuard();
    }
  }

  let amount = t('Amount');
  if (input && input?.type) {
    amount = input?.type.charAt(0).toUpperCase() + input?.type.slice(1).toLowerCase();
    if (amount === 'Quantity') amount = 'Amount';
  }

  return (
    <Box component={component} aria-labelledby={htmlId} className={classes.repair}>
      <Box pt={0.5} pr={1.5}>
        <Typography className={classes.attribute}>
          <span className="font-weight-600">{t('Repair')}</span>: <span id={htmlId}>{!langCookie ? name.de : name[langCookie]}</span>
        </Typography>
        {!!inputValue && input?.type !== AmountType.NO_INPUT && (
          <Typography className={classes.attribute}>
            <span className="font-weight-600">{t(amount)}</span>: {inputValue}
          </Typography>
        )}
        <Typography className={classes.attribute}>
          <span id={priceHtmlId} className="font-weight-600">
            {t('Price')}
          </span>
          : <span aria-labelledby={priceHtmlId}>{formatPriceCent(priceCent)}</span>
        </Typography>
        <Box margin={'5px 0'} />
        {(images?.length ?? 0) > 0 && (
          <div>
            <Button color="secondary" className={classes.filesButton}>
              OPTIONAL: {t('ATTACHED FILES')} ({images?.length ?? 0} / 10)
            </Button>
          </div>
        )}
        {inputComment && (
          <Typography className={classes.attribute}>
            <span className="font-weight-600">{t('Comments')}</span>: <span className="text-break">{inputComment}</span>
          </Typography>
        )}
      </Box>
      {!disableRemove && (
        <IconButton aria-label={t('Delete')} disabled={loading} onClick={handleDelete} size="small">
          {loading ? <CircularProgress color="secondary" size={20} /> : <Close color="secondary" />}
        </IconButton>
      )}
    </Box>
  );
}
