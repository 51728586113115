import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { ProductCategory } from '../../interfaces/item';
import { ReactComponent as BgWide } from './bgWide.svg';
import { ReactComponent as DefaultImage } from '../../../images/svg/default.svg';
import estimate from '../../../images/svg/estimate.svg';
import bg from './bg.svg';
import classes from './Button.module.css';
import cookies from 'js-cookie';

// styles
const useStyles = makeStyles((theme) => {
  const s = (x) => theme.spacing(x);
  return {
    labelBlock: {
      height: s(4),
      lineHeight: 1,
    },
  };
});

interface ProductCategoryStepButtonViewProps {
  name: string;
  htmlId?: string;
  imageUrl?: string;
}

function ProductCategoryStepButtonView({ name, htmlId, imageUrl }: ProductCategoryStepButtonViewProps) {
  const muiClasses = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <img src={bg} alt={name} style={{ width: '100%' }} />
      <div className={classes.contentOverlay}>
        {!!imageUrl && (
          <div className={classes.imageWrapper}>
            <img alt={name} className={classes.image} src={imageUrl} />
          </div>
        )}
        {!imageUrl && (
          <div className={classes.imageWrapper}>
            <DefaultImage className={classes.image} />
          </div>
        )}
        <Typography align="center" className={muiClasses.labelBlock} color="textPrimary" display="block" id={htmlId} variant="button">
          {t(name)}
        </Typography>
      </div>
    </>
  );
}

function ProductCategoryStepWideButtonView({ name, htmlId, imageUrl }: ProductCategoryStepButtonViewProps) {
  const { width } = useWindowDimensions();

  const muiClasses = useStyles();
  const { t } = useTranslation();
  return (
    <div>
      {width < 575 && <BgWide title={name} style={{ width: '100%' }} />}
      {width >= 575 && <img src={bg} alt={name} style={{ width: '100%' }} />}
      <div className={classes.contentOverlay}>
        {!!imageUrl && (
          <div className={classes.imageWrapper}>
            <img alt={name} className={classes.image} src={imageUrl} />
          </div>
        )}
        {!imageUrl && (
          <div className={classes.imageWrapper}>
            <DefaultImage className={classes.image} />
          </div>
        )}
        <Typography align="center" className={muiClasses.labelBlock} color="textPrimary" display="block" id={htmlId} variant="button">
          {t(name)}
        </Typography>
      </div>
    </div>
  );
}

export function ProductCategoryStepLink() {
  const { t } = useTranslation();
  return (
    <Link to="/estimate/new">
      <button className={classes.main}>
        <ProductCategoryStepWideButtonView name={t('Get estimation')} imageUrl={estimate} />
      </button>
    </Link>
  );
}

interface ProductCategoryStepButtonProps extends ProductCategory {
  onClick?(): void;
}

/**
 * Category image button component.
 */
export default function ProductCategoryStepButton({ id, name, imageUrl, onClick = () => {} }: ProductCategoryStepButtonProps) {
  const htmlId = `ProductCategoryStepButton__${id}`;
  const langCookie = cookies.get('language') || '';

  return (
    <button aria-labelledby={htmlId} className={classes.main} onClick={onClick}>
      <ProductCategoryStepButtonView name={!langCookie ? name.en : name[langCookie]} imageUrl={imageUrl} htmlId={htmlId} />
    </button>
  );
}

// NOTE the filter styling I use for the SVG is fairly new and might cause issues on some browsers
