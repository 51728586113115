import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';

@OpenApiMethod({
  method: HttpMethods.DELETE,
  apiName: 'customer',
  path: '/cart',
  operationId: 'CartController_clearCart',
  parameters: [],
})
export class CartControllerClearCartMethod extends BaseOpenApiMethod<void> implements Method<void, void> {
  public call(): Promise<void> {
    return super.call();
  }
}
