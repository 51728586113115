import React, { ElementType } from 'react';
import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import CartRepair from '../Repair';
import { getRepairsPrice } from '../../utils';
import formatPriceCent from '../../../utils/format-price-cent';
import { Product, Repair } from '../../../types';
import cookies from 'js-cookie';
import i18n from 'i18n';

const useStyles = makeStyles((theme) => {
  const s = (x) => theme.spacing(x);
  return {
    garment: {
      display: 'block', // in case of `<li>`
      '&:not(:last-child)': {
        borderBottom: '1px solid rgb(91, 188, 204, .32)',
        marginBottom: s(2.5),
        paddingBottom: s(2.5),
      },
    },
    repairsList: {
      listStyle: 'none',
      marginBottom: s(2),
      paddingLeft: s(2),
    },
  };
});

interface CartGarmentProps {
  component?: ElementType;
  disableRemove?: boolean;
  allowRepairAddition?: boolean;
  items?: Repair[];
  withoutGarmentButton?: boolean;
  withVouchers?: boolean;
}

/**
 * Cart garment (card) controller component.
 */
export default function CartGarment({ component = 'div', disableRemove = false, allowRepairAddition = true, items, withoutGarmentButton }: CartGarmentProps) {
  const { t } = useTranslation();
  const muiClasses = useStyles();
  const buttonLabelHtmlId = 'CartGarment-addRepairButtonLabel';
  const langCookie = cookies.get('language') || '';

  return (
    <>
      {items?.map((product, idx) => (
        <Box key={idx} className={muiClasses.garment} component={component} data-garmentid={product.product._id} data-testid="CartGarment">
          <Typography variant="h2">{!langCookie ? product.product.productCategory?.title.de : product.product.productCategory?.title[langCookie]}</Typography>
          <Box mb={0.5} />
          <Typography>
            <span className="font-weight-600">{t('Type')}</span>:{' '}
            {!langCookie ? product.product.productType?.title.de : product.product.productType?.title[langCookie]}
          </Typography>
          <Box mb={1.5} />
          <ul aria-label="Repairs" className={muiClasses.repairsList}>
            {product.repairs?.map((x, i) => (
              <CartRepair key={i} repairIndex={i} component="li" disableRemove={disableRemove} repair={product} product={x} />
            ))}
          </ul>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box>
              <Box hidden>
                <Typography component="div" color="secondary" variant="h4">
                  {t('Edit')}
                </Typography>
              </Box>
            </Box>
            <Typography aria-label="Garment Price" component="div" variant="h3">
              {formatPriceCent(getRepairsPrice(product.repairs as Product[]))}
            </Typography>
          </Box>
          {allowRepairAddition ? (
            <>
              <Box mb={2} />
              <Box textAlign="center">
                {!withoutGarmentButton && (
                  <Button
                    aria-labelledby={buttonLabelHtmlId}
                    color="primary"
                    component={Link}
                    role="link"
                    to={`/product/garment/${product.product.seo?.[i18n.language]}/${product.product.itemId}`}
                    variant="contained"
                  >
                    <span id={buttonLabelHtmlId}>{t('Add another repair')}</span>
                  </Button>
                )}
              </Box>
            </>
          ) : null}
        </Box>
      ))}
    </>
  );
}
