export enum AmountType {
  NO_INPUT = 'NO_INPUT',
  QUANTITY = 'QUANTITY',
  LENGTH = 'LENGTH',
}

export default interface CartItem {
  id: string;
  garmentId: string;
  /**
   * @deprecated
   */
  categoryName: string;
  category: {
    id: string;
    name: string;
  };
  /**
   * @deprecated
   */
  typeName: string;
  type: {
    id: string;
    name: string;
  };
  /**
   * @deprecated
   */
  repairName: string;
  repair: {
    id: string;
    name: string;
  };
  priceCent: number;
  quantity?: number;
  amountType?: AmountType;
  comment?: string;
}
