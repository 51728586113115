import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import banner1 from './banner1.png';
import banner2 from './banner2.png';
import banner3 from './banner3.png';
import banner4 from './banner4.png';
import banner5 from './banner5.png';
import banner6 from './banner6.png';
import SwiperCore, { Mousewheel, Autoplay } from 'swiper';
import styles from './BannerSlider.module.css';
import { BannerSliderItem } from './BannerSliderItem';

const BannerSlider = () => {
  const images = [banner6, banner5, banner4, banner3, banner2, banner1];

  SwiperCore.use([Mousewheel, Autoplay]);

  return (
    <Swiper
      loop
      autoplay={{
        delay: 9000,
      }}
      slidesPerView={'auto'}
      pagination={{
        clickable: true,
      }}
      spaceBetween={150}
      centeredSlides
      className={styles.swiper}
    >
      {images.map((src) => (
        <SwiperSlide key={src} style={{ backgroundImage: `url(${src})` }} className={styles.slide}>
          <BannerSliderItem />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default BannerSlider;
