import React from 'react';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import ProductHeader from '../Header';
import ProductDetailedButton, { ProductDetailedButtonLink } from '../DetailedButton/DetailedButton';
import AppNotFound from '../../../components/NotFound';
import AppWhatsApp from '../../../components/WhatsApp/WhatsApp';
import { groupByType } from '../../utils';
import { sendEvent } from '../../../utils/google-analytics';
import { Product, ProductType } from '../../../types';
import RunningLine from 'components/RunningLine';
import i18n from 'i18n';

// styles
const useStyles = makeStyles(() => {
  return {
    list: {
      listStyle: 'none',
      padding: 0,
    },
  };
});

// props
interface ProductTypeStepRouteProps {
  seo: string;
}
interface ProductTypeStepProps extends RouteComponentProps<ProductTypeStepRouteProps> {
  products: Product[];
}

/**
 * Product type step component.
 */
export default function ProductTypeStep({ products, seo }: ProductTypeStepProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const headingHtmlId = 'ProductTypeStep-heading';
  const types = groupByType(products, seo ?? '');

  if (!seo || types.length === 0) {
    return <AppNotFound />;
  }

  const onProductDetailClick = (x: ProductType) => {
    //@ts-ignore
    sendEvent('repair', `item_type_selected-type_${x.title}`, x.title);
    navigate(`./${seo}/${x.seo?.[i18n.language]}`);
  };

  function renderButtons() {
    const buttons = types.map((x, i) => (
      <Box component="li" key={i} mb={2}>
        <ProductDetailedButton onClick={() => onProductDetailClick(x)} name={x.title} {...x} />
      </Box>
    ));
    buttons.push(<ProductDetailedButtonLink key={types.length} />);
    return buttons;
  }

  return (
    <Box>
      <RunningLine />
      <Container maxWidth="sm">
        <ProductHeader categoryId={seo} />
        <Box mb={1} />
        <Typography className="with-bar" id={headingHtmlId} variant="h1">
          {t('Select the item type')}
        </Typography>
        <Box mb={4} />
        <ul aria-labelledby={headingHtmlId} className={classes.list}>
          {renderButtons()}
        </ul>
        <Box mb={6} />
        <AppWhatsApp />
      </Container>
    </Box>
  );
}
