import { PlatformConfigControllerGetAllCartConstantsMethod } from '../methods/platform-config-controller/get-all-cart-constants.method';
import { PlatformConfigControllerGetAllCityConfigMethod } from '../methods/platform-config-controller/get-all-city-config.method';
import { PlatformConfigControllerGetAllPaymentMethodsMethod } from '../methods/platform-config-controller/get-all-payment-methods.method';
import { PlatformConfigControllerGetAllPlatformConfigMethod } from '../methods/platform-config-controller/get-all-platform-config.method';
import { PlatformConfigControllerGetCityConfigMethod } from '../methods/platform-config-controller/get-city-config.method';

export class PlatformConfigController {
  public readonly getAllPlatformConfig = new PlatformConfigControllerGetAllPlatformConfigMethod();
  public readonly getAllCartConstants = new PlatformConfigControllerGetAllCartConstantsMethod();
  public readonly getAllCityConfig = new PlatformConfigControllerGetAllCityConfigMethod();
  public readonly getAllPaymentMethods = new PlatformConfigControllerGetAllPaymentMethodsMethod();
  public readonly getCityConfig = new PlatformConfigControllerGetCityConfigMethod();
}
