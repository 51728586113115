import React from 'react';
import { Button, IconButton, makeStyles } from '@material-ui/core';
import { Link } from '@reach/router';
import { ReactComponent as BackIcon } from '../../images/svg/back.svg';
import { useTranslation } from 'react-i18next';

// styles
const useStyles = makeStyles((theme) => {
  const s = (x: number) => theme.spacing(x);
  return {
    icon: {
      width: s(4),
      height: s(4),
    },
    btn: {
      paddingLeft: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  };
});

// props
interface AppBackLinkProps {
  to?: string;
  isGoBackActivated?: boolean;
  onClick?: () => void;
}

/**
 * Generic "Back" icon button-link component.
 */
export default function AppBackLink({ to = '/', isGoBackActivated = false, onClick }: AppBackLinkProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  if (isGoBackActivated) {
    return (
      <Button
        disableRipple
        classes={{
          root: classes.btn,
        }}
        onClick={() => {
          onClick && onClick();
          window.history.back();
        }}
        aria-label={t('Go back')}
      >
        <BackIcon className={classes.icon} />
      </Button>
    );
  }

  return (
    <IconButton
      component={Link}
      disableRipple
      to={to}
      classes={{
        root: classes.btn,
      }}
      aria-label={t('Go back')}
    >
      <BackIcon className={classes.icon} />
    </IconButton>
  );
}
