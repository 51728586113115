import { Box } from '@material-ui/core';
import { Redirect, Router } from '@reach/router';
import AppNotFound from 'components/NotFound/NotFound';
import React from 'react';
import Voucher from '../VoucherPage/Voucher';
import VoucherDetailsStepGarment from '../VoucherDetails/VoucherDetails';
import VoucherSuccess from '../Success/VoucherSuccess';
import { useSelector } from 'react-redux';
import ProductLoading from 'Product/components/Loading';
import { selectVoucherItems } from 'Voucher/store/selectors';

// export const vouchersProduct = [
//   {
//     id: '1',
//     priceCent: 2500,
//     title: {
//       en: 'Gift Voucher',
//       de: 'Gift Voucher',
//     },
//   },
//   {
//     id: '2',
//     priceCent: 5000,
//     title: {
//       en: 'Gift Voucher',
//       de: 'Gift Voucher',
//     },
//   },
//   {
//     id: '3',
//     priceCent: 10000,
//     title: {
//       en: 'Gift Voucher',
//       de: 'Gift Voucher',
//     },
//   },
//   {
//     id: '4',
//     priceCent: 12500,
//     title: {
//       en: 'Gift Voucher',
//       de: 'Gift Voucher',
//     },
//   },
// ];

export default function VoucherMain() {
  const vouchersProduct = useSelector(selectVoucherItems);
  if (!vouchersProduct) {
    return <ProductLoading />;
  }
  return (
    <Box component={Router}>
      <Voucher path="select" voucher={vouchersProduct} />
      <Redirect from="/" to="select" noThrow />
      <Voucher path="garment" voucher={vouchersProduct} />
      <VoucherSuccess path="success/:targetId" />
      <VoucherDetailsStepGarment path=":targetId" voucher={vouchersProduct} />
      <AppNotFound default />
    </Box>
  );
}
