import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { ProfileControllerUpdateAddressParameter } from '../../parameters/profile-controller-update-address.parameter';
import { ProfileControllerUpdateAddressRequestBody } from '../../request-bodies/profile-controller-update-address.request-body';

interface ProfileControllerUpdateAddressOpenApiParameters {
  parameters: ProfileControllerUpdateAddressParameter;
  requestBody: ProfileControllerUpdateAddressRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.PATCH,
  apiName: 'customer',
  path: '/profile/address-list/{addressId}',
  operationId: 'ProfileController_updateAddress',
  parameters: [
    {
      name: 'addressId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class ProfileControllerUpdateAddressMethod extends BaseOpenApiMethod<void> implements Method<void, ProfileControllerUpdateAddressOpenApiParameters> {
  public call(parameters: ProfileControllerUpdateAddressOpenApiParameters): Promise<void> {
    return super.call(parameters);
  }
}
