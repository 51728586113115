/**
 * Markdown
 */
const weilWirDenUsedLookLiebenBodyDe = `

## Woher kommt der Begriff Denim eigentlich?

Der Begriff Denim ist eine amerikanische Abkürzung des französischen Ausdrucks „Serge de Nîmes“, übersetzt „Gewebe aus der Nîmes“. Der Grund dafür ist, dass der besonders gewebte, mit Indigo getönte Baumwollstoff zu Beginn seiner Zeit aus der französischen Stadt Nîmes stammte.

## Denim- robuster als man denkt

Denim ist einer der robustesten Stoffe, den wir in unserem Kleiderschrank finden können. Ursprünglich wurde dieser Stoff für die Arbeitshosen von Goldgräbern in den USA verwendet, da er als sehr unempfindlich und reißfest galt. Aber leider ist der Stoff heutzutage oftmals nicht mehr ganz so strapazierbar, wie er es einmal war. Deswegen ist es besonders wichtig beim Kauf Deiner neuen Lieblingsjeans darauf zu achten, dass der Stoff über eine ausreichende Dicke verfügt- so ersparst Du Dir plötzliche, unerwünschte Risse und Deine Jeans sieht länger aus wie neu!

Die Ironie dahinter ist, heute kaufen wir die Jeans auch ganz gerne im Used Look. Das heißt, wir kaufen eine Jeans, die künstlich gealtert ist und so aussieht, als hätten wir sie schon Jahre getragen. Also eine Jeans, die man für uns für einen Aufpreis schon mal beschädigt hat. Aber es können auch durch das häufige Tragen Löcher entstehen, die uns schlussendlich gar nicht stören. Das kann ziemlich stylish aussehen- eine Sichtweise, die gerade Oma oftmals nicht wirklich teilen kann. Falls Du also der Frage „Kind, kannst Du Dir nicht mal eine neue Hose kaufen?“ standhältst, kannst Du Dir wirklich coole Outfits mit Deiner Used Look Jeans zusammenwürfeln. Falls die Löcher aber doch zu groß werden oder an unpassenden Stellen (z. B. im Schritt) entstehen, muss die Jeans nicht gleich in die Tonne!

## So lässt sich Deine Lieblingsjeans reparieren

Du ahnst es schon. Deine Lieblingsjeans lässt sich wunderbar reparieren! Dazu wird meist ein möglichst passendes Stück Stoff unter das Loch gelegt und von außen festgenäht. Hierfür wird ein Faden, der gut zum Stoff passt, gewählt, um die Stelle möglichst unsichtbar zu reparieren. Das lässt sich am einfachsten mit einer Nähmaschine machen.

Alternativ kann man auch sichtbare Patches aufbügeln und festnähen. Das funktioniert besonders gut, wenn die Jeans an Stellen gerissen ist, die nicht unmittelbar an einer Naht liegen. Das Patch fungiert bei dieser Methode wie ein Pflaster und erinnert stark an unsere Kindheit, als Mama nach einem kleinen Unfall auf dem Spielplatz unsere Lieblingshose mit bunten Patches repariert hat. Patches können auch heute unsere Jeans retten und dabei gar nicht mal so schlecht aussehen. Es gibt zahlreiche Motive und Styles. Deiner Kreativität sind hier keine Grenzen gesetzt. Nur an eine Regel musst Du Dich halten. Achte darauf, dass das Patch groß genug ist, um den Riss vollständig zu bedecken.

Weiter kannst Du auch die traditionell japanische Technik, das sogenannte „Sashiko“ anwenden, mit der Du Deine Lieblingsjeans nicht nur reparierst, sondern zu einem wahren Unikat machst. Hierfür brauchst Du eine Baumwollunterlage, etwas Garn, einen Markierstift und eine Nadel. Wenn Freestyle nicht so Deins ist und Du gerne besonders geometrische Formen gestalten möchtest, kann Dir ein Stickrahmen helfen. Für diese Technik werden mehrfach Stiche in paralleler Position gesetzt, was ein weiteres Aufgehen des Risses verhindert. Meisten werden mit Hilfe dieser besonderen Stichweise geometrische Designs angefertigt, die vielmehr dekorativen Zwecken gelten als nur der reinen Reparatur. So schön wie die Reparatur aussehen kann, könnte man sich ja schon fast freuen, dass die Jeans zuvor gerissen ist.

Auch die Flicktechnik „Boro“ hat ihren Ursprung in Japan und eignet sich genau wie „Sashiko“ super zum dekorativen Beheben von Rissen. Die Technik besteht aus dem Annähen von kleinen Stoffteilen, die am beschädigten Bereich befestigt werden und so Löcher und starke Abnutzung verdecken. Im ersten Moment magst Du vielleicht denken: „Hmm ist das einfach nur die Patchwork Methode mit einem anderen Namen?“ Aber nein! Die einzelnen Stoffteile werden im Gegensatz zur Patchwork Methode, aufeinander und übereinander gelegt, statt sauber aneinander.

Jetzt kennst Du ein paar Tricks, wie Du Deine Lieblingsjeans beim nächsten Missgeschick retten kannst und sie so sogar noch schöner und einzigartiger machen kann. Aber auch falls Du Dir das alles nicht zutrauen solltest oder einfach nicht die Zeit dazu hast, ist das absolut kein Problem!

**Unser #LocalHero weiß, wie man Deine Lieblingsjeans im Nu repariert.**

[Ich brauche eine Jeans Reparatur!](/product/Hosen/Jeans)

`;

export default weilWirDenUsedLookLiebenBodyDe;
