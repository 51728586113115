import { AppController } from './controller/app-controller';
import { AssignmentController } from './controller/assignment-controller';
import { AuthController } from './controller/auth-controller';
import { CartConstantsController } from './controller/cart-constants-controller';
import { CartController } from './controller/cart-controller';
import { CustomerController } from './controller/customer-controller';
import { DiscountController } from './controller/discount-controller';
import { EstimateController } from './controller/estimate-controller';
import { HealthController } from './controller/health-controller';
import { MailerController } from './controller/mailer-controller';
import { OrderController } from './controller/order-controller';
import { OrderPaypalController } from './controller/order-paypal-controller';
import { PlatformConfigController } from './controller/platform-config-controller';
import { ProductController } from './controller/product-controller';
import { ProfileController } from './controller/profile-controller';
import { RatingController } from './controller/rating-controller';
import { SeedController } from './controller/seed-controller';
import { TailorController } from './controller/tailor-controller';
import { TransportConstantsController } from './controller/transport-constants-controller';
import { TransportController } from './controller/transport-controller';
import { WarmupController } from './controller/warmup-controller';

export class ApiService {
  public readonly appController = new AppController();
  public readonly warmupController = new WarmupController();
  public readonly assignmentController = new AssignmentController();
  public readonly authController = new AuthController();
  public readonly cartConstantsController = new CartConstantsController();
  public readonly cartController = new CartController();
  public readonly customerController = new CustomerController();
  public readonly estimateController = new EstimateController();
  public readonly mailerController = new MailerController();
  public readonly orderController = new OrderController();
  public readonly orderPaypalController = new OrderPaypalController();
  public readonly productController = new ProductController();
  public readonly tailorController = new TailorController();
  public readonly discountController = new DiscountController();
  public readonly profileController = new ProfileController();
  public readonly transportController = new TransportController();
  public readonly transportConstantsController = new TransportConstantsController();
  public readonly ratingController = new RatingController();
  public readonly seedController = new SeedController();
  public readonly healthController = new HealthController();
  public readonly platformConfigController = new PlatformConfigController();
}
const API_SERVICE = new ApiService();
export default API_SERVICE;
