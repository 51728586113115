import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { TailorControllerSetByIdParameter } from '../../parameters/tailor-controller-set-by-id.parameter';
import { TailorControllerSetByIdRequestBody } from '../../request-bodies/tailor-controller-set-by-id.request-body';
import { TailorControllerSetByIdResponse } from '../../responses/tailor-controller-set-by-id.response';

interface TailorControllerSetByIdOpenApiParameters {
  parameters: TailorControllerSetByIdParameter;
  requestBody: TailorControllerSetByIdRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.PUT,
  apiName: 'customer',
  path: '/tailor/{tailorId}',
  operationId: 'TailorController_setById',
  parameters: [
    {
      name: 'tailorId',
      required: true,
      in: OpenApiParameterType.PATH,
    },
  ],
})
export class TailorControllerSetByIdMethod
  extends BaseOpenApiMethod<TailorControllerSetByIdResponse>
  implements Method<TailorControllerSetByIdResponse, TailorControllerSetByIdOpenApiParameters>
{
  public call(parameters: TailorControllerSetByIdOpenApiParameters): Promise<TailorControllerSetByIdResponse> {
    return super.call(parameters);
  }
}
