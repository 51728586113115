import { EstimationApi, UploadedFileData } from '../interfaces/api';
import EstimationRequestPost from '../interfaces/estimationRequestPost';

const delay = (ms: number) => new Promise((r) => setTimeout(r, ms));

async function createRequest(estimationRequest: EstimationRequestPost) {
  console.log('Creating request', estimationRequest);
  await delay(1500);
  console.log('Request created');
}

async function uploadImage(clientName: string, creationTime: number, file: UploadedFileData): Promise<void> {
  await delay(1500);

  const bools = [true, false];

  const toFail = bools[Math.floor(Math.random() * bools.length)];

  if (toFail) {
    // eslint-disable-next-line no-throw-literal
    throw 'Error';
  }

  console.log('Request created');
}

async function uploadImageOrder(clientName: string, creationTime: number): Promise<void> {
  await delay(1500);

  const bools = [true, false];

  const toFail = bools[Math.floor(Math.random() * bools.length)];

  if (toFail) {
    // eslint-disable-next-line no-throw-literal
    throw 'Error';
  }

  console.log('Request created');
}
/**
 * Estimation API definitions for automated testing.
 *
 * The definitions of this API are used in automated tests as defaults.
 */
const estimationApiTest: EstimationApi = {
  createRequest,
  uploadImage,
  uploadImageOrder
};

export default estimationApiTest;

// Question: Can we use redux to store some app state to make the interactions
// seem real?
