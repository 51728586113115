import imageInitialState from './initial-state';
import {
  ADD_IMAGES,
  REMOVE_IMAGE,
  CLEAR_IMAGES,
  UPDATE_IMAGE,
  UPLOAD_IMAGES,
  MARK_FILE_UPLOAD_COMPLETED,
  MARK_FILE_UPLOAD_FAILED,
  MARK_FILE_UPLOAD_STARTED,
  MARK_ALL_UPLOADS_STARTED,
  MARK_SOME_UPLOADS_FAILED,
  MARK_ALL_UPLOADS_COMPLETED,
  CLEAR_STATE,
  MARK_SUBMIT_FAILED,
  MARK_SUBMIT_STARTED,
  RETRY_UPLOAD,
  SET_SIZE_ERROR,
  REMOVE_SIZE_ERROR,
  ADD_FIRST_NAME,
  ADD_LAST_NAME,
} from './types';

export default function estimationReducer(state = imageInitialState, action) {
  switch (action.type) {
    case ADD_IMAGES: {
      if (!state.images) {
        return state;
      }
      const { imageFiles } = action.payload;
      const images = [...state.images, ...imageFiles];
      return { ...state, images };
    }
    case REMOVE_IMAGE: {
      if (!state.images) {
        return state;
      }
      const { imageFile } = action.payload;
      const images = state.images.filter((curItem) => curItem.id !== imageFile.id);
      return { ...state, images };
    }
    case CLEAR_IMAGES: {
      const images = [];
      return { ...state, images };
    }
    case UPDATE_IMAGE: {
      if (!state.images) {
        return state;
      }
      const { imageFile } = action.payload;
      const reducedImages = state.images.filter((curItem) => curItem.id !== imageFile.id); // Remove the existing image
      const images = [...reducedImages, imageFile];
      return { ...state, images };
    }
    case MARK_ALL_UPLOADS_STARTED: {
      return { ...state, ...{ uploadState: 'UPLOADING' } };
    }
    case MARK_SOME_UPLOADS_FAILED: {
      return { ...state, ...{ uploadState: 'UPLOAD_FAILED' } };
    }
    case MARK_ALL_UPLOADS_COMPLETED: {
      return { ...state, ...{ uploadState: 'UPLOAD_COMPLETED' } };
    }
    case UPLOAD_IMAGES: {
      if (!state.images || state.uploadState !== 'NONE') {
        return state;
      }
      const images = [...state.images];
      const updatedImages = images.map((img) => {
        return { ...img, ...{ uploadStatus: 'TO_UPLOAD' } };
      });
      return { ...state, ...{ images: updatedImages } };
    }
    case ADD_FIRST_NAME: {
      const { firstName } = action.payload;
      return { ...state, firstName };
    }
    case ADD_LAST_NAME: {
      const { lastName } = action.payload;
      return { ...state, lastName };
    }
    case MARK_FILE_UPLOAD_STARTED: {
      if (!state.images) {
        return state;
      }
      const { id } = action.payload;

      const images = [...state.images];
      const updatedImages = images.map((img) => {
        if (img.id === id) {
          return { ...img, ...{ uploadStatus: 'UPLOADING' } };
        } else {
          return { ...img };
        }
      });

      return { ...state, ...{ images: updatedImages } };
    }
    case MARK_FILE_UPLOAD_COMPLETED: {
      if (!state.images) {
        return state;
      }
      const { id } = action.payload;

      const images = [...state.images];
      const updatedImages = images.map((img) => {
        if (img.id === id) {
          return { ...img, ...{ uploadStatus: 'COMPLETED' } };
        } else {
          return { ...img };
        }
      });

      return { ...state, ...{ images: updatedImages } };
    }
    case MARK_FILE_UPLOAD_FAILED: {
      if (!state.images) {
        return state;
      }
      const { id } = action.payload;

      const images = [...state.images];
      const updatedImages = images.map((img) => {
        if (img.id === id) {
          return { ...img, ...{ uploadStatus: 'FAILED' } };
        } else {
          return { ...img };
        }
      });

      return { ...state, ...{ images: updatedImages } };
    }
    case CLEAR_STATE: {
      return { ...imageInitialState };
    }
    case MARK_SUBMIT_FAILED: {
      return { ...state, ...{ submitState: 'SUBMIT_FAILED' } };
    }
    case MARK_SUBMIT_STARTED: {
      return { ...state, ...{ submitState: 'SUBMITTING' } };
    }
    case SET_SIZE_ERROR: {
      return { ...state, ...{ sizeError: [...state.sizeError, action.payload.id] } };
    }
    case REMOVE_SIZE_ERROR: {
      return { ...state, ...{ sizeError: state.sizeError.filter((val) => val !== action.payload.id) } };
    }
    case RETRY_UPLOAD: {
      if (!state.images) {
        return state;
      }
      const images = [...state.images];
      const updatedImages = images.map((img) => {
        if (img.uploadStatus === 'FAILED') {
          return { ...img, ...{ uploadStatus: 'TO_UPLOAD' } };
        } else {
          return { ...img };
        }
      });
      return { ...state, ...{ images: updatedImages } };
    }
    default:
      return state;
  }
}
