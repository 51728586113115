import { TransportControllerAssignTransportMethod } from '../methods/transport-controller/assign-transport.method';
import { TransportControllerGetOpenMethod } from '../methods/transport-controller/get-open.method';
import { TransportControllerGetStuckMethod } from '../methods/transport-controller/get-stuck.method';
import { TransportControllerGetUnscheduledMethod } from '../methods/transport-controller/get-unscheduled.method';
import { TransportControllerMarkAsDoneMethod } from '../methods/transport-controller/mark-as-done.method';
import { TransportControllerMarkAsResolvedMethod } from '../methods/transport-controller/mark-as-resolved.method';
import { TransportControllerMarkAsStuckMethod } from '../methods/transport-controller/mark-as-stuck.method';

export class TransportController {
  public readonly getUnscheduled = new TransportControllerGetUnscheduledMethod();
  public readonly getOpen = new TransportControllerGetOpenMethod();
  public readonly getStuck = new TransportControllerGetStuckMethod();
  public readonly markAsDone = new TransportControllerMarkAsDoneMethod();
  public readonly markAsStuck = new TransportControllerMarkAsStuckMethod();
  public readonly assignTransport = new TransportControllerAssignTransportMethod();
  public readonly markAsResolved = new TransportControllerMarkAsResolvedMethod();
}
