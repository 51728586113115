/**
 * Markdown
 */
const repairRebelsLaunchEventEn = `
  ## Fast fashion was yesterday, slow fashion and thus repairs are the future!
  
  Düsseldorf, August 25, 2022. We opened our doors for fashion enthusiasts and fashion
  rethinkers of the modern age. After an intensive market research and IT development phase,
  the Repair Rebels online repair booking system went live, and this was celebrated!
  
  At the launch event in our co-working space at Lorettostraße 9, the Düsseldorf fashion
  community gathered under the Repair Rebels motto "Repaired Clothes are Loved Stronger"
  to discuss the future of sustainable fashion, timeless design, and local craftsmanship.
  Long-term supporters and partners of our startup, Prof. Dr. Stefan Spinler from WHU - Otto
  Beisheim School of Management, Tobias Werthwein from the Düsseldorf Chamber of Crafts,
  and João Matias from YKK Europe showed up to wish our team a good start and highlighted
  the increasing importance of digitalization in crafts and the need for cross-industry
  collaboration.
  
  At the Panel Talk, on the topic of Globalization and Local Craftsmanship, Heiko Wunder from
  Wunderwerk Fashion, master shoemaker Rolf Rainer from Runder Tisch Reparatur, and
  Lukas Punder from CANO Shoes & Retraced were able to provide practical insights for more
  sustainability within the industry with their many years of experience. In the final panel, on
  Future Fashion Resell, Rent & Repair, Design & Brand Communication Expert Sandra
  Lai-Chun Cheung and Janice Saya of The Mintage Vintage Store, provided guests with their
  personal experiences and insights. The motto here was, wear as long as possible what you
  already have in your closet, otherwise, buy second-hand or rent your clothes, so that you
  use sustainable labels only as a last option.
  
  In between the talks, the networking of guests accompanied with live DJ music and
  vegetarian food led to exciting discussions. "This place can become the meeting point of a
  strong repair and craft community, the atmosphere today was extraordinary and you can tell
  that people here want to make a difference," as Rolf Rainer told the team.
  
  Many sustainable fashion startups, designers, and fashion experts attended the evening and
  celebrated with us. Although Repair Rebels is an online service, guests, as well as
  passersby, were able to bring their clothing repairs to the Repair Station in person that
  evening and meet our entire team. The next Repair Rebels event is already scheduled for
  October 15, in celebration of the [International Repair Day](https://openrepair.org/international-repair-day/). Stay tuned!
  
  > "The atmosphere today was extraordinary and you can tell
    that people here want to make a difference" - Rolf Rainer master shoemaker
`;

export default repairRebelsLaunchEventEn;
