import CheckoutState from '../interfaces/state';
import { Timeframe } from '../../interfaces/customer-data';

const checkoutInitialState: CheckoutState = {
  customerData: {
    contact: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    },
    pickupAddress: {
      street: '',
      zipCode: '',
    },
    deliveryAddress: {
      street: '',
      zipCode: '',
    },
    pickupTime: {
      date: null,
      timeframe: Timeframe['MORNING'],
    },
    deliveryTime: {
      date: null,
      timeframe: Timeframe['MORNING'],
    },
    flags: {
      acceptTerms: false,
      joinClub: false,
    },
  },
  customerShippingData: {
    contact: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    },
    address: {
      street: '',
      zipCode: '',
      city: '',
      country: '',
    },
    flags: {
      acceptTerms: false,
      joinClub: false,
    },
  },
};

export default checkoutInitialState;
