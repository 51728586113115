import React from 'react';
import Loading from '../../../components/Loading';

/**
 * Product loading state component.
 */
export default function ProductLoading() {
  return (
    <div data-testid="ProductLoading">
      <Loading />
    </div>
  );
}
