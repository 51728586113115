import voucherInitialState from './initial-state';
import { SET_ITEMS_VOUCHER_BY_FETCH } from './types';

export default function voucherReducer(state = voucherInitialState, action) {
  switch (action.type) {
    case SET_ITEMS_VOUCHER_BY_FETCH: {
      const { voucherItems } = action.payload;
      return { ...state, voucherItems };
    }
    default:
      return state;
  }
}
