import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, OpenApiParameterType, BaseOpenApiMethod } from '../../open-api.method';
import { OrderControllerGetPaginatedParameter } from '../../parameters/order-controller-get-paginated.parameter';
import { OrderControllerGetPaginatedResponse } from '../../responses/order-controller-get-paginated.response';

interface OrderControllerGetPaginatedOpenApiParameters {
  parameters: OrderControllerGetPaginatedParameter;
}

@OpenApiMethod({
  method: HttpMethods.GET,
  apiName: 'customer',
  path: '/order',
  operationId: 'OrderController_getPaginated',
  parameters: [
    {
      name: 'limit',
      required: false,
      in: OpenApiParameterType.QUERY,
    },
    {
      name: 'status',
      required: false,
      in: OpenApiParameterType.QUERY,
    },
    {
      name: 'statusses',
      required: false,
      in: OpenApiParameterType.QUERY,
    },
    {
      name: 'customerId',
      required: false,
      in: OpenApiParameterType.QUERY,
    },
    {
      name: 'lastEvaluatedKey',
      required: false,
      in: OpenApiParameterType.QUERY,
    },
  ],
})
export class OrderControllerGetPaginatedMethod
  extends BaseOpenApiMethod<OrderControllerGetPaginatedResponse>
  implements Method<OrderControllerGetPaginatedResponse, OrderControllerGetPaginatedOpenApiParameters>
{
  public call(parameters?: OrderControllerGetPaginatedOpenApiParameters): Promise<OrderControllerGetPaginatedResponse> {
    return super.call(parameters);
  }
}
