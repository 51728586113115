/**
 * Markdown
 */
const repairRebelsRepairEventDe = `

  Erschreckenderweise werden in unserer heutigen Welt viele Gegenstände einfach
  weggeschmissen und leider nicht repariert oder recycelt. Um dieser heranwachsenden
  Umweltkatastrophe entgegenzuwirken, fand am 15.Oktober 2022 der [Internationale Reparaturen Tag](https://openrepair.org/international-repair-day/) statt. 
  Mit über 400 offiziellen Events feierte die Welt die 24 Reparaturstunden in
  diesem Jahr unter dem Motto „Repair Everywhere“. Seit 2017 plädiert die Initiative in
  Zusammenarbeit mit der Open Repair Alliance schon dafür, die Relevanz von Reparaturen
  aller Art zu beleuchten und dadurch CO2-Emissionen einzusparen. Der Reparaturtag soll
  dazu aufrufen, jegliche Reparaturen -ganz egal ob Elektronik-, Möbel- oder
  Modereparaturen- in Angriff zu nehmen und daran zu erinnern, wie bedeutsam die lokale
  Handwerkskunst ist.
  
  Auch wir von [Repair Rebels](https://www.repair-rebels.com/) feierten diesen
  Tag natürlich mit unserer Community in unserem Düsseldorfer
  Co-Working Space auf der Lorettostraße 9. Zwischen einem Glas Wein oder Bio-Schorle
  und vegetarischen Häppchen gab es die Gelegenheit, unsere Handwerkskünstler persönlich
  zu treffen. Außerdem konnten die Gäste ihre liebgewonnen, kaputten Kleidungsstücke,
  Schuhe oder Taschen bei unserer Reparatur Annahmestelle abgeben und somit mehr über
  unseren Service und die vielfältigen Möglichkeiten der Modereparatur erfahren – denn
  Repaired Clothes are Loved Stronger!
  
  Angefangen hat das ganze Event mit einem herzlichen Willkommensgruß von der Repair
  Rebels Gründerin Dr. Monika Hauck. Repair Rebels’ Vision an diesem Tag wurde deutlicher
  denn je: Die Menschen sollen stolz darauf sein, ihre Kleidung zu reparieren. Textil- und
  Schuhreparaturen sollen, mit Hilfe der Digitalisierung der lokalen Handwerksindustrie, so
  modisch und einfach, wie der Kauf neuer Artikel sein. Und ganz besonders an diesem Tag,
  sollten Reparateure - Enthusiasten und Profis - motiviert werden, ihre Reparaturfähigkeiten
  weitergeben, um andere zum Mitmachen zu bewegen. Ein voller Erfolg, denn in den
  Gesichtern aller anwesenden Rebels spiegelte sich Vorfreude auf eine Zukunft ganz nach
  dem Motto: “Wir reparieren unsere Kleidung und reparieren damit auch ein Stück weit
  unsere Welt.”.
  
  Im Anschluss bestärkte der [Oberbürgermeister Düsseldorfs](https://www.duesseldorf.de/ob.html) 
  Dr. Stephan Keller die Repair
  Revolution mit den Worten: ”In einer Welt, wo wir dem Fast Food eine Slow Food Bewegung
  entgegengesetzt haben, ist es an der Zeit, dass wir auch dem Fast Fashion ein Slow
  Fashion entgegenbringen."
  Unterstützt wurde die Aussage seiner Worte am gleichen Tag noch durch die Tatsache, dass
  er selbst eine Reparatur für Repair Rebels in Auftrag gab, die wir natürlich sehr gerne wie
  Neue ans Rathaus zurückschicken werden!
  
  Gespannt folgte die Repair Rebels Community auch den Gedanken von Jan Matzoll von der
  Fraktion [B90/ DIE GRÜNEN NRW](https://gruene-fraktion-nrw.de/personen/jan-matzoll/), der ebenfalls
  sein Wissen mit uns teilte: “Ein Recht auf Reparatur, ein faires und transparentes
  Lieferketten Gesetz, nachhaltige Beschaffungen in allen öffentlichen Einrichtungen und eine
  echte Kreislaufwirtschaft sind der Schlüssel zur Veränderung. Politik im Land und auch im
  Bund Europa haben sich da auf dem Weg gemacht, aber wenn man jahrzehntelang in die
  falsche Richtung rennt, dann reichts eben nicht, wenn man sich gemütlich auf den Weg
  macht, sondern da müssen wir wirklich einen Schritt einlegen in Richtung Kreislaufwirtschaft,
  in Richtung Reparaturkultur”. Wahre Worte, die so mal ausgesprochen werden mussten,
  finden wir!
  
  Abgerundet wurde die Zusammenkunft mit einer Ehrung an all unsere begabten
  [Handwerkskünstler](https://www.repair-rebels.com/tailor), 
  die sich ansässig des internationalen Reparaturtag wirklich ein kräftiges
  “Dankeschön” verdient hatten.
  
  Nach einer kurzen Pause, in der sich die Gäste über den neu gewonnen Input austauschen
  konnten, ging es mit der Präsentation von Anne-Marie Morschek von
  [Brautkleidatelier DSigns](https://www.brautkleidatelier.de/) weiter. Als
  gelernte Schneiderin und studierte Modedesignerin hatte sie im Laufe zwei Jahrzehnte
  Unmengen an Wissen und Erfahrung sammeln können. Diese Kenntnisse in ihrem
  Fachbereich teilte sie mit uns an diesem Tag in Form eines kostenlosen
  Nähmaschinen-Workshops für absolute Anfänger. Ob Groß oder Klein, begeisterter
  Anfänger oder erfahrener Nähmaschinenprofi- jeder durfte sich mal an der Nähmaschine
  versuchen. Aus dem Rattern der Nähmaschinen, anregenden Vorträgen und magischer
  Atmosphäre, geprägt von entspannter Musik, guten Snacks und dem Lachen zufriedener
  Gäste, entstand schließlich eine Blaue, Orange & Schwarze Festgirlande, die wir im Namen
  des internationalen Reparaturtags stolz an unsere Wand in Loretto anbrachten!
    
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Repair-Event+by+Repair+Rebels/IMG_6186.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Repair-Event+by+Repair+Rebels/IMG_6402.jpg)
  ![](https://repair-rebels-assets.s3.eu-central-1.amazonaws.com/Blog-Posts/Repair-Event+by+Repair+Rebels/IMG_6442.jpg)

`;

export default repairRebelsRepairEventDe;
