import React, { useContext, useEffect } from 'react';
import { RouteComponentProps, useNavigate } from '@reach/router';
import Loading from '../../../components/Loading';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { UserSlice } from '../../store';
import ApiContext from '../../../api/context';
import { CustomerAuthRole } from '../../../enums';
import AppState from 'interfaces/app-state';

/**
 * Sign Out component.
 */
export default function AuthLogout(props: RouteComponentProps) {
  const api = useContext(ApiContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector(
    (state: AppState) => ({
      user: state.auth,
    }),
    shallowEqual
  );
  const isMember = user.role === CustomerAuthRole.CUSTOMER;
  const { logoutReducer } = UserSlice.actions;

  useEffect(() => {
    (async () => {
      if (!isMember) {
        navigate('/');
        return;
      }

      await api.auth.logout();
      dispatch(logoutReducer());
      navigate('/');
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMember]);

  return <Loading />;
}
