/**
 * Markdown
 */
const diesesMalMacheIchAllesRichtigBodyDe = `

## Kaschmirwolle

Jeder, der einmal einen Kaschmir Pullover getragen hat, versteht, weshalb viele die besondere Naturfaser zu den wohl angenehmsten überhaupt zählen. Kaschmir ist bekannt als eine der edelsten Wollen der Welt.

Aber warum ist das so? Die Wolle stammt von den sogenannten Kaschmirziegen, die lediglich einmal jährlich gekämmt werden können. Vor der Verwertung durchläuft die gewonnene Wolle einen aufwendigen Trennungsprozess. So erhält man nach der Sortierung eine kleine Menge Kaschmirwolle. Für Deinen Kaschmir Pullover werden im Durchschnitt vier Ziegen gebraucht. Wenn man das Ganze mit Schafwolle vergleicht, fällt schnell auf, wie wenig das eigentlich ist. Ein Schaf liefert Wolle für circa 15 Pullover.

## Kaschmir Pullover Waschen? Nicht immer nötig!

Du hast Angst, Deinen Kaschmir Pullover zu waschen? Verständlich, denn bei falscher Wäsche kann er schnell einlaufen, seine Form verlieren oder verfilzen. Halte Dich an ein paar einfache Faustregeln, dann geht beim nächsten Mal garantiert nichts mehr schief!

Vorab: Merino- und Kaschmirwolle sind sehr hochwertige Materialien, die Gerüche absorbieren und Wärme besonders gut speichern können. Ähnlich wie unser eigenes Haar möchte auch Wolle sorgsam gepflegt werden. Wie erklären wir Dir hier:

Aber bevor Du loslegst, bedenke, dass Dein Lieblingspullover eigentlich nicht so oft gewaschen werden muss. Wolle ist von Natur aus schmutzabweisend. Um diesen Schutz zu erhalten und den Pullover lange neu aussehen zu lassen, solltest Du, wenn immer möglich, auf das Waschen verzichten.  Es wird empfohlen, Deinen Pullover erst nach dem zehnten Mal Tragen zu waschen. Zwischen den Wäschen reicht es vollkommen aus, ihn durch Lüften von unerwünschten Gerüchen zu befreien- vor allem wenn keine sichtbaren Flecken zu sehen sind. Hänge Deinen Pullover dazu nach dem Tragen einfach auf einen Kleiderbügel und lasse ihn über Nacht lüften, am besten draußen an der frischen Luft.

## So gar nicht waschen ist keine Option? Dann aber bitte vorsichtig!

### 1. Handwäsche

Am schonendsten ist die Wäsche per Hand. Nutze hierzu ein spezielles Waschmittel oder nutze alternativ ein mildes Shampoo (ja genau eins das Du auch für Deine Haare verwendest). Lasse das Kleidungsstück in klarem Wasser mit dem Waschmittel ca. 30 Minuten einweichen. Achte darauf, dass die Wassertemperatur unter 30 Grad beträgt. Um zu vermeiden, dass Dein Pullover aufquillt, solltest Du ihn nach der Reinigung nicht viel länger im Wasserbad lassen. Spüle das Kleidungsstück im Anschluss mit klarem Wasser aus. Übe dabei möglichst wenig Reibung aus. Drücke es, wenn Du fertig bist, nur vorsichtig mit den Händen aus und vermeide dabei, den Pullover zu wringen oder zu verziehen.

Da wir die ursprüngliche Form Deines Pullovers erhalten möchten, solltest Du das nasse Kleidungsstück nun möglichst im Liegen trocknen lassen, zum Beispiel auf einem Handtuch. Vermeide hierbei unbedingt direkte Sonneneinstrahlung oder die Heizung- auch nicht, wenn es schnell gehen soll.

So sieht Dein Lieblingspullover auch nächstes Jahr noch aus wie neu! 

### 2. Maschinenwäsche

Neben der Wäsche von Hand kannst Du auch das Hand- oder Wollwaschprogramm der Waschmaschine bei maximal 30 Grad Celsius verwenden. Hier solltest Du auf Folgendes achten: Wir sind es gewöhnt, unsere Kleidung beim Waschvorgang auf links zu drehen. Aber unserem geliebten Kaschmirpullover gefällt das überhaupt nicht- deswegen solltest Du diesen Schritt weglassen. Wasche am besten nur in kleinen Mengen, mehr als drei bis vier Kaschmir Teile sollten nicht zusammen in einer Trommel landen. Achte zudem darauf, mit niedriger Drehzahl zu waschen (maximal 600 Umdrehungen/Min.)- und kein Schleudern bitte! Das Waschmittel sollte ein spezielles Waschmittel für Kaschmir sein und Weichspüler sollte unbedingt weggelassen werden!

## Pflegetipps

Auch die richtige Pflege nach der Wäsche trägt dazu bei, dass Dein Pullover länger hält. Hier drei weitere Tipps von uns:

Eigentlich ist das Bügeln von Kaschmirpullovern nicht wirklich nötig. Falls es aber doch einmal nötig sein sollte, kannst Du ein leichtes Baumwolltuch zwischen legen, um so direkten Kontakt zwischen dem Bügeleisen und dem Pullover zu vermeiden. Außerdem empfehlen wir Dir auf der niedrigsten Stufe zu bügeln.

Aber auch im Kleiderschrank geht die richtige Pflege weiter. Obwohl wir schnell dazu tendieren, unsere „guten“ Kleidungsstücke auf den Kleiderbügel zu hängen, ist bei Kaschmir Kleidungsstücken Vorsicht geboten. Am besten ist es, wenn man sie im Liegen lagert. Dazu kannst Du sie locker falten und darauf verzichten, weitere Kleidungsstücke darauf zu stapeln.

So wie alle Wollpullover können sich auch bei Kaschmirpullovern kleine Knötchen an den Stellen bilden, wo natürliche Reibung entsteht. Aber hier solltest Du auf keinen Fall zur Pinzette oder Schere greifen- Es gibt Hilfsmittel, die extra auf die Empfindlichkeit Deines Kaschmir Pullovers ausgerichtet sind- zum Beispiel einen Kaschmir Kamm oder Pilling Rasierer. In diese lohnt es sich wirklich zu investieren.

> "Hänge Deinen Pullover nach dem Tragen einfach auf einen Kleiderbügel und lasse ihn über Nacht lüften, am besten draußen an der frischen Luft."

`;

export default diesesMalMacheIchAllesRichtigBodyDe;
