import { Method } from '@rxap/utilities';
import { OpenApiMethod, HttpMethods, BaseOpenApiMethod } from '../../open-api.method';
import { ProfileControllerRegisterRequestBody } from '../../request-bodies/profile-controller-register.request-body';
import { ProfileControllerRegisterResponse } from '../../responses/profile-controller-register.response';

interface ProfileControllerRegisterOpenApiParameters {
  requestBody: ProfileControllerRegisterRequestBody;
}

@OpenApiMethod({
  method: HttpMethods.POST,
  apiName: 'customer',
  path: '/profile/register',
  operationId: 'ProfileController_register',
  parameters: [],
})
export class ProfileControllerRegisterMethod
  extends BaseOpenApiMethod<ProfileControllerRegisterResponse>
  implements Method<ProfileControllerRegisterResponse, ProfileControllerRegisterOpenApiParameters>
{
  public call(parameters: ProfileControllerRegisterOpenApiParameters): Promise<ProfileControllerRegisterResponse> {
    return super.call(parameters);
  }
}
