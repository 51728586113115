import { SET_ITEMS } from './types';
import { Product } from '../../types';

export function setItems(items: Product[]) {
  return {
    type: SET_ITEMS,
    payload: {
      items,
    },
  };
}
