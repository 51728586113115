/**
 * Markdown
 */
const weilWirIhrTreuBleibenWollenBodyDe = `

 Sie begleitet uns idealerweise über Jahrzehnte, geht mit uns auf aufregende Partys, Konzerte und Reisen. Jeans sind wie unsere zweite Haut. Sie leben und altern mit uns. Und egal, wie alt sie sind, sie haben immer eine zweite Chance verdient. Ja, unsere Lieblingsjeans… Hach, wenn wir sie doch ewig tragen könnten! Was sollte denn auch dagegen sprechen? Spar Dir den Stress eine Neue zu suchen. Mit ein paar kleinen Tricks und der richtigen Pflege hält Deine Jeans ewig. Im Folgenden haben wir die besten Tipps zum schonenden Waschen Deiner Jeans gesammelt.
 
 ## Lüfte Deine Jeans statt sie zu waschen
 
 Du hast es bestimmt schon geahnt. Bei 60 Grad waschen und anschließend in den Trockner schmeißen ist nicht die ideale Lösung. Also unser und auch Levi‘s Tipp: Gar nicht waschen! Und das ist gar nicht mal so eine schlechte Idee. Hierzu solltest Du aber ein paar Kleinigkeiten beachten: Lasse Deine Jeans nach dem Tragen stets lüften, um unangenehme Gerüche loszuwerden und wasche kleine Flecken einfach vorsichtig punktuell aus. Wie? Das geht ganz einfach. Du kannst die meisten Flecken leicht mit einer Zahnbürste und klarem Wasser entfernen. Falls das nicht ausreicht, probiere es doch mal mit einer leichten Spülmittel-Lösung.
 
 ## Gerüche durch Wasserdampf entfernen
 
 Falls Du nach einem langen Arbeitstag gerne mal eine ausgiebige Dusche nimmst, kannst Du Deine Hose direkt mitnehmen. Hänge sie einfach während Du duschst an einem Bügel ins Badezimmer. Der Wasserdampf, der beim Duschen entsteht, hilft unerwünschte Gerüche zu entfernen. Zudem nimmt Deine Hose direkt den Geruch Deines geliebten Duschgels auf. Danach ist die Hose wie frisch gewaschen- ganz ohne die Strapazen einer Maschinenwäsche.
 
 ## Wäsche per Hand
 
 Falls Lüften und die Dampfreinigung nicht helfen, kannst Du Deine Jeans auch mit kaltem Wasser waschen. Das ist vor allem bei unbehandeltem Raw Denim oder Hosen im knalligen Indigo sehr gut. Die Wäsche von Hand ist sehr viel schonender als eine Maschinenwäsche und die Farbe Deiner Hose wird so am wenigsten ausgewaschen. Lasse sie nach dem Waschen am besten im Liegen an der frischen Luft trocknen.
 
 ## Das solllte immer Deine letzte Wahl sein: Die Maschinenwäsche
 
 Du kannst Dich mit dem Gedanken, Deine Hose ohne die Waschmaschine zu reinigen doch noch nicht so ganz anfreunden? Das ist ab und an völlig okay! Aber dann beachte bitte folgende Dinge: Wasche Deine Jeans auf Links. Schließe den Reißverschluss und den Knopf, um Reibung am Stoff zu vermeiden. Nutze ein mildes Waschmittel und wasche sie bei der niedrigsten Temperatur- das heißt bei höchstens 30 Grad und mit nicht mehr als tausend Umdrehungen. Noch besser ist es, wenn Du Deine Lieblingsjeans im kalten Wollwaschgang wäschst. Dieser Waschgang ist besonders schonend zum Material und minimiert die Gefahr, dass Deine Hose nach dem Waschen gefühlte drei Nummern kleiner ist. Auch wenn es okay ist, seine Hose ab und zu mit der Waschmaschine zu reinigen, solltest Du vielleicht in Erwägung ziehen, sie wirklich nur zu waschen, wenn es absolut nötig ist. Da Jeans aus Denim, einem Baumwollfaserwebstoff bestehen, quellen sie bei Wasserkontakt auf und werden somit besonders empfindlich. Außerdem führt häufiges Waschen dazu, dass Deine Jeans schneller die Farbe verliert.  Wenn Deine Hose fertig gewaschen ist- Finger weg vom Trockner, lasse sie idealerweise im Liegen trocknen.
 
 Jetzt, wo der Sommer allmählich angefangen hat, kannst Du Deine Hose sogar am besten an der frischen Luft trockenen lassen. Aber auch hier solltest Du eines beachten: Vermeide starke, direkte Sonneneinstrahlung! Denn diese wirkt wie ein natürliches Bleichmittel auf Deine Hose und macht sie anfälliger für Beschädigungen. Danach solltest Du am besten auch auf das Bügeleisen verzichten.
 
 ## Last but not least- richtig Lagern
 
 Auch nach der Wäsche kannst Du aktiv dazu beitragen, dass Deine Jeans lange hält. Oft denken wir uns nichts dabei, wenn wir unsere Jeans gefaltet in den Kleiderschrank zurücklegen. Auf Dauer ist das aber nicht so gut für Deine Hose, denn sie kann an den Faltstellen ihre Farbe verändern- was wir natürlich nicht wollen. Deswegen bietet es sich hier an, die Hose auf einem Hosenbügel in Deinem Kleiderschrank unterzubringen.
 
 ## Schlusswort
 
 Je älter eine Jeans, desto schöner wird sie- aber nur, wenn Du sie richtig gepflegt hast. Also beachte unsere Tipps und trage Deine Jeans am besten Dein Leben lang! Aber wenn die lange Zeit der Liebe und des Tragens dann doch Mal Spuren hinterlassen hat, schicke sie uns zu. Unsere #LocalHeros lieben es, Jeans zu reparieren. Und vertraue uns, es ist mehr möglich, als Du denkst!
 
 > "Je älter eine Jeans, desto schöner wird sie- aber nur, wenn Du sie richtig gepflegt hast."
 
 `;

export default weilWirIhrTreuBleibenWollenBodyDe;
