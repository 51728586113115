import React from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ReactComponent as RightArrowIcon } from '../../../images/svg/right-arrow-icon.svg';
import { ReactComponent as ArcArrow } from './arc-arrow.svg';
import classes from './Sponsors.module.css';
import logos from './logos.png';
import visaLogo from './visa-logo.png';
import villageLogo from './village-capital-logo.png';

export default function HomepageSponsors() {
  const { t } = useTranslation();
  return (
    <div className="container pt-4">
      <p className={classes.paragraph}>
        {t('Contact Information - Bodytext 1')}
        <RightArrowIcon className="right-quote-arrow" />
      </p>
      <div className={classes.allLogos}>
        <div className={`h4 d-inline-block ${classes.logosHeading}`}>{t('Our Supporters')}</div>
        <div className={classes.logosImageBlock}>
          <LazyLoadImage
            className={classes.villageLogo}
            alt="Village Capital Logo"
            src={villageLogo}
            effect="blur"
            // height={image.height}
            // width={image.width}
          />
          <LazyLoadImage
            className={classes.logos}
            alt="Ignition SPECIAL Batch Vodafone_Logo"
            src={logos}
            effect="blur"
            // height={image.height}
            // width={image.width}
          />
          <LazyLoadImage
            className={classes.visaLogo}
            alt="Visa Foundation Logo"
            src={visaLogo}
            effect="blur"
            // height={image.height}
            // width={image.width}
          />
          <ArcArrow className={classes.arcArrow} title="Arc arrow" />
        </div>
      </div>
    </div>
  );
}
